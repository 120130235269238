<mtx-loading [loading]="loading">
  <div class="d-flex justify-content-center">
    <img
      image
      [src]="event?.banner_image?.source || event?.thumbnail_image?.source"
      class="img-fluid"
      [alt]="event?.title"
    />
  </div>
  <a
    *ngIf="attendee?.participant?.join_link && !event?.ended"
    [href]="attendee?.participant?.join_link"
    class="btn btn-primary w-100 mt-4 py-3 fw-bold"
    target="_blank"
  >
    الدخول للقاء
  </a>
</mtx-loading>
