import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {of, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Event, Section} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";

@Component({
  selector: "app-event-details",
  templateUrl: "./event-details.component.html",
  styleUrls: ["./event-details.component.scss"],
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faDownload = faDownload;

  activeTabId = "overview";

  loading!: boolean;

  event!: Event;
  section!: Section | null;

  constructor(
    private eventSvc: EventService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getEvent();
    this.getSection();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getEvent() {
    this.eventSvc
      .getEvent$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        this.event = event;
      });
  }

  getSection() {
    this.route.params
      .pipe(
        switchMap((params) => {
          const {event_id, chapter_id, section_id} = params;
          if (!event_id || !chapter_id || !section_id) return of(null);
          return this.eventSvc.getSection(event_id, chapter_id, section_id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((section) => {
        this.section = section;
      });
  }
}
