import {Component, HostListener, Inject, OnDestroy} from "@angular/core";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faFacebook, faInstagram, faXTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faChevronUp,
  faCircleNotch,
  faEnvelope,
  faEnvelopeOpenText,
  faLocationDot,
  faPhone,
  faPrint,
  faQuestion,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject} from "rxjs";
import {PLATFORM_SERVICE, PlatformService} from "src/app/services/platform.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  faLocationDot = faLocationDot;
  faPhone = faPhone;
  faPrint = faPrint;
  faEnvelope = faEnvelope;
  faQuestion = faQuestion;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faChevronUp = faChevronUp;
  faCircleNotch = faCircleNotch;
  faCheck = faCheck;
  faTriangleExclamation = faTriangleExclamation;

  links: {title: string; href: string}[] = [
    {title: "نشرة التربية الرقمية", href: "https://abegs.store/redirect/categories/1581627417"},
    {title: "التقارير و الوثائق", href: "https://www.abegs.org/elearningreports"},
    {title: "الدراسات والبحوث", href: "https://library.abegs.org/journal/journal_issues/index"},
    {title: "التعليم للجميع", href: "https://www.abegs.org/sdg4"},
    {title: "قناة اليوتيوب", href: "https://www.youtube.com/playlist?list=PLUu4O0oQg4FNrCOdb0EGL6oKmzhtZ_4tP"},
    {title: "منصة المهارات الحياتية", href: "https://skills.abegs.org/home"},
    {title: "الإنفوجراف التعليمي", href: "https://www.abegs.org/displayimages"},
    {title: "المرصد الخليجي للذكاء الاصطناعي في التعليم", href: "https://goaie.org"},
  ];

  socialMediaLinks: {title: string; href: string; icon: IconDefinition}[] = [
    {
      title: "youtube",
      icon: faYoutube,
      href: "https://www.youtube.com/abegschanel",
    },
    {
      title: "instagram",
      icon: faInstagram,
      href: "https://www.instagram.com/_abegsorg",
    },
    {
      title: "facebook",
      icon: faFacebook,
      href: "https://www.facebook.com/abegsorg",
    },
    {
      title: "twitter",
      icon: faXTwitter,
      href: "https://twitter.com/abegsorg",
    },
  ];

  scrollTopBtnVisible!: boolean;

  constructor(@Inject(PLATFORM_SERVICE) private platformSvc: PlatformService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get year() {
    return new Date().getFullYear();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    const SCROLL_OFFSET = 300;

    const window = this.platformSvc.getWindow();

    this.scrollTopBtnVisible = window.scrollY > SCROLL_OFFSET;
  }
}
