<mtx-loader [loading]="loading">
  <form #ngForm="ngForm" class="p-5" [formGroup]="form" (ngSubmit)="save()">
    <div class="d-flex justify-content-between">
      <h2>{{ !user_id ? "إضافة" : "تعديل" }} مستخدم</h2>
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الاسم الأول</mat-label>
            <input formControlName="first_name" type="text" matInput placeholder="الاسم الأول" />
            <mat-error *ngIf="first_name.invalid">{{ first_name.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الاسم الأوسط</mat-label>
            <input formControlName="middle_name" type="text" matInput placeholder="الاسم الأوسط" />
            <mat-error *ngIf="middle_name.invalid">{{ middle_name.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الاسم الأخير</mat-label>
            <input formControlName="last_name" type="text" matInput placeholder="الاسم الأخير" />
            <mat-error *ngIf="last_name.invalid">{{ last_name.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الاسم الأول بالإنجليزية</mat-label>
            <input formControlName="first_name_en" type="text" matInput placeholder="الاسم الأول بالإنجليزية" />
            <mat-error *ngIf="first_name_en.invalid">{{ first_name_en.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الاسم الأوسط بالإنجليزية</mat-label>
            <input formControlName="middle_name_en" type="text" matInput placeholder="الاسم الأوسط بالإنجليزية" />
            <mat-error *ngIf="middle_name_en.invalid">{{ middle_name_en.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الاسم الأخير بالإنجليزية</mat-label>
            <input formControlName="last_name_en" type="text" matInput placeholder="الاسم الأخير بالإنجليزية" />
            <mat-error *ngIf="last_name_en.invalid">{{ last_name_en.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>البريد الإلكتروني</mat-label>
            <input formControlName="email" type="email" matInput placeholder="البريد الإلكتروني" />
            <mat-error *ngIf="email.invalid">{{ email.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <app-phone-input
            formControlName="phone"
            [placeholder]="'رقم الجوال'"
            [invalid]="errorStateMatcher.isErrorState(phone, ngForm)"
            [error]="phone.errors | error"
          ></app-phone-input>
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>كلمة المرور</mat-label>
            <input formControlName="password" type="password" matInput placeholder="كلمة المرور" />
            <mat-error *ngIf="password.invalid">{{ password.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الدولة</mat-label>
            <mat-select formControlName="country_id">
              <mat-option [value]="null">غير محدد</mat-option>
              <mat-option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="country_id.invalid">{{ country_id.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الصلاحية</mat-label>
            <mat-select formControlName="role">
              <mat-option *ngFor="let r of roles" [value]="r.value">
                {{ r.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="role.invalid">{{ role.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الحالة</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let s of statuses" [value]="s.value">
                {{ s.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="status.invalid">{{ status.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
