import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {DateTime} from "luxon";
import {pairwise, ReplaySubject, startWith, takeUntil} from "rxjs";
import {EventType, EventVisibility} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {CreateEventRequestBody, EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

const EventForm = v
  .object({
    title: v.string(),
    start_date: v.dateTime(),
    end_date: v.dateTime(),
    duration_in_minutes: v.number().min(0),
    type: v.enum(["course", "seminar", "workshop"]),
    visibility: v.enum(["public", "private"]),
  })
  .refine((form) => form.start_date.toMillis() < form.end_date.toMillis(), {
    path: ["end_date"],
    message: "تاريخ النهاية يجب أن يلي تاريخ البداية",
  });

@Component({
  selector: "app-dashboard-event-form",
  templateUrl: "./dashboard-event-form.component.html",
  styleUrls: ["./dashboard-event-form.component.scss"],
})
export class DashboardEventFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      title: new FormControl<string>("", {nonNullable: true}),
      start_date: new FormControl<DateTime>(DateTime.now(), {nonNullable: true}),
      end_date: new FormControl<DateTime>(DateTime.now(), {nonNullable: true}),
      duration_in_minutes: new FormControl<number>(0, {nonNullable: true}),
      type: new FormControl<EventType>("seminar", {nonNullable: true}),
      visibility: new FormControl<EventVisibility>("public", {nonNullable: true}),
    },
    makeValidators(EventForm),
  );

  types = [
    {name: "ورشة", value: "workshop"},
    {name: "دورة", value: "course"},
    {name: "ندوة", value: "seminar"},
  ];

  visibilities = [
    {name: "خاصة", value: "private"},
    {name: "عامة", value: "public"},
  ];

  loading!: boolean;

  constructor(
    private router: Router,
    private eventSvc: EventService,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.listenAndUpdateDuration();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get title() {
    return this.form.controls.title;
  }

  get start_date() {
    return this.form.controls.start_date;
  }

  get end_date() {
    return this.form.controls.end_date;
  }

  get duration_in_minutes() {
    return this.form.controls.duration_in_minutes;
  }

  get type() {
    return this.form.controls.type;
  }

  get visibility() {
    return this.form.controls.visibility;
  }

  listenAndUpdateDuration() {
    this.form.valueChanges
      .pipe(startWith<typeof this.form.value>({}), pairwise(), takeUntil(this.unsubscribe$))
      .subscribe(([previous, current]) => {
        if (this.duration_in_minutes.dirty) return;

        const {start_date: prevStartDate, end_date: prevEndDate} = previous;
        const {start_date: currentStartDate, end_date: currentEndDate} = current;

        if (!currentStartDate || !currentEndDate) return;

        if (prevStartDate && prevEndDate && prevStartDate.equals(currentStartDate) && prevEndDate.equals(currentEndDate))
          return;

        const {minutes: duration_in_minutes} = currentEndDate.diff(currentStartDate, "minutes").toObject();
        if (duration_in_minutes && duration_in_minutes > 0) {
          this.form.patchValue({duration_in_minutes: Math.ceil(duration_in_minutes)});
        }
      });
  }

  createEvent() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const body: CreateEventRequestBody = {
      title: this.title.value,
      start_date: this.start_date.value.toJSDate(),
      end_date: this.end_date.value.toJSDate(),
      duration_in_seconds: this.duration_in_minutes.value * 60,
      type: this.type.value,
      visibility: this.visibility.value,
    };

    this.loading = true;
    this.eventSvc
      .createEvent(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (event) => {
          markAllAsPristine(this.form);
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.router.navigateByUrl(`/dashboard/events/${event.id}`);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.form.dirty;
  }
}
