import {Component, OnDestroy} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ReplaySubject, takeUntil} from "rxjs";
import {AuthService} from "src/app/services/auth.service";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const ForgotPasswordForm = v.object({
  email: v.string({format: "email"}),
});

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      email: new FormControl("", {nonNullable: true}),
    },
    makeValidators(ForgotPasswordForm),
  );

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  loading!: boolean;

  success!: boolean;

  constructor(private authSvc: AuthService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get email() {
    return this.form.controls.email;
  }

  forgotPassword() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.getRawValue();

    this.loading = true;
    this.authSvc
      .forgotPassword(form)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.success = true;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
