import {Stamps} from "./helpers";
import {User} from "./user.dto";

export type ReviewAction = "like" | "dislike" | "reset";

export interface Review extends Stamps {
  user_id: string;
  user?: User;
  event_id: string;
  content: string;
  rating: number;

  likes?: number;
  dislikes?: number;

  action_taken?: ReviewAction;
  date_action_taken?: Date;
}
