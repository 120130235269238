<div *ngIf="!loading; else loader">
  <div
    *ngIf="feedback"
    class="alert"
    role="alert"
    [ngClass]="{'alert-success': feedback.success, 'alert-danger': !feedback.success}"
  >
    {{ feedback.message }}
  </div>

  <h3 class="text-primary fw-bold mt-5">تسجيل الدخول</h3>
  <p class="text-l-gray fw-light">اهلاً بالعودة،</p>

  <form #ngForm="ngForm" [formGroup]="form" class="mt-5" autocomplete="off" (ngSubmit)="login()">
    <div class="row">
      <div class="col-xl-6">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="email">البريد الإلكتروني</label>
          <input
            required
            type="email"
            class="form-control py-3 focus-shadow-none"
            id="email"
            formControlName="email"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(email, ngForm)}"
          />
          <div *ngIf="email.invalid" class="invalid-feedback">{{ email.errors | error }}</div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="password">كلمة المرور</label>
          <input
            required
            type="password"
            class="form-control py-3 focus-shadow-none"
            id="password"
            formControlName="password"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(password, ngForm)}"
          />
          <div *ngIf="password.invalid" class="invalid-feedback">{{ password.errors | error }}</div>
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-primary w-100 py-3 mt-5" type="submit">دخول</button>
      </div>
    </div>
  </form>
  <hr class="my-4" />
  <app-social-auth (login)="onSocialAuth($event)"></app-social-auth>
  <hr class="my-4" />
  <div class="d-flex justify-content-between align-items-center text-l-gray">
    <div>
      <a routerLink="/forgot-password">نسيت كلمة المرور</a>
    </div>
    <div>
      مستخدم جديد؟
      <a routerLink="/register">سجل الآن</a>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="d-flex flex-column align-items-center justify-content-center gap-5">
    <h1>يرجى الانتظار</h1>
    <div class="spinner-border text-primary" style="width: 10rem; height: 10rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
