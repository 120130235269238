import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

import {PaginatedResult, Role, SortDirection, User, UserStatus} from "../dtos";
import {queryFromJson, SILENCE_ERRORS} from "../utils";

export interface ListUsersQuery {
  filter?: {
    full_name?: string;
    email?: string;
    phone?: string;
    country_id?: string;
    email_verified?: boolean;
  };
  sort?: {
    date_created?: SortDirection;
  };
  paginate?: {
    page: number;
    limit: number;
  };
}

export interface CreateUserRequestBody {
  email: string;
  phone?: string | null;
  country_id?: string | null;
  password: string;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  role: Role;
  status: UserStatus;
}

export interface UpdateUserRequestBody {
  email: string;
  phone?: string | null;
  country_id?: string | null;
  password?: string;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  role: Role;
  status: UserStatus;
}

export interface SendMessageRequestBody {
  full_name: string;
  email: string;
  phone: string;
  address: string;
  message: string;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly V1_ENDPOINTS = {
    LIST_USERS: (query?: string) => `${environment.server.host}/api/v1/users` + (query ? `?${query}` : ""),
    CREATE_USER: () => `${environment.server.host}/api/v1/users`,
    GET_USER: (user_id: string) => `${environment.server.host}/api/v1/users/${user_id}`,
    UPDATE_USER: (user_id: string) => `${environment.server.host}/api/v1/users/${user_id}`,
    DELETE_USER: (user_id: string) => `${environment.server.host}/api/v1/users/${user_id}`,
    SEND_MESSAGE: () => `${environment.server.host}/api/v1/users/messages`,
  };

  constructor(private httpClient: HttpClient) {}

  listUsers(query?: ListUsersQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<User>>(this.V1_ENDPOINTS.LIST_USERS(_query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createUser(body: CreateUserRequestBody, silent = false) {
    return this.httpClient.post<User>(this.V1_ENDPOINTS.CREATE_USER(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getUser(user_id: string, silent = false) {
    return this.httpClient.get<User>(this.V1_ENDPOINTS.GET_USER(user_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateUser(user_id: string, body: UpdateUserRequestBody, silent = false) {
    return this.httpClient.put<User>(this.V1_ENDPOINTS.UPDATE_USER(user_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteUser(user_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_USER(user_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  sendMessage(body: SendMessageRequestBody, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.SEND_MESSAGE(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
