<mtx-loader [loading]="loading">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col" colspan="3">نموذج تقييم {{ event?.type_ar }} "{{ event?.title }}"</th>
        </tr>
      </thead>
      <tbody class="fw-light" style="vertical-align: middle">
        <tr *ngFor="let question of form?.questions ?? []; index as questionIndex">
          <td>{{ questionIndex + 1 }}. {{ question.title }}</td>
          <td class="text-end">
            <ng-container *ngIf="question.type === 'choice' && question.data.type === 'radio'">
              <div
                *ngFor="let choice of question.data.options; index as choiceIndex"
                class="form-check form-check-inline d-inline-block"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question-{{ questionIndex }}"
                  id="question-{{ questionIndex }}-choice-{{ choiceIndex }}"
                  (change)="updateResponse(question.id, choice.value)"
                  [value]="choice.value"
                  [checked]="choice.value === response.get(question.id)"
                />
                <label class="form-check-label" for="question-{{ questionIndex }}-choice-{{ choiceIndex }}">
                  {{ choice.value }}
                </label>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <button [disabled]="loading" class="btn btn-outline-secondary px-5 py-3 rounded-0" (click)="submit()">حفظ</button>
</mtx-loader>
