<app-navbar></app-navbar>
<main class="page-content">
  <mtx-loader [loading]="loading">
    <div class="text-light fw-bold bg-primary">
      <div class="container py-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
        <h5 class="my-4">{{ event?.title }}</h5>

        <div class="d-flex align-items-center gap-2">
          <h5 *ngIf="event?.enrollment_closed && !event?.ended && !attendee">تم إغلاق التسجيل</h5>
          <h5 *ngIf="event?.ended && !attendee">ال{{ event?.type_ar }} منتهية</h5>
          <button
            *ngIf="!event?.enrollment_closed && !event?.ended && !attendee"
            (click)="enroll()"
            class="btn btn-secondary px-4"
          >
            سجل الآن
          </button>
          <a *ngIf="attendee" [routerLink]="['/events', event_id, 'details']" class="btn btn-secondary px-4">
            الدخول لل{{ event?.type_ar ?? "فعالية" }}
          </a>
        </div>
      </div>
    </div>
    <main class="page-content">
      <div class="my-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 d-flex flex-column">
              <img
                image
                [src]="event?.banner_image?.source ?? event?.thumbnail_image?.source"
                class="img-fluid w-100 rounded-4 cover"
                style="height: 350px"
                [alt]="event?.title"
              />

              <button
                *ngIf="!event?.enrollment_closed && !event?.ended && !attendee"
                (click)="enroll()"
                class="btn btn-primary w-100 mt-4 py-3 fw-bold"
              >
                سجل لل{{ event?.type_ar ?? "فعالية" }}
              </button>

              <button
                *ngIf="event?.enrollment_closed && !event?.ended && !attendee"
                disabled
                class="btn btn-outline-danger w-100 mt-4 py-3 fw-bold"
              >
                تم إغلاق التسجيل
              </button>

              <button *ngIf="event?.ended && !attendee" disabled class="btn btn-outline-danger w-100 mt-4 py-3 fw-bold">
                ال{{ event?.type_ar }} منتهية
              </button>

              <a
                *ngIf="attendee"
                [routerLink]="['/events', event_id, 'details']"
                class="btn btn-primary w-100 mt-4 py-3 fw-bold"
              >
                الدخول لل{{ event?.type_ar ?? "فعالية" }}
              </a>
            </div>

            <div class="col-lg-9">
              <div class="p-4">
                <section class="mb-4">
                  <h4 class="section-title fw-bold">{{ event?.title }}</h4>
                  <p *ngIf="event?.subtitle">{{ event?.subtitle }}</p>

                  <hr class="my-3" />

                  <div class="row gap-2">
                    <div *ngIf="event?.duration_in_seconds" class="col-auto">
                      <fa-icon [icon]="faClock" class="me-2"></fa-icon>
                      إجمالي الساعات : {{ duration_in_hours }}
                    </div>

                    <div *ngIf="event?.date_updated" class="col-auto">
                      <fa-icon [icon]="faPenToSquare" class="me-2"></fa-icon>
                      آخر تحديث: {{ event?.date_updated | date: "yyyy/MM/dd" }}
                    </div>
                  </div>
                </section>

                <section *ngIf="event?.description" class="mb-4">
                  <p class="section-title fw-bold">الوصف</p>
                  <hr class="my-3" />
                  <p [innerHTML]="event?.description"></p>
                </section>

                <section *ngIf="event?.objectives" class="mb-4">
                  <p class="section-title fw-bold">أهداف ال{{ event?.type_ar }}</p>
                  <hr class="my-3" />
                  <p [innerHTML]="event?.objectives"></p>
                </section>

                <section *ngIf="event?.prerequisites" class="mb-4">
                  <p class="section-title fw-bold">متطلبات ال{{ event?.type_ar }}</p>
                  <hr class="my-3" />
                  <p [innerHTML]="event?.prerequisites"></p>
                </section>

                <section *ngIf="event?.audience" class="mb-4">
                  <p class="section-title fw-bold">المستهدفون من ال{{ event?.type_ar }}</p>
                  <hr class="my-3" />
                  <p [innerHTML]="event?.audience"></p>
                </section>

                <section *ngIf="event?.certificate_id" class="mb-4">
                  <p class="section-title fw-bold">الشهادة</p>
                  <hr class="my-3" />
                  <p>
                    احصل على الشهادة من خلال إكمال ال{{ event?.type_ar ?? "فعالية" }} {{
                      event?.evaluation_form_id ? " و التقييم" : ""
                    }}
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </mtx-loader>
</main>
<app-footer></app-footer>
