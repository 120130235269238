/* eslint-disable @typescript-eslint/ban-ts-comment */
import {Inject, Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {first} from "rxjs";
import {environment} from "src/environments/environment";

import {PLATFORM_SERVICE, PlatformService} from "./platform.service";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private running = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private window!: Window & {dataLayer?: any; gtag?: any};

  constructor(
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private router: Router,
  ) {}

  run() {
    if (this.running) return;

    this.window = this.platformSvc.getWindow();

    this.injectScript();

    this.running = true;
  }

  private injectScript() {
    this.router.events.pipe(first((event) => event instanceof NavigationEnd)).subscribe(() => {
      const script = this.window.document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.analytics.measurementId}`;
      script.async = true;

      this.window.document.head.appendChild(script);

      this.gtag("js", new Date());
      this.gtag("config", environment.analytics.measurementId);

      console.debug("[AnalyticsService] Script has been injected.");
    });
  }

  get dataLayer() {
    return (this.window["dataLayer"] ??= []);
  }

  get gtag() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-this-alias
    const self = this;
    return (this.window["gtag"] ??= function () {
      // eslint-disable-next-line prefer-rest-params
      self.dataLayer.push(arguments);
    });
  }
}
