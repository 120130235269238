import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {EventService} from "src/app/services/event.service";

@Component({
  selector: "app-dashboard-event-wizard",
  templateUrl: "./dashboard-event-wizard.component.html",
  styleUrls: ["./dashboard-event-wizard.component.scss"],
})
export class DashboardEventWizardComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  tabs: {title: string; id: string; route: string; visible: boolean}[] = [
    {
      id: "info",
      title: "البيانات الأساسية",
      route: "/dashboard/events/:event_id/info",
      visible: true,
    },
    {
      id: "settings",
      title: "الإعدادات",
      route: "/dashboard/events/:event_id/settings",
      visible: true,
    },
    {
      id: "meeting",
      title: "اللقاء",
      route: "/dashboard/events/:event_id/meeting",
      visible: false,
    },
    {
      id: "chapters",
      title: "المحتوى",
      route: "/dashboard/events/:event_id/chapters",
      visible: true,
    },
    {
      id: "documents",
      title: "الوثائق",
      route: "/dashboard/events/:event_id/documents",
      visible: true,
    },
    {
      id: "evaluation",
      title: "نموذج التقييم",
      route: "/dashboard/events/:event_id/evaluation",
      visible: true,
    },
  ];

  event_id!: string;

  constructor(
    private route: ActivatedRoute,
    private eventSvc: EventService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.eventSvc.initDashboard();
    this.eventSvc.checkDashboardTabs();
    this.getEvent();
  }

  ngOnDestroy(): void {
    this.eventSvc.destroyDashboard();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get filteredTabs() {
    return this.tabs.filter((tab) => tab.visible);
  }

  resolveRoute(route: string) {
    return route.replace(":event_id", this.event_id.toString());
  }

  getEvent() {
    this.eventSvc
      .getDashboardTabs$()
      .pipe(
        switchMap(() => this.eventSvc.getEvent(this.event_id)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((event) => {
        const tab = this.tabs.find((tab) => tab.id === "meeting");
        if (tab) {
          tab.visible = event.has_meeting;
        }
      });
  }
}
