import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Country} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {CountryService} from "src/app/services/country.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

const CountryForm = v.object({
  name: v.string(),
  code: v.string(),
});

@Component({
  selector: "app-dashboard-country-form",
  templateUrl: "./dashboard-country-form.component.html",
  styleUrls: ["./dashboard-country-form.component.scss"],
})
export class DashboardCountryFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      name: new FormControl("", {nonNullable: true}),
      code: new FormControl("", {nonNullable: true}),
      order: new FormControl(0, {nonNullable: true}),
    },
    makeValidators(CountryForm),
  );

  loading!: boolean;

  country_id?: string;

  constructor(
    private countrySvc: CountryService,
    private toastSvc: ToastService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const country_id = this.route.snapshot.params["country_id"];
    if (country_id) {
      this.country_id = country_id;
      this.getCountry();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get name() {
    return this.form.controls.name;
  }

  get code() {
    return this.form.controls.code;
  }

  getCountry() {
    if (!this.country_id) return;

    this.loading = true;
    this.countrySvc
      .getCountry(this.country_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((country) => {
        this.patchForm(country);
        this.loading = false;
      });
  }

  patchForm(country: Country) {
    this.form.patchValue(country);
    markAllAsPristine(this.form);
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.getRawValue();

    this.loading = true;
    if (this.country_id) {
      this.countrySvc
        .updateCountry(this.country_id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (country) => {
            this.patchForm(country);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.countrySvc
        .createCountry(form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (country) => {
            markAllAsPristine(this.form);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.router.navigate(["/dashboard", "countries", country.id]);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  isDirty() {
    return this.form.dirty;
  }
}
