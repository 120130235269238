<h1 class="fw-bold py-3" mat-dialog-title class="{{ CLASSES[data?.level ?? 'danger'].header }}">
  <fa-icon [icon]="ICONS[data?.level ?? 'danger']"></fa-icon>
  {{ data?.title ?? "تأكيد" }}
</h1>
<div mat-dialog-content class="py-5" [innerHTML]="data?.message ?? 'هل أنت متأكد من المتابعة؟' | safe: 'html'"></div>
<div mat-dialog-actions class="d-flex gap-2 m-3">
  <button [mat-dialog-close]="true" class="btn fw-bold px-3 py-2" class="{{ CLASSES[data?.level ?? 'danger'].button }}">
    متابعة
  </button>
  <button mat-dialog-close class="btn px-3 py-2">إلغاء</button>
</div>
