import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faCheck,
  faEnvelope,
  faLocationDot,
  faPersonChalkboard,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {combineLatest, ReplaySubject, takeUntil} from "rxjs";
import {SwiperDirective} from "src/app/directives/swiper.directive";
import {Event, User} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";
import {EventService} from "src/app/services/event.service";
import {ReportService} from "src/app/services/report.service";
import {ToastService} from "src/app/services/toast.service";
import {UserService} from "src/app/services/user.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";
import {SwiperOptions} from "swiper/types/swiper-options";

const ContactForm = v.object({
  full_name: v.string(),
  email: v.string({format: "email"}),
  phone: v.string({format: "phone"}),
  address: v.string(),
  message: v.string(),
});

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  faCheck = faCheck;
  faArrowLeftLong = faArrowLeftLong;
  faArrowRightLong = faArrowRightLong;
  faPersonChalkboard = faPersonChalkboard;
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faLocationDot = faLocationDot;

  @ViewChild(SwiperDirective) swiper!: SwiperDirective;
  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      1400: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
    },
  };
  slides: {active: boolean; imageSrc: string; title: string; subtitle: string}[] = [
    {
      active: true,
      imageSrc: "./assets/images/landing/slider-1.png",
      title: "محتوى تدريبي متميز",
      subtitle: "تدريب فعّال في الميدان التربوي و التعليمي",
    },
    {
      active: false,
      imageSrc: "./assets/images/landing/slider-1.png",
      title: "محتوى تدريبي متميز",
      subtitle: "تدريب فعّال في الميدان التربوي و التعليمي",
    },
  ];

  currentEvents: Event[] = [];
  pastEvents: Event[] = [];

  attendees = 0;
  workshopsCourses = 0;
  seminars = 0;

  user!: User | null;

  form = new FormGroup(
    {
      full_name: new FormControl("", {nonNullable: true}),
      email: new FormControl("", {nonNullable: true}),
      phone: new FormControl("", {nonNullable: true}),
      address: new FormControl("", {nonNullable: true}),
      message: new FormControl("", {nonNullable: true}),
    },
    makeValidators(ContactForm),
  );

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  sending!: boolean;

  constructor(
    private authSvc: AuthService,
    private eventSvc: EventService,
    private reportSvc: ReportService,
    private userSvc: UserService,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.getEvents();
    this.getMetrics();
    this.getUser();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get full_name() {
    return this.form.controls.full_name;
  }

  get email() {
    return this.form.controls.email;
  }

  get phone() {
    return this.form.controls.phone;
  }

  get address() {
    return this.form.controls.address;
  }

  get message() {
    return this.form.controls.message;
  }

  next() {
    this.swiper.next();
  }

  previous() {
    this.swiper.previous();
  }

  getEvents() {
    combineLatest([
      this.eventSvc.listEvents({
        filter: {
          visibility: "public",
          status: ["published"],
          ended: true,
        },
        sort: {
          date_created: "desc",
        },
        paginate: {
          page: 1,
          limit: 12,
        },
      }),
      this.eventSvc.listEvents({
        filter: {
          visibility: "public",
          status: ["published"],
          ended: false,
        },
        sort: {
          date_created: "desc",
        },
        paginate: {
          page: 1,
          limit: 12,
        },
      }),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([pastEvents, currentEvents]) => {
        this.pastEvents = pastEvents.rows;
        this.currentEvents = currentEvents.rows;
      });
  }

  getMetrics() {
    this.reportSvc
      .getMetrics(true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((report) => {
        this.attendees = report["attendees"] ?? 0;
        this.workshopsCourses = (report["courses"] ?? 0) + (report["worksops"] ?? 0);
        this.seminars = report["seminars"] ?? 0;
      });
  }

  getUser() {
    this.authSvc
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
        if (user) {
          this.form.patchValue({
            full_name: user.full_name,
            email: user.email,
            phone: user.phone ?? "",
          });
          markAllAsPristine(this.form);
        }
      });
  }

  send() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.sending = true;

    this.userSvc
      .sendMessage(this.form.getRawValue())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.sending = false;
          this.toastSvc.success("تم إرسال الرسالة بنجاح");
        },
        error: () => {
          this.sending = false;
        },
      });
  }
}
