import {AbstractControl, FormArray, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {DateTime} from "luxon";
import phone from "phone";
import {environment} from "src/environments/environment";
import {z} from "zod";

const PASSWORD_REGEX = new RegExp(
  `^(?=(.*[a-z]){${environment.password.lowercase},})(?=(.*[A-Z]){${environment.password.uppercase},})(?=(.*[0-9]){${environment.password.digits},})(?=(.*[!@#$%^&*()\\-__+.]){${environment.password.special},}).{${environment.password.length},}$`,
);

const DAYS = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DATE_REGEX = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;
const TIME_REGEX = /^(\d\d):(\d\d):(\d\d(?:\.\d+)?)(z|([+-])(\d\d)(?::?(\d\d))?)?$/i;
const DATE_TIME_SEPARATOR_REGEX = /t|\s/i;

type StringFormat = "uuid" | "email" | "password" | "phone" | "date" | "time" | "date-time";

type StringOptions = {format: Exclude<StringFormat, "time" | "date-time">};

type TimeOptions = {format: "time" | "date-time"; strictTimeZone?: boolean};

function mapValidation(validation: Exclude<z.StringValidation, object>) {
  return {
    email: "البريد الإلكتروني",
    url: "الرابط",
    uuid: "uuid",
    cuid: "cuid",
    cuid2: "cuid2",
    ip: "ip",
    emoji: "emoji",
    ulid: "ulid",
    regex: "التعبير النمطي",
    datetime: "التاريخ والوقت",
  }[validation];
}

function mapType(type: z.ZodParsedType) {
  return {
    function: "دالة",
    number: "رقم",
    string: "سلسلة",
    nan: "مدخل غير رقمي",
    integer: "عدد صحيح",
    float: "عدد عشري",
    boolean: "قيمة منطقية",
    date: "تاريخ",
    bigint: "عدد صحيح كبير",
    undefined: "غير معرف",
    symbol: "رمز",
    null: "لا شيء",
    array: "مصفوفة",
    object: "كائن",
    unknown: "غير معروف",
    promise: "برومس",
    void: "بدون قيمة",
    never: "ابدا",
    map: "ماب",
    set: "مجموعة",
  }[type];
}

function mapCount(count: number | bigint, singular: string, dual: string, plural: string) {
  if (count === 1) return `${singular} واحد`;
  if (count === 2) return `${dual}`;
  if (count >= 3 && count <= 10) return `${count} ${plural}`;
  return `${count} ${singular}`;
}

function formatDateTime(date: Date) {
  const year = date.getFullYear().toFixed(4);
  const month = date.getFullYear().toFixed(2);
  const day = date.getFullYear().toFixed(2);

  const hours = date.getHours().toFixed(2);
  const minutes = date.getMinutes().toFixed(2);

  return `${year}/${month}/${day} ${hours}:${minutes}`;
}

export function setErrorMap() {
  z.setErrorMap((issue, ctx) => {
    let message: string;

    switch (issue.code) {
      case z.ZodIssueCode.invalid_type:
        if (issue.received === z.ZodParsedType.undefined) {
          message = "مطلوب";
        } else {
          message = `المتوقع ${mapType(issue.expected)}، المستلم ${mapType(issue.received)}`;
        }
        break;
      case z.ZodIssueCode.invalid_literal:
        message = `قيمة حرفية غير صالحة، المتوقع ${JSON.stringify(issue.expected, z.util.jsonStringifyReplacer)}`;
        break;
      case z.ZodIssueCode.unrecognized_keys:
        message = `عنصر (عناصر) غير معروف في الكائن: ${z.util.joinValues(issue.keys, ", ")}`;
        break;
      case z.ZodIssueCode.invalid_union:
        message = `مدخل غير صالح`;
        break;
      case z.ZodIssueCode.invalid_union_discriminator:
        message = `قيمة مميزة غير صالحة. المتوقع ${z.util.joinValues(issue.options)}`;
        break;
      case z.ZodIssueCode.invalid_enum_value:
        message = `قيمة تعداد غير صالحة. المتوقع ${z.util.joinValues(issue.options)}، المستلم '${issue.received}'`;
        break;
      case z.ZodIssueCode.invalid_arguments:
        message = `معاملات الدالة غير صالح`;
        break;
      case z.ZodIssueCode.invalid_return_type:
        message = `نوع إرجاع دالة غير صالح`;
        break;
      case z.ZodIssueCode.invalid_date:
        message = `تاريخ غير صالح`;
        break;
      case z.ZodIssueCode.invalid_string:
        if (typeof issue.validation === "object") {
          if ("includes" in issue.validation) {
            message = `إدخال غير صالح: يجب أن يحتوي على ${issue.validation.includes}`;

            if (typeof issue.validation.position === "number") {
              message = `${message} في موضع واحد أو أكثر أكبر من أو يساوي ${issue.validation.position}`;
            }
          } else if ("startsWith" in issue.validation) {
            message = `إدخال غير صالح: يجب أن يبدأ بـ "${issue.validation.startsWith}"`;
          } else if ("endsWith" in issue.validation) {
            message = `إدخال غير صالح: يجب أن ينتهي بـ "${issue.validation.endsWith}"`;
          } else {
            z.util.assertNever(issue.validation);
          }
        } else if (issue.validation !== "regex") {
          message = `${mapValidation(issue.validation)} غير صالح`;
        } else {
          message = "غير صالح";
        }
        break;
      case z.ZodIssueCode.too_small:
        if (issue.type === "array") {
          if (issue.exact) {
            message = `يجب أن تحتوي المصفوفة على ${mapCount(issue.minimum, "عنصر", "عنصرين", "عناصر")}`;
          } else if (issue.inclusive) {
            message = `يجب أن تحتوي المصفوفة على ${mapCount(issue.minimum, "عنصر", "عنصرين", "عناصر")} على الأقل`;
          } else {
            message = `يجب أن تحتوي المصفوفة على أكثر من ${mapCount(issue.minimum, "عنصر", "عنصرين", "عناصر")}`;
          }
        } else if (issue.type === "string") {
          if (issue.exact) {
            message = `يجب أن يحتوي الإدخال على ${mapCount(issue.minimum, "حرف", "حرفين", "أحرف")}`;
          } else if (issue.inclusive) {
            message = `يجب أن يحتوي الإدخال على ${mapCount(issue.minimum, "حرف", "حرفين", "أحرف")} على الأقل`;
          } else {
            message = `يجب أن يحتوي الإدخال على أكثر من ${mapCount(issue.minimum, "حرف", "حرفين", "أحرف")}`;
          }
        } else if (issue.type === "number") {
          if (issue.exact) {
            message = `يجب أن يكون الرقم ${issue.minimum}`;
          } else if (issue.inclusive) {
            message = `يجب أن يكون الرقم أكبر من أو يساوي ${issue.minimum}`;
          } else {
            message = `يجب أن يكون الرقم أكبر من ${issue.minimum}`;
          }
        } else if (issue.type === "date") {
          if (issue.exact) {
            message = `يجب أن يكون التاريخ ${formatDateTime(new Date(Number(issue.minimum)))}`;
          } else if (issue.inclusive) {
            message = `يجب أن يكون التاريخ أكبر من أو يساوي ${formatDateTime(new Date(Number(issue.minimum)))}`;
          } else {
            message = `يجب أن يكون التاريخ أكبر من ${formatDateTime(new Date(Number(issue.minimum)))}`;
          }
        } else message = "مدخل غير صالح";
        break;
      case z.ZodIssueCode.too_big:
        if (issue.type === "array") {
          if (issue.exact) {
            message = `يجب أن تحتوي المصفوفة على ${mapCount(issue.maximum, "عنصر", "عنصرين", "عناصر")}`;
          } else if (issue.inclusive) {
            message = `يجب أن تحتوي المصفوفة على ${mapCount(issue.maximum, "عنصر", "عنصرين", "عناصر")} كحد أقصى`;
          } else {
            message = `يجب أن تحتوي المصفوفة على أقل من ${mapCount(issue.maximum, "عنصر", "عنصرين", "عناصر")}`;
          }
        } else if (issue.type === "string") {
          if (issue.exact) {
            message = `يجب أن يحتوي الإدخال على ${mapCount(issue.maximum, "حرف", "حرفين", "أحرف")}`;
          } else if (issue.inclusive) {
            message = `يجب أن يحتوي الإدخال على ${mapCount(issue.maximum, "حرف", "حرفين", "أحرف")} كحد أقصى`;
          } else {
            message = `يجب أن يحتوي الإدخال على أقل من ${mapCount(issue.maximum, "حرف", "حرفين", "أحرف")}`;
          }
        } else if (issue.type === "number") {
          if (issue.exact) {
            message = `يجب أن يكون الرقم ${issue.maximum}`;
          } else if (issue.inclusive) {
            message = `يجب أن يكون الرقم أقل من أو يساوي ${issue.maximum}`;
          } else {
            message = `يجب أن يكون الرقم أقل من ${issue.maximum}`;
          }
        } else if (issue.type === "date") {
          if (issue.exact) {
            message = `يجب أن يكون التاريخ ${formatDateTime(new Date(Number(issue.maximum)))}`;
          } else if (issue.inclusive) {
            message = `يجب أن يكون التاريخ أصغر من أو يساوي ${formatDateTime(new Date(Number(issue.maximum)))}`;
          } else {
            message = `يجب أن يكون التاريخ أصغر من ${formatDateTime(new Date(Number(issue.maximum)))}`;
          }
        } else message = "مدخل غير صالح";
        break;
      case z.ZodIssueCode.custom:
        message = `مدخل غير صالح`;
        break;
      case z.ZodIssueCode.invalid_intersection_types:
        message = `تعذر دمج نتائج التقاطع`;
        break;
      case z.ZodIssueCode.not_multiple_of:
        message = `يجب أن يكون الرقم من مضاعفات ${issue.multipleOf}`;
        break;
      case z.ZodIssueCode.not_finite:
        message = "يجب ان يكون العدد محدود";
        break;
      default:
        message = ctx.defaultError;
        z.util.assertNever(issue);
    }

    return {message};
  });
}

function isLeapYear(year: number): boolean {
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
}

function isDate(date: string): boolean {
  const matches: string[] | null = DATE_REGEX.exec(date);
  if (!matches) return false;
  const year: number = +matches[1];
  const month: number = +matches[2];
  const day: number = +matches[3];
  return month >= 1 && month <= 12 && day >= 1 && day <= (month === 2 && isLeapYear(year) ? 29 : DAYS[month]);
}

function isTime(time: string, strictTimeZone?: boolean) {
  const matches: string[] | null = TIME_REGEX.exec(time);
  if (!matches) return false;
  const hr: number = +matches[1];
  const min: number = +matches[2];
  const sec: number = +matches[3];
  const tz: string | undefined = matches[4];
  const tzSign: number = matches[5] === "-" ? -1 : 1;
  const tzH: number = +(matches[6] || 0);
  const tzM: number = +(matches[7] || 0);
  if (tzH > 23 || tzM > 59 || (strictTimeZone && !tz)) return false;
  if (hr <= 23 && min <= 59 && sec < 60) return true;
  const utcMin = min - tzM * tzSign;
  const utcHr = hr - tzH * tzSign - (utcMin < 0 ? 1 : 0);
  return (utcHr === 23 || utcHr === -1) && (utcMin === 59 || utcMin === -1) && sec < 61;
}

function isDateTime(value: string, strictTimeZone?: boolean) {
  const dateTime: string[] = value.split(DATE_TIME_SEPARATOR_REGEX);
  return dateTime.length === 2 && isDate(dateTime[0]) && isTime(dateTime[1], strictTimeZone);
}

function validateString(options: {nullable: true} & Partial<StringOptions | TimeOptions>): z.ZodNullable<z.ZodString>;
function validateString(options?: {nullable?: false} & Partial<StringOptions | TimeOptions>): z.ZodString;
function validateString(
  options?: {nullable?: boolean} & Partial<StringOptions | TimeOptions>,
): z.ZodString | z.ZodNullable<z.ZodString> {
  const _options = options ?? {};

  let schema: z.ZodSchema = z.string().trim();
  let format: z.ZodString | z.ZodEffects<z.ZodString, string, string> = z.string();

  if (_options.format) {
    switch (_options.format) {
      case "uuid":
        format = format.uuid();
        break;
      case "email":
        format = format.email();
        break;
      case "password":
        format = format.regex(PASSWORD_REGEX, formatPasswordErrorMessage());
        break;
      case "phone":
        format = format.transform((val, ctx) => {
          const result = phone(val);
          if (!result.isValid) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "رقم الجوال غير صالح",
            });
            return z.NEVER;
          }

          return result.phoneNumber;
        });
        break;
      case "date":
        format = format.refine(isDate, "صيغة التاريخ غير صالحة");
        break;
      case "time":
        format = format.refine((arg) => isTime(arg, _options.strictTimeZone), "صيغة الوقت غير صالحة");
        break;
      case "date-time":
        format = format.refine((arg) => isDateTime(arg, _options.strictTimeZone), "صيغة التاريخ غير صالحة");
        break;
      default:
        throw new Error("unknown string format");
    }
  }

  if (_options.nullable) {
    schema = schema.pipe(format.or(z.literal("").transform(() => null))).nullable();
  } else {
    schema = _options.format ? schema.pipe(format) : (schema as z.ZodString).min(1);
  }

  return schema as z.ZodString | z.ZodNullable<z.ZodString>;
}

export function clearErrors(control: AbstractControl) {
  if (control instanceof FormControl) {
    control.setErrors(null);
    return;
  }

  if (control instanceof FormArray) {
    control.controls.forEach((control) => clearErrors(control));
    control.setErrors(null);
    return;
  }

  if (control instanceof FormGroup) {
    for (const key in control.controls) {
      const _control = control.controls[key];
      clearErrors(_control);
    }
    control.setErrors(null);
    return;
  }

  throw new Error("Unsupported control");
}

export function makeValidators(schema: z.ZodSchema): {validators: ValidatorFn} {
  const validators: ValidatorFn = function (control) {
    const result = schema.safeParse(control.getRawValue());

    clearErrors(control);

    if (result.success) return null;

    const errorMap = result.error.issues.reduce<Record<string, string>>((map, issue) => {
      const key = issue.path.join(".");
      map[key] = issue.message;
      return map;
    }, {});

    Object.keys(errorMap).forEach((key) => {
      const _control = control.get(key);
      if (_control) {
        _control.setErrors({[key]: errorMap[key]});
      }
    });

    return errorMap;
  };
  return {validators};
}

export class ShowOnDirtyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = !!form?.submitted;
    return !!(control?.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

function formatPasswordErrorMessage() {
  const components: string[] = [];

  const combine = () => (components.length > 0 ? components.join(" ") : "كلمة المرور غير صالحة");

  const specify =
    environment.password.uppercase > 0 ||
    environment.password.lowercase > 0 ||
    environment.password.digits > 0 ||
    environment.password.special > 0;

  if (environment.password.length > 0) {
    components.push(
      `يجب أن تكون كلمة المرور مكونة من ${mapCount(environment.password.length, "رمز", "رمزين", "رموز")} على الأقل`,
    );
  }

  if (!specify) {
    return combine();
  }

  if (environment.password.length > 0) {
    components.push("و تحتوي على");
  } else {
    components.push("يجب أن تحتوي كلمة المرور على");
  }

  const specifications: string[] = [];

  if (environment.password.lowercase > 0) {
    specifications.push(`${mapCount(environment.password.lowercase, "حرف صغير", "حرفين صغيرين", "أحرف صغيرة")} على الأقل`);
  }
  if (environment.password.uppercase > 0) {
    specifications.push(`${mapCount(environment.password.uppercase, "حرف كبير", "حرفين كبيرين", "أحرف كبيرة")} على الأقل`);
  }
  if (environment.password.digits > 0) {
    specifications.push(`${mapCount(environment.password.digits, "رقم", "رقمين", "أرقام")} على الأقل`);
  }
  if (environment.password.special > 0) {
    specifications.push(`${mapCount(environment.password.special, "علامة خاصة", "علامتين", "علامات خاصة")} على الأقل`);
  }

  if (specifications.length > 0) {
    components.push(specifications.join("، "));
  }

  return combine();
}

export const v = {
  string: validateString,
  nativeString: z.string,
  date: z.date,
  dateTime: () =>
    z.custom<DateTime>((arg) => {
      return arg instanceof DateTime;
    }, "صيغة التاريخ غير سليمة"),
  object: z.object,
  enum: z.enum,
  array: z.array,
  record: z.record,
  union: z.union,
  number: z.coerce.number,
  boolean: z.coerce.boolean,
  literal: z.literal,
  discriminatedUnion: z.discriminatedUnion,
  custom: z.custom,

  IssueCode: z.ZodIssueCode,
  NEVER: z.NEVER,
};
