<mtx-loader [loading]="loading">
  <h1 mat-dialog-title>إرسال رسالة مخصصة</h1>

  <div mat-dialog-content>
    <form [formGroup]="form">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>عنوان الرسالة</mat-label>
            <input matInput placeholder="عنوان الرسالة" formControlName="subject" />
            <mat-error *ngIf="form.controls.subject.invalid">{{ form.controls.subject.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>نص الرسالة</mat-label>
            <textarea matInput formControlName="content" placeholder="نص الرسالة" rows="10"></textarea>
            <mat-error *ngIf="form.controls.content.invalid">{{ form.controls.content.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <button [disabled]="form.invalid" mat-flat-button color="primary" (click)="onConfirm()">موافق</button>
    <button mat-flat-button (click)="onDismiss()">إلغاء</button>
  </div>
</mtx-loader>
