import {Component, OnDestroy, OnInit} from "@angular/core";
import {combineLatest, ReplaySubject, takeUntil} from "rxjs";
import {Attendee, Event} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";

@Component({
  selector: "app-event-landing",
  templateUrl: "./event-landing.component.html",
  styleUrls: ["./event-landing.component.scss"],
})
export class EventLandingComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  event?: Event;
  attendee?: Attendee;

  loading!: boolean;

  constructor(private eventSvc: EventService) {}

  ngOnInit(): void {
    this.loading = true;
    combineLatest([this.eventSvc.getEvent$(), this.eventSvc.getAttendee$()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([event, attendee]) => {
        this.event = event;
        this.attendee = attendee;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
