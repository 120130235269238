import {Stamps} from "./helpers";
import {User} from "./user.dto";

export type QuestionAction = "upvote" | "downvote";

export interface Question extends Stamps {
  user_id: string;
  user?: User;
  event_id: string;
  title: string;
  content: string;

  upvotes?: number;
  downvotes?: number;

  action_taken?: QuestionAction;
  date_action_taken?: Date;

  answers?: QuestionAnswer[];
}

export interface QuestionAnswer extends Stamps {
  question_id: string;
  user_id: string;
  user?: User;
  content: string;
}
