import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Attendee, Event} from "src/app/dtos";
import {AttendeeService} from "src/app/services/attendee.service";
import {ToastService} from "src/app/services/toast.service";
import {ErrorCode, errorMessages, HttpStatusCode} from "src/app/utils";

@Component({
  selector: "app-verify-certificate",
  templateUrl: "./verify-certificate.component.html",
  styleUrls: ["./verify-certificate.component.scss"],
})
export class VerifyCertificateComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  loading!: boolean;

  attendee_id!: string;
  attendee?: Attendee;
  event?: Event;

  constructor(
    private attendeeSvc: AttendeeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.attendee_id = this.route.snapshot.params["attendee_id"];
    this.verifyAttendee();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  verifyAttendee() {
    this.loading = true;
    this.attendeeSvc
      .verifyAttendee(this.attendee_id, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (attendee) => {
          this.loading = false;
          this.attendee = attendee;
          this.event = attendee.event;
        },
        error: (error: unknown) => {
          let message: string;
          if (error instanceof HttpErrorResponse) {
            const errorCode = error.error.errorCode as ErrorCode;
            const statusCode = error.error.statusCode as number;

            if (statusCode === HttpStatusCode.NOT_FOUND) {
              message = errorMessages[errorCode];
            } else {
              message = errorMessages["internal_server_error"];
            }
          } else {
            message = errorMessages["internal_server_error"];
          }
          this.toastSvc.error(message);
          this.router.navigate([""]);
        },
      });
  }
}
