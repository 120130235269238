<app-navbar></app-navbar>
<app-profile-header></app-profile-header>

<mat-drawer-container autosize>
  <mat-drawer mode="side" disableClose opened>
    <app-profile-sidebar></app-profile-sidebar>
  </mat-drawer>

  <main class="w-100 bg-white">
    <router-outlet></router-outlet>
  </main>
</mat-drawer-container>

<app-footer></app-footer>
