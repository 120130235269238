import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Observable, of, ReplaySubject, switchMap} from "rxjs";
import {environment} from "src/environments/environment";

import {Gender, User} from "../dtos";
import {ErrorCode, errorMessages, INTERCEPT, SILENCE_ERRORS} from "../utils";
import {ToastService} from "./toast.service";

export interface RegisterRequestBody {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface LoginRequestBody {
  email: string;
  password: string;
}

export interface LoginResponse {
  access_token: {
    value: string;
    expires_in: number;
  };
  refresh_token: {
    value: string;
    expires_in: number;
  };
}

export interface RefreshTokenRequestBody {
  token: string;
}

export interface RefreshTokenResponse {
  access_token: {
    value: string;
    expires_in: number;
  };
  refresh_token: {
    value: string;
    expires_in: number;
  };
}

export interface VerifyEmailRequestBody {
  token: string;
}

export interface ForgotPasswordRequestBody {
  email: string;
}

export interface ResetPasswordRequestBody {
  password: string;
  token: string;
}

export interface UpdatePasswordRequestBody {
  password: string;
}

export interface CreateProfileRequestBody {
  phone: string | null;
  country_id: string | null;

  first_name: string;
  middle_name: string;
  last_name: string;
}

export interface UpdateProfileRequestBody {
  email?: string;
  phone?: string | null;
  country_id?: string | null;

  first_name_en?: string;
  middle_name_en?: string;
  last_name_en?: string;

  photo_id?: string | null;
  gender?: Gender | null;
  birth_date?: Date | null;
  job?: string | null;
  workplace?: string | null;
  biography?: string | null;
}

export interface ExternalLoginResponse {
  access_token: {
    value: string;
    expires_in: number;
  };
  refresh_token: {
    value: string;
    expires_in: number;
  };
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly V1_ENDPOINTS = {
    REGISTER: () => `${environment.server.host}/api/v1/auth/register`,
    LOGIN: () => `${environment.server.host}/api/v1/auth/login`,
    LOGOUT: () => `${environment.server.host}/api/v1/auth/logout`,
    REFRESH_TOKEN: () => `${environment.server.host}/api/v1/auth/refresh-token`,
    VERIFY_EMAIL: () => `${environment.server.host}/api/v1/auth/verify-email`,
    FORGOT_PASSWORD: () => `${environment.server.host}/api/v1/auth/forgot-password`,
    RESET_PASSWORD: () => `${environment.server.host}/api/v1/auth/reset-password`,
    UPDATE_PASSWORD: () => `${environment.server.host}/api/v1/auth/update-password`,
    GET_PROFILE: () => `${environment.server.host}/api/v1/auth/profile`,
    CREATE_PROFILE: () => `${environment.server.host}/api/v1/auth/profile`,
    UPDATE_PROFILE: () => `${environment.server.host}/api/v1/auth/profile`,
    EXTERNAL_LOGIN: () => `${environment.server.host}/api/v1/auth/external-login`,
    DELETE_USER: () => `${environment.server.host}/api/v1/auth`,
  };

  private _token = new ReplaySubject<string | null>(1);
  private _user = new ReplaySubject<User | null>(1);

  initialized!: boolean;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
  ) {}

  init() {
    if (this.initialized) return;

    this.initialized = true;
    this.initToken();
    this.getToken()
      .pipe(
        switchMap((token) => {
          if (!token) return of(null);
          return this.getProfile();
        }),
      )
      .subscribe({
        next: (user) => {
          this.setUser(user);
        },
      });
  }

  setToken(token: string | null) {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
    this._token.next(token);
  }

  setRefreshToken(token: string | null) {
    if (token) {
      localStorage.setItem("refreshToken", token);
    } else {
      localStorage.removeItem("refreshToken");
    }
  }

  getToken(): Observable<string | null> {
    this.init();
    return this._token.asObservable();
  }

  getRefreshToken(): string | null {
    return localStorage.getItem("refreshToken");
  }

  setUser(user: User | null) {
    this._user.next(user);
  }

  getUser(): Observable<User | null> {
    this.init();
    return this._user.asObservable();
  }

  initToken() {
    const token = localStorage.getItem("token");
    this._token.next(token);
  }

  clear() {
    this.setToken(null);
    this.setRefreshToken(null);
    this.setUser(null);
  }

  translateSuccessFeedback(message: string) {
    switch (message) {
      case "google_success":
        return "تم تسجيل الدخول بنجاح";
      case "registered":
        return "تم تسجيل حسابكم بنجاح. ستصلكم رسالة علي صندوق الوارد للتحقق من بريدكم الإلكتروني و ذلك قبل تسجيل الدخول";
      case "email_verified":
        return "تم التأكد من البريد الإلكتروني و يمكنك تسجيل الدخول";
      case "password_updated":
        return "تم تغيير كلمة المرور بنجاح و يمكنكم تسجيل الدخول";
      default:
        return null;
    }
  }

  translateErrorFeedback(message: string) {
    switch (message) {
      case "google_error":
        return "حدث خطأ أثناء تسجيل الدخول برجاء المحاولة في وقت لاحق";
      case "email_verified":
        return "تم التأكد من البريد الإلكتروني و يمكنك تسجيل الدخول";
      case "invalid_token":
        return "الرابط غير سليم";
      case "token_expired":
        return "انتهت صلاحية الرابط يرجى إعادة تسجيل الدخول لإرسال الرابط مرة أخرى";
      case "internal":
      case "internal_server_error":
        return "حدث خطأ أثناء معالجة طلبكم يرجى إبلاغ إدارة التقنية في حال تكرار المشكلة";
      default:
        return errorMessages[message as ErrorCode] || null;
    }
  }

  handleFeedback() {
    const feedback = this.getFeedbback();
    if (!feedback) return;

    if (feedback.success) this.toastSvc.success(feedback.message);
    else this.toastSvc.error(feedback.message);
  }

  getFeedbback() {
    const status = this.route.snapshot.queryParamMap.get("status");
    const message = this.route.snapshot.queryParamMap.get("message");

    if (!status || !message) return null;

    if (status === "success") {
      const feedback = this.translateSuccessFeedback(message);
      return feedback ? {success: true, message: feedback} : null;
    }

    if (status === "error") {
      const feedback = this.translateErrorFeedback(message);
      return feedback ? {success: false, message: feedback} : null;
    }

    return null;
  }

  register(body: RegisterRequestBody, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.REGISTER(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  login(body: LoginRequestBody, silent = false) {
    return this.httpClient.post<LoginResponse>(this.V1_ENDPOINTS.LOGIN(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  logout(silent = true) {
    return this.httpClient.post<void>(
      this.V1_ENDPOINTS.LOGOUT(),
      {},
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  refreshToken(body: RefreshTokenRequestBody, silent = false) {
    return this.httpClient.post<RefreshTokenResponse>(this.V1_ENDPOINTS.REFRESH_TOKEN(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent).set(INTERCEPT, false),
    });
  }

  verifyEmail(body: VerifyEmailRequestBody, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.VERIFY_EMAIL(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  forgotPassword(body: ForgotPasswordRequestBody, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.FORGOT_PASSWORD(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  resetPassword(body: ResetPasswordRequestBody, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.RESET_PASSWORD(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updatePassword(body: UpdatePasswordRequestBody, silent = false) {
    return this.httpClient.put<void>(this.V1_ENDPOINTS.UPDATE_PASSWORD(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getProfile(silent = false) {
    return this.httpClient.get<User>(this.V1_ENDPOINTS.GET_PROFILE(), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createProfile(body: CreateProfileRequestBody, silent = false) {
    return this.httpClient.post<User>(this.V1_ENDPOINTS.CREATE_PROFILE(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateProfile(body: UpdateProfileRequestBody, silent = false) {
    return this.httpClient.put<User>(this.V1_ENDPOINTS.UPDATE_PROFILE(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  externalLogin(token: string, silent = false) {
    return this.httpClient.post<ExternalLoginResponse>(
      this.V1_ENDPOINTS.EXTERNAL_LOGIN(),
      {token},
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  deleteUser(silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_USER(), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
