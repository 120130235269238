import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {User} from "src/app/dtos";
import {ConfirmService} from "src/app/services/confirm.service";
import {ToastService} from "src/app/services/toast.service";
import {UserService} from "src/app/services/user.service";
import {makeValidators, v} from "src/app/utils/validations";

const FilterForm = v.object({
  full_name: v.string().optional(),
  email: v.string().optional(),
});

@Component({
  selector: "app-dashboard-users-list",
  templateUrl: "./dashboard-users-list.component.html",
  styleUrls: ["./dashboard-users-list.component.scss"],
})
export class DashboardUsersListComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ["id", "full_name", "email", "country", "status", "actions"];
  dataSource: MatTableDataSource<User> = new MatTableDataSource();

  form = new FormGroup(
    {
      full_name: new FormControl<string | undefined>(undefined, {nonNullable: true}),
      email: new FormControl<string | undefined>(undefined, {nonNullable: true}),
    },
    makeValidators(FilterForm),
  );

  loading = false;
  totalRows = 0;
  pageIndex = 0;

  constructor(
    private userSvc: UserService,
    private toastSvc: ToastService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.listUsers();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  listUsers() {
    this.loading = true;
    this.userSvc
      .listUsers({
        filter: this.form.getRawValue(),
        paginate: {
          page: this.pageIndex + 1,
          limit: 20,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.dataSource.data = result.rows;
          this.totalRows = result.totalRows;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  pageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listUsers();
  }

  search() {
    this.pageIndex = 0;
    this.listUsers();
  }

  reset() {
    this.form.reset({});
    this.search();
  }

  delete(user_id: string) {
    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          this.loading = true;
          return this.userSvc.deleteUser(user_id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.search();
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
