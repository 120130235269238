import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import {ActivatedRoute} from "@angular/router";
import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Announcement, User} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {makeValidators, v} from "src/app/utils/validations";

const AnnouncementForm = v.object({
  id: v.string({format: "uuid", nullable: true}).optional(),
  title: v.string(),
  content: v.string(),
});

@Component({
  selector: "app-event-announcements",
  templateUrl: "./event-announcements.component.html",
  styleUrls: ["./event-announcements.component.scss"],
})
export class EventAnnouncementsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faPlus = faPlus;
  faPen = faPen;
  faTrash = faTrash;

  showAnnouncementForm!: boolean;
  currentToUpdateAnnouncement: string | null = null;

  loading!: boolean;

  announcementForm = new FormGroup(
    {
      id: new FormControl(""),
      title: new FormControl("", {nonNullable: true}),
      content: new FormControl("", {nonNullable: true}),
    },
    makeValidators(AnnouncementForm),
  );

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  event_id!: string;

  user!: User | null;

  page = 0;
  totalRows!: number;
  hasNextPage!: boolean;

  announcements: Announcement[] = [];

  constructor(
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private authSvc: AuthService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getUser();
    this.getAnnouncements();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUser() {
    this.authSvc
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  getAnnouncements(push = false) {
    this.loading = true;

    if (!push) {
      this.announcements = [];
    }

    this.eventSvc
      .listAnnouncements(this.event_id, {
        paginate: {
          page: this.page + 1,
          limit: 5,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.loading = false;
          this.totalRows = result.totalRows;
          this.hasNextPage = result.hasNextPage;
          this.announcements.push(...result.rows);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  getNextPage() {
    this.page++;
    this.getAnnouncements(true);
  }

  toggleAnnouncementForm(announcementIndex?: number) {
    if (announcementIndex !== undefined) {
      this.showAnnouncementForm = false;
      const announcement = this.announcements.at(announcementIndex);
      if (announcement) {
        this.currentToUpdateAnnouncement = this.currentToUpdateAnnouncement ? null : announcement.id;
        if (this.currentToUpdateAnnouncement) {
          this.announcementForm.reset({
            id: announcement.id,
            title: announcement.title,
            content: announcement.content,
          });
        }
      }
    } else {
      this.currentToUpdateAnnouncement = null;
      this.showAnnouncementForm = !this.showAnnouncementForm;
      this.announcementForm.reset({
        id: null,
        title: "",
        content: "",
      });
    }
  }

  save(announcementIndex?: number) {
    if (this.announcementForm.invalid) {
      this.announcementForm.markAllAsTouched();
      return;
    }

    this.loading = true;
    const {id, ...form} = this.announcementForm.getRawValue();
    if (id) {
      this.eventSvc
        .updateAnnouncement(this.event_id, id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (announcement) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            if (announcementIndex !== undefined) {
              this.announcements[announcementIndex] = announcement;
            }
            this.currentToUpdateAnnouncement = null;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createAnnouncement(this.event_id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (announcement) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.showAnnouncementForm = false;
            this.announcements.unshift(announcement);
            this.totalRows++;
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  deleteAnnouncement(index: number) {
    const announcement = this.announcements.at(index);

    if (!announcement) return;

    if (!this.user || (this.user.role !== "admin" && announcement.user_id !== this.user.id)) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          return this.eventSvc.deleteAnnouncement(this.event_id, announcement.id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.toastSvc.success("تم تنفيذ الأمر بنجاح");
        this.announcements.splice(index, 1);
        this.totalRows--;
      });
  }
}
