import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {File} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";

import {FileDialogComponent, FileDialogData} from "../../shared/file-dialog/file-dialog.component";

@Component({
  selector: "app-dashboard-event-documents-form",
  templateUrl: "./dashboard-event-documents-form.component.html",
  styleUrls: ["./dashboard-event-documents-form.component.scss"],
})
export class DashboardEventDocumentsFormComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  loading!: boolean;

  event_id!: string;

  files!: File[];

  constructor(
    private toastSvc: ToastService,
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getFiles();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFiles() {
    this.loading = true;
    this.eventSvc
      .getFiles(this.event_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((files) => {
        this.files = files;
        this.loading = false;
      });
  }

  drop(event: CdkDragDrop<File>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    this.updateFiles();
  }

  openFileDialog(file_id?: string) {
    this.dialog
      .open<FileDialogComponent, FileDialogData>(FileDialogComponent, {data: {id: file_id}})
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: boolean | File | undefined) => {
        if (result === true) {
          this.getFiles();
        } else if (!result) {
          return;
        } else {
          this.createFile(result);
        }
      });
  }

  createFile(file: File) {
    this.loading = true;
    this.eventSvc
      .createFile(this.event_id, {file_id: file.id})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (files) => {
          this.files = files;
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  updateFiles() {
    this.loading = true;
    this.eventSvc
      .updateFiles(
        this.event_id,
        this.files.map((file, index) => ({id: file.id, order: index + 1})),
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (files) => {
          this.files = files;
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
