<mtx-loader [loading]="loading">
  <div class="container-fluid mt-3">
    <div class="row">
      <div *ngFor="let event of events" class="col-lg-6 col-xl-4">
        <app-card [event]="event"></app-card>
      </div>
    </div>
    <h5 *ngIf="!(events && events.length > 0)" class="p-5 d-flex justify-content-center align-items-center">
      لا توجد فعاليات
    </h5>
    <app-pagination [options]="options" (pageChange)="onPageChange($event)"></app-pagination>
  </div>
</mtx-loader>
