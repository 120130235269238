<div>
  <fa-icon
    [icon]="icon"
    class="me-2"
    [ngClass]="{
      'text-danger': data.level === 'error',
      'text-success': data.level === 'success',
      'text-info': data.level === 'info'
    }"
  ></fa-icon>
  <span>{{ data.message }}</span>
</div>
<div *ngIf="data.description" [innerHtml]="data.description"></div>
