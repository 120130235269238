import {Injectable} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

import {ConfirmDialogComponent, ConfirmDialogData} from "../components/shared/confirm-dialog/confirm-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ConfirmService {
  constructor(private dialog: MatDialog) {}

  confirm(data?: ConfirmDialogData): MatDialogRef<ConfirmDialogComponent, boolean>;
  confirm(message?: string): MatDialogRef<ConfirmDialogComponent, boolean>;
  confirm(dataOrMessage?: string | ConfirmDialogData): MatDialogRef<ConfirmDialogComponent, boolean> {
    const data = dataOrMessage ? (typeof dataOrMessage === "string" ? {message: dataOrMessage} : dataOrMessage) : {};
    return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {data});
  }
}
