<app-navbar></app-navbar>
<main class="page-content">
  <mtx-loader [loading]="loading">
    <div class="text-light fw-bold bg-primary">
      <div class="container py-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
        <h5 *ngIf="!event" class="my-4">التحقق من الشهادة</h5>
        <h5 *ngIf="event" class="my-4">التحقق من شهادة {{ event.type_ar }} {{ event.title }}</h5>

        <a *ngIf="event && event.id" [routerLink]="['/banner', event.id]" class="btn btn-secondary px-4">
          الاطلاع على ال{{ event.type_ar }}
        </a>
      </div>
    </div>
    <main class="page-content">
      <div class="my-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto">
              <div class="card rounded-0 py-4 px-4 mt-5">
                <div class="card-body">
                  <h6 class="fw-bold">الحاصل علي الشهادة</h6>
                  <div class="d-flex align-items-center gap-2">
                    <img
                      image
                      [src]="attendee?.user?.photo?.source"
                      style="width: 50px; height: 50px"
                      [alt]="attendee?.full_name"
                      class="rounded-circle cover mx-3"
                    />

                    <p class="d-inline-block text-warning mb-0">{{ attendee?.full_name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </mtx-loader>
</main>
<app-footer></app-footer>
