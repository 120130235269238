import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";
import {faCheck, faInfoCircle, faXmark} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {
  faCheck = faCheck;
  faXmark = faXmark;
  faInfoCircle = faInfoCircle;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {level: "success" | "error" | "info"; message: string; description?: string},
  ) {}

  get icon() {
    switch (this.data.level) {
      case "error":
        return faXmark;
      case "success":
        return faCheck;
      case "info":
        return faInfoCircle;
    }
  }
}
