<mtx-loader [loading]="loading">
  <div class="p-4">
    <div *ngIf="files.length > 0" class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">عنوان الوثيقة</th>
            <th scope="col" style="width: 100px">تحميل</th>
          </tr>
        </thead>
        <tbody class="fw-light" style="vertical-align: middle">
          <tr *ngFor="let file of files; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ file.filename_download }}</td>
            <td>
              <a
                [href]="file.source"
                class="btn btn-primary rounded-circle d-inline-flex align-items-center"
                style="aspect-ratio: 1/1"
              >
                <fa-icon [icon]="faDownload"></fa-icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!files || !files.length" class="text-center mt-5 bg-primary text-white py-4">لا توجد وثائق</div>
  </div>
</mtx-loader>
