<mtx-loader [loading]="loading">
  <form #ngForm="ngForm" [formGroup]="form" autocomplete="off">
    <div class="d-flex justify-content-between align-items-center mb-5">
      <h5>الوحدات</h5>
    </div>

    <mat-accordion formArrayName="chapters" cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-expansion-panel
        *ngFor="let chapter of chapters.controls; index as chapterIndex"
        [formGroup]="chapter"
        cdkDrag
        [cdkDragDisabled]="form.dirty"
        hideToggle
        #panel
      >
        <div class="drag-placeholder" *cdkDragPlaceholder></div>

        <mat-expansion-panel-header>
          <mat-panel-title>
            <button
              matTooltip="اسحب لإعادة الترتيب"
              matTooltipPosition="above"
              mat-icon-button
              matSuffix
              cdkDragHandle
              [disabled]="form.dirty"
            >
              <mat-icon>drag_handler</mat-icon>
            </button>
            <span
              *ngIf="errorStateMatcher.isErrorState(chapter, ngForm)"
              class="bg-danger rounded-circle me-2"
              style="width: 5px; height: 5px"
            ></span>
            <span [ngClass]="{'text-danger fw-bolder': errorStateMatcher.isErrorState(chapter, ngForm)}">
              الوحدة {{ chapterIndex + 1 }}
            </span>
          </mat-panel-title>
          <mat-panel-description class="d-flex justify-content-between">
            <div>{{ chapter.value.title }}</div>
            <div *ngIf="!panel.expanded">
              <a
                *ngIf="chapter.value.id"
                matTooltip="فصول الوحدة"
                matTooltipPosition="above"
                mat-icon-button
                matSuffix
                [routerLink]="['/dashboard', 'events', event_id, 'chapters', chapter.value.id]"
              >
                <mat-icon>local_library</mat-icon>
              </a>
              <button
                matTooltip="حذف الوحدة"
                matTooltipPosition="above"
                mat-icon-button
                matSuffix
                (click)="deleteChapter(chapterIndex)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>عنوان الوحدة</mat-label>
          <input matInput type="text" placeholder="عنوان الوحدة" [formControl]="chapter.controls.title" />

          <mat-error *ngIf="chapter.controls.title.invalid">{{ chapter.controls.title.errors | error }}</mat-error>
        </mat-form-field>

        <mat-card-actions class="d-flex justify-content-end">
          <a
            *ngIf="chapter.value.id"
            matTooltip="فصول الوحدة"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            [routerLink]="['/dashboard', 'events', event_id, 'chapters', chapter.value.id]"
          >
            <mat-icon>local_library</mat-icon>
          </a>
          <button
            matTooltip="حذف الوحدة"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            (click)="deleteChapter(chapterIndex)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            matTooltip="حفظ"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            (click)="saveChapter(chapter, chapterIndex)"
          >
            <mat-icon>done</mat-icon>
          </button>
        </mat-card-actions>
      </mat-expansion-panel>
    </mat-accordion>

    <button class="mt-3 w-100" mat-flat-button (click)="pushNewChapter()">
      <span>إضافة المزيد</span>
      <mat-icon>add</mat-icon>
    </button>
  </form>
</mtx-loader>
