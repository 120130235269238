import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {faCircleInfo, faWarning} from "@fortawesome/free-solid-svg-icons";

export interface ConfirmDialogData {
  title?: string;
  message?: string;
  level?: "danger" | "warning" | "info";
  width?: string;
}

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  faWarning = faWarning;
  faCircleInfo = faCircleInfo;

  CLASSES = {
    danger: {
      header: "bg-danger text-white",
      button: "btn-danger",
    },
    warning: {
      header: "bg-warning",
      button: "btn-warning",
    },
    info: {
      header: "bg-info text-white",
      button: "btn-info",
    },
  };

  ICONS = {
    danger: faWarning,
    warning: faWarning,
    info: faCircleInfo,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData | null,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {
    this.dialogRef.updateSize(data?.width ?? "400px");
  }
}
