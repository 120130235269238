import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Ticket, TicketType} from "src/app/dtos/ticket.dto";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {TicketService} from "src/app/services/ticket.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

const TicketForm = v.object({
  type: v.enum(["issue", "inquiry", "request"]),
  description: v.string(),
});

@Component({
  selector: "app-profile-ticket-form",
  templateUrl: "./profile-ticket-form.component.html",
  styleUrls: ["./profile-ticket-form.component.scss"],
})
export class ProfileTicketFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      type: new FormControl<TicketType>("issue", {nonNullable: true}),
      description: new FormControl<string>("", {nonNullable: true}),
    },
    makeValidators(TicketForm),
  );

  types = [
    {
      name: "مشكلة",
      value: "issue",
    },
    {
      name: "استفسار",
      value: "inquiry",
    },
    {
      name: "اقتراح",
      value: "request",
    },
  ];

  statusesClasses: Record<string, string> = {
    pending: "text-bg-secondary",
    "in-progress": "text-bg-primary",
    done: "text-bg-success",
  };

  loading!: boolean;

  ticket_id?: string;
  ticket?: Ticket;

  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private ticketSvc: TicketService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const ticket_id = this.route.snapshot.params["ticket_id"];
    if (ticket_id) {
      this.ticket_id = ticket_id;
      this.getTicket();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get type() {
    return this.form.controls.type;
  }

  get description() {
    return this.form.controls.description;
  }

  getTicket() {
    if (!this.ticket_id) return;

    this.loading = true;
    this.ticketSvc
      .getTicket(this.ticket_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ticket) => {
        this.ticket = ticket;
        this.loading = false;
      });
  }

  save() {
    if (this.ticket_id) return;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.getRawValue();

    this.loading = true;
    this.ticketSvc
      .createTicket(form)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (ticket) => {
          markAllAsPristine(this.form);
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.router.navigate(["/profile", "tickets", ticket.id]);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.form.dirty;
  }
}
