import {BreakpointObserver} from "@angular/cdk/layout";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faBars, faPersonChalkboard} from "@fortawesome/free-solid-svg-icons";
import {combineLatest, ReplaySubject, takeUntil} from "rxjs";
import {Attendee, Chapter, Event, Section} from "src/app/dtos";
import {AttendeeService} from "src/app/services/attendee.service";
import {EventService} from "src/app/services/event.service";
import {PLATFORM_SERVICE, PlatformService} from "src/app/services/platform.service";
import {ToastService} from "src/app/services/toast.service";

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
})
export class EventComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faPersonChalkboard = faPersonChalkboard;
  faBars = faBars;

  event_id!: string;
  event?: Event;
  attendee?: Attendee;
  chapters?: Chapter[];

  isLargeScreen!: boolean;

  loading!: boolean;

  constructor(
    private eventSvc: EventService,
    private attendeeSvc: AttendeeService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.eventSvc.init(this.event_id);
    this.getData();

    const breakpoint = "(min-width: 992px)";
    this.breakpointObserver
      .observe([breakpoint])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.isLargeScreen = result.breakpoints[breakpoint];
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.eventSvc.destory();
  }

  get badge() {
    if (!this.event || !this.attendee || !this.event.badge_id) return false;

    return true;
  }

  get certificate() {
    if (!this.event || !this.attendee || !this.event.certificate_id) return false;

    if (this.event.evaluation_form_id && !this.attendee.evaluation_form_response_id) return false;

    return this.attendee.status === "attended";
  }

  get evaluation() {
    if (!this.event || !this.attendee || !this.event.ended) return false;

    if (!this.event.evaluation_form_id) return false;

    return this.attendee.status === "attended";
  }

  downloadBadge() {
    if (!this.attendee) return;

    if (this.attendee.badge_file) {
      this.platformSvc.getWindow().open(this.attendee.badge_file.source, "_blank");
      return;
    }

    this.loading = true;
    this.attendeeSvc
      .getAttendeeBadge(this.attendee.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          if (result.file) {
            this.platformSvc.getWindow().open(result.file.source, "_blank");
          } else {
            this.toastSvc.success("جاري طباعة البادج. يرجى مراجعة الصفحة في وقت لاحق.");
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  downloadCertificate() {
    if (!this.attendee) return;

    if (this.attendee.certificate_file) {
      this.platformSvc.getWindow().open(this.attendee.certificate_file.source, "_blank");
      return;
    }

    this.loading = true;
    this.attendeeSvc
      .getAttendeeCertificate(this.attendee.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          if (result.file) {
            this.platformSvc.getWindow().open(result.file.source, "_blank");
          } else {
            this.toastSvc.success(
              "جاري طباعة الشهادة و سيصلكم إشعار عن طريق البريد الإلكتروني عند الانتهاء. كما يمكنكم الوصول إلى الشهادة عن طريق مراجعة الصفحة في وقت لاحق",
            );
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  get meeting() {
    if (!this.event || !this.attendee) return "";

    if (!this.event.meeting_id || !this.attendee.participant?.join_link) return "";

    return this.attendee.participant.join_link;
  }

  getData() {
    this.loading = true;
    combineLatest([this.eventSvc.getEvent$(), this.eventSvc.getAttendee$(), this.eventSvc.getChapters(this.event_id)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([event, attendee, chapters]) => {
        this.event = event;
        this.attendee = attendee;
        this.chapters = chapters;
        this.loading = false;
      });
  }

  markSectionAsViewed(section: Section) {
    if (section.viewed) return;
    this.eventSvc
      .viewSection(this.event_id, section.chapter_id, section.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        section.viewed = true;
      });
  }
}
