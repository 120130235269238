import {File} from "./file.dto";
import {Stamps} from "./helpers";

export interface Chapter extends Stamps {
  event_id: string;
  title: string;
  order: number;
  sections?: Section[];
}

export interface Section extends Stamps {
  chapter_id: string;
  title: string;
  description: string | null;
  content: string;
  order: number;
  files?: File[];

  viewed?: boolean;
  date_viewed?: Date;
}
