import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

import {Paginate, PaginatedResult, SortDirection} from "../dtos";
import {Ticket, TicketStatus, TicketType} from "../dtos/ticket.dto";
import {queryFromJson, SILENCE_ERRORS} from "../utils";

export interface CreateTicketRequestBody {
  type: TicketType;
  description: string;
}

export interface ListTicketsQuery {
  filter?: {
    email?: string;
    type?: TicketType;
    status?: TicketStatus;
  };
  sort?: {
    date_created?: SortDirection;
  };
  paginate?: Paginate;
}

export interface ListUserTicketsQuery {
  filter?: {
    type?: TicketType;
    status?: TicketStatus;
  };
  paginate?: Paginate;
}

export interface UpdateTicketRequestBody {
  type?: TicketType;
  description?: string;
  status?: TicketStatus;
  notes?: string | null;
}

@Injectable({
  providedIn: "root",
})
export class TicketService {
  private readonly V1_ENDPOINTS = {
    CREATE_TICKET: () => `${environment.server.host}/api/v1/tickets`,
    LIST_TICKETS: (query?: string) => `${environment.server.host}/api/v1/tickets` + (query ? `?${query}` : ""),
    LIST_USER_TICKETS: (query?: string) => `${environment.server.host}/api/v1/tickets/me` + (query ? `?${query}` : ""),
    GET_TICKET: (ticket_id: string) => `${environment.server.host}/api/v1/tickets/${ticket_id}`,
    UPDATE_TICKET: (ticket_id: string) => `${environment.server.host}/api/v1/tickets/${ticket_id}`,
    DELETE_TICKET: (ticket_id: string) => `${environment.server.host}/api/v1/tickets/${ticket_id}`,
  };

  constructor(private httpClient: HttpClient) {}

  createTicket(body: CreateTicketRequestBody, silent = false) {
    return this.httpClient.post<Ticket>(this.V1_ENDPOINTS.CREATE_TICKET(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  listTickets(query?: ListTicketsQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Ticket>>(this.V1_ENDPOINTS.LIST_TICKETS(_query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  listUserTickets(query?: ListUserTicketsQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Ticket>>(this.V1_ENDPOINTS.LIST_USER_TICKETS(_query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getTicket(ticket_id: string, silent = false) {
    return this.httpClient.get<Ticket>(this.V1_ENDPOINTS.GET_TICKET(ticket_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateTicket(ticket_id: string, body: UpdateTicketRequestBody, silent = false) {
    return this.httpClient.put<Ticket>(this.V1_ENDPOINTS.UPDATE_TICKET(ticket_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteTicket(ticket_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_TICKET(ticket_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
