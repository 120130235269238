export interface PaginationOptions {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  page: number;
  limit: number;
  nextPage: number | null;
  prevPage: number | null;
}

export interface PaginatedResult<T> extends PaginationOptions {
  rows: T[];
  totalRows: number;
  totalPages: number;
}

export type SortDirection = "asc" | "desc";

export interface Paginate {
  page: number;
  limit: number;
}
