import {DragDropModule} from "@angular/cdk/drag-drop";
import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {ErrorStateMatcher} from "@angular/material/core";
import {MatDialogModule} from "@angular/material/dialog";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatLuxonDateModule} from "@angular/material-luxon-adapter";
import {BrowserModule, provideClientHydration} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MTX_DATETIME_FORMATS} from "@ng-matero/extensions/core";
import {MtxCalendar, MtxDatetimepickerModule} from "@ng-matero/extensions/datetimepicker";
import {MtxLoaderModule} from "@ng-matero/extensions/loader";
import {MtxLuxonDatetimeModule} from "@ng-matero/extensions-luxon-adapter";
import {EditorModule, TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";
import {register as registerSwiperBundle} from "swiper/element/bundle";

import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {AuthComponent} from "./components/auth/auth.component";
import {ForgotPasswordComponent} from "./components/auth/forgot-password/forgot-password.component";
import {LoginComponent} from "./components/auth/login/login.component";
import {RegisterComponent} from "./components/auth/register/register.component";
import {ResetPasswordComponent} from "./components/auth/reset-password/reset-password.component";
import {SocialAuthComponent} from "./components/auth/social-auth/social-auth.component";
import {VerifyEmailComponent} from "./components/auth/verify-email/verify-email.component";
import {BannerComponent} from "./components/banner/banner.component";
import {CatalogComponent} from "./components/catalog/catalog.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {DashboardAttendeeFormComponent} from "./components/dashboard/dashboard-attendee-form/dashboard-attendee-form.component";
import {DashboardAttendeesListComponent} from "./components/dashboard/dashboard-attendees-list/dashboard-attendees-list.component";
import {DashboardBadgeFormComponent} from "./components/dashboard/dashboard-badge-form/dashboard-badge-form.component";
import {DashboardBadgesListComponent} from "./components/dashboard/dashboard-badges-list/dashboard-badges-list.component";
import {DashboardCertificateFormComponent} from "./components/dashboard/dashboard-certificate-form/dashboard-certificate-form.component";
import {DashboardCertificatesListComponent} from "./components/dashboard/dashboard-certificates-list/dashboard-certificates-list.component";
import {DashboardCountriesListComponent} from "./components/dashboard/dashboard-countries-list/dashboard-countries-list.component";
import {DashboardCountryFormComponent} from "./components/dashboard/dashboard-country-form/dashboard-country-form.component";
import {DashboardEventChaptersFormComponent} from "./components/dashboard/dashboard-event-chapters-form/dashboard-event-chapters-form.component";
import {DashboardEventDocumentsFormComponent} from "./components/dashboard/dashboard-event-documents-form/dashboard-event-documents-form.component";
import {DashboardEventEvaluationFormComponent} from "./components/dashboard/dashboard-event-evaluation-form/dashboard-event-evaluation-form.component";
import {DashboardEventFormComponent} from "./components/dashboard/dashboard-event-form/dashboard-event-form.component";
import {DashboardEventInfoFormComponent} from "./components/dashboard/dashboard-event-info-form/dashboard-event-info-form.component";
import {DashboardEventMeetingFormComponent} from "./components/dashboard/dashboard-event-meeting-form/dashboard-event-meeting-form.component";
import {DashboardEventSectionsFormComponent} from "./components/dashboard/dashboard-event-sections-form/dashboard-event-sections-form.component";
import {DashboardEventSettingsFormComponent} from "./components/dashboard/dashboard-event-settings-form/dashboard-event-settings-form.component";
import {DashboardEventWizardComponent} from "./components/dashboard/dashboard-event-wizard/dashboard-event-wizard.component";
import {DashboardEventsListComponent} from "./components/dashboard/dashboard-events-list/dashboard-events-list.component";
import {DashboardFormComponent} from "./components/dashboard/dashboard-form/dashboard-form.component";
import {DashboardFormsListComponent} from "./components/dashboard/dashboard-forms-list/dashboard-forms-list.component";
import {DashboardLandingComponent} from "./components/dashboard/dashboard-landing/dashboard-landing.component";
import {DashboardMeetingDialogComponent} from "./components/dashboard/dashboard-meeting-dialog/dashboard-meeting-dialog.component";
import {DashboardNotificationDialogComponent} from "./components/dashboard/dashboard-notification-dialog/dashboard-notification-dialog.component";
import {DashboardSidebarComponent} from "./components/dashboard/dashboard-sidebar/dashboard-sidebar.component";
import {DashboardTicketFormComponent} from "./components/dashboard/dashboard-ticket-form/dashboard-ticket-form.component";
import {DashboardTicketsListComponent} from "./components/dashboard/dashboard-tickets-list/dashboard-tickets-list.component";
import {DashboardUserFormComponent} from "./components/dashboard/dashboard-user-form/dashboard-user-form.component";
import {DashboardUsersListComponent} from "./components/dashboard/dashboard-users-list/dashboard-users-list.component";
import {EventComponent} from "./components/event/event.component";
import {EventAnnouncementsComponent} from "./components/event/event-announcements/event-announcements.component";
import {EventDetailsComponent} from "./components/event/event-details/event-details.component";
import {EventDocumentsComponent} from "./components/event/event-documents/event-documents.component";
import {EventEvaluationComponent} from "./components/event/event-evaluation/event-evaluation.component";
import {EventLandingComponent} from "./components/event/event-landing/event-landing.component";
import {EventNotesComponent} from "./components/event/event-notes/event-notes.component";
import {EventOverviewComponent} from "./components/event/event-overview/event-overview.component";
import {EventQaComponent} from "./components/event/event-qa/event-qa.component";
import {EventReviewsComponent} from "./components/event/event-reviews/event-reviews.component";
import {LandingComponent} from "./components/landing/landing.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {ProfileDetailsComponent} from "./components/profile/profile-details/profile-details.component";
import {ProfileEventsComponent} from "./components/profile/profile-events/profile-events.component";
import {ProfileFavoritesComponent} from "./components/profile/profile-favorites/profile-favorites.component";
import {ProfileHeaderComponent} from "./components/profile/profile-header/profile-header.component";
import {ProfileSecurityComponent} from "./components/profile/profile-security/profile-security.component";
import {ProfileSidebarComponent} from "./components/profile/profile-sidebar/profile-sidebar.component";
import {ProfileTicketFormComponent} from "./components/profile/profile-ticket-form/profile-ticket-form.component";
import {ProfileTicketsListComponent} from "./components/profile/profile-tickets-list/profile-tickets-list.component";
import {ProfileWizardComponent} from "./components/profile-wizard/profile-wizard.component";
import {CardComponent} from "./components/shared/card/card.component";
import {ConfirmDialogComponent} from "./components/shared/confirm-dialog/confirm-dialog.component";
import {FileDialogComponent} from "./components/shared/file-dialog/file-dialog.component";
import {FooterComponent} from "./components/shared/footer/footer.component";
import {NavbarComponent} from "./components/shared/navbar/navbar.component";
import {PaginationComponent} from "./components/shared/pagination/pagination.component";
import {PhoneInputComponent} from "./components/shared/phone-input/phone-input.component";
import {RatingComponent} from "./components/shared/rating/rating.component";
import {SuccessIconComponent} from "./components/shared/success-icon/success-icon.component";
import {ToastComponent} from "./components/shared/toast/toast.component";
import {VerifyCertificateComponent} from "./components/verify-certificate/verify-certificate.component";
import {CounterDirective} from "./directives/counter.directive";
import {IfLoggedInDirective} from "./directives/if-logged-in.directive";
import {IfRoleDirective} from "./directives/if-role.directive";
import {ImageDirective} from "./directives/image.directive";
import {SwiperDirective} from "./directives/swiper.directive";
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {TokenInterceptor} from "./interceptors/token.interceptor";
import {ErrorPipe} from "./pipes/error.pipe";
import {SafePipe} from "./pipes/safe.pipe";
import {PLATFORM_SERVICE, PlatformBrowserService} from "./services/platform.service";
import {ShowOnDirtyErrorStateMatcher} from "./utils/validations";

registerSwiperBundle();

//! Monkey-patch to fix an issue with calendar component used by the datetime picker
MtxCalendar.prototype._updateDate = function (date) {
  return date;
};

const getRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 من ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} من ${length}`;
};

export function getPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = "العدد في كل صفحة:";
  paginatorIntl.nextPageLabel = "الصفحة التالية";
  paginatorIntl.previousPageLabel = "الصفحة السابقة";
  paginatorIntl.getRangeLabel = getRangeLabel;

  return paginatorIntl;
}

const angular = [
  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  HttpClientModule,
] as const;

const material = [
  DragDropModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatTooltipModule,
  MatExpansionModule,
  MatTableModule,
  MatPaginatorModule,
  MatChipsModule,
  MatStepperModule,
  MatLuxonDateModule,
] as const;

const thirdParties = [FontAwesomeModule, MtxDatetimepickerModule, MtxLuxonDatetimeModule, MtxLoaderModule, EditorModule];

const directives = [CounterDirective, SwiperDirective, ImageDirective, IfLoggedInDirective, IfRoleDirective];

const components = [
  CardComponent,
  FooterComponent,
  NavbarComponent,
  PaginationComponent,
  ToastComponent,
  FileDialogComponent,
  RatingComponent,
  PhoneInputComponent,
  SuccessIconComponent,
  ConfirmDialogComponent,

  AppComponent,

  LandingComponent,

  ProfileWizardComponent,

  AuthComponent,
  LoginComponent,
  RegisterComponent,
  SocialAuthComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  VerifyEmailComponent,

  CatalogComponent,

  BannerComponent,

  EventComponent,
  EventLandingComponent,
  EventDetailsComponent,
  EventOverviewComponent,
  EventQaComponent,
  EventNotesComponent,
  EventReviewsComponent,
  EventDocumentsComponent,
  EventEvaluationComponent,
  EventAnnouncementsComponent,

  VerifyCertificateComponent,

  ProfileComponent,
  ProfileSidebarComponent,
  ProfileDetailsComponent,
  ProfileHeaderComponent,
  ProfileSecurityComponent,
  ProfileFavoritesComponent,
  ProfileEventsComponent,
  ProfileTicketsListComponent,
  ProfileTicketFormComponent,
  DashboardTicketsListComponent,
  DashboardTicketFormComponent,

  DashboardComponent,
  DashboardSidebarComponent,
  DashboardUsersListComponent,
  DashboardEventsListComponent,
  DashboardAttendeesListComponent,
  DashboardEventFormComponent,
  DashboardEventWizardComponent,
  DashboardEventInfoFormComponent,
  DashboardEventSettingsFormComponent,
  DashboardEventChaptersFormComponent,
  DashboardEventDocumentsFormComponent,
  DashboardEventEvaluationFormComponent,
  DashboardLandingComponent,
  DashboardAttendeeFormComponent,
  DashboardUserFormComponent,
  DashboardCertificateFormComponent,
  DashboardBadgeFormComponent,
  DashboardCountryFormComponent,
  DashboardCertificatesListComponent,
  DashboardBadgesListComponent,
  DashboardCountriesListComponent,
  DashboardFormsListComponent,
  DashboardFormComponent,
  DashboardMeetingDialogComponent,
  DashboardEventSectionsFormComponent,
  DashboardEventMeetingFormComponent,
  DashboardNotificationDialogComponent,
] as const;

const pipes = [ErrorPipe, SafePipe] as const;

@NgModule({
  declarations: [...directives, ...components, ...pipes],
  imports: [AppRoutingModule, ...angular, ...material, ...thirdParties],
  providers: [
    provideClientHydration(),
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: PLATFORM_SERVICE, useClass: PlatformBrowserService},
    {
      provide: MatPaginatorIntl,
      useValue: getPaginatorIntl(),
    },
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher,
    },
    {provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js"},
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: "D",
          monthInput: "LLLL",
          yearInput: "yyyy",
          datetimeInput: "f",
          timeInput: "t",
        },
        display: {
          dateInput: "D",
          monthInput: "LLLL",
          yearInput: "yyyy",
          datetimeInput: "yyyy/LL/dd hh:mm a",
          timeInput: "t",
          monthYearLabel: "yyyy",
          dateA11yLabel: "DDD",
          monthYearA11yLabel: "LLLL yyyy",
          popupHeaderDateLabel: "ccc, dd LLL",
        },
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
