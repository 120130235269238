import {animate, style, transition, trigger} from "@angular/animations";
import {Component, HostListener, Inject, OnInit} from "@angular/core";
import {PLATFORM_SERVICE, PlatformService} from "src/app/services/platform.service";

@Component({
  selector: "app-profile-sidebar",
  templateUrl: "./profile-sidebar.component.html",
  styleUrls: ["./profile-sidebar.component.scss"],
  animations: [trigger("fadeIn", [transition(":enter", [style({opacity: 0}), animate("350ms", style({opacity: 1}))])])],
})
export class ProfileSidebarComponent implements OnInit {
  items: {title: string; route: string; icon: string}[] = [
    {
      title: "بياناتي",
      route: "/profile/home",
      icon: "account_circle",
    },
    {
      title: "فعالياتي",
      route: "/profile/events",
      icon: "event",
    },
    {
      title: "مفضلتي",
      route: "/profile/favorites",
      icon: "bookmark",
    },
    {
      title: "الأمان",
      route: "/profile/security",
      icon: "shield",
    },
    {
      title: "الدعم الفني",
      route: "/profile/tickets",
      icon: "support_agent",
    },
  ];

  collapsed = false;

  constructor(@Inject(PLATFORM_SERVICE) private platformSvc: PlatformService) {}
  ngOnInit(): void {
    this.onResize();
  }
  @HostListener("window:resize")
  onResize() {
    const SCREEN_BREAKPOINT = 768;

    const window = this.platformSvc.getWindow();
    const screenWidth = window.innerWidth;

    this.collapsed = screenWidth <= SCREEN_BREAKPOINT;
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }
}
