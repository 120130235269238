import {Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {distinctUntilChanged, ReplaySubject, takeUntil} from "rxjs";

import {AuthService} from "../services/auth.service";

@Directive({
  selector: "[ifLoggedIn]",
})
export class IfLoggedInDirective implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  isLoggedIn!: boolean;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authSvc: AuthService,
  ) {}

  ngOnInit(): void {
    this.authSvc
      .getToken()
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((token) => {
        this.isLoggedIn = !!token;
        this.updateView();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateView() {
    if (!this.isLoggedIn) {
      this.viewContainer.clear();
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
