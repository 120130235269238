import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Country, Role, User, UserStatus} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {CountryService} from "src/app/services/country.service";
import {ToastService} from "src/app/services/toast.service";
import {UserService} from "src/app/services/user.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const makeUserForm = (passwordRequired = true) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shape: any = {
    first_name: v.string(),
    middle_name: v.string(),
    last_name: v.string(),
    first_name_en: v.string(),
    middle_name_en: v.string(),
    last_name_en: v.string(),
    email: v.string({format: "email"}),
    phone: v.string({format: "phone"}),
    password: v.string({format: "password"}),
    country_id: v.string({format: "uuid"}),
    role: v.enum(["user", "admin"]),
    status: v.enum(["active", "suspended"]),
  };
  if (passwordRequired) {
    return v.object(shape);
  }

  shape.password = v.string({format: "password", nullable: true}).optional();
  return v.object(shape);
};

@Component({
  selector: "app-dashboard-user-form",
  templateUrl: "./dashboard-user-form.component.html",
  styleUrls: ["./dashboard-user-form.component.scss"],
})
export class DashboardUserFormComponent implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup({
    first_name: new FormControl("", {nonNullable: true}),
    middle_name: new FormControl("", {nonNullable: true}),
    last_name: new FormControl("", {nonNullable: true}),
    first_name_en: new FormControl("", {nonNullable: true}),
    middle_name_en: new FormControl("", {nonNullable: true}),
    last_name_en: new FormControl("", {nonNullable: true}),
    email: new FormControl("", {nonNullable: true}),
    password: new FormControl<string | undefined>(undefined),
    phone: new FormControl("", {nonNullable: true}),
    country_id: new FormControl("", {nonNullable: true}),
    role: new FormControl<Role>("user", {nonNullable: true}),
    status: new FormControl<UserStatus>("active", {nonNullable: true}),
  });

  statuses = [
    {name: "نشط", value: "active"},
    {name: "معلق", value: "suspended"},
  ];

  roles = [
    {name: "مستخدم", value: "user"},
    {name: "مشرف نظام", value: "admin"},
  ];

  countries: Country[] = [];

  loading!: boolean;

  user_id?: string;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private userSvc: UserService,
    private countrySvc: CountryService,
    private toastSvc: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getCountries();

    const user_id = this.route.snapshot.params["user_id"];
    if (user_id) {
      this.user_id = user_id;
      this.getUser();
    }
  }

  ngAfterViewInit(): void {
    if (this.user_id) {
      const {validators} = makeValidators(makeUserForm(false));
      this.form.setValidators(validators);
      this.form.updateValueAndValidity();
    } else {
      const {validators} = makeValidators(makeUserForm(true));
      this.form.addValidators(validators);
      this.form.updateValueAndValidity();
    }
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get first_name() {
    return this.form.controls.first_name;
  }

  get middle_name() {
    return this.form.controls.middle_name;
  }

  get last_name() {
    return this.form.controls.last_name;
  }

  get first_name_en() {
    return this.form.controls.first_name_en;
  }

  get middle_name_en() {
    return this.form.controls.middle_name_en;
  }

  get last_name_en() {
    return this.form.controls.last_name_en;
  }

  get email() {
    return this.form.controls.email;
  }

  get phone() {
    return this.form.controls.phone;
  }

  get password() {
    return this.form.controls.password;
  }

  get country_id() {
    return this.form.controls.country_id;
  }

  get role() {
    return this.form.controls.role;
  }

  get status() {
    return this.form.controls.status;
  }

  getCountries() {
    this.countrySvc
      .getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countries) => {
        this.countries = countries;
      });
  }

  getUser() {
    if (!this.user_id) return;

    this.loading = true;
    this.userSvc
      .getUser(this.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.patchForm(user);
        this.loading = false;
      });
  }

  patchForm(user: User) {
    this.form.patchValue({
      ...user,
      phone: user.phone ?? "",
      country_id: user.country_id ?? "",
    });
    markAllAsPristine(this.form);
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const {password, ...form} = this.form.getRawValue();

    this.loading = true;
    if (this.user_id) {
      this.userSvc
        .updateUser(this.user_id, {
          ...form,
          password: password ?? undefined,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (user) => {
            this.patchForm(user);
            if (this.email.dirty) {
              this.toastSvc.success(
                "تم تنفيذ الأمر بنجاح و سيتم تحديث البريد الإلكتروني بمجرد تأكيده من خلال رسالة سترسل إليه",
              );
            } else {
              this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            }
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.userSvc
        .createUser({
          ...form,
          password: password as string,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (user) => {
            markAllAsPristine(this.form);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.router.navigate(["/dashboard", "users", user.id]);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  isDirty() {
    return this.form.dirty;
  }
}
