<div *ngIf="!loading && section" class="p-3">
  <h4 class="section-title fw-bold py-4">{{ section.title }}</h4>

  <section *ngIf="section.description" class="mb-4">
    <p class="section-title fw-bold">الوصف</p>
    <hr class="my-3" />
    <p [innerHTML]="section.description"></p>
  </section>

  <section class="mb-4">
    <p class="section-title fw-bold">المحتوى</p>
    <hr class="my-3" />
    <div [innerHTML]="section.content | safe: 'html'"></div>
  </section>

  <section *ngIf="section.files && section.files.length > 0" class="mb-4">
    <p class="section-title fw-bold">المرفقات</p>
    <hr class="my-3" />
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">عنوان المرفق</th>
            <th scope="col" style="width: 100px">تحميل</th>
          </tr>
        </thead>
        <tbody class="fw-light" style="vertical-align: middle">
          <tr *ngFor="let file of section.files; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ file.filename_download }}</td>
            <td>
              <a
                [href]="file.source"
                class="btn btn-primary rounded-circle d-inline-flex align-items-center"
                style="aspect-ratio: 1/1"
              >
                <fa-icon [icon]="faDownload"></fa-icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>

<mat-tab-group>
  <mat-tab label="نظرة عامة">
    <ng-template matTabContent>
      <app-event-overview></app-event-overview>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="event?.questions_enabled" label="أسئلة و أجوبة">
    <ng-template matTabContent>
      <app-event-qa></app-event-qa>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="event?.notes_enabled" label="الملاحظات">
    <ng-template matTabContent>
      <app-event-notes></app-event-notes>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="event?.reviews_enabled" label="التقييمات">
    <ng-template matTabContent>
      <app-event-reviews></app-event-reviews>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="event?.announcements_enabled" label="الإعلانات">
    <ng-template matTabContent>
      <app-event-announcements></app-event-announcements>
    </ng-template>
  </mat-tab>
  <mat-tab label="الوثائق">
    <ng-template matTabContent>
      <app-event-documents></app-event-documents>
    </ng-template>
  </mat-tab>
</mat-tab-group>
