<div class="vertical-center">
  <div *ngIf="!success" class="container bg-white p-5">
    <h1 class="text-primary text-center fw-bold">استكمال بيانات الحساب الشخصي</h1>
    <h5 class="text-center">يرجى استكمال البيانات المطلوبة للمتابعة</h5>
    <form #ngForm="ngForm" autocomplete="off" [formGroup]="form" class="mt-5">
      <div class="alert" role="alert" class="alert alert-danger text-center h5 fw-bold lh-base py-4 px-3 my-5">
        يرجى مراعاة صحة البيانات المدخلة حيث أنه لن يتم السماح بتعديل هذه البيانات بعد الحفظ.
        <br />
        تظهر البيانات التالية على الشهادات الصادرة من خلال المنصة و لا يتحمل المكتب مسؤلية وجود خطأ بها.
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="first_name">الاسم الأول</label>
            <input
              type="text"
              class="form-control py-3 focus-shadow-none"
              id="first_name"
              formControlName="first_name"
              [ngClass]="{
                'is-invalid': errorStateMatcher.isErrorState(first_name, ngForm)
              }"
            />
            <div *ngIf="first_name.invalid" class="invalid-feedback">
              {{ first_name.errors | error }}
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="middle_name">الاسم الأوسط</label>
            <input
              type="text"
              class="form-control py-3 focus-shadow-none"
              id="middle_name"
              formControlName="middle_name"
              [ngClass]="{
                'is-invalid': errorStateMatcher.isErrorState(middle_name, ngForm)
              }"
            />
            <div *ngIf="middle_name.invalid" class="invalid-feedback">
              {{ middle_name.errors | error }}
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="last_name">الاسم الأخير</label>
            <input
              type="text"
              class="form-control py-3 focus-shadow-none"
              id="last_name"
              formControlName="last_name"
              [ngClass]="{
                'is-invalid': errorStateMatcher.isErrorState(last_name, ngForm)
              }"
            />
            <div *ngIf="last_name.invalid" class="invalid-feedback">
              {{ last_name.errors | error }}
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-4">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="first_name_en">الاسم الأول بالإنجليزية</label>
            <input
              type="text"
              class="form-control py-3 focus-shadow-none"
              id="first_name_en"
              formControlName="first_name_en"
              [ngClass]="{
                'is-invalid': errorStateMatcher.isErrorState(first_name_en, ngForm)
              }"
            />
            <div *ngIf="first_name_en.invalid" class="invalid-feedback">
              {{ first_name_en.errors | error }}
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="middle_name_en">الاسم الأوسط بالإنجليزية</label>
            <input
              type="text"
              class="form-control py-3 focus-shadow-none"
              id="middle_name_en"
              formControlName="middle_name_en"
              [ngClass]="{
                'is-invalid': errorStateMatcher.isErrorState(middle_name_en, ngForm)
              }"
            />
            <div *ngIf="middle_name_en.invalid" class="invalid-feedback">
              {{ middle_name_en.errors | error }}
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="last_name_en">الاسم الأخير بالإنجليزية</label>
            <input
              type="text"
              class="form-control py-3 focus-shadow-none"
              id="last_name_en"
              formControlName="last_name_en"
              [ngClass]="{
                'is-invalid': errorStateMatcher.isErrorState(last_name_en, ngForm)
              }"
            />
            <div *ngIf="last_name_en.invalid" class="invalid-feedback">
              {{ last_name_en.errors | error }}
            </div>
          </div>
        </div> -->
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="phone">رقم الجوال</label>
            <app-phone-input formControlName="phone"></app-phone-input>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="fw-light text-l-gray mb-2" for="country_id">الدولة</label>
            <select class="form-select py-3 focus-shadow-none" id="country_id" formControlName="country_id">
              <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="my-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="accept-checkbox"
              formControlName="accept"
              [ngClass]="{
                'is-invalid': errorStateMatcher.isErrorState(accept, ngForm)
              }"
            />
            <label class="form-check-label fw-bold" for="accept-checkbox">
              أقر بأن البيانات المدخلة سليمة و دقيقة و أتحمل مسؤلية أية أخطاء فيها
            </label>
            <div *ngIf="accept.invalid" class="invalid-feedback fw-bold">يرجى الإقرار بسلامة البيانات المدخلة</div>
          </div>
        </div>
        <button type="button" [disabled]="loading" class="btn btn-primary px-4 py-2" (click)="save()">حفظ</button>
      </div>
    </form>
  </div>
</div>
