import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {distinctUntilChanged, map, ReplaySubject, takeUntil} from "rxjs";

import {Role} from "../dtos";
import {AuthService} from "../services/auth.service";

@Directive({
  selector: "[ifRole]",
})
export class IfRoleDirective implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  isLoggedIn!: boolean;
  role!: Role;

  requiredRole!: Role;

  @Input({required: true})
  set ifRole(value: Role) {
    this.requiredRole = value;
    this.updateView();
  }

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authSvc: AuthService,
  ) {}

  ngOnInit(): void {
    this.authSvc
      .getUser()
      .pipe(
        map((user) => (user ? user.role : null)),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((role) => {
        this.isLoggedIn = !!role;
        if (role) {
          this.role = role;
        }
        this.updateView();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateView() {
    if (!this.isLoggedIn || this.requiredRole !== this.role) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
