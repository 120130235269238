import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {SILENCE_ERRORS} from "src/app/utils";
import {environment} from "src/environments/environment";

import {Country} from "../dtos";

export interface CreateCountryRequestBody {
  code: string;
  name: string;
  order: number;
}

export type UpdateCountriesRequestBody = {
  id: string;
  order: number;
}[];

export interface UpdateCountryRequestBody {
  code: string;
  name: string;
  order: number;
}

@Injectable({
  providedIn: "root",
})
export class CountryService {
  private readonly V1_ENDPOINTS = {
    GET_COUNTRIES: () => `${environment.server.host}/api/v1/countries`,
    CREATE_COUNTRY: () => `${environment.server.host}/api/v1/countries`,
    UPDATE_COUNTRIES: () => `${environment.server.host}/api/v1/countries`,
    GET_COUNTRY: (country_id: string) => `${environment.server.host}/api/v1/countries/${country_id}`,
    UPDATE_COUNTRY: (country_id: string) => `${environment.server.host}/api/v1/countries/${country_id}`,
    DELETE_COUNTRY: (country_id: string) => `${environment.server.host}/api/v1/countries/${country_id}`,
  };

  constructor(private httpClient: HttpClient) {}

  getCountries(silent = false) {
    return this.httpClient.get<Country[]>(this.V1_ENDPOINTS.GET_COUNTRIES(), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createCountry(body: CreateCountryRequestBody, silent = false) {
    return this.httpClient.post<Country>(this.V1_ENDPOINTS.CREATE_COUNTRY(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateCountries(body: UpdateCountriesRequestBody, silent = false) {
    return this.httpClient.put<Country[]>(this.V1_ENDPOINTS.UPDATE_COUNTRIES(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getCountry(country_id: string, silent = false) {
    return this.httpClient.get<Country>(this.V1_ENDPOINTS.GET_COUNTRY(country_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateCountry(country_id: string, body: UpdateCountryRequestBody, silent = false) {
    return this.httpClient.put<Country>(this.V1_ENDPOINTS.UPDATE_COUNTRY(country_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteCountry(country_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_COUNTRY(country_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
