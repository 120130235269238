import {File} from "./file.dto";
import {Stamps} from "./helpers";

export const VALID_CERTIFICATE_FIELD_PROPERTIES = [
  "inset",
  "font",
  "color",
  "top",
  "left",
  "bottom",
  "right",
  "font-size",
  "font-weight",
  "font-family",
  "color",
  "width",
  "height",
  "text-align",
] as const;

export type CertificateStatus = "draft" | "published" | "archived";

export interface CertificateField {
  name: string;
  properties: Record<string, string | number | boolean>;
}

export interface Certificate extends Stamps {
  name: string;
  image_id: string;
  image?: File;
  fields: CertificateField[];
  status: CertificateStatus;

  status_ar: string;
}
