<div>
  <h3 class="text-primary fw-bold mt-5">إنشاء حساب جديد</h3>
  <p class="text-l-gray fw-light">أنشئ حساب و اشترك في كافة الفعاليات</p>

  <form #ngForm="ngForm" class="mt-5" autocomplete="off" [formGroup]="form" (ngSubmit)="register()">
    <div class="row">
      <div class="col-xxl-4">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="first_name">الاسم الأول</label>
          <input
            required
            type="text"
            class="form-control py-3 focus-shadow-none"
            id="first_name"
            formControlName="first_name"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(first_name, ngForm)}"
          />
          <div *ngIf="first_name.invalid" class="invalid-feedback">{{ first_name.errors | error }}</div>
        </div>
      </div>
      <div class="col-xxl-4">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="middle_name">الاسم الأوسط</label>
          <input
            type="text"
            class="form-control py-3 focus-shadow-none"
            id="middle_name"
            formControlName="middle_name"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(middle_name, ngForm)}"
          />
          <div *ngIf="middle_name.invalid" class="invalid-feedback">{{ middle_name.errors | error }}</div>
        </div>
      </div>
      <div class="col-xxl-4">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="last_name">الاسم الأخير</label>
          <input
            required
            type="text"
            class="form-control py-3 focus-shadow-none"
            id="last_name"
            formControlName="last_name"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(last_name, ngForm)}"
          />
          <div *ngIf="last_name.invalid" class="invalid-feedback">{{ last_name.errors | error }}</div>
        </div>
      </div>
      <div class="col-xxl-4">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="email">البريد الإلكتروني</label>
          <input
            required
            type="email"
            class="form-control py-3 focus-shadow-none"
            id="email"
            formControlName="email"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(email, ngForm)}"
          />
          <div *ngIf="email.invalid" class="invalid-feedback">{{ email.errors | error }}</div>
        </div>
      </div>
      <div class="col-xxl-4">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="password">كلمة المرور</label>
          <input
            required
            type="password"
            class="form-control py-3 focus-shadow-none"
            id="password"
            formControlName="password"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(password, ngForm)}"
          />
          <div *ngIf="password.invalid" class="invalid-feedback">{{ password.errors | error }}</div>
        </div>
      </div>
      <div class="col-xxl-4">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="password_confirm">تأكيد كلمة المرور</label>
          <input
            required
            type="password"
            class="form-control py-3 focus-shadow-none"
            id="password_confirm"
            formControlName="password_confirm"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(password_confirm, ngForm)}"
          />
          <div *ngIf="password_confirm.invalid" class="invalid-feedback">{{ password_confirm.errors | error }}</div>
        </div>
      </div>

      <div class="col-12">
        <button class="btn btn-primary w-100 py-3 mt-5" type="submit">إنشاء حساب</button>
      </div>
    </div>
  </form>
  <hr class="my-4" />
  <app-social-auth></app-social-auth>
  <hr class="my-4" />
  <div class="text-center text-l-gray">
    لديك حساب؟
    <a routerLink="/login">تسجيل الدخول</a>
  </div>
</div>
