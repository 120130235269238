<nav id="navbar_top" class="navbar navbar-expand-lg bg-white py-1 py-md-0 sticky-top">
  <div class="container">
    <a class="navbar-brand py-0" routerLink="">
      <!-- Small screens -->
      <img class="d-lg-none logo-sm" src="./assets/images/logo/abegs.svg" height="80px" alt="Logo" />

      <!-- Large screens -->
      <img class="d-none d-lg-block" src="./assets/images/logo/abegs-expanded.svg" height="80px" alt="Logo" />
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
          <img class="d-block" src="./assets/images/logo/abegs-expanded.svg" height="82px" alt="Logo" />
        </h5>
        <button type="button" class="btn-close me-4" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>

      <div class="offcanvas-body" id="navbar-top">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item mx-2 my-2">
            <a class="nav-link fw-bold" routerLink="/events">تصفّح الفعاليات</a>
          </li>
          <li *ifRole="'admin'" class="nav-item mx-2 my-2">
            <a class="nav-link fw-bold" routerLink="/dashboard">لوحة التحكم</a>
          </li>
          <li *ngIf="!user" class="nav-item mx-2 my-2">
            <a class="px-4 btn btn-primary" routerLink="/register">سجل معنا</a>
          </li>
          <li *ngIf="!user" class="nav-item mx-2 my-2">
            <a class="px-4 btn btn-outline-secondary" routerLink="/login">دخول</a>
          </li>
          <li *ngIf="user" class="nav-item mx-2 my-2">
            <a routerLink="/profile" class="nav-link fw-bold d-flex align-items-center gap-2">
              <img
                image
                fallback="avatar"
                src="{{ user.photo?.source }}"
                class="d-block rounded-circle cover"
                [alt]="user.full_name"
                width="40px"
                height="40px"
              />
              حسابي
            </a>
          </li>
          <li *ngIf="user" class="nav-item mx-2 my-2">
            <a class="nav-link fw-bold" href="javascript:void(0)" (click)="logout()">
              <fa-icon [icon]="faRightFromBracket" flip="horizontal"></fa-icon>
              تسجيل الخروج
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
