<mtx-loader [loading]="loading">
  <div class="p-5">
    <div class="d-flex justify-content-between">
      <h2>الدول</h2>
    </div>

    <mat-list class="my-4" cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-list-item *ngFor="let country of countries" class="border border-1 rounded-1" cdkDrag>
        <div class="drag-placeholder" *cdkDragPlaceholder></div>

        <div matListItemTitle>{{ country.name }} ({{ country.code }})</div>

        <div matListItemMeta>
          <a
            matTooltip="تعديل"
            matTooltipPosition="above"
            mat-icon-button
            [routerLink]="['/dashboard', 'countries', country.id]"
          >
            <mat-icon>edit</mat-icon>
          </a>
          <button matTooltip="حذف" matTooltipPosition="above" mat-icon-button (click)="deleteCountry(country.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <button matTooltip="اسحب لإعادة الترتيب" matTooltipPosition="above" mat-icon-button cdkDragHandle>
            <mat-icon>drag_handler</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>

    <a class="mt-3 w-100" mat-flat-button [routerLink]="['/dashboard/countries/new']">
      <span>إضافة المزيد</span>
      <mat-icon>add</mat-icon>
    </a>
  </div>
</mtx-loader>
