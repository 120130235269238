import {countryPhoneData} from "phone";

const FLAGS = [
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "EU",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "IC",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TA",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "XK",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW",
];

const COUNTRIES_NAMES_AR = [
  {
    name: "أفغانستان",
    code: "AF",
  },
  {
    name: "جزر آلاند",
    code: "AX",
  },
  {
    name: "ألبانيا",
    code: "AL",
  },
  {
    name: "الجزائر",
    code: "DZ",
  },
  {
    name: "ساموا الأمريكية",
    code: "AS",
  },
  {
    name: "أندورا",
    code: "AD",
  },
  {
    name: "أنغولا",
    code: "AO",
  },
  {
    name: "أنغويلا",
    code: "AI",
  },
  {
    name: "القطب الجنوبي",
    code: "AQ",
  },
  {
    name: "أنتيغوا وبربودا",
    code: "AG",
  },
  {
    name: "الأرجنتين",
    code: "AR",
  },
  {
    name: "أرمينيا",
    code: "AM",
  },
  {
    name: "أروبا",
    code: "AW",
  },
  {
    name: "أستراليا",
    code: "AU",
  },
  {
    name: "النمسا",
    code: "AT",
  },
  {
    name: "أذربيجان",
    code: "AZ",
  },
  {
    name: "الباهاما",
    code: "BS",
  },
  {
    name: "البحرين",
    code: "BH",
  },
  {
    name: "بنغلاديش",
    code: "BD",
  },
  {
    name: "بربادوس",
    code: "BB",
  },
  {
    name: "بيلاروس",
    code: "BY",
  },
  {
    name: "بلجيكا",
    code: "BE",
  },
  {
    name: "بليز",
    code: "BZ",
  },
  {
    name: "بنين",
    code: "BJ",
  },
  {
    name: "برمودا",
    code: "BM",
  },
  {
    name: "بوتان",
    code: "BT",
  },
  {
    name: "بوليفيا",
    code: "BO",
  },
  {
    name: "البوسنة والهرسك",
    code: "BA",
  },
  {
    name: "بوتسوانا",
    code: "BW",
  },
  {
    name: "جزيرة بوفيه",
    code: "BV",
  },
  {
    name: "البرازيل",
    code: "BR",
  },
  {
    name: "إقليم المحيط الهندي البريطاني",
    code: "IO",
  },
  {
    name: "بروناي دار السلام",
    code: "BN",
  },
  {
    name: "بلغاريا",
    code: "BG",
  },
  {
    name: "بوركينا فاسو",
    code: "BF",
  },
  {
    name: "بوروندي",
    code: "BI",
  },
  {
    name: "كمبوديا",
    code: "KH",
  },
  {
    name: "الكاميرون",
    code: "CM",
  },
  {
    name: "كندا",
    code: "CA",
  },
  {
    name: "الرأس الأخضر",
    code: "CV",
  },
  {
    name: "جزر كايمان",
    code: "KY",
  },
  {
    name: "جمهورية أفريقيا الوسطى",
    code: "CF",
  },
  {
    name: "تشاد",
    code: "TD",
  },
  {
    name: "شيلي",
    code: "CL",
  },
  {
    name: "الصين",
    code: "CN",
  },
  {
    name: "جزيرة الكريسماس",
    code: "CX",
  },
  {
    name: "جزر كوكوس (كيلينغ)",
    code: "CC",
  },
  {
    name: "كولومبيا",
    code: "CO",
  },
  {
    name: "جزر القمر",
    code: "KM",
  },
  {
    name: "الكونغو",
    code: "CG",
  },
  {
    name: "جمهورية الكونغو الديمقراطية",
    code: "CD",
  },
  {
    name: "جزر كوك",
    code: "CK",
  },
  {
    name: "كوستاريكا",
    code: "CR",
  },
  {
    name: "ساحل العاج",
    code: "CI",
  },
  {
    name: "كرواتيا",
    code: "HR",
  },
  {
    name: "كوبا",
    code: "CU",
  },
  {
    name: "قبرص",
    code: "CY",
  },
  {
    name: "الجمهورية التشيكية",
    code: "CZ",
  },
  {
    name: "الدانمارك",
    code: "DK",
  },
  {
    name: "جيبوتي",
    code: "DJ",
  },
  {
    name: "دومينيكا",
    code: "DM",
  },
  {
    name: "جمهورية الدومينيكان",
    code: "DO",
  },
  {
    name: "الإكوادور",
    code: "EC",
  },
  {
    name: "مصر",
    code: "EG",
  },
  {
    name: "السلفادور",
    code: "SV",
  },
  {
    name: "غينيا الاستوائية",
    code: "GQ",
  },
  {
    name: "إريتريا",
    code: "ER",
  },
  {
    name: "إستونيا",
    code: "EE",
  },
  {
    name: "إثيوبيا",
    code: "ET",
  },
  {
    name: "جزر فوكلاند (مالفيناس)",
    code: "FK",
  },
  {
    name: "جزر فارو",
    code: "FO",
  },
  {
    name: "فيجي",
    code: "FJ",
  },
  {
    name: "فنلندا",
    code: "FI",
  },
  {
    name: "فرنسا",
    code: "FR",
  },
  {
    name: "غويانا الفرنسية",
    code: "GF",
  },
  {
    name: "بولينيزيا الفرنسية",
    code: "PF",
  },
  {
    name: "الأقاليم الجنوبية لفرنسا",
    code: "TF",
  },
  {
    name: "الغابون",
    code: "GA",
  },
  {
    name: "غامبيا",
    code: "GM",
  },
  {
    name: "جورجيا",
    code: "GE",
  },
  {
    name: "ألمانيا",
    code: "DE",
  },
  {
    name: "غانا",
    code: "GH",
  },
  {
    name: "جبل طارق",
    code: "GI",
  },
  {
    name: "اليونان",
    code: "GR",
  },
  {
    name: "جرينلاند",
    code: "GL",
  },
  {
    name: "غرينادا",
    code: "GD",
  },
  {
    name: "جوادلوب",
    code: "GP",
  },
  {
    name: "غوام",
    code: "GU",
  },
  {
    name: "غواتيمالا",
    code: "GT",
  },
  {
    name: "غيرنسي",
    code: "GG",
  },
  {
    name: "غينيا",
    code: "GN",
  },
  {
    name: "غينيا بيساو",
    code: "GW",
  },
  {
    name: "غيانا",
    code: "GY",
  },
  {
    name: "هايتي",
    code: "HT",
  },
  {
    name: "جزيرة هيرد وجزر ماكدونالد",
    code: "HM",
  },
  {
    name: "الكرسي الرسولي (دولة الفاتيكان)",
    code: "VA",
  },
  {
    name: "هندوراس",
    code: "HN",
  },
  {
    name: "هونغ كونغ",
    code: "HK",
  },
  {
    name: "هنغاريا",
    code: "HU",
  },
  {
    name: "أيسلندا",
    code: "IS",
  },
  {
    name: "الهند",
    code: "IN",
  },
  {
    name: "إندونيسيا",
    code: "ID",
  },
  {
    name: "جمهورية إيران الإسلامية",
    code: "IR",
  },
  {
    name: "العراق",
    code: "IQ",
  },
  {
    name: "أيرلندا",
    code: "IE",
  },
  {
    name: "جزيرة مان",
    code: "IM",
  },
  {
    name: "إيطاليا",
    code: "IT",
  },
  {
    name: "جامايكا",
    code: "JM",
  },
  {
    name: "اليابان",
    code: "JP",
  },
  {
    name: "جيرسي",
    code: "JE",
  },
  {
    name: "الأردن",
    code: "JO",
  },
  {
    name: "كازاخستان",
    code: "KZ",
  },
  {
    name: "كينيا",
    code: "KE",
  },
  {
    name: "كيريباتي",
    code: "KI",
  },
  {
    name: "كوريا، جمهورية الشعب الديمقراطية",
    code: "KP",
  },
  {
    name: "كوريا، جمهورية",
    code: "KR",
  },
  {
    name: "الكويت",
    code: "KW",
  },
  {
    name: "قيرغيزستان",
    code: "KG",
  },
  {
    name: "جمهورية لاو الديمقراطية الشعبية",
    code: "LA",
  },
  {
    name: "لاتفيا",
    code: "LV",
  },
  {
    name: "لبنان",
    code: "LB",
  },
  {
    name: "ليسوتو",
    code: "LS",
  },
  {
    name: "ليبيريا",
    code: "LR",
  },
  {
    name: "الجماهيرية العربية الليبية",
    code: "LY",
  },
  {
    name: "ليختنشتاين",
    code: "LI",
  },
  {
    name: "ليتوانيا",
    code: "LT",
  },
  {
    name: "لوكسمبورغ",
    code: "LU",
  },
  {
    name: "ماكاو",
    code: "MO",
  },
  {
    name: "مقدونيا، جمهورية يوغوسلافيا السابقة",
    code: "MK",
  },
  {
    name: "مدغشقر",
    code: "MG",
  },
  {
    name: "ملاوي",
    code: "MW",
  },
  {
    name: "ماليزيا",
    code: "MY",
  },
  {
    name: "المالديف",
    code: "MV",
  },
  {
    name: "مالي",
    code: "ML",
  },
  {
    name: "مالطا",
    code: "MT",
  },
  {
    name: "جزر مارشال",
    code: "MH",
  },
  {
    name: "مارتينيك",
    code: "MQ",
  },
  {
    name: "موريتانيا",
    code: "MR",
  },
  {
    name: "موريشيوس",
    code: "MU",
  },
  {
    name: "مايوت",
    code: "YT",
  },
  {
    name: "المكسيك",
    code: "MX",
  },
  {
    name: "ميكرونيزيا، ولايات موحدة",
    code: "FM",
  },
  {
    name: "مولدوفا، جمهورية",
    code: "MD",
  },
  {
    name: "موناكو",
    code: "MC",
  },
  {
    name: "منغوليا",
    code: "MN",
  },
  {
    name: "مونتسيرات",
    code: "MS",
  },
  {
    name: "المغرب",
    code: "MA",
  },
  {
    name: "موزمبيق",
    code: "MZ",
  },
  {
    name: "ميانمار",
    code: "MM",
  },
  {
    name: "ناميبيا",
    code: "NA",
  },
  {
    name: "ناورو",
    code: "NR",
  },
  {
    name: "نيبال",
    code: "NP",
  },
  {
    name: "هولندا",
    code: "NL",
  },
  {
    name: "جزر الأنتيل الهولندية",
    code: "AN",
  },
  {
    name: "كاليدونيا الجديدة",
    code: "NC",
  },
  {
    name: "نيوزيلندا",
    code: "NZ",
  },
  {
    name: "نيكاراغوا",
    code: "NI",
  },
  {
    name: "النيجر",
    code: "NE",
  },
  {
    name: "نيجيريا",
    code: "NG",
  },
  {
    name: "نيوي",
    code: "NU",
  },
  {
    name: "جزيرة نورفولك",
    code: "NF",
  },
  {
    name: "جزر ماريانا الشمالية",
    code: "MP",
  },
  {
    name: "النرويج",
    code: "NO",
  },
  {
    name: "عُمان",
    code: "OM",
  },
  {
    name: "باكستان",
    code: "PK",
  },
  {
    name: "بالاو",
    code: "PW",
  },
  {
    name: "الأراضي الفلسطينية المحتلة",
    code: "PS",
  },
  {
    name: "بنما",
    code: "PA",
  },
  {
    name: "بابوا غينيا الجديدة",
    code: "PG",
  },
  {
    name: "باراغواي",
    code: "PY",
  },
  {
    name: "بيرو",
    code: "PE",
  },
  {
    name: "الفلبين",
    code: "PH",
  },
  {
    name: "بيتكيرن",
    code: "PN",
  },
  {
    name: "بولندا",
    code: "PL",
  },
  {
    name: "البرتغال",
    code: "PT",
  },
  {
    name: "بورتوريكو",
    code: "PR",
  },
  {
    name: "قطر",
    code: "QA",
  },
  {
    name: "لا ريونيون",
    code: "RE",
  },
  {
    name: "رومانيا",
    code: "RO",
  },
  {
    name: "الاتحاد الروسي",
    code: "RU",
  },
  {
    name: "رواندا",
    code: "RW",
  },
  {
    name: "سانت هيلانة",
    code: "SH",
  },
  {
    name: "سانت كيتس ونيفيس",
    code: "KN",
  },
  {
    name: "سانت لوسيا",
    code: "LC",
  },
  {
    name: "سانت بيير وميكلون",
    code: "PM",
  },
  {
    name: "سانت فنسنت والغرينادين",
    code: "VC",
  },
  {
    name: "ساموا",
    code: "WS",
  },
  {
    name: "سان مارينو",
    code: "SM",
  },
  {
    name: "ساو تومي وبرينسيبي",
    code: "ST",
  },
  {
    name: "المملكة العربية السعودية",
    code: "SA",
  },
  {
    name: "السنغال",
    code: "SN",
  },
  {
    name: "صربيا والجبل الأسود",
    code: "CS",
  },
  {
    name: "سيشيل",
    code: "SC",
  },
  {
    name: "سيراليون",
    code: "SL",
  },
  {
    name: "سنغافورة",
    code: "SG",
  },
  {
    name: "سلوفاكيا",
    code: "SK",
  },
  {
    name: "سلوفينيا",
    code: "SI",
  },
  {
    name: "جزر سليمان",
    code: "SB",
  },
  {
    name: "الصومال",
    code: "SO",
  },
  {
    name: "جنوب أفريقيا",
    code: "ZA",
  },
  {
    name: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    code: "GS",
  },
  {
    name: "إسبانيا",
    code: "ES",
  },
  {
    name: "سريلانكا",
    code: "LK",
  },
  {
    name: "السودان",
    code: "SD",
  },
  {
    name: "سورينام",
    code: "SR",
  },
  {
    name: "سفالبارد ويان ماين",
    code: "SJ",
  },
  {
    name: "سوازيلاند",
    code: "SZ",
  },
  {
    name: "السويد",
    code: "SE",
  },
  {
    name: "سويسرا",
    code: "CH",
  },
  {
    name: "الجمهورية العربية السورية",
    code: "SY",
  },
  {
    name: "تايوان، إقليم الصين",
    code: "TW",
  },
  {
    name: "طاجيكستان",
    code: "TJ",
  },
  {
    name: "تنزانيا، جمهورية متحدة",
    code: "TZ",
  },
  {
    name: "تايلاند",
    code: "TH",
  },
  {
    name: "تيمور الشرقية",
    code: "TL",
  },
  {
    name: "توغو",
    code: "TG",
  },
  {
    name: "توكيلاو",
    code: "TK",
  },
  {
    name: "تونغا",
    code: "TO",
  },
  {
    name: "ترينيداد وتوباغو",
    code: "TT",
  },
  {
    name: "تونس",
    code: "TN",
  },
  {
    name: "تركيا",
    code: "TR",
  },
  {
    name: "تركمانستان",
    code: "TM",
  },
  {
    name: "جزر تركس وكايكوس",
    code: "TC",
  },
  {
    name: "توفالو",
    code: "TV",
  },
  {
    name: "أوغندا",
    code: "UG",
  },
  {
    name: "أوكرانيا",
    code: "UA",
  },
  {
    name: "الإمارات العربية المتحدة",
    code: "AE",
  },
  {
    name: "المملكة المتحدة",
    code: "GB",
  },
  {
    name: "الولايات المتحدة",
    code: "US",
  },
  {
    name: "الجزر البعيدة الصغيرة للولايات المتحدة",
    code: "UM",
  },
  {
    name: "أوروغواي",
    code: "UY",
  },
  {
    name: "أوزبكستان",
    code: "UZ",
  },
  {
    name: "فانواتو",
    code: "VU",
  },
  {
    name: "فنزويلا",
    code: "VE",
  },
  {
    name: "فيتنام",
    code: "VN",
  },
  {
    name: "جزر العذراء البريطانية",
    code: "VG",
  },
  {
    name: "جزر العذراء الأمريكية",
    code: "VI",
  },
  {
    name: "واليس وفوتونا",
    code: "WF",
  },
  {
    name: "الصحراء الغربية",
    code: "EH",
  },
  {
    name: "اليمن",
    code: "YE",
  },
  {
    name: "زامبيا",
    code: "ZM",
  },
  {
    name: "زيمبابوي",
    code: "ZW",
  },
];

export const COUNTRIES = countryPhoneData
  .map((country) => ({
    ...country,
    country_name_ar: COUNTRIES_NAMES_AR.find((c) => c.code === country.alpha2)?.name,
  }))
  .sort((a, b) =>
    a.country_name_ar && b.country_name_ar
      ? a.country_name_ar.localeCompare(b.country_name_ar)
      : a.country_name.localeCompare(b.country_name),
  );

export type Country = (typeof COUNTRIES)[number];
