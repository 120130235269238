<div class="event-card px-3">
  <div>
    <img
      image
      [src]="event.thumbnail_image?.source ?? event.banner_image?.source"
      class="img-fluid w-100 rounded-4"
      style="height: 350px"
      [alt]="event.title"
      [routerLink]="['/banner', event.id]"
    />
  </div>
  <div class="card mx-3 mx-lg-5 rounded-4 overlap-card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <p>{{ event.start_date | date: "yyyy/MM/dd" }}</p>
        <button
          *ifLoggedIn
          class="btn z-1"
          (click)="doAction($event)"
          [disabled]="loading"
          (mouseover)="onFocus()"
          (mouseleave)="onBlur()"
          (focus)="onFocus()"
          (blur)="onBlur()"
        >
          <fa-icon *ngIf="loading" [icon]="faCircleNotch" size="lg" animation="spin"></fa-icon>
          <fa-icon
            *ngIf="!loading && ((event.action_taken !== 'follow' && !focus) || (event.action_taken === 'follow' && focus))"
            [icon]="faBookmarkRegular"
            size="lg"
          ></fa-icon>
          <fa-icon
            *ngIf="
              !loading && ((event.action_taken === 'follow' && !focus) || ($any(event.action_taken) !== 'follow' && focus))
            "
            [icon]="faBookmarkSolid"
            size="lg"
            class="text-danger"
          ></fa-icon>
        </button>
      </div>
      <h6 class="fw-bold mb-4 card-title">
        {{ event.title }}
      </h6>
      <hr />
      <p class="text-l-gray">
        <fa-icon [icon]="faUser" class="me-2"></fa-icon>
        مكتب التربية العربي لدول الخليج
      </p>
      <p *ngIf="event.reviews_enabled && !!event.rating && !!event.users_rated && event.rating > 0 && event.users_rated > 0">
        تقييمات الحضور
        <app-rating [rating]="event.rating" [users]="event.users_rated" class="ms-3"></app-rating>
      </p>
    </div>
    <a [routerLink]="['/banner', event.id]" class="stretched-link">الذهاب للفعالية</a>
  </div>
</div>
