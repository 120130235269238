import {Location} from "@angular/common";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {catchError, Observable, switchMap, throwError} from "rxjs";

import {AuthService} from "../services/auth.service";
import {ToastService} from "../services/toast.service";
import {ErrorCode, HttpStatusCode, INTERCEPT, mapError, SILENCE_ERRORS} from "../utils";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private location: Location,
    private authSvc: AuthService,
    private toastSvc: ToastService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const silent = request.context.get(SILENCE_ERRORS);
    const intercept = request.context.get(INTERCEPT);

    const returnUrl = this.location.path();

    return next.handle(request).pipe(
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse) || !intercept) return throwError(() => error);

        if (error.status !== HttpStatusCode.UNAUTHORIZED) {
          return throwError(() => error);
        }

        const refreshToken = this.authSvc.getRefreshToken();

        if (!refreshToken) {
          this.clearAuth(returnUrl);
          return throwError(() => error);
        }

        return this.authSvc.refreshToken({token: refreshToken}).pipe(
          switchMap((tokens) => {
            this.authSvc.setToken(tokens.access_token.value);
            this.authSvc.setRefreshToken(tokens.refresh_token.value);
            return next.handle(request);
          }),
          catchError((error) => {
            this.clearAuth(returnUrl);
            return throwError(() => error);
          }),
        );
      }),
      catchError((error) => {
        if (!silent) {
          this.toastSvc.error(mapError(error));
        }
        return throwError(() => error)
      })
    );
  }

  clearAuth(returnUrl: string) {
    this.authSvc.clear();
    this.router.navigate(["login"], {queryParams: {returnUrl}});
  }
}
