import {AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2} from "@angular/core";

@Directive({
  selector: "img[image]",
})
export class ImageDirective implements AfterViewInit {
  error!: boolean;

  fallbackImages = {
    avatar: "/assets/images/avatar.svg",
    placeholder: "/assets/images/placeholder.svg",
  };

  _fallback: "avatar" | "placeholder" = "placeholder";

  @Input()
  set fallback(fallback: "avatar" | "placeholder") {
    this._fallback = fallback;
  }

  @Input()
  set src(src: string | null | undefined) {
    this.setLoading(true);
    this.setSrc(src);
  }

  constructor(
    private element: ElementRef<HTMLImageElement>,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    const parent = this.element.nativeElement.parentNode;
    const divElement = this.renderer.createElement("div");
    this.renderer.appendChild(divElement, this.renderer.createElement("div"));
    this.renderer.addClass(divElement, "image-loader");
    this.renderer.insertBefore(parent, divElement, this.element.nativeElement);
    this.renderer.removeChild(parent, this.element.nativeElement);
    this.renderer.removeAttribute(this.element.nativeElement, "image");
    this.renderer.appendChild(divElement, this.element.nativeElement);
  }

  setSrc(src: string | null | undefined) {
    this.renderer.setAttribute(this.element.nativeElement, "src", src ?? "");
  }

  setLoading(loading: boolean) {
    if (loading) {
      this.renderer.removeClass(this.element.nativeElement.parentNode, "loaded");
    } else {
      this.renderer.addClass(this.element.nativeElement.parentNode, "loaded");
    }
  }

  @HostListener("load", ["$event"])
  onLoad(event: Event) {
    this.setLoading(false);
  }

  @HostListener("error", ["$event"])
  onError() {
    this.setSrc(this.fallbackImages[this._fallback]);
  }
}
