import {Event} from "./event.dto";
import {Stamps} from "./helpers";
import {User} from "./user.dto";

export interface Announcement extends Stamps {
  event_id: string;
  event?: Event;
  user_id: string;
  user?: User;
  title: string;
  content: string;
}
