import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MatExpansionPanel} from "@angular/material/expansion";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Form, FormChoiceQuestionData} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {FormService, UpdateFormRequestBody} from "src/app/services/form.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsDirty, markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

type QuestionFormGroup = FormGroup<{
  id: FormControl<string | null | undefined>;
  title: FormControl<string>;
  data: FormArray<FormControl<string>>;
}>;

const EvaluationForm = v.object({
  title: v.string(),
  questions: v
    .array(
      v.object({
        id: v.string({format: "uuid", nullable: true}).optional(),
        title: v.string(),
        data: v.array(v.string()).min(1),
      }),
    )
    .min(1),
});

@Component({
  selector: "app-dashboard-form",
  templateUrl: "./dashboard-form.component.html",
  styleUrls: ["./dashboard-form.component.scss"],
})
export class DashboardFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      title: new FormControl("", {nonNullable: true}),
      questions: new FormArray<QuestionFormGroup>([]),
    },
    makeValidators(EvaluationForm),
  );

  loading!: boolean;

  form_id?: string;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private toastSvc: ToastService,
    private formSvc: FormService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const form_id = this.route.snapshot.params["form_id"];
    if (form_id) {
      this.form_id = form_id;
      this.getForm();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get title() {
    return this.form.controls.title;
  }

  get questions() {
    return this.form.controls.questions;
  }

  buildForm(form: Form) {
    this.title.setValue(form.title);
    this.questions.clear();
    const questions = form.questions ?? [];
    questions.forEach((question) => {
      if (question.type !== "choice") return;
      const choices = question.data;
      this.questions.push(
        new FormGroup({
          id: new FormControl<string | null | undefined>(question.id),
          title: new FormControl(question.title, {nonNullable: true}),
          data: new FormArray(choices.options.map((choice) => new FormControl(choice.value, {nonNullable: true}))),
        }),
      );
    });
    markAllAsPristine(this.form);
  }

  getForm() {
    if (!this.form_id) return;

    this.loading = true;
    this.formSvc.getForm(this.form_id).subscribe((form) => {
      this.buildForm(form);
      this.loading = false;
    });
  }

  copyQuestion(questionIndex: number, event?: globalThis.Event, panel?: MatExpansionPanel) {
    if (event && panel) {
      event.stopPropagation();
      panel.close();
    }
    const question = this.questions.at(questionIndex);
    if (question) {
      const rawValue = question.getRawValue();
      const copy = new FormGroup({
        id: new FormControl<string | null | undefined>(null),
        title: new FormControl(rawValue.title, {nonNullable: true}),
        data: new FormArray<FormControl<string>>(
          rawValue.data.map((choice) => new FormControl(choice, {nonNullable: true})),
        ),
      });
      this.questions.push(copy);
      markAllAsDirty(copy);
      this.cdRef.detectChanges();
    }
  }

  deleteQuestion(index: number) {
    this.questions.removeAt(index);
    updateFormStatus(this.form);
  }

  deleteChoice(questionIndex: number, choiceIndex: number) {
    const question = this.questions.at(questionIndex);
    if (!question) return;

    question.controls.data.removeAt(choiceIndex);

    updateFormStatus(this.form);
  }

  createQuestion() {
    const question = new FormGroup({
      id: new FormControl<string | null | undefined>(null),
      title: new FormControl("", {nonNullable: true}),
      data: new FormArray<FormControl<string>>([]),
    });
    this.questions.push(question);
    markAllAsDirty(question);
    this.cdRef.detectChanges();
  }

  createChoice(questionIndex: number, event?: globalThis.Event, panel?: MatExpansionPanel) {
    if (event && panel) {
      event.stopPropagation();
      panel.open();
    }
    const question = this.questions.at(questionIndex);
    if (!question) return;

    const choice = new FormControl("", {nonNullable: true});
    question.controls.data.push(choice);
    markAllAsDirty(choice);
    this.cdRef.detectChanges();
  }

  clearForm() {
    this.questions.clear();
    markAllAsDirty(this.form);
  }

  drop(event: CdkDragDrop<QuestionFormGroup>) {
    moveItemInArray(this.questions.controls, event.previousIndex, event.currentIndex);
  }

  prepareForm() {
    const form = this.form.getRawValue();

    const body: UpdateFormRequestBody = {
      title: form.title,
      graded: false,
      template: true,
      tags: ["evaluation"],
      questions: form.questions.map((question, index) => ({
        id: question.id,
        title: question.title,
        data: {
          type: "radio",
          options: question.data.map((choice) => ({value: choice})),
        } as FormChoiceQuestionData,
        grading: null,
        order: index + 1,
        type: "choice",
        description: null,
      })),
    };

    return body;
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    if (this.form_id) {
      this.formSvc
        .updateForm(this.form_id, this.prepareForm())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (form) => {
            this.buildForm(form);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.formSvc
        .createForm(this.prepareForm())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (form) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.router.navigate(["/dashboard", "forms", form.id]);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  isDirty() {
    return this.form.dirty;
  }
}
