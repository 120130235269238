import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {
  faArrowDownLong,
  faArrowUpLong,
  faCommentDots,
  faMagnifyingGlass,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Question, QuestionAction, User} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const QuestionForm = v.object({
  id: v.string({format: "uuid", nullable: true}).optional(),
  title: v.string(),
  content: v.string(),
});

const AnswerForm = v.object({
  id: v.string({format: "uuid", nullable: true}).optional(),
  content: v.string(),
});

@Component({
  selector: "app-event-qa",
  templateUrl: "./event-qa.component.html",
  styleUrls: ["./event-qa.component.scss"],
})
export class EventQaComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faMagnifyingGlass = faMagnifyingGlass;
  faArrowUpLong = faArrowUpLong;
  faArrowDownLong = faArrowDownLong;
  faPen = faPen;
  faTrash = faTrash;
  faCommentDots = faCommentDots;

  showQuestionForm!: boolean;
  currentToUpdateQuestion: string | null = null;

  currentToAnswerQuestion: string | null = null;
  currentToUpdateAnswer: string | null = null;

  loading!: boolean;

  questionForm = new FormGroup(
    {
      id: new FormControl(""),
      title: new FormControl("", {nonNullable: true}),
      content: new FormControl("", {nonNullable: true}),
    },
    makeValidators(QuestionForm),
  );

  answerForm = new FormGroup(
    {
      id: new FormControl(""),
      content: new FormControl("", {nonNullable: true}),
    },
    makeValidators(AnswerForm),
  );

  filterForm = new FormGroup({
    title: new FormControl(""),
    sort: new FormControl<"date_created" | "upvotes">("upvotes"),
  });

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  event_id!: string;

  user!: User | null;

  page = 0;
  totalRows!: number;
  hasNextPage!: boolean;

  questions: Question[] = [];

  constructor(
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private authSvc: AuthService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getUser();
    this.search();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUser() {
    this.authSvc
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  search(push = false) {
    this.loading = true;

    const {title, sort} = this.filterForm.getRawValue();

    if (!push) {
      this.questions = [];
    }

    this.eventSvc
      .listQuestions(this.event_id, {
        filter: {
          title: title ?? undefined,
        },
        sort: {
          upvotes: sort === "upvotes" ? "desc" : undefined,
          date_created: sort === "date_created" ? "desc" : undefined,
        },
        paginate: {
          page: this.page + 1,
          limit: 5,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.loading = false;
          this.totalRows = result.totalRows;
          this.hasNextPage = result.hasNextPage;
          this.questions.push(...result.rows);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  getNextPage() {
    this.page++;
    this.search(true);
  }

  toggleQuestionForm(questionIndex?: number) {
    this.currentToAnswerQuestion = null;
    this.currentToUpdateAnswer = null;
    if (questionIndex !== undefined) {
      this.showQuestionForm = false;
      const question = this.questions.at(questionIndex);
      if (question) {
        this.currentToUpdateQuestion = this.currentToUpdateQuestion ? null : question.id;
        if (this.currentToUpdateQuestion) {
          this.questionForm.reset({
            id: question.id,
            title: question.title,
            content: question.content,
          });
        }
      }
    } else {
      this.currentToUpdateQuestion = null;
      this.showQuestionForm = !this.showQuestionForm;
      this.questionForm.reset({
        id: null,
        title: "",
        content: "",
      });
    }
  }

  save(questionIndex?: number) {
    if (this.questionForm.invalid) {
      this.questionForm.markAllAsTouched();
      return;
    }

    this.loading = true;
    const {id, ...form} = this.questionForm.getRawValue();
    if (id) {
      this.eventSvc
        .updateQuestion(this.event_id, id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (question) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            if (questionIndex !== undefined) {
              this.questions[questionIndex] = question;
            }
            this.currentToUpdateQuestion = null;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createQuestion(this.event_id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (question) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.showQuestionForm = false;
            this.questions.unshift(question);
            this.totalRows++;
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  doAction(question: Question, action: QuestionAction) {
    this.eventSvc
      .doQuestionAction(this.event_id, question.id, action)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        question.upvotes = question.upvotes ?? 0;
        question.downvotes = question.downvotes ?? 0;
        if (action === "upvote") {
          question.upvotes++;
          if (question.action_taken === "downvote") question.downvotes--;
        } else {
          question.downvotes++;
          if (question.action_taken === "upvote") question.upvotes--;
        }
        question.action_taken = action;
      });
  }

  deleteQuestion(index: number) {
    const question = this.questions.at(index);

    if (!question) return;

    if (!this.user || (this.user.role !== "admin" && question.user_id !== this.user.id)) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          return this.eventSvc.deleteQuestion(this.event_id, question.id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.toastSvc.success("تم تنفيذ الأمر بنجاح");
        this.questions.splice(index, 1);
        this.totalRows--;
      });
  }

  toggleAnswerForm(questionIndex: number, answerIndex?: number) {
    const question = this.questions.at(questionIndex);
    if (!question) return;

    this.showQuestionForm = false;
    this.currentToUpdateQuestion = null;

    if (answerIndex !== undefined) {
      this.currentToAnswerQuestion = null;
      const answer = question.answers?.at(answerIndex);
      if (!answer) return;
      this.currentToUpdateAnswer = this.currentToUpdateAnswer ? null : answer.id;
      if (this.currentToUpdateAnswer) {
        this.answerForm.reset({
          id: answer.id,
          content: answer.content,
        });
      }
    } else {
      this.currentToUpdateAnswer = null;
      this.currentToAnswerQuestion = this.currentToAnswerQuestion ? null : question.id;
      this.answerForm.reset({
        id: null,
        content: "",
      });
    }
  }

  saveAnswer(questionIndex: number, answerIndex?: number) {
    const question = this.questions.at(questionIndex);
    if (!question) return;

    if (this.answerForm.invalid) {
      this.answerForm.markAllAsTouched();
      return;
    }

    this.loading = true;

    const {id, ...form} = this.answerForm.getRawValue();
    if (id) {
      this.eventSvc
        .updateQuestionAnswer(this.event_id, question.id, id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (answer) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            if (answerIndex !== undefined) {
              this.questions[questionIndex].answers?.splice(answerIndex, 1, answer);
            }
            this.currentToUpdateAnswer = null;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createQuestionAnswer(this.event_id, question.id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (answer) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.currentToAnswerQuestion = null;
            question.answers?.unshift(answer);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  deleteAnswer(questionIndex: number, answerIndex: number) {
    const question = this.questions.at(questionIndex);
    if (!question) return;

    const answer = question.answers?.at(answerIndex);
    if (!answer) return;

    if (!this.user || this.user.role !== "admin") return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          return this.eventSvc.deleteQuestionAnswer(this.event_id, question.id, answer.id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.toastSvc.success("تم تنفيذ الأمر بنجاح");
        this.questions[questionIndex].answers?.splice(answerIndex, 1);
      });
  }
}
