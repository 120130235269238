<div class="p-5">
  <div class="d-flex justify-content-between">
    <h2>البادجات</h2>
    <a mat-flat-button color="primary" [routerLink]="['/dashboard/badges/new']">إضافة</a>
  </div>

  <form class="mt-3" autocomplete="off" [formGroup]="form">
    <div class="row d-flex align-items-center my-3">
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>اسم البادج</mat-label>
          <input matInput placeholder="اسم البادج" formControlName="name" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-3 d-flex gap-2 p-3">
        <button mat-flat-button color="accent" (click)="reset()">إزالة</button>
        <button mat-flat-button color="accent" (click)="search()">بحث</button>
      </div>
    </div>
  </form>

  <div>
    <mtx-loader [loading]="loading">
      <mat-table matTableResponsive [dataSource]="dataSource">
        <!-- id Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; index as i">
            {{ i + 1 + pageIndex * 20 }}
          </mat-cell>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>اسم البادج</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>الحالة</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.status_ar }}
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a mat-icon-button matTooltip="تعديل" matTooltipPosition="above" [routerLink]="['/dashboard', 'badges', row.id]">
              <mat-icon>edit</mat-icon>
            </a>
            <button mat-icon-button matTooltip="حذف" matTooltipPosition="above" (click)="delete(row.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div *ngIf="this.dataSource.data.length === 0" class="text-center py-5">لا توجد بادجات</div>

      <mat-paginator
        #paginator
        [length]="totalRows"
        [pageIndex]="pageIndex"
        [pageSize]="20"
        (page)="pageChanged($event)"
      ></mat-paginator>
    </mtx-loader>
  </div>
</div>
