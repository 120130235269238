import {HttpClient, HttpContext} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {combineLatest, ReplaySubject, takeUntil} from "rxjs";
import {environment} from "src/environments/environment";

import {
  Announcement,
  Attendee,
  AttendeeStatus,
  Chapter,
  Event,
  EventAction,
  EventStatus,
  EventType,
  EventVisibility,
  File,
  FormResponse,
  Note,
  Paginate,
  PaginatedResult,
  Question,
  QuestionAction,
  QuestionAnswer,
  Review,
  ReviewAction,
  Section,
  SortDirection,
} from "../dtos";
import {queryFromJson, SILENCE_ERRORS} from "../utils";
import {AuthService} from "./auth.service";
import {PLATFORM_SERVICE, PlatformService} from "./platform.service";

export interface ListEventsQuery {
  filter?: {
    title?: string;
    tags?: string[];
    type?: EventType[];
    ended?: boolean;
    status?: EventStatus[];
    visibility?: EventVisibility;
    following?: boolean;
    attending?: boolean;
  };
  sort?: {
    date_created?: SortDirection;
  };
  paginate?: Paginate;
}

export interface CreateEventRequestBody {
  title: string;
  start_date: Date;
  end_date: Date;
  duration_in_seconds: number;
  type: EventType;
  visibility: EventVisibility;
}

export interface UpdateEventRequestBody {
  title?: string;
  subtitle?: string | null;
  description?: string | null;
  start_date?: Date;
  end_date?: Date;
  duration_in_seconds?: number;
  max_allowed_attendees?: number | null;
  thumbnail_image_id?: string | null;
  banner_image_id?: string | null;
  tags?: string[];
  objectives?: string | null;
  prerequisites?: string | null;
  audience?: string | null;
  type?: EventType;
  visibility?: EventVisibility;
  ended?: boolean;
  status?: EventStatus;
  certificate_id?: string | null;
  certificate_image_id?: string | null;
  badge_id?: string | null;
  badge_image_id?: string | null;
  evaluation_form_id?: string | null;
  questions_enabled?: boolean;
  reviews_enabled?: boolean;
  notes_enabled?: boolean;
  announcements_enabled?: boolean;
  has_meeting?: boolean;
  meeting_id?: string | null;
  min_attendance_duration_in_seconds?: number | null;
  payment_required?: boolean;
  price?: number | null;
  price_currency_code?: string | null;
}

export interface CreateChapterRequestBody {
  title: string;
  order: number;
}

export type UpdateChaptersRequestBody = {id: string; order: number}[];

export interface UpdateChapterRequestBody {
  title: string;
  order: number;
}

export interface CreateSectionRequestBody {
  title: string;
  description?: string | null;
  content: string;
  order: number;
  files: string[];
}

export type UpdateSectionsRequestBody = {id: string; order: number}[];

export interface UpdateSectionRequestBody {
  title: string;
  description?: string | null;
  content: string;
  order: number;
  files: string[];
}

export interface ListAttendeesQuery {
  filter?: {
    email?: string;
    full_name?: string;
    evaluated?: boolean;
    certificate?: boolean;
    status?: AttendeeStatus[];
  };
  sort?: {
    date_created?: SortDirection;
  };
  paginate?: Paginate;
}

export interface CreateAttendeeRequestBody {
  email: string;
  full_name: string;
  full_name_en?: string | null;
  status: AttendeeStatus;
}

export interface UpdateAttendeeRequestBody {
  full_name: string;
  full_name_en?: string | null;
  status: AttendeeStatus;
}

export interface NotifyAttendeeRequestBody {
  type: "notification" | "evaluation" | "certificate" | "registration";
}

export type NotifyAttendeesRequestBody =
  | {
      type: "notification" | "evaluation" | "certificate" | "registration";
    }
  | {type: "custom"; message: {subject: string; content: string}};

export interface CreateFileRequestBody {
  file_id: string;
}

export type UpdateFilesRequestBody = {id: string; order: number}[];

export type SubmitEvaluationRequestBody = {
  question_id: string;
  value: string;
}[];

export interface ListQuestionsQuery {
  filter?: {
    title?: string;
  };
  sort: {
    date_created?: SortDirection;
    upvotes?: SortDirection;
  };
  paginate?: Paginate;
}

export interface CreateQuestionRequestBody {
  title: string;
  content: string;
}

export interface UpdateQuestionRequestBody {
  title?: string;
  content?: string;
}

export interface ListQuestionAnswersQuery {
  paginate?: Paginate;
}

export interface CreateQuestionAnswerRequestBody {
  content: string;
}

export interface UpdateQuestionAnswerRequestBody {
  content?: string;
}

export interface ListNotesQuery {
  paginate?: Paginate;
}

export interface CreateNoteRequestBody {
  content: string;
}

export interface UpdateNoteRequestBody {
  content?: string;
}

export interface ListReviewsQuery {
  sort?: {
    likes?: SortDirection;
    rating?: SortDirection;
    date_created?: SortDirection;
  };
  paginate?: Paginate;
}

export interface CreateReviewRequestBody {
  content: string;
  rating: number;
}

export interface UpdateReviewRequestBody {
  content?: string;
  rating?: number;
}

export interface ListAnnouncementQuery {
  paginate?: Paginate;
}

export interface CreateAnnouncementRequestBody {
  title: string;
  content: string;
}

export interface UpdateAnnouncementRequestBody {
  title?: string;
  content?: string;
}

@Injectable({
  providedIn: "root",
})
export class EventService {
  private readonly V1_ENDPOINTS = {
    LIST_EVENTS: (query?: string) => `${environment.server.host}/api/v1/events` + (query ? `?${query}` : ""),
    CREATE_EVENT: () => `${environment.server.host}/api/v1/events`,
    GET_EVENT: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}`,
    UPDATE_EVENT: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}`,
    DELETE_EVENT: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}`,

    GET_CHAPTERS: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/chapters`,
    CREATE_CHAPTER: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/chapters`,
    UPDATE_CHAPTERS: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/chapters`,
    GET_CHAPTER: (event_id: string, chapter_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}`,
    UPDATE_CHAPTER: (event_id: string, chapter_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}`,
    DELETE_CHAPTER: (event_id: string, chapter_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}`,

    GET_SECTIONS: (event_id: string, chapter_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}/sections`,
    CREATE_SECTION: (event_id: string, chapter_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}/sections`,
    UPDATE_SECTIONS: (event_id: string, chapter_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}/sections`,
    GET_SECTION: (event_id: string, chapter_id: string, section_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}/sections/${section_id}`,
    UPDATE_SECTION: (event_id: string, chapter_id: string, section_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}/sections/${section_id}`,
    DELETE_SECTION: (event_id: string, chapter_id: string, section_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}/sections/${section_id}`,
    VIEW_SECTION: (event_id: string, chapter_id: string, section_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/chapters/${chapter_id}/sections/${section_id}/views`,

    ENROLL: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/enroll`,

    LIST_ATTENDEES: (event_id: string, query?: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/attendees` + (query ? `?${query}` : ""),
    CREATE_ATTENDEE: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/attendees`,
    GET_ATTENDEE: (event_id: string, attendee_id: string | "me") =>
      `${environment.server.host}/api/v1/events/${event_id}/attendees/${attendee_id}`,
    UPDATE_ATTENDEE: (event_id: string, attendee_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/attendees/${attendee_id}`,
    DELETE_ATTENDEE: (event_id: string, attendee_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/attendees/${attendee_id}`,

    CALCULATE_ATTENDANCE: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/attendees/calculate`,
    NOTIFY_ATTENDEES: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/attendees/notify`,
    NOTIFY_ATTENDEE: (event_id: string, attendee_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/attendees/${attendee_id}/notify`,
    IMPORT_ATTENDEES: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/attendees/import`,
    SCHEDULE_ATTENDEES_REGISTRATION: (event_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/attendees/register`,

    GET_FILES: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/files`,
    CREATE_FILE: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/files`,
    UPDATE_FILES: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/files`,

    DO_ACTION: (event_id: string, action: EventAction) =>
      `${environment.server.host}/api/v1/events/${event_id}/actions/${action}`,

    LIST_QUESTIONS: (event_id: string, query?: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions` + (query ? `?${query}` : ""),
    CREATE_QUESTION: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/questions`,
    GET_QUESTION: (event_id: string, question_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}`,
    UPDATE_QUESTION: (event_id: string, question_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}`,
    DELETE_QUESTION: (event_id: string, question_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}`,
    DO_QUESTION_ACTION: (event_id: string, question_id: string, action: QuestionAction) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}/actions/${action}`,
    LIST_QUESTION_ANSWERS: (event_id: string, question_id: string, query?: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}/answers` + (query ? `?${query}` : ""),
    CREATE_QUESTION_ANSWER: (event_id: string, question_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}/answers`,
    GET_QUESTION_ANSWER: (event_id: string, question_id: string, answer_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}/answers/${answer_id}`,
    UPDATE_QUESTION_ANSWER: (event_id: string, question_id: string, answer_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}/answers/${answer_id}`,
    DELETE_QUESTION_ANSWER: (event_id: string, question_id: string, answer_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/questions/${question_id}/answers/${answer_id}`,

    LIST_NOTES: (event_id: string, query?: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/notes` + (query ? `?${query}` : ""),
    CREATE_NOTE: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/notes`,
    GET_NOTE: (event_id: string, note_id: string) => `${environment.server.host}/api/v1/events/${event_id}/notes/${note_id}`,
    UPDATE_NOTE: (event_id: string, note_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/notes/${note_id}`,
    DELETE_NOTE: (event_id: string, note_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/notes/${note_id}`,

    LIST_REVIEWS: (event_id: string, query?: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/reviews` + (query ? `?${query}` : ""),
    CREATE_REVIEW: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/reviews`,
    GET_REVIEW: (event_id: string, review_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/reviews/${review_id}`,
    UPDATE_REVIEW: (event_id: string, review_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/reviews/${review_id}`,
    DELETE_REVIEW: (event_id: string, review_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/reviews/${review_id}`,
    DO_REVIEW_ACTION: (event_id: string, review_id: string, action: ReviewAction) =>
      `${environment.server.host}/api/v1/events/${event_id}/reviews/${review_id}/actions/${action}`,

    LIST_ANNOUNCEMENTS: (event_id: string, query?: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/announcements` + (query ? `?${query}` : ""),
    CREATE_ANNOUNCEMENT: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/announcements`,
    GET_ANNOUNCEMENT: (event_id: string, announcement_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/announcements/${announcement_id}`,
    UPDATE_ANNOUNCEMENT: (event_id: string, announcement_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/announcements/${announcement_id}`,
    DELETE_ANNOUNCEMENT: (event_id: string, announcement_id: string) =>
      `${environment.server.host}/api/v1/events/${event_id}/announcements/${announcement_id}`,

    GET_EVALUATION_FORM_RESPONSE: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/evaluation`,
    SUBMIT_EVALUATION: (event_id: string) => `${environment.server.host}/api/v1/events/${event_id}/evaluation`,
  };

  refresh$!: ReplaySubject<void>;
  event$!: ReplaySubject<Event>;
  attendee$!: ReplaySubject<Attendee>;
  section$!: ReplaySubject<string | null>;
  destroyed$!: ReplaySubject<void>;

  dashboardTabs$!: ReplaySubject<void>;

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private router: Router,
    private authSvc: AuthService,
  ) {}

  // Call this ONLY in ngOnInit lifecycle method of EventComponent
  init(event_id: string) {
    this.event$ = new ReplaySubject<Event>(1);
    this.attendee$ = new ReplaySubject<Attendee>(1);
    this.section$ = new ReplaySubject<string | null>(1);
    this.destroyed$ = new ReplaySubject<void>(1);
    this.refresh$ = new ReplaySubject<void>(1);

    this.refresh$.subscribe(() => {
      combineLatest([this.getEvent(event_id), this.getAttendee(event_id, "me")])
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: ([event, attendee]) => {
            this.event$.next(event);
            this.attendee$.next(attendee);
          },
          error: () => {
            this.destroyed$.next();
            this.router.navigate(["/events"]);
          },
        });
    });

    this.refresh$.next();
  }

  // Call this ONLY in ngOnDestroy lifecycle method of EventComponent
  destory() {
    this.refresh$?.complete();
    this.destroyed$?.next();
    this.destroyed$?.complete();
    this.event$?.complete();
    this.attendee$?.complete();
    this.section$?.complete();
  }

  initDashboard() {
    this.dashboardTabs$ = new ReplaySubject<void>(1);
  }

  checkDashboardTabs() {
    this.dashboardTabs$.next();
  }

  getDashboardTabs$() {
    return this.dashboardTabs$.asObservable();
  }

  destroyDashboard() {
    this.dashboardTabs$.complete();
  }

  getEvent$() {
    return this.event$.asObservable();
  }

  getAttendee$() {
    return this.attendee$.asObservable();
  }

  refresh() {
    this.refresh$.next();
  }

  listEvents(query?: ListEventsQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Event>>(this.V1_ENDPOINTS.LIST_EVENTS(_query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createEvent(body: CreateEventRequestBody, silent = false) {
    return this.httpClient.post<Event>(this.V1_ENDPOINTS.CREATE_EVENT(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getEvent(event_id: string, silent = false) {
    return this.httpClient.get<Event>(this.V1_ENDPOINTS.GET_EVENT(event_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateEvent(event_id: string, body: UpdateEventRequestBody, silent = false) {
    return this.httpClient.put<Event>(this.V1_ENDPOINTS.UPDATE_EVENT(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteEvent(event_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_EVENT(event_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getChapters(event_id: string, silent = false) {
    return this.httpClient.get<Chapter[]>(this.V1_ENDPOINTS.GET_CHAPTERS(event_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createChapter(event_id: string, body: CreateChapterRequestBody, silent = false) {
    return this.httpClient.post<Chapter>(this.V1_ENDPOINTS.CREATE_CHAPTER(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateChapters(event_id: string, body: UpdateChaptersRequestBody, silent = false) {
    return this.httpClient.put<Chapter[]>(this.V1_ENDPOINTS.UPDATE_CHAPTERS(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getChapter(event_id: string, chapter_id: string, silent = false) {
    return this.httpClient.get<Chapter>(this.V1_ENDPOINTS.GET_CHAPTER(event_id, chapter_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateChapter(event_id: string, chapter_id: string, body: UpdateChapterRequestBody, silent = false) {
    return this.httpClient.put<Chapter>(this.V1_ENDPOINTS.UPDATE_CHAPTER(event_id, chapter_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteChapter(event_id: string, chapter_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_CHAPTER(event_id, chapter_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getSections(event_id: string, chapter_id: string, silent = false) {
    return this.httpClient.get<Section[]>(this.V1_ENDPOINTS.GET_SECTIONS(event_id, chapter_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createSection(event_id: string, chapter_id: string, body: CreateSectionRequestBody, silent = false) {
    return this.httpClient.post<Section>(this.V1_ENDPOINTS.CREATE_SECTION(event_id, chapter_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateSections(event_id: string, chapter_id: string, body: UpdateSectionsRequestBody, silent = false) {
    return this.httpClient.put<Section[]>(this.V1_ENDPOINTS.UPDATE_SECTIONS(event_id, chapter_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getSection(event_id: string, chapter_id: string, section_id: string, silent = false) {
    return this.httpClient.get<Section>(this.V1_ENDPOINTS.GET_SECTION(event_id, chapter_id, section_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateSection(event_id: string, chapter_id: string, section_id: string, body: UpdateSectionRequestBody, silent = false) {
    return this.httpClient.put<Section>(this.V1_ENDPOINTS.GET_SECTION(event_id, chapter_id, section_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteSection(event_id: string, chapter_id: string, section_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_SECTION(event_id, chapter_id, section_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  viewSection(event_id: string, chapter_id: string, section_id: string, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.VIEW_SECTION(event_id, chapter_id, section_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  listAttendees(event_id: string, query?: ListAttendeesQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Attendee>>(this.V1_ENDPOINTS.LIST_ATTENDEES(event_id, _query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createAttendee(event_id: string, body: CreateAttendeeRequestBody, silent = false) {
    return this.httpClient.post<Attendee>(this.V1_ENDPOINTS.CREATE_ATTENDEE(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getAttendee(event_id: string, attendee_id: string | "me", silent = false) {
    return this.httpClient.get<Attendee>(this.V1_ENDPOINTS.GET_ATTENDEE(event_id, attendee_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateAttendee(event_id: string, attendee_id: string, body: UpdateAttendeeRequestBody, silent = false) {
    return this.httpClient.put<Attendee>(this.V1_ENDPOINTS.UPDATE_ATTENDEE(event_id, attendee_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteAttendee(event_id: string, attendee_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_ATTENDEE(event_id, attendee_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  calculateAttendance(event_id: string, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.CALCULATE_ATTENDANCE(event_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  notifyAttendees(event_id: string, body: NotifyAttendeesRequestBody, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.NOTIFY_ATTENDEES(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  notifyAttendee(event_id: string, attendee_id: string, body: NotifyAttendeeRequestBody, silent = false) {
    return this.httpClient.post<void>(this.V1_ENDPOINTS.NOTIFY_ATTENDEE(event_id, attendee_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  importAttendees(event_id: string, file: globalThis.File, silent = false) {
    const formData = new FormData();

    formData.append("files", file, file.name);

    return this.httpClient.post<void>(this.V1_ENDPOINTS.IMPORT_ATTENDEES(event_id), formData, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  scheduleAttendeesRegistration(event_id: string, silent = false) {
    return this.httpClient.post<void>(
      this.V1_ENDPOINTS.SCHEDULE_ATTENDEES_REGISTRATION(event_id),
      {},
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  getFiles(event_id: string, silent = false) {
    return this.httpClient.get<File[]>(this.V1_ENDPOINTS.GET_FILES(event_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createFile(event_id: string, body: CreateFileRequestBody, silent = false) {
    return this.httpClient.post<File[]>(this.V1_ENDPOINTS.CREATE_FILE(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateFiles(event_id: string, body: UpdateFilesRequestBody, silent = false) {
    return this.httpClient.put<File[]>(this.V1_ENDPOINTS.UPDATE_FILES(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  enroll(event_id: string, silent = false) {
    return this.httpClient.post<Attendee>(
      this.V1_ENDPOINTS.ENROLL(event_id),
      {},
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  doAction(event_id: string, action: EventAction, silent = false) {
    return this.httpClient.post<void>(
      this.V1_ENDPOINTS.DO_ACTION(event_id, action),
      {},
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  listQuestions(event_id: string, query?: ListQuestionsQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Question>>(this.V1_ENDPOINTS.LIST_QUESTIONS(event_id, _query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createQuestion(event_id: string, body: CreateQuestionRequestBody, silent = false) {
    return this.httpClient.post<Question>(this.V1_ENDPOINTS.CREATE_QUESTION(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getQuestion(event_id: string, question_id: string, silent = false) {
    return this.httpClient.get<Question>(this.V1_ENDPOINTS.GET_QUESTION(event_id, question_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateQuestion(event_id: string, question_id: string, body: UpdateQuestionRequestBody, silent = false) {
    return this.httpClient.put<Question>(this.V1_ENDPOINTS.UPDATE_QUESTION(event_id, question_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteQuestion(event_id: string, question_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_QUESTION(event_id, question_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  doQuestionAction(event_id: string, question_id: string, action: QuestionAction, silent = false) {
    return this.httpClient.post<void>(
      this.V1_ENDPOINTS.DO_QUESTION_ACTION(event_id, question_id, action),
      {},
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  listQuestionAnswers(event_id: string, question_id: string, query?: ListQuestionAnswersQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<QuestionAnswer>>(
      this.V1_ENDPOINTS.LIST_QUESTION_ANSWERS(event_id, question_id, _query),
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  createQuestionAnswer(event_id: string, question_id: string, body: CreateQuestionAnswerRequestBody, silent = false) {
    return this.httpClient.post<QuestionAnswer>(this.V1_ENDPOINTS.CREATE_QUESTION_ANSWER(event_id, question_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getQuestionAnswer(event_id: string, question_id: string, answer_id: string, silent = false) {
    return this.httpClient.get<QuestionAnswer>(this.V1_ENDPOINTS.GET_QUESTION_ANSWER(event_id, question_id, answer_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateQuestionAnswer(
    event_id: string,
    question_id: string,
    answer_id: string,
    body: UpdateQuestionAnswerRequestBody,
    silent = false,
  ) {
    return this.httpClient.put<QuestionAnswer>(
      this.V1_ENDPOINTS.UPDATE_QUESTION_ANSWER(event_id, question_id, answer_id),
      body,
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  deleteQuestionAnswer(event_id: string, question_id: string, answer_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_QUESTION_ANSWER(event_id, question_id, answer_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  listNotes(event_id: string, query?: ListNotesQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Note>>(this.V1_ENDPOINTS.LIST_NOTES(event_id, _query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createNote(event_id: string, body: CreateNoteRequestBody, silent = false) {
    return this.httpClient.post<Note>(this.V1_ENDPOINTS.CREATE_NOTE(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getNote(event_id: string, note_id: string, silent = false) {
    return this.httpClient.get<Note>(this.V1_ENDPOINTS.GET_NOTE(event_id, note_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateNote(event_id: string, note_id: string, body: UpdateNoteRequestBody, silent = false) {
    return this.httpClient.put<Note>(this.V1_ENDPOINTS.UPDATE_NOTE(event_id, note_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteNote(event_id: string, note_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_NOTE(event_id, note_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  listReviews(event_id: string, query?: ListReviewsQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Review>>(this.V1_ENDPOINTS.LIST_REVIEWS(event_id, _query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createReview(event_id: string, body: CreateReviewRequestBody, silent = false) {
    return this.httpClient.post<Review>(this.V1_ENDPOINTS.CREATE_REVIEW(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getReview(event_id: "me" | string, review_id: string, silent = false) {
    return this.httpClient.get<Review>(this.V1_ENDPOINTS.GET_REVIEW(event_id, review_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateReview(event_id: string, review_id: string, body: UpdateReviewRequestBody, silent = false) {
    return this.httpClient.put<Review>(this.V1_ENDPOINTS.UPDATE_REVIEW(event_id, review_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteReview(event_id: string, review_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_REVIEW(event_id, review_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  doReviewAction(event_id: string, review_id: string, action: ReviewAction, silent = false) {
    return this.httpClient.post<void>(
      this.V1_ENDPOINTS.DO_REVIEW_ACTION(event_id, review_id, action),
      {},
      {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      },
    );
  }

  listAnnouncements(event_id: string, query?: ListAnnouncementQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Announcement>>(this.V1_ENDPOINTS.LIST_ANNOUNCEMENTS(event_id, _query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createAnnouncement(event_id: string, body: CreateAnnouncementRequestBody, silent = false) {
    return this.httpClient.post<Announcement>(this.V1_ENDPOINTS.CREATE_ANNOUNCEMENT(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getAnnouncement(event_id: string, announcement_id: string, silent = false) {
    return this.httpClient.get<Announcement>(this.V1_ENDPOINTS.GET_ANNOUNCEMENT(event_id, announcement_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateAnnouncement(event_id: string, announcement_id: string, body: UpdateAnnouncementRequestBody, silent = false) {
    return this.httpClient.put<Announcement>(this.V1_ENDPOINTS.UPDATE_ANNOUNCEMENT(event_id, announcement_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteAnnouncement(event_id: string, announcement_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_ANNOUNCEMENT(event_id, announcement_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getEvaluationFormResponse(event_id: string, silent = false) {
    return this.httpClient.get<FormResponse>(this.V1_ENDPOINTS.GET_EVALUATION_FORM_RESPONSE(event_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  submitEvaluation(event_id: string, body: SubmitEvaluationRequestBody, silent = false) {
    return this.httpClient.post<FormResponse>(this.V1_ENDPOINTS.SUBMIT_EVALUATION(event_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
