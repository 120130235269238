import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {combineLatest, filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Chapter, File, Section} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsDirty, markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

import {FileDialogComponent, FileDialogData} from "../../shared/file-dialog/file-dialog.component";

type FileFormGroup = FormGroup<{id: FormControl<string>; filename_download: FormControl<string>}>;

type SectionFormGroup = FormGroup<{
  id: FormControl<string | null | undefined>;
  title: FormControl<string>;
  description: FormControl<string | null | undefined>;
  content: FormControl<string>;
  files: FormArray<FileFormGroup>;
}>;

const SectionsForm = v.object({
  sections: v.array(
    v.object({
      id: v.string({format: "uuid", nullable: true}).optional(),
      title: v.string(),
      description: v.string({nullable: true}).optional(),
      content: v.string(),
      files: v.array(
        v.object({
          id: v.string({format: "uuid"}),
        }),
      ),
    }),
  ),
});

@Component({
  selector: "app-dashboard-event-sections-form",
  templateUrl: "./dashboard-event-sections-form.component.html",
  styleUrls: ["./dashboard-event-sections-form.component.scss"],
})
export class DashboardEventSectionsFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      sections: new FormArray<SectionFormGroup>([]),
    },
    makeValidators(SectionsForm),
  );

  loading!: boolean;

  event_id!: string;
  chapter_id!: string;
  chapter?: Chapter;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private toastSvc: ToastService,
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private confirmSvc: ConfirmService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.chapter_id = this.route.snapshot.params["chapter_id"];
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get sections() {
    return this.form.controls.sections;
  }

  getData() {
    this.loading = true;
    combineLatest([
      this.eventSvc.getChapter(this.event_id, this.chapter_id),
      this.eventSvc.getSections(this.event_id, this.chapter_id),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([chapter, sections]) => {
        this.chapter = chapter;
        this.buildForm(sections);
        this.loading = false;
      });
  }

  getSections() {
    this.loading = true;
    this.eventSvc
      .getSections(this.event_id, this.chapter_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sections) => {
        this.buildForm(sections);
        this.loading = false;
      });
  }

  buildForm(sections: Section[]) {
    this.sections.clear();
    sections.forEach((section) => {
      this.sections.push(
        new FormGroup({
          id: new FormControl<string | null | undefined>(section.id),
          title: new FormControl(section.title, {nonNullable: true}),
          description: new FormControl<string | null | undefined>(section.description),
          content: new FormControl(section.content, {nonNullable: true}),
          files: new FormArray<FileFormGroup>(
            section.files?.map(
              (file) =>
                new FormGroup({
                  id: new FormControl(file.id, {nonNullable: true}),
                  filename_download: new FormControl(file.filename_download, {nonNullable: true}),
                }),
            ) ?? [],
          ),
        }),
      );
    });
    markAllAsPristine(this.form);
  }

  pushNewSection() {
    const section = new FormGroup({
      id: new FormControl<string | null | undefined>(null),
      title: new FormControl("", {nonNullable: true}),
      description: new FormControl<string | null | undefined>(null),
      content: new FormControl("", {nonNullable: true}),
      files: new FormArray<FileFormGroup>([]),
    });
    this.sections.push(section);
    markAllAsDirty(section);
    this.cdRef.detectChanges();
  }

  deleteSection(sectionIndex: number) {
    const section = this.sections.at(sectionIndex);
    if (section) {
      const {id} = section.getRawValue();
      if (id) {
        this.confirmSvc
          .confirm()
          .afterClosed()
          .pipe(
            filter((result) => !!result),
            switchMap(() => {
              this.loading = true;
              return this.eventSvc.deleteSection(this.event_id, this.chapter_id, id);
            }),
            takeUntil(this.unsubscribe$),
          )
          .subscribe({
            next: () => {
              this.toastSvc.success("تم تنفيذ الأمر بنجاح");
              this.loading = false;
              this.getSections();
            },
            error: () => {
              this.loading = false;
            },
          });
      } else {
        this.sections.removeAt(sectionIndex);
        updateFormStatus(this.form);
      }
    }
  }

  saveSection(section: SectionFormGroup, index: number) {
    if (section.invalid) {
      section.markAllAsTouched();
      return;
    }

    const {id, title, description, content, files} = section.getRawValue();
    this.loading = true;
    if (id) {
      this.eventSvc
        .updateSection(this.event_id, this.chapter_id, id, {
          title: title,
          description,
          content,
          files: files.map((file) => file.id),
          order: index + 1,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.getSections();
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createSection(this.event_id, this.chapter_id, {
          title,
          description,
          content,
          files: files.map((file) => file.id),
          order: index + 1,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.getSections();
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  updateSections() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const body = this.form
      .getRawValue()
      .sections.filter((section) => !!section.id)
      .map((section, index) => ({id: section.id as string, order: index + 1}));

    this.loading = true;
    this.eventSvc
      .updateSections(this.event_id, this.chapter_id, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.getSections();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  drop(event: CdkDragDrop<SectionFormGroup>) {
    moveItemInArray(this.sections.controls, event.previousIndex, event.currentIndex);
    this.updateSections();
  }

  openFileDialog(sectionIndex: number, file_id?: string) {
    this.dialog
      .open<FileDialogComponent, FileDialogData>(FileDialogComponent, {data: {id: file_id}})
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: boolean | File | undefined) => {
        if (result === true) {
          this.getSectionAtIndex(sectionIndex);
        } else if (!result) {
          return;
        } else {
          const section = this.sections.at(sectionIndex);
          section.controls.files.push(
            new FormGroup({
              id: new FormControl(result.id, {nonNullable: true}),
              filename_download: new FormControl(result.filename_download, {nonNullable: true}),
            }),
          );
        }
      });
  }

  getSectionAtIndex(sectionIndex: number) {
    const section = this.sections.at(sectionIndex);
    const {id} = section.getRawValue();
    if (id) {
      this.loading = true;
      this.eventSvc
        .getSection(this.event_id, this.chapter_id, id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (result) => {
            section.patchValue({
              id: result.id,
              title: result.title,
              description: result.description,
              content: result.content,
            });
            section.controls.files.clear();
            result.files?.forEach((file) => {
              section.controls.files.push(
                new FormGroup({
                  id: new FormControl(file.id, {nonNullable: true}),
                  filename_download: new FormControl(file.filename_download, {nonNullable: true}),
                }),
              );
            });
            markAllAsPristine(section);
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  isDirty() {
    return this.form.dirty;
  }
}
