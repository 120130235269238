<mtx-loader [loading]="loading">
  <form #ngForm="ngForm" [formGroup]="form" class="p-5" (ngSubmit)="save()">
    <div class="d-flex justify-content-between">
      <h2>{{ !certificate_id ? "إضافة" : "تعديل" }} شهادة</h2>
      <div class="d-flex gap-2">
        <a *ngIf="previewUrl" [href]="previewUrl" mat-flat-button color="accent" target="_blank">استعراض</a>
        <button mat-flat-button color="primary" type="submit">حفظ</button>
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>اسم الشهادة</mat-label>
            <input formControlName="name" type="text" matInput placeholder="اسم الشهادة" />
            <mat-error *ngIf="name.invalid">{{ name.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-6 d-flex flex-column justify-content-between align-items-center my-4 gap-3">
          <img
            image
            [src]="imageSrc"
            style="height: 200px"
            class="img-fluid"
            alt="thumbnail"
            [ngClass]="{'border border-2 border-danger': errorStateMatcher.isErrorState(image_id, ngForm)}"
          />
          <input #fileInput type="file" class="visually-hidden" (change)="onFileChange($event, image_id)" />
          <p *ngIf="errorStateMatcher.isErrorState(image_id, ngForm)" class="text-danger m-0">يرجى إختيار صورة الشهادة</p>
          <button mat-flat-button (click)="fileInput.click()" color="accent" class="w-100" type="button">
            اختر صورة الشهادة
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h5>الإعدادات</h5>
          <h5 *ngIf="!fields.controls.length" class="text-danger">لا توجد إعدادات لحقول الشهادة.</h5>
          <div
            class="border border-2 pt-4 px-lg-5"
            *ngFor="let field of fields.controls; index as fieldIndex"
            [formGroup]="field"
          >
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-8 col-10">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>الحقل</mat-label>
                  <mat-select [formControl]="field.controls.name">
                    <mat-option *ngFor="let name of names" [value]="name.value" [disabled]="selected(name.value)">
                      {{ name.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="field.controls.name.invalid">{{ field.controls.name.errors | error }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-2">
                <button
                  matTooltip="حذف الحقل"
                  matTooltipPosition="above"
                  mat-icon-button
                  (click)="deleteField(fieldIndex)"
                  type="button"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <div>
              <table class="table table-bordered">
                <caption class="caption-top fw-bold text-black">
                  إعدادات الحقل
                  <button
                    *ngIf="hasRemainingProperties(field.controls.properties)"
                    (click)="pushNewProperty(field.controls.properties)"
                    class="btn btn-sm"
                    type="button"
                  >
                    <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </caption>
                <thead>
                  <tr>
                    <th>المفتاح</th>
                    <th>القيمة</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td *ngIf="isEmpty(field.controls.properties)" colspan="3">لم يتم إضافة قيم</td>
                  </tr>
                  <tr *ngFor="let property of field.controls.properties.controls | keyvalue">
                    <td>
                      <select
                        class="form-select focus-shadow-none"
                        (change)="onPropertyChange(field.controls.properties, property.key, $any($event.target)?.value)"
                      >
                        <option
                          *ngFor="let validProperty of VALID_PROPERTIES"
                          [value]="validProperty"
                          [selected]="validProperty === property.key"
                          [disabled]="isPropertySelected(field.controls.properties, validProperty)"
                          dir="ltr"
                        >
                          {{ validProperty }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <div>
                        <input
                          type="text"
                          class="form-control focus-shadow-none"
                          [formControl]="$any(property.value)"
                          dir="ltr"
                          [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(property.value, ngForm)}"
                        />
                        <div *ngIf="property.value.invalid" class="invalid-feedback">
                          {{ property.value.errors | error }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <button class="btn btn-sm btn-danger" (click)="field.controls.properties.removeControl(property.key)">
                        <fa-icon [icon]="faTrash"></fa-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button *ngIf="hasRemainingField" class="mt-3 w-100" mat-flat-button (click)="pushNewField()" type="button">
            <span>إضافة المزيد</span>
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <div class="col-12 mt-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الحالة</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let s of statuses" [value]="s.value">
                {{ s.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="status.invalid">{{ status.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
