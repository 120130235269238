import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";

import {ToastComponent} from "../components/shared/toast/toast.component";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private snackbar: MatSnackBar) {}

  info(message = "تم التنفيذ بنجاح", description?: string) {
    this.snackbar.openFromComponent(ToastComponent, {
      data: {
        level: "info",
        message,
        description,
      },
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 5000,
    });
  }

  success(message = "تم التنفيذ بنجاح", description?: string) {
    this.snackbar.openFromComponent(ToastComponent, {
      data: {
        level: "success",
        message,
        description,
      },
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 5000,
    });
  }

  error(message = "فشل تنفيذ الأمر برجاء المحاولة لاحقًا", description?: string) {
    this.snackbar.openFromComponent(ToastComponent, {
      data: {
        level: "error",
        message,
        description,
      },
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 5000,
    });
  }
}
