import {Component, EventEmitter, Output} from "@angular/core";

@Component({
  selector: "app-social-auth",
  templateUrl: "./social-auth.component.html",
  styleUrls: ["./social-auth.component.scss"],
})
export class SocialAuthComponent {
  @Output() login = new EventEmitter<"google">();
}
