import {Component} from "@angular/core";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent {
  faArrowLeft = faArrowLeft;
}
