<div class="p-4">
  <section class="mb-4">
    <h4 class="section-title fw-bold">{{ event?.title }}</h4>
    <p *ngIf="event?.subtitle">{{ event?.subtitle }}</p>

    <hr class="my-3" />

    <div class="row gap-2">
      <div *ngIf="event?.duration_in_seconds" class="col-auto">
        <fa-icon [icon]="faClock" class="me-2"></fa-icon>
        إجمالي الساعات : {{ duration_in_hours }}
      </div>
      <div *ngIf="sections" class="col-auto">
        <fa-icon [icon]="faBook" class="me-2"></fa-icon>
        عدد المحاضرات : {{ sections }} محاضرة
      </div>
      <div *ngIf="event?.date_updated" class="col-auto">
        <fa-icon [icon]="faPenToSquare" class="me-2"></fa-icon>
        آخر تحديث: {{ event?.date_updated | date: "yyyy/MM/dd" }}
      </div>
    </div>
  </section>

  <section *ngIf="event?.description" class="mb-4">
    <p class="section-title fw-bold">الوصف</p>
    <hr class="my-3" />
    <p [innerHTML]="event?.description"></p>
  </section>

  <section *ngIf="event?.objectives" class="mb-4">
    <p class="section-title fw-bold">أهداف ال{{ event?.type_ar }}</p>
    <hr class="my-3" />
    <p [innerHTML]="event?.objectives"></p>
  </section>

  <section *ngIf="event?.prerequisites" class="mb-4">
    <p class="section-title fw-bold">متطلبات ال{{ event?.type_ar }}</p>
    <hr class="my-3" />
    <p [innerHTML]="event?.prerequisites"></p>
  </section>

  <section *ngIf="event?.audience" class="mb-4">
    <p class="section-title fw-bold">المستهدفون من ال{{ event?.type_ar }}</p>
    <hr class="my-3" />
    <p [innerHTML]="event?.audience"></p>
  </section>

  <section *ngIf="event?.badge_id" class="mb-4">
    <p class="section-title fw-bold">بادج الحضور</p>
    <hr class="my-3" />
    <p>احرص على اصطحاب البادج معك لحضور ال{{ event?.type_ar }}</p>

    <button class="btn btn-outline-secondary px-4 py-3 me-3 rounded-0" (click)="downloadBadge()">بادج الحضور</button>
  </section>

  <section *ngIf="event?.certificate_id" class="mb-4">
    <p class="section-title fw-bold">الشهادة</p>
    <hr class="my-3" />
    <p>
      احصل على الشهادة من خلال إكمال ال{{ event?.type_ar ?? "فعالية" }} {{
        event?.evaluation_form_id ? " و التقييم" : ""
      }}
    </p>

    <a
      *ngIf="evaluation"
      class="btn btn-outline-secondary px-4 py-3 me-3 rounded-0"
      [routerLink]="['/events', event_id, 'evaluation']"
    >
      تقييم ال{{ event?.type_ar ?? "فعالية" }}
    </a>

    <button *ngIf="certificate" class="btn btn-outline-secondary px-4 py-3 me-3 rounded-0" (click)="downloadCertificate()">
      شهادة إتمام ال{{ event?.type_ar ?? "فعالية" }}
    </button>
  </section>
</div>
