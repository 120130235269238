import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {AuthComponent} from "./components/auth/auth.component";
import {ForgotPasswordComponent} from "./components/auth/forgot-password/forgot-password.component";
import {LoginComponent} from "./components/auth/login/login.component";
import {RegisterComponent} from "./components/auth/register/register.component";
import {ResetPasswordComponent} from "./components/auth/reset-password/reset-password.component";
import {VerifyEmailComponent} from "./components/auth/verify-email/verify-email.component";
import {BannerComponent} from "./components/banner/banner.component";
import {CatalogComponent} from "./components/catalog/catalog.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {DashboardAttendeeFormComponent} from "./components/dashboard/dashboard-attendee-form/dashboard-attendee-form.component";
import {DashboardAttendeesListComponent} from "./components/dashboard/dashboard-attendees-list/dashboard-attendees-list.component";
import {DashboardBadgeFormComponent} from "./components/dashboard/dashboard-badge-form/dashboard-badge-form.component";
import {DashboardBadgesListComponent} from "./components/dashboard/dashboard-badges-list/dashboard-badges-list.component";
import {DashboardCertificateFormComponent} from "./components/dashboard/dashboard-certificate-form/dashboard-certificate-form.component";
import {DashboardCertificatesListComponent} from "./components/dashboard/dashboard-certificates-list/dashboard-certificates-list.component";
import {DashboardCountriesListComponent} from "./components/dashboard/dashboard-countries-list/dashboard-countries-list.component";
import {DashboardCountryFormComponent} from "./components/dashboard/dashboard-country-form/dashboard-country-form.component";
import {DashboardEventChaptersFormComponent} from "./components/dashboard/dashboard-event-chapters-form/dashboard-event-chapters-form.component";
import {DashboardEventDocumentsFormComponent} from "./components/dashboard/dashboard-event-documents-form/dashboard-event-documents-form.component";
import {DashboardEventEvaluationFormComponent} from "./components/dashboard/dashboard-event-evaluation-form/dashboard-event-evaluation-form.component";
import {DashboardEventFormComponent} from "./components/dashboard/dashboard-event-form/dashboard-event-form.component";
import {DashboardEventInfoFormComponent} from "./components/dashboard/dashboard-event-info-form/dashboard-event-info-form.component";
import {DashboardEventMeetingFormComponent} from "./components/dashboard/dashboard-event-meeting-form/dashboard-event-meeting-form.component";
import {DashboardEventSectionsFormComponent} from "./components/dashboard/dashboard-event-sections-form/dashboard-event-sections-form.component";
import {DashboardEventSettingsFormComponent} from "./components/dashboard/dashboard-event-settings-form/dashboard-event-settings-form.component";
import {DashboardEventWizardComponent} from "./components/dashboard/dashboard-event-wizard/dashboard-event-wizard.component";
import {DashboardEventsListComponent} from "./components/dashboard/dashboard-events-list/dashboard-events-list.component";
import {DashboardFormComponent} from "./components/dashboard/dashboard-form/dashboard-form.component";
import {DashboardFormsListComponent} from "./components/dashboard/dashboard-forms-list/dashboard-forms-list.component";
import {DashboardLandingComponent} from "./components/dashboard/dashboard-landing/dashboard-landing.component";
import {DashboardTicketFormComponent} from "./components/dashboard/dashboard-ticket-form/dashboard-ticket-form.component";
import {DashboardTicketsListComponent} from "./components/dashboard/dashboard-tickets-list/dashboard-tickets-list.component";
import {DashboardUserFormComponent} from "./components/dashboard/dashboard-user-form/dashboard-user-form.component";
import {DashboardUsersListComponent} from "./components/dashboard/dashboard-users-list/dashboard-users-list.component";
import {EventComponent} from "./components/event/event.component";
import {EventDetailsComponent} from "./components/event/event-details/event-details.component";
import {EventEvaluationComponent} from "./components/event/event-evaluation/event-evaluation.component";
import {EventLandingComponent} from "./components/event/event-landing/event-landing.component";
import {LandingComponent} from "./components/landing/landing.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {ProfileDetailsComponent} from "./components/profile/profile-details/profile-details.component";
import {ProfileEventsComponent} from "./components/profile/profile-events/profile-events.component";
import {ProfileFavoritesComponent} from "./components/profile/profile-favorites/profile-favorites.component";
import {ProfileSecurityComponent} from "./components/profile/profile-security/profile-security.component";
import {ProfileTicketFormComponent} from "./components/profile/profile-ticket-form/profile-ticket-form.component";
import {ProfileTicketsListComponent} from "./components/profile/profile-tickets-list/profile-tickets-list.component";
import {ProfileWizardComponent} from "./components/profile-wizard/profile-wizard.component";
import {VerifyCertificateComponent} from "./components/verify-certificate/verify-certificate.component";
import {blockLoggedIn, guard, loggedIn} from "./guards/auth.guard";
import {dataLossGuard} from "./guards/data-loss.guard";

const routes: Routes = [
  {path: "", component: LandingComponent},
  {path: "profile-wizard", canActivate: [guard()], component: ProfileWizardComponent},
  {
    path: "",
    component: AuthComponent,
    children: [
      {path: "login", canActivate: [guard(blockLoggedIn)], component: LoginComponent},
      {path: "register", canActivate: [guard(blockLoggedIn)], component: RegisterComponent},
      {path: "forgot-password", canActivate: [guard(blockLoggedIn)], component: ForgotPasswordComponent},
      {path: "reset-password", canActivate: [guard(blockLoggedIn)], component: ResetPasswordComponent},
      {path: "verify-email", component: VerifyEmailComponent},
    ],
  },
  {path: "banner/:event_id", component: BannerComponent},
  {path: "events", component: CatalogComponent},
  {
    path: "events/:event_id",
    component: EventComponent,
    canActivate: [guard()],
    children: [
      {path: "", component: EventLandingComponent},
      {path: "details", component: EventDetailsComponent},
      {path: "chapters/:chapter_id/sections/:section_id", component: EventDetailsComponent},
      {path: "evaluation", component: EventEvaluationComponent},
    ],
  },
  {
    path: "verify-certificate/:attendee_id",
    component: VerifyCertificateComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [guard()],
    children: [
      {path: "", redirectTo: "home", pathMatch: "full"},
      {path: "home", canDeactivate: [dataLossGuard], component: ProfileDetailsComponent},
      {path: "security", canDeactivate: [dataLossGuard], component: ProfileSecurityComponent},
      {path: "favorites", component: ProfileFavoritesComponent},
      {path: "events", component: ProfileEventsComponent},
      {path: "tickets", component: ProfileTicketsListComponent},
      {path: "tickets/new", canDeactivate: [dataLossGuard], component: ProfileTicketFormComponent},
      {path: "tickets/:ticket_id", component: ProfileTicketFormComponent},
    ],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [guard(loggedIn("admin"))],
    children: [
      {path: "home", component: DashboardLandingComponent},
      {path: "users", component: DashboardUsersListComponent},
      {path: "users/new", canDeactivate: [dataLossGuard], component: DashboardUserFormComponent},
      {path: "users/:user_id", canDeactivate: [dataLossGuard], component: DashboardUserFormComponent},
      {path: "countries", component: DashboardCountriesListComponent},
      {path: "countries/new", canDeactivate: [dataLossGuard], component: DashboardCountryFormComponent},
      {path: "countries/:country_id", canDeactivate: [dataLossGuard], component: DashboardCountryFormComponent},
      {path: "certificates", component: DashboardCertificatesListComponent},
      {path: "certificates/new", canDeactivate: [dataLossGuard], component: DashboardCertificateFormComponent},
      {path: "certificates/:certificate_id", canDeactivate: [dataLossGuard], component: DashboardCertificateFormComponent},
      {path: "badges", component: DashboardBadgesListComponent},
      {path: "badges/new", canDeactivate: [dataLossGuard], component: DashboardBadgeFormComponent},
      {path: "badges/:badge_id", canDeactivate: [dataLossGuard], component: DashboardBadgeFormComponent},
      {path: "forms", component: DashboardFormsListComponent},
      {path: "forms/new", canDeactivate: [dataLossGuard], component: DashboardFormComponent},
      {path: "forms/:form_id", canDeactivate: [dataLossGuard], component: DashboardFormComponent},
      {path: "events", component: DashboardEventsListComponent},
      {path: "events/new", canDeactivate: [dataLossGuard], component: DashboardEventFormComponent},
      {
        path: "events/:event_id",
        component: DashboardEventWizardComponent,
        children: [
          {path: "", redirectTo: "info", pathMatch: "full"},
          {path: "info", canDeactivate: [dataLossGuard], component: DashboardEventInfoFormComponent},
          {path: "settings", canDeactivate: [dataLossGuard], component: DashboardEventSettingsFormComponent},
          {path: "meeting", component: DashboardEventMeetingFormComponent},
          {path: "chapters", canDeactivate: [dataLossGuard], component: DashboardEventChaptersFormComponent},
          {path: "chapters/:chapter_id", canDeactivate: [dataLossGuard], component: DashboardEventSectionsFormComponent},
          {path: "documents", component: DashboardEventDocumentsFormComponent},
          {path: "evaluation", canDeactivate: [dataLossGuard], component: DashboardEventEvaluationFormComponent},
        ],
      },
      {path: "events/:event_id/attendees", component: DashboardAttendeesListComponent},
      {path: "events/:event_id/attendees/new", canDeactivate: [dataLossGuard], component: DashboardAttendeeFormComponent},
      {
        path: "events/:event_id/attendees/:attendee_id",
        canDeactivate: [dataLossGuard],
        component: DashboardAttendeeFormComponent,
      },
      {path: "tickets", component: DashboardTicketsListComponent},
      {path: "tickets/:ticket_id", canDeactivate: [dataLossGuard], component: DashboardTicketFormComponent},
    ],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
