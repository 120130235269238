<mtx-loader [loading]="loading">
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="updateEvent()">
    <div class="d-flex justify-content-end">
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div class="row my-4">
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>عنوان الفعالية</mat-label>
          <input matInput placeholder="عنوان الفعالية" formControlName="title" />
          <mat-error *ngIf="title.invalid">{{ title.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>عنوان فرعي</mat-label>
          <input matInput placeholder="عنوان فرعي" formControlName="subtitle" />
          <mat-error *ngIf="subtitle.invalid">{{ subtitle.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>نوع الفعالية</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let t of types" [value]="t.value">
              {{ t.name }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="type.invalid">{{ type.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 mb-4">
        <mat-label>وصف الفعالية</mat-label>
        <editor
          formControlName="description"
          class="w-100"
          [init]="{
            base_url: '/tinymce',
            suffix: '.min',
            language: 'ar',
            language_url: './assets/scripts/langs/ar.js',
            height: 300,
            directionality: 'rtl',
            menubar: false,
            plugins: ['table', 'lists', 'link', 'media'],
            toolbar: 'align | bold | numlist bullist | h3 h4 h5 | link',
            table_appearance_options: false,
            table_default_styles: {width: '100%'},
            table_default_attributes: {class: 'table table-striped table-hover table-bordered details-table'},
            content_style: 'body { font-family: Arial }'
          }"
        ></editor>
        <mat-error *ngIf="description.invalid">{{ description.errors | error }}</mat-error>
      </div>

      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>تاريخ بداية الفعالية</mat-label>
          <input
            matInput
            [mtxDatetimepicker]="startDatePicker"
            placeholder="تاريخ بداية الفعالية"
            formControlName="start_date"
            (click)="startDatePicker.open()"
            dir="ltr"
          />
          <mtx-datetimepicker-toggle [for]="startDatePicker" matSuffix></mtx-datetimepicker-toggle>
          <mtx-datetimepicker
            #startDatePicker
            type="datetime"
            mode="portrait"
            twelvehour
            touchUi
            timeInput
            dir="ltr"
          ></mtx-datetimepicker>
          <mat-error *ngIf="start_date.invalid">{{ start_date.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>تاريخ نهاية الفعالية</mat-label>
          <input
            matInput
            [mtxDatetimepicker]="endDatePicker"
            placeholder="تاريخ نهاية الفعالية"
            formControlName="end_date"
            (click)="endDatePicker.open()"
            dir="ltr"
          />
          <mtx-datetimepicker-toggle [for]="endDatePicker" matSuffix></mtx-datetimepicker-toggle>
          <mtx-datetimepicker
            #endDatePicker
            type="datetime"
            mode="portrait"
            twelvehour
            touchUi
            timeInput
            dir="ltr"
          ></mtx-datetimepicker>
          <mat-error *ngIf="end_date.invalid">{{ end_date.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>فترة الفعالية بالدقائق</mat-label>
          <input type="number" matInput placeholder="فترة الفعالية بالدقائق" formControlName="duration_in_minutes" />
          <mat-error *ngIf="duration_in_minutes.invalid">{{ duration_in_minutes.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>خصوصية الفعالية</mat-label>
          <mat-select formControlName="visibility">
            <mat-option *ngFor="let v of visibilities" [value]="v.value">
              {{ v.name }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="visibility.invalid">{{ visibility.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 d-flex flex-column justify-content-between align-items-center my-4">
        <img image [src]="thumbnailImageSrc" style="height: 200px" class="img-fluid" alt="thumbnail" />
        <input #thumbnailInput type="file" class="visually-hidden" (change)="onFileChange($event, thumbnail_image_id)" />
        <button mat-flat-button (click)="thumbnailInput.click()" color="accent" class="w-100 mt-4" type="button">
          اختر الصورة المصغرة
        </button>
      </div>

      <div class="col-md-6 d-flex flex-column justify-content-between align-items-center my-4">
        <img image [src]="bannerImageSrc" style="height: 200px" class="img-fluid" alt="banner" />
        <input #bannerInput type="file" class="visually-hidden" (change)="onFileChange($event, banner_image_id)" />
        <button mat-flat-button (click)="bannerInput.click()" color="accent" class="w-100 mt-4" type="button">
          اختر صورة البانر
        </button>
      </div>

      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>الكلمات الدلالية</mat-label>
          <mat-chip-grid #chipGrid [formControl]="tags">
            <mat-chip-row *ngFor="let tag of tags.value" (removed)="removeTag(tag)">
              {{ tag }}
              <button matChipRemove type="button">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="ادخل كلمة دلالية" [matChipInputFor]="chipGrid" (matChipInputTokenEnd)="addTag($event)" />
          <mat-error *ngIf="tags.invalid">{{ tags.errors | error }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 mb-4">
        <mat-label>أهداف الفعالية</mat-label>
        <editor
          formControlName="objectives"
          class="w-100"
          [init]="{
            base_url: '/tinymce',
            suffix: '.min',
            language: 'ar',
            language_url: './assets/scripts/langs/ar.js',
            height: 300,
            directionality: 'rtl',
            menubar: false,
            plugins: ['table', 'lists', 'link', 'media'],
            toolbar: 'align | bold | numlist bullist | h3 h4 h5 | link',
            table_appearance_options: false,
            table_default_styles: {width: '100%'},
            table_default_attributes: {class: 'table table-striped table-hover table-bordered details-table'},
            content_style: 'body { font-family: Arial }'
          }"
        ></editor>
        <mat-error *ngIf="objectives.invalid">{{ objectives.errors | error }}</mat-error>
      </div>

      <div class="col-12 mb-4">
        <mat-label>المتطلبات المعرفية</mat-label>
        <editor
          formControlName="prerequisites"
          class="w-100"
          [init]="{
            base_url: '/tinymce',
            suffix: '.min',
            language: 'ar',
            language_url: './assets/scripts/langs/ar.js',
            height: 300,
            directionality: 'rtl',
            menubar: false,
            plugins: ['table', 'lists', 'link', 'media'],
            toolbar: 'align | bold | numlist bullist | h3 h4 h5 | link',
            table_appearance_options: false,
            table_default_styles: {width: '100%'},
            table_default_attributes: {class: 'table table-striped table-hover table-bordered details-table'},
            content_style: 'body { font-family: Arial }'
          }"
        ></editor>
        <mat-error *ngIf="prerequisites.invalid">{{ prerequisites.errors | error }}</mat-error>
      </div>

      <div class="col-12 mb-4">
        <mat-label>المستهدفون</mat-label>
        <editor
          formControlName="audience"
          class="w-100"
          [init]="{
            base_url: '/tinymce',
            suffix: '.min',
            language: 'ar',
            language_url: './assets/scripts/langs/ar.js',
            height: 300,
            directionality: 'rtl',
            menubar: false,
            plugins: ['table', 'lists', 'link', 'media'],
            toolbar: 'align | bold | numlist bullist | h3 h4 h5 | link',
            table_appearance_options: false,
            table_default_styles: {width: '100%'},
            table_default_attributes: {class: 'table table-striped table-hover table-bordered details-table'},
            content_style: 'body { font-family: Arial }'
          }"
        ></editor>
        <mat-error *ngIf="audience.invalid">{{ audience.errors | error }}</mat-error>
      </div>

      <div class="col-md-6 my-4 my-md-2">
        <mat-checkbox formControlName="ended">الفعالية منتهية؟</mat-checkbox>
      </div>

      <div class="col-md-6 my-4 my-md-2">
        <mat-checkbox formControlName="enrollment_closed">التسجيل مغلق؟</mat-checkbox>
      </div>

      <div class="col-md-6 my-4 my-md-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>حالة الفعالية</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let s of statuses" [value]="s.value">
              {{ s.name }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="status.invalid">{{ status.errors | error }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mtx-loader>
