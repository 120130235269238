import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ReplaySubject, takeUntil} from "rxjs";
import {Event} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {makeValidators, v} from "src/app/utils/validations";

const FilterForm = v.object({
  title: v.string().optional(),
});

@Component({
  selector: "app-dashboard-events-list",
  templateUrl: "./dashboard-events-list.component.html",
  styleUrls: ["./dashboard-events-list.component.scss"],
})
export class DashboardEventsListComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ["id", "title", "start_date", "end_date", "status", "actions"];
  dataSource: MatTableDataSource<Event> = new MatTableDataSource();

  form = new FormGroup(
    {
      title: new FormControl<string | undefined>(undefined, {nonNullable: true}),
    },
    makeValidators(FilterForm),
  );

  loading = false;
  totalRows = 0;
  pageIndex = 0;

  processing: Record<string, boolean> = {};

  constructor(
    private eventSvc: EventService,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.listEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  listEvents() {
    this.loading = true;
    this.eventSvc
      .listEvents({
        filter: this.form.getRawValue(),
        paginate: {
          page: this.pageIndex + 1,
          limit: 20,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.dataSource.data = result.rows;
          this.totalRows = result.totalRows;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  pageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listEvents();
  }

  search() {
    this.pageIndex = 0;
    this.listEvents();
  }

  reset() {
    this.form.reset({});
    this.search();
  }

  toggleEndedFlag(event: Event) {
    this.processing[event.id] = true;

    this.eventSvc
      .updateEvent(event.id, {ended: !event.ended})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          event.ended = result.ended;
          this.processing[event.id] = false;
        },
        error: () => {
          this.processing[event.id] = false;
        },
      });
  }

  publish(event: Event) {
    if (event.status === "published") return;

    this.processing[event.id] = true;
    this.eventSvc
      .updateEvent(event.id, {status: "published"})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          event.status = result.status;
          this.processing[event.id] = false;
        },
        error: () => {
          this.processing[event.id] = false;
        },
      });
  }

  displayDate(date: string) {
    return new Date(date).toLocaleString("ar-EG");
  }

  calculateAttendance(event: Event) {
    this.processing[event.id] = true;
    this.eventSvc
      .calculateAttendance(event.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.processing[event.id] = false;
        },
        error: () => {
          this.processing[event.id] = false;
        },
      });
  }
}
