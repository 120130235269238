<mtx-loader [loading]="loading">
  <form [formGroup]="form" class="p-5" (ngSubmit)="save()">
    <div class="d-flex justify-content-between">
      <h2>{{ !attendee_id ? "إضافة" : "تعديل" }} حضور</h2>
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>البريد الإلكتروني</mat-label>
          <input formControlName="email" type="email" matInput placeholder="البريد الإلكتروني" />
          <mat-error *ngIf="email.invalid">{{ email.errors | error }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>الاسم</mat-label>
          <input formControlName="full_name" type="text" matInput placeholder="الاسم" />
          <mat-error *ngIf="full_name.invalid">{{ full_name.errors | error }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>الاسم باللغة الإنجليزية</mat-label>
          <input formControlName="full_name_en" type="text" matInput placeholder="الاسم باللغة الإنجليزية" />
          <mat-error *ngIf="full_name_en.invalid">{{ full_name_en.errors | error }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>الحالة</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let s of statuses" [value]="s.value">
              {{ s.name }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="status.invalid">{{ status.errors | error }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mtx-loader>
