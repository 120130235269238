import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Badge, BadgeStatus} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {BadgeService, CreateBadgeRequestBody, UpdateBadgeRequestBody} from "src/app/services/badge.service";
import {FileService} from "src/app/services/file.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsDirty, markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

type FieldFormGroup = FormGroup<{
  name: FormControl<string>;
  top: FormControl<string | null | undefined>;
  left: FormControl<string | null | undefined>;
}>;

const BadgeForm = v.object({
  name: v.string(),
  image_id: v.string({format: "uuid"}),
  fields: v.array(
    v.object({
      name: v.string(),
      top: v.string({nullable: true}).optional(),
      left: v.string({nullable: true}).optional(),
    }),
  ),
  status: v.enum(["draft", "published", "archived"]),
});

@Component({
  selector: "app-dashboard-badge-form",
  templateUrl: "./dashboard-badge-form.component.html",
  styleUrls: ["./dashboard-badge-form.component.scss"],
})
export class DashboardBadgeFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      name: new FormControl("", {nonNullable: true}),
      image_id: new FormControl("", {nonNullable: true}),
      fields: new FormArray<FieldFormGroup>([
        new FormGroup({
          name: new FormControl("full_name", {nonNullable: true}),
          top: new FormControl<string | undefined>(undefined),
          left: new FormControl<string | undefined>(undefined),
        }),
        new FormGroup({
          name: new FormControl("image", {nonNullable: true}),
          top: new FormControl<string | undefined>(undefined),
          left: new FormControl<string | undefined>(undefined),
        }),
      ]),
      status: new FormControl<BadgeStatus>("published", {nonNullable: true}),
    },
    makeValidators(BadgeForm),
  );

  names = [
    {name: "عنوان البادج", value: "title"},
    {name: "اسم المتلقي", value: "full_name"},
    {name: "صورة البادج", value: "image"},
    {name: "مقر العمل", value: "workplace"},
  ];

  statuses = [
    {name: "مسودة", value: "draft"},
    {name: "منشور", value: "published"},
    {name: "مؤرشف", value: "archived"},
  ];

  loading!: boolean;

  badge_id?: string;
  previewUrl?: string;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private badgeSvc: BadgeService,
    private fileSvc: FileService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const badge_id = this.route.snapshot.params["badge_id"];
    if (badge_id) {
      this.badge_id = badge_id;
      this.getBadgePreviewUrl();
      this.getBadge();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get name() {
    return this.form.controls.name;
  }

  get image_id() {
    return this.form.controls.image_id;
  }

  get fields() {
    return this.form.controls.fields;
  }

  get status() {
    return this.form.controls.status;
  }

  get imageSrc() {
    return this.image_id.valid && !!this.image_id.value ? this.fileSvc.getFileDownloadLink(this.image_id.value) : null;
  }

  get hasRemainingField() {
    return !!this.getNextField();
  }

  getNextField() {
    const chosen = this.fields.getRawValue().map((field) => field.name);
    const names = this.names.filter((name) => !chosen.includes(name.value));
    return names.at(0);
  }

  selected(name: string) {
    return this.fields.getRawValue().some((field) => field.name === name);
  }

  patchForm(badge: Badge) {
    this.form.patchValue(badge);
    this.fields.clear();
    badge.fields.forEach((field) => {
      this.fields.push(
        new FormGroup({
          name: new FormControl(field.name, {nonNullable: true}),
          top: new FormControl<string | undefined>(field.properties["top"]?.toString() ?? undefined),
          left: new FormControl<string | undefined>(field.properties["left"]?.toString() ?? undefined),
        }),
      );
    });
    markAllAsPristine(this.form);
  }

  getBadgePreviewUrl() {
    if (!this.badge_id) return;

    this.badgeSvc
      .previewBadge(this.badge_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((url) => {
        this.previewUrl = url;
      });
  }

  getBadge() {
    if (!this.badge_id) return;

    this.loading = true;
    this.badgeSvc
      .getBadge(this.badge_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((badge) => {
        this.patchForm(badge);
        this.loading = false;
      });
  }

  onFileChange(event: Event, control: FormControl<string | null | undefined>) {
    this.loading = true;
    this.fileSvc
      .uploadFromEvent(event, undefined, ["image/"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (file) => {
          control.setValue(file.id);
          control.markAsDirty();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  deleteField(fieldIndex: number) {
    this.fields.removeAt(fieldIndex);
    updateFormStatus(this.form);
  }

  pushNewField() {
    const field = this.getNextField();
    if (!field) return;

    const fieldFormGroup = new FormGroup({
      name: new FormControl(field.value, {nonNullable: true}),
      top: new FormControl<string | null | undefined>(null),
      left: new FormControl<string | null | undefined>(null),
    });
    this.fields.push(fieldFormGroup);
    markAllAsDirty(fieldFormGroup);
    this.cdRef.detectChanges();
  }

  prepareForm() {
    const form = this.form.getRawValue();

    const body: CreateBadgeRequestBody | UpdateBadgeRequestBody = {
      name: form.name,
      image_id: form.image_id,
      fields: form.fields.map((field) => ({
        name: field.name,
        properties: {
          ...(field.top ? {top: field.top} : {}),
          ...(field.left ? {left: field.left} : {}),
        },
      })),
      status: form.status,
    };

    return body;
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.prepareForm();

    if (!form.fields.length) {
      this.toastSvc.error("يرجى تعبئة إعدادات الحقول");
      return;
    }

    this.loading = true;
    if (this.badge_id) {
      this.badgeSvc
        .updateBadge(this.badge_id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (badge) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.patchForm(badge);
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.badgeSvc
        .createBadge(form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (badge) => {
            markAllAsPristine(this.form);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.router.navigate(["/dashboard", "badges", badge.id]);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  isDirty() {
    return this.form.dirty;
  }
}
