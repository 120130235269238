<div>
  <div *ngIf="success" class="alert alert-success" role="alert">
    ستصلك رسالة على البريد الإلكتروني لاسترجاع كلمة المرور أثناء كان لديك حساب
  </div>

  <h3 class="text-primary fw-bold mt-5">نسيت كلمة المرور؟</h3>
  <p class="text-l-gray fw-light">ادخل البريد الإلكتروني الخاص بحسابك</p>

  <form #ngForm="ngForm" [formGroup]="form" class="mt-5" autocomplete="off" (ngSubmit)="forgotPassword()">
    <div class="row">
      <div class="col-xl-12">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="email">البريد الإلكتروني</label>
          <input
            required
            type="email"
            class="form-control py-3 focus-shadow-none"
            id="email"
            formControlName="email"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(email, ngForm)}"
          />
          <div *ngIf="email.invalid" class="invalid-feedback">{{ email.errors | error }}</div>
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-primary w-100 py-3 mt-5" type="submit">إرسال</button>
      </div>
    </div>
  </form>
  <hr class="my-4" />
  <div class="d-flex justify-content-between align-items-center text-l-gray">
    <div>
      لديك حساب؟
      <a routerLink="/login">تسجيل دخول</a>
    </div>
    <div>
      مستخدم جديد؟
      <a routerLink="/register">سجل الآن</a>
    </div>
  </div>
</div>
