import {HttpClient, HttpContext} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

import {Attendee, PrintJob} from "../dtos";
import {SILENCE_ERRORS} from "../utils";
import {PLATFORM_SERVICE, PlatformService} from "./platform.service";

@Injectable({
  providedIn: "root",
})
export class AttendeeService {
  private readonly V1_ENDPOINTS = {
    VERIFY_ATTENDEE: (attendee_id: string) => `${environment.server.host}/api/v1/attendees/${attendee_id}`,
    GET_ATTENDEE_CERTIFICATE: (attendee_id: string) =>
      `${environment.server.host}/api/v1/attendees/${attendee_id}/certificate`,
    GET_ATTENDEE_BADGE: (attendee_id: string) => `${environment.server.host}/api/v1/attendees/${attendee_id}/badge`,
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
  ) {}

  verifyAttendee(attendee_id: string, silent = false) {
    return this.httpClient.get<Attendee>(this.V1_ENDPOINTS.VERIFY_ATTENDEE(attendee_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getAttendeeCertificate(attendee_id: string, silent = false) {
    return this.httpClient.get<PrintJob>(this.V1_ENDPOINTS.GET_ATTENDEE_CERTIFICATE(attendee_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getAttendeeBadge(attendee_id: string, silent = false) {
    return this.httpClient.get<PrintJob>(this.V1_ENDPOINTS.GET_ATTENDEE_BADGE(attendee_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
