import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {of, ReplaySubject, switchMap, takeUntil, tap} from "rxjs";
import {Event, Meeting} from "src/app/dtos";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {MeetingService} from "src/app/services/meeting.service";
import {PLATFORM_SERVICE, PlatformService} from "src/app/services/platform.service";
import {ToastService} from "src/app/services/toast.service";
import {extractText} from "src/app/utils";

import {
  DashboardMeetingDialogComponent,
  DashboardMeetingDialogData,
} from "../dashboard-meeting-dialog/dashboard-meeting-dialog.component";

@Component({
  selector: "app-dashboard-event-meeting-form",
  templateUrl: "./dashboard-event-meeting-form.component.html",
  styleUrls: ["./dashboard-event-meeting-form.component.scss"],
})
export class DashboardEventMeetingFormComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  loading!: boolean;

  event_id!: string;
  event!: Event;

  meeting!: Meeting | null;

  constructor(
    private eventSvc: EventService,
    private meetingSvc: MeetingService,
    private toastSvc: ToastService,
    private confirmSvc: ConfirmService,
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get expiredOrDeleted() {
    return (
      this.meeting &&
      ((this.meeting.meeting_type === "meeting" && !this.meeting.meeting) ||
        (this.meeting.meeting_type === "webinar" && !this.meeting.webinar))
    );
  }

  get data() {
    return this.meeting?.meeting_type === "meeting" ? this.meeting.meeting : this.meeting?.webinar;
  }

  getData() {
    this.loading = true;
    this.eventSvc
      .getEvent(this.event_id)
      .pipe(
        tap((event) => {
          this.event = event;
        }),
        switchMap((event) => (event.meeting_id ? this.meetingSvc.getMeeting(event.meeting_id) : of(null))),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((meeting) => {
        this.meeting = meeting;
        this.loading = false;
      });
  }

  openMeetingDialog() {
    this.dialog
      .open<DashboardMeetingDialogComponent, DashboardMeetingDialogData>(DashboardMeetingDialogComponent, {
        data: {
          id: this.meeting?.id,
          supplements: {
            duration: Math.ceil(this.event.duration_in_seconds / 60),
            start_time: new Date(this.event.start_date),
            topic: this.event.title,
            agenda: this.event.description ? extractText(this.platformSvc.getDocument(), this.event.description) : undefined,
          },
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: boolean | Meeting | undefined) => {
        if (result === true) {
          this.getData();
        } else if (!result) {
          return;
        } else {
          this.saveMeeting(result);
        }
      });
  }

  saveMeeting(meeting: Meeting) {
    this.loading = true;
    this.eventSvc
      .updateEvent(this.event_id, {meeting_id: meeting.id})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.getData();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  deleteMeeting() {
    const meeting_id = this.meeting?.id;
    if (!meeting_id) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.loading = true;
          this.meetingSvc
            .deleteMeeting(meeting_id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: () => {
                this.loading = false;
                this.toastSvc.success("تم تنفيذ الأمر بنجاح");
                this.getData();
              },
              error: () => {
                this.loading = false;
              },
            });
        }
      });
  }
}
