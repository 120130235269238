import {Component, Inject, OnDestroy} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {filter, of, ReplaySubject, switchMap, take, takeUntil, zip} from "rxjs";

import {AnalyticsService} from "./services/analytics.service";
import {AuthService} from "./services/auth.service";
import {PLATFORM_SERVICE, PlatformService} from "./services/platform.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  faChevronUp = faChevronUp;

  constructor(
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private router: Router,
    private authSvc: AuthService,
    private analyticsService: AnalyticsService,
  ) {
    this.subscribeToRouterEvents();
    this.analyticsService.run();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  subscribeToRouterEvents() {
    const source$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    source$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.authSvc.handleFeedback();
    });

    source$
      .pipe(
        switchMap((event) => zip(of(event), this.authSvc.getUser())),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(([_, user]) => {
        this.updateBodyClass();
        if (!!user && !user.profile_created) {
          this.router.navigate(["/profile-wizard"]);
        }
      });
  }

  updateBodyClass() {
    const document = this.platformSvc.getDocument();

    const classes = ["subPage", "world", "start"] as const;
    const isRoot = ["", "/"].includes(this.router.url);

    if (isRoot) {
      classes.forEach((cls) => {
        document.body.classList.remove(cls);
      });
    } else {
      classes.forEach((cls) => {
        document.body.classList.add(cls);
      });
    }
  }
}
