<div class="input-group has-validation">
  <input
    dir="ltr"
    type="tel"
    class="form-control py-3 focus-shadow-none"
    [class.is-invalid]="invalid"
    [(ngModel)]="phone"
    (ngModelChange)="onPhoneChange()"
    (focus)="onPhoneChange()"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [readOnly]="readOnly"
  />
  <button
    dir="ltr"
    class="btn btn-outline-secondary dropdown-toggle d-flex align-items-center gap-2"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    [disabled]="disabled || readOnly"
  >
    <ng-container *ngIf="selectedCountry; else noSelectedCountry">
      <div class="d-inline-flex align-items-center gap-2">
        <div class="flag:{{selectedCountry.alpha2}}"></div>
        <span>+{{ selectedCountry.country_code }}</span>
      </div>
    </ng-container>
    <ng-template #noSelectedCountry>الدولة</ng-template>
  </button>
  <div dir="ltr" class="dropdown-menu py-0">
    <div>
      <input type="text" class="form-control py-2 focus-shadow-none" placeholder="بحث" [(ngModel)]="filterCriteria" />
    </div>
    <div class="country-menu">
      <button
        *ngFor="let country of filteredCountries"
        class="d-flex align-items-center gap-2 py-2 dropdown-item"
        type="button"
        (click)="selectCountry(country)"
      >
        <div class="flag:{{country.alpha2}}"></div>
        <span>+{{ country.country_code }} {{ country.country_name_ar ?? country.country_name }}</span>
      </button>
    </div>
    <div *ngIf="filteredCountries.length === 0" class="py-2">لم يتم العثور على نتائج</div>
  </div>
  <div *ngIf="error" class="invalid-feedback">
    {{ error }}
  </div>
</div>
