<app-navbar></app-navbar>
<main class="page-content">
  <div class="text-light fw-bold bg-primary">
    <div class="container py-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
      <h5 class="my-4">الفعاليات</h5>
    </div>
  </div>

  <div class="container mb-4">
    <mtx-loader [loading]="loading">
      <h5 *ngIf="events && events.length > 0" class="my-5">عدد الفعاليات: {{ totalRows }} فعالية</h5>
      <div class="row">
        <div *ngFor="let event of events" class="col-lg-4 col-md-6">
          <app-card [event]="event"></app-card>
        </div>
      </div>
      <h5 *ngIf="!(events && events.length > 0)" class="p-5 d-flex justify-content-center align-items-center">
        لا توجد فعاليات
      </h5>
      <app-pagination [options]="options" (pageChange)="onPageChange($event)"></app-pagination>
    </mtx-loader>
  </div>
</main>
<app-footer></app-footer>
