import {Injectable} from "@angular/core";
import {initializeApp} from "firebase/app";
import {Auth, getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private auth: Auth;

  constructor() {
    const app = initializeApp(environment.firebase);
    this.auth = getAuth(app);
  }

  signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    return signInWithPopup(this.auth, provider);
  }
}
