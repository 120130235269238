<mtx-loader [loading]="loading">
  <form #ngForm="ngForm" [formGroup]="form" autocomplete="off">
    <div class="d-flex justify-content-between align-items-center mb-5">
      <h5>دورس الوحدة {{ chapter?.title }}</h5>
    </div>

    <mat-accordion formArrayName="sections" cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-expansion-panel
        *ngFor="let section of sections.controls; index as sectionIndex"
        [formGroup]="section"
        cdkDrag
        [cdkDragDisabled]="form.dirty"
        hideToggle
        #panel
      >
        <div class="drag-placeholder" *cdkDragPlaceholder></div>

        <mat-expansion-panel-header>
          <mat-panel-title>
            <button
              matTooltip="اسحب لإعادة الترتيب"
              matTooltipPosition="above"
              mat-icon-button
              matSuffix
              cdkDragHandle
              [disabled]="form.dirty"
            >
              <mat-icon>drag_handler</mat-icon>
            </button>
            <span
              *ngIf="errorStateMatcher.isErrorState(section, ngForm)"
              class="bg-danger rounded-circle me-2"
              style="width: 5px; height: 5px"
            ></span>
            <span [ngClass]="{'text-danger fw-bolder': errorStateMatcher.isErrorState(section, ngForm)}">
              الدرس {{ sectionIndex + 1 }}
            </span>
          </mat-panel-title>
          <mat-panel-description class="d-flex justify-content-between">
            <div>{{ section.value.title }}</div>
            <div *ngIf="!panel.expanded">
              <button
                matTooltip="حذف الدرس"
                matTooltipPosition="above"
                mat-icon-button
                matSuffix
                (click)="deleteSection(sectionIndex)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>عنوان الدرس</mat-label>
              <input matInput type="text" placeholder="عنوان الدرس" [formControl]="section.controls.title" />

              <mat-error *ngIf="section.controls.title.invalid">{{ section.controls.title.errors | error }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>وصف الدرس</mat-label>
              <textarea matInput placeholder="وصف الدرس" [formControl]="section.controls.description"></textarea>
              <mat-error *ngIf="section.controls.description.invalid">
                {{ section.controls.description.errors | error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-label>المحتوى</mat-label>
            <editor
              formControlName="content"
              class="w-100"
              [init]="{
                base_url: '/tinymce',
                suffix: '.min',
                language: 'ar',
                language_url: './assets/scripts/langs/ar.js',
                height: 300,
                directionality: 'rtl',
                menubar: false,
                plugins: ['table', 'lists', 'link', 'media', 'mediaembed'],
                toolbar: 'align | bold | table | numlist bullist | h3 h4 h5 | link media mediaembed',
                table_appearance_options: false,
                table_default_styles: {width: '100%'},
                table_default_attributes: {class: 'table table-striped table-hover table-bordered details-table'},
                mediaembed_max_width: 450,
                content_style: 'body { font-family: Arial }'
              }"
            ></editor>
            <mat-error *ngIf="section.controls.content.invalid">
              {{ section.controls.content.errors | error }}
            </mat-error>
          </div>
          <div class="col-12 my-4">
            <h6>مرفقات الدرس</h6>
            <mat-list>
              <mat-list-item *ngFor="let file of section.controls.files.controls" class="border border-1 rounded-1">
                <mat-icon matListItemIcon>note</mat-icon>
                <div matListItemTitle>{{ file.value.filename_download }}</div>

                <div matListItemMeta>
                  <button
                    matTooltip="تعديل \ حذف"
                    matTooltipPosition="above"
                    mat-icon-button
                    (click)="openFileDialog(sectionIndex, file.value.id)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </mat-list-item>
            </mat-list>

            <button class="mt-3 w-100" mat-flat-button (click)="openFileDialog(sectionIndex)">
              <span>إضافة المزيد</span>
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <mat-card-actions class="d-flex justify-content-end">
          <button
            matTooltip="حذف الوحدة"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            (click)="deleteSection(sectionIndex)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            matTooltip="حفظ"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            (click)="saveSection(section, sectionIndex)"
          >
            <mat-icon>done</mat-icon>
          </button>
        </mat-card-actions>
      </mat-expansion-panel>
    </mat-accordion>

    <button class="mt-3 w-100" mat-flat-button (click)="pushNewSection()">
      <span>إضافة المزيد</span>
      <mat-icon>add</mat-icon>
    </button>
  </form>
</mtx-loader>
