<mat-nav-list class="bg-primary bg-opacity-10 h-100">
  <a
    mat-list-item
    *ngFor="let item of items"
    [routerLink]="item.route"
    routerLinkActive="active"
    #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact: false}"
    [activated]="rla.isActive"
    [ngClass]="{'px-0': collapsed}"
  >
    <mat-icon matListItemIcon class="mx-4">{{ item.icon }}</mat-icon>
    <span @fadeIn *ngIf="!collapsed" matListItemTitle class="pe-5">
      {{ item.title }}
    </span>
  </a>

  <mat-list-item (click)="toggleCollapse()" (keydown)="toggleCollapse()" [ngClass]="{'px-0': collapsed}">
    <mat-icon matListItemIcon class="mx-4">{{ collapsed ? "chevron_left" : "chevron_right" }}</mat-icon>
    <span @fadeIn *ngIf="!collapsed" matListItemTitle class="pe-5">إغلاق</span>
  </mat-list-item>
</mat-nav-list>
