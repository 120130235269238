import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {from, of, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {AuthService} from "src/app/services/auth.service";
import {FirebaseService} from "src/app/services/firebase.service";
import {ToastService} from "src/app/services/toast.service";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const LoginForm = v.object({
  email: v.string(),
  password: v.string(),
});

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      email: new FormControl("", {nonNullable: true}),
      password: new FormControl("", {nonNullable: true}),
    },
    makeValidators(LoginForm),
  );

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  feedback!: {success: boolean; message: string} | null;

  loading!: boolean;

  constructor(
    private authSvc: AuthService,
    private toastSvc: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private firebaseSvc: FirebaseService,
  ) {}

  get returnUrl(): string | undefined {
    return this.route.snapshot.queryParams["returnUrl"];
  }

  async ngOnInit(): Promise<void> {
    this.feedback = this.authSvc.getFeedbback();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get email() {
    return this.form.controls.email;
  }

  get password() {
    return this.form.controls.password;
  }

  login() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.getRawValue();

    this.loading = true;
    this.authSvc
      .login(form)
      .pipe(
        switchMap((result) => {
          this.authSvc.setToken(result.access_token.value);
          this.authSvc.setRefreshToken(result.refresh_token.value);
          return this.authSvc.getUser();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (result) => {
          if (!result) return;

          this.loading = false;
          this.toastSvc.success("تم تسجيل الدخول بنجاح");
          if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigate([""]);
          }
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  async onSocialAuth(provider: "google") {
    this.loading = true;
    const result = await this.firebaseSvc.signInWithGoogle();
    const token = await result.user.getIdToken();
    this.authSvc
      .externalLogin(token)
      .pipe(
        switchMap((result) => {
          if (!result) {
            return of(null);
          }
          this.authSvc.setToken(result.access_token.value);
          this.authSvc.setRefreshToken(result.refresh_token.value);
          return this.authSvc.getUser();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (user) => {
          if (!user) {
            this.loading = false;
            return;
          }

          this.toastSvc.success("تم تسجيل الدخول بنجاح");
          if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigate([""]);
          }
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
      });
  }
}
