<ng-template #sidebar>
  <a *ngIf="meeting && !event?.ended" [href]="meeting" target="_blank" class="btn btn-primary w-100 py-3 fw-bold rounded-0">
    الدخول للقاء
  </a>
  <a [routerLink]="['/events', event_id]" class="btn btn-light w-100 py-3 fw-bold rounded-0 border">إعلان الفعالية</a>
  <a [routerLink]="['/events', event_id, 'details']" class="btn btn-light w-100 py-3 fw-bold rounded-0 border">
    تفاصيل الفعالية
  </a>

  <hr />

  <div *ngIf="chapters && chapters.length > 0" class="accordion accordion-flush" id="event-chapter-list">
    <h5 class="text-center fw-bold py-2">الوحدات</h5>
    <div *ngFor="let chapter of chapters; index as chapterIndex" class="accordion-item rounded-0 border">
      <h2 class="accordion-header" id="heading-{{ chapterIndex }}">
        <button
          class="accordion-button collapsed focus-shadow-none fs-4 bg-primary bg-opacity-10"
          type="button"
          data-bs-toggle="collapse"
          [attr.data-bs-target]="'#collapse-' + chapterIndex"
          aria-expanded="false"
          [attr.aria-controls]="'collapse-' + chapterIndex"
        >
          <span class="overflow-hidden text-ellipse">{{ chapterIndex + 1 }}. {{ chapter.title }}</span>
        </button>
      </h2>
      <div
        *ngIf="chapter.sections"
        id="collapse-{{ chapterIndex }}"
        class="accordion-collapse collapse"
        [class.show]="chapterIndex === 0"
        [attr.aria-labelledby]="'heading-' + chapterIndex"
        data-bs-parent="#event-chapter-list"
      >
        <div class="accordion-body p-0">
          <ul class="list-group list-group-flush">
            <li *ngFor="let section of chapter.sections; index as sectionIndex" class="list-group-item py-3">
              <div class="d-flex align-items-center justify-content-between overflow-hidden text-ellipse">
                <div>
                  <p class="mb-0">{{ sectionIndex + 1 }}. {{ section.title }}</p>
                  <fa-icon [icon]="faPersonChalkboard" class="ms-4 mt-3"></fa-icon>
                  <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
                  <a
                    class="stretched-link"
                    (click)="markSectionAsViewed(section)"
                    [routerLink]="['/events', event_id, 'chapters', chapter.id, 'sections', section.id]"
                  ></a>
                </div>
                <input class="form-check-input lg me-1" type="checkbox" [checked]="section.viewed" onclick="return false;" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-navbar></app-navbar>
<main class="page-content">
  <div class="text-light fw-bold bg-primary">
    <div class="container py-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
      <div class="d-flex flex-row justify-content-center align-items-center my-4">
        <button
          *ngIf="!isLargeScreen"
          type="button"
          class="btn btn-primary me-2"
          data-bs-toggle="offcanvas"
          data-bs-target="#event-offcanvas"
          aria-label="Open"
        >
          <fa-icon [icon]="faBars"></fa-icon>
        </button>

        <h5 class="my-0">{{ event?.title }}</h5>
      </div>

      <div>
        <a *ngIf="meeting" [href]="meeting" target="_blank" class="btn btn-secondary ms-3 px-4">الدخول للقاء</a>
        <a *ngIf="evaluation" [routerLink]="['/events', event_id, 'evaluation']" class="btn btn-secondary ms-3 px-4">
          التقييم
        </a>
        <button *ngIf="badge" (click)="downloadBadge()" class="btn btn-secondary ms-3 px-4" target="_blank">البادج</button>
        <button *ngIf="certificate" (click)="downloadCertificate()" class="btn btn-secondary ms-3 px-4" target="_blank">
          الشهادة
        </button>
      </div>
    </div>
  </div>
  <div class="my-5">
    <div class="container">
      <div class="row">
        <div *ngIf="isLargeScreen" class="col-lg-4">
          <ng-container *ngTemplateOutlet="sidebar"></ng-container>
        </div>
        <div class="col-12 col-lg-8">
          <div class="row">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>

<div class="offcanvas offcanvas-start" tabindex="-1" id="event-offcanvas" aria-labelledby="offcanvasLabel">
  <div class="offcanvas-header d-flex justify-content-end">
    <button type="button" class="btn-close text-reset p-3" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body p-0">
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
  </div>
</div>
