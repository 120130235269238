<mtx-loader [loading]="loading">
  <div class="d-flex flex-column align-items-center gap-5 p-5" *ngIf="!meeting">
    <div class="d-flex flex-column align-items-center gap-4">
      <mat-icon style="transform: scale(2)">warning</mat-icon>
      <h2>لا يوجد لقاء</h2>
    </div>
    <button class="btn btn-lg btn-primary" (click)="openMeetingDialog()">إضافة</button>
  </div>
  <div *ngIf="meeting">
    <div class="d-flex flex-column align-items-center gap-5 p-5" *ngIf="expiredOrDeleted">
      <div class="d-flex flex-column align-items-center gap-4">
        <mat-icon style="transform: scale(2)">warning</mat-icon>
        <h2>اللقاء منتهي الصلاحية أو تم حذفه من خلال زووم</h2>
      </div>
      <button class="btn btn-lg btn-danger" (click)="deleteMeeting()">حذف</button>
    </div>
    <div *ngIf="!expiredOrDeleted">
      <div class="d-flex justify-content-between">
        <h2>بيانات اللقاء</h2>
        <div class="d-flex align-items-center gap-2">
          <button class="btn px-4 btn-danger" (click)="deleteMeeting()">حذف</button>
          <button class="btn px-4 btn-primary" (click)="openMeetingDialog()">تعديل</button>
        </div>
      </div>
      <table class="table table-borderless">
        <tbody>
          <tr>
            <th scope="col">رقم اللقاء</th>
            <td>{{ meeting.meeting_id }}</td>
          </tr>
          <tr>
            <th scope="col">النوع</th>
            <td>{{ meeting.meeting_type === "meeting" ? "اجتماع" : "ويبنار" }}</td>
          </tr>
          <tr>
            <th scope="col">الموضوع</th>
            <td>{{ data?.topic }}</td>
          </tr>
          <tr>
            <th scope="col">وقت البداية</th>
            <td>{{ data?.start_time | date: "yyyy/MM/dd hh:mm a" }}</td>
          </tr>
          <tr>
            <th scope="col">المدة بالدقائق</th>
            <td>{{ data?.duration }}</td>
          </tr>
          <tr>
            <th scope="col">رابط التسجيل</th>
            <td>
              <a [href]="data?.join_url" target="_blank">{{ data?.join_url }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</mtx-loader>
