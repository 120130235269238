/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import phoneValidator from "phone";
import {convertArabicNumerals} from "src/app/utils";

import {COUNTRIES, Country} from "./data";

@Component({
  selector: "app-phone-input",
  templateUrl: "./phone-input.component.html",
  styleUrls: ["./phone-input.component.scss", "./flags.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneInputComponent,
    },
  ],
})
export class PhoneInputComponent implements ControlValueAccessor {
  @Input() invalid = false;
  @Input() error: string | undefined;
  @Input() placeholder = "";
  @Input() readOnly = false;

  phone = "";

  filterCriteria = "";
  selectedCountry: Country | undefined;

  onChange = (phone: string) => {};
  onTouched = () => {};

  disabled = false;
  touched = false;

  get filteredCountries() {
    if (!this.filterCriteria || this.filterCriteria === "") return COUNTRIES;

    return COUNTRIES.filter(
      (country) =>
        `${country.country_name_ar}+${country.country_code}`.toLowerCase().includes(this.filterCriteria.toLowerCase()) ||
        `${country.country_name}+${country.country_code}`.toLowerCase().includes(this.filterCriteria.toLowerCase()),
    );
  }

  get value() {
    const code = this.selectedCountry?.country_code ?? "";
    const phone = this.phone ?? "";

    return `+${code}${phone}`;
  }

  selectCountry(country: Country) {
    if (!this.disabled) {
      this.selectedCountry = country;
      this.onChange(this.value);
    }
  }

  onPhoneChange() {
    this.phone = convertArabicNumerals(this.phone);
    if (!this.disabled) {
      if (!this.selectedCountry && this.phone.startsWith("+")) {
        const result = phoneValidator(this.phone);
        if (result.isValid) {
          this.selectedCountry = COUNTRIES.find((country) => country.alpha2 === result.countryIso2);
          this.phone = result.phoneNumber.replace(result.countryCode, "");
        }
      }
      this.onChange(this.value);
    }
  }

  writeValue(phone: string): void {
    const result = phoneValidator(phone);

    if (result.isValid) {
      this.selectedCountry = COUNTRIES.find((country) => country.alpha2 === result.countryIso2);
      this.phone = result.phoneNumber.replace(result.countryCode, "");
    } else {
      this.selectedCountry = undefined;
      this.phone = phone;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
