<div>
  <h3 class="text-primary fw-bold mt-5">استرجاع كلمة المرور</h3>
  <p class="text-l-gray fw-light">ادخل كلمة المرور الجديدة للمتابعة</p>

  <form #ngForm="ngForm" [formGroup]="form" class="mt-5" autocomplete="off" (ngSubmit)="resetPassword()">
    <div class="row">
      <div class="col-xl-12">
        <div class="mb-3">
          <label class="fw-light text-l-gray mb-2" for="password">كلمة المرور</label>
          <input
            required
            type="password"
            class="form-control py-3 focus-shadow-none"
            id="password"
            formControlName="password"
            [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(password, ngForm)}"
          />
          <div *ngIf="password.invalid" class="invalid-feedback">{{ password.errors | error }}</div>
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-primary w-100 py-3 mt-5" type="submit">إرسال</button>
      </div>
    </div>
  </form>
  <hr class="my-4" />
  <div class="d-flex justify-content-between align-items-center text-l-gray">
    <div>
      لديك حساب؟
      <a routerLink="/login">تسجيل دخول</a>
    </div>
    <div>
      مستخدم جديد؟
      <a routerLink="/register">سجل الآن</a>
    </div>
  </div>
</div>
