import {ChangeDetectorRef, Component, Input, OnDestroy} from "@angular/core";
import {faBookmark as faBookmarkRegular} from "@fortawesome/free-regular-svg-icons";
import {faBookBookmark as faBookmarkSolid, faCircleNotch, faUser} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {Event} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faUser = faUser;
  faCircleNotch = faCircleNotch;
  faBookmarkSolid = faBookmarkSolid;
  faBookmarkRegular = faBookmarkRegular;

  loading!: boolean;

  focus!: boolean;

  @Input({required: true}) event!: Event;

  constructor(
    private eventSvc: EventService,
    private toastSvc: ToastService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  doAction(event: globalThis.Event) {
    event.stopPropagation();

    this.loading = true;
    const action = this.event.action_taken === "follow" ? "unfollow" : "follow";
    this.eventSvc
      .doAction(this.event.id, action)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.event.action_taken = action;
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onFocus() {
    this.focus = true;
  }

  onBlur() {
    this.focus = false;
  }
}
