import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {map} from "rxjs";
import {environment} from "src/environments/environment";

import {SILENCE_ERRORS} from "../utils";

export type Metric = "attendees" | "eligibles" | "events" | "workshops" | "seminars" | "courses" | "users";

export type GetMetricsResponse = {name: Metric; value: number}[];

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private readonly V1_ENDPOINTS = {
    GET_METRICS: () => `${environment.server.host}/api/v1/reports/metrics`,
  };

  constructor(private httpClient: HttpClient) {}

  getMetrics(silent = false) {
    return this.httpClient
      .get<GetMetricsResponse>(this.V1_ENDPOINTS.GET_METRICS(), {
        context: new HttpContext().set(SILENCE_ERRORS, silent),
      })
      .pipe(
        map((result) => {
          return result.reduce<{[metric: string]: number}>((report, metric) => {
            report[metric.name] = metric.value;
            return report;
          }, {});
        }),
      );
  }
}
