/* eslint-disable @typescript-eslint/no-explicit-any */
import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, NavigationExtras, Router, RouterStateSnapshot} from "@angular/router";
import {map, Observable, take, UnaryFunction} from "rxjs";

import {Role, User} from "../dtos";
import {AuthService} from "../services/auth.service";
import {ToastService} from "../services/toast.service";

export type PipeResult = {can: true} | {can: false; message?: string; route?: string[]; extras?: NavigationExtras};
export type AuthPipe = UnaryFunction<Observable<User | null>, Observable<PipeResult>>;
export type AuthPipeGenerator = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => AuthPipe;

// TODO refactor
export const loggedIn =
  (role?: Role): AuthPipeGenerator =>
  (route, state) =>
    map((user) => {
      if (!user) {
        return {can: false, message: "يرجى تسجيل الدخول", route: ["/login"], extras: {queryParams: {returnUrl: state.url}}};
      }

      if (!role) return {can: true};

      if (user.role !== role) {
        return {
          can: false,
          message: "ليس لديك الصلاحية الكافية للوصول إلي هذه الصفحة",
          route: [""],
        };
      }

      return {can: true};
    });

export const blockLoggedIn: AuthPipeGenerator = (route, state) => map((user) => ({can: !user}));

export const guard =
  (fn: AuthPipeGenerator = loggedIn()): CanActivateFn =>
  (route, state) => {
    const authSvc = inject(AuthService);
    const toastSvc = inject(ToastService);
    const router = inject(Router);

    return authSvc.getUser().pipe(
      take(1),
      fn(route, state),
      map((result) => {
        if (result.can) return true;

        if (result.message) {
          toastSvc.error(result.message);
        }

        if (!result.route) return false;

        return router.createUrlTree(result.route, result.extras);
      }),
    );
  };
