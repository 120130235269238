import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {
  faAddressCard,
  faCertificate,
  faClock,
  faEnvelope,
  faFileImport,
  faLink,
  faPlus,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import {filter, ReplaySubject, takeUntil} from "rxjs";
import {Attendee, AttendeeStatus, Event} from "src/app/dtos";
import {AttendeeService} from "src/app/services/attendee.service";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {FileService} from "src/app/services/file.service";
import {ToastService} from "src/app/services/toast.service";
import {html, roundToNearestQuarter} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

import {
  DashboardNotificationDialogComponent,
  DashboardNotificationDialogData,
} from "../dashboard-notification-dialog/dashboard-notification-dialog.component";

const FilterForm = v.object({
  full_name: v.string().optional(),
  email: v.string().optional(),
  evaluated: v.boolean().optional(),
  certificate: v.boolean().optional(),
  status: v.enum(["enrolled", "attended"]).optional(),
});

@Component({
  selector: "app-dashboard-attendees-list",
  templateUrl: "./dashboard-attendees-list.component.html",
  styleUrls: ["./dashboard-attendees-list.component.scss"],
})
export class DashboardAttendeesListComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  faPlus = faPlus;
  faFileImport = faFileImport;
  faClock = faClock;
  faEnvelope = faEnvelope;
  faSquarePollHorizontal = faSquarePollHorizontal;
  faCertificate = faCertificate;
  faAddressCard = faAddressCard;
  faLink = faLink;

  roundToNearestQuarter = roundToNearestQuarter;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ["id", "full_name", "email", "status", "actions"];
  dataSource: MatTableDataSource<Attendee> = new MatTableDataSource();

  form = new FormGroup(
    {
      full_name: new FormControl<string | undefined>(undefined, {nonNullable: true}),
      email: new FormControl<string | undefined>(undefined, {nonNullable: true}),
      evaluated: new FormControl<boolean | undefined>(undefined, {nonNullable: true}),
      certificate: new FormControl<boolean | undefined>(undefined, {nonNullable: true}),
      status: new FormControl<AttendeeStatus | undefined>(undefined, {nonNullable: true}),
    },
    makeValidators(FilterForm),
  );

  event_id!: string;
  event?: Event;

  statuses = [
    {name: "مسجل", value: "enrolled"},
    {name: "حضر", value: "attended"},
  ];

  loading = false;
  totalRows = 0;
  pageIndex = 0;

  processing: Record<string, boolean> = {};

  @ViewChild("fileInput") fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private route: ActivatedRoute,
    private eventSvc: EventService,
    private toastSvc: ToastService,
    private fileSvc: FileService,
    private confirmSvc: ConfirmService,
    private attendeeSvc: AttendeeService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getEvent();
    this.listAttendees();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getEvent() {
    this.eventSvc
      .getEvent(this.event_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        this.event = event;

        this.displayedColumns = [
          "id",
          "full_name",
          "email",
          ...(event.meeting_id ? ["duration_in_minutes"] : []),
          ...(event.evaluation_form_id ? ["evaluated"] : []),
          ...(event.certificate_id ? ["certificate"] : []),
          "status",
          "actions",
        ];
      });
  }

  listAttendees() {
    this.loading = true;
    const {status, ...filter} = this.form.getRawValue();
    this.eventSvc
      .listAttendees(this.event_id, {
        filter: {
          ...filter,
          status: status ? [status] : undefined,
        },
        paginate: {
          page: this.pageIndex + 1,
          limit: 20,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.dataSource.data = result.rows;
          this.totalRows = result.totalRows;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  pageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listAttendees();
  }

  search() {
    this.pageIndex = 0;
    this.listAttendees();
  }

  reset() {
    this.form.reset({});
    this.search();
  }

  notifyAttendees(type: "notification" | "evaluation" | "certificate" | "registration") {
    this.loading = true;
    this.eventSvc
      .notifyAttendees(this.event_id, {type})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  openNotificationDialog() {
    this.dialog.open<DashboardNotificationDialogComponent, DashboardNotificationDialogData>(
      DashboardNotificationDialogComponent,
      {
        data: {
          event_id: this.event_id,
        },
      },
    );
  }

  notifyAttendee(attendee_id: string, type: "notification" | "evaluation" | "certificate" | "registration") {
    this.processing[attendee_id] = true;
    this.eventSvc
      .notifyAttendee(this.event_id, attendee_id, {type})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.processing[attendee_id] = false;
        },
        error: () => {
          this.processing[attendee_id] = false;
        },
      });
  }

  evaluated(attendee: Attendee) {
    if (!this.event?.evaluation_form_id) return true;

    return !!attendee.evaluation_form_response_id;
  }

  eligibileForCertificate(attendee: Attendee) {
    if (!this.event?.certificate_id) return false;

    return attendee.status === "attended";
  }

  calculateAttendance() {
    this.loading = true;
    this.eventSvc
      .calculateAttendance(this.event_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.reset();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  showImportInstructions() {
    this.confirmSvc
      .confirm({
        title: "تعليمات",
        level: "info",
        message: html` <div>يجب رفع ملف إكسل يحتوي على البيانات التالية</div>
          <table class="table table-bordered table-sm mt-3">
            <thead>
              <tr>
                <th>العمود الأول</th>
                <th>العمود الثاني</th>
                <th>العمود الثالث</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>البريد الإلكتروني</td>
                <td>الاسم الكامل باللغة العربية</td>
                <td>الاسم الكامل باللغة الإنجليزية (إختياري)</td>
              </tr>
            </tbody>
          </table>`,
        width: "500px",
      })
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.fileInput.nativeElement.click();
      });
  }

  import(event: globalThis.Event) {
    const file = this.fileSvc.getFileFromEvent(event);

    if (!file) {
      this.toastSvc.error("يرجى إختيار ملف");
      return;
    }

    if (
      !file.type ||
      !["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)
    ) {
      this.toastSvc.error("صيغة الملف غير مقبولة");
      return;
    }

    this.loading = true;
    this.eventSvc
      .importAttendees(this.event_id, file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.toastSvc.success("تم الرفع بنجاح");
          this.reset();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  scheduleAttendeesRegistration() {
    this.loading = true;
    this.eventSvc
      .scheduleAttendeesRegistration(this.event_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success("جاري تنفيذ الأمر");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  printCertificate(attendee_id: string) {
    this.processing[attendee_id] = true;
    this.attendeeSvc
      .getAttendeeCertificate(attendee_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          if (result.file) {
            this.toastSvc.success("تمت طباعة الشهادة");
          } else {
            this.toastSvc.success("جاري طباعة الشهادة و سيتم إرسال إشعار للمتدرب عن طريق البريد الإلكتروني عند الانتهاء");
          }
          this.processing[attendee_id] = false;
        },
        error: () => {
          this.processing[attendee_id] = false;
        },
      });
  }
}
