import {Country} from "./country.dto";
import {File} from "./file.dto";
import {DateStamps} from "./helpers";

export type Gender = "male" | "female";

export type UserStatus = "active" | "suspended";

export type Role = "user" | "admin";

export interface User extends DateStamps {
  email: string;

  first_name: string;
  middle_name: string;
  last_name: string;
  first_name_en: string;
  middle_name_en: string;
  last_name_en: string;

  phone: string | null;
  country_id: string | null;
  country?: Country | null;
  photo_id: string | null;
  photo?: File | null;

  role: Role;

  email_verified: boolean;
  phone_verified: boolean;

  status: UserStatus;

  linked: boolean;

  must_change_password: boolean;

  profile_created: boolean;
  profile_updated: boolean;

  gender?: Gender | null;
  birth_date?: Date | null;
  job?: string | null;
  workplace?: string | null;
  biography?: string | null;

  full_name: string;
  full_name_en: string;
  status_ar: string;
}
