<mtx-loader [loading]="loading">
  <div class="container p-5">
    <div class="row">
      <form
        #passwordNgForm="ngForm"
        [formGroup]="passwordForm"
        class="col-12"
        autocomplete="off"
        (ngSubmit)="updatePassword()"
      >
        <div class="row d-flex justify-content-center">
          <div class="col-md-8 text-primary mb-3">
            <h4>تغيير كلمة المرور</h4>
          </div>
          <div class="col-md-8">
            <label class="fw-light text-l-gray mb-2" for="password">كلمة المرور</label>
            <div class="input-group mb-3 input-group-with-icon">
              <input
                id="password"
                type="password"
                class="form-control py-3 focus-shadow-none"
                formControlName="password"
                [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(password, passwordNgForm)}"
              />
              <div *ngIf="password.invalid" class="invalid-feedback">{{ password.errors | error }}</div>
            </div>
          </div>
          <div class="col-md-8">
            <label class="fw-light text-l-gray mb-2" for="password_confirm">تأكيد كلمة المرور</label>
            <div class="input-group mb-3 input-group-with-icon">
              <input
                id="password_confirm"
                type="password"
                class="form-control py-3 focus-shadow-none"
                formControlName="password_confirm"
                [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(password_confirm, passwordNgForm)}"
              />
              <div *ngIf="password_confirm.invalid" class="invalid-feedback">{{ password_confirm.errors | error }}</div>
            </div>
          </div>
          <div class="col-md-8">
            <button [disabled]="loading" class="btn btn-primary w-100 py-3 mt-5" type="submit">حفظ</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</mtx-loader>
