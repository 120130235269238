import {ChangeDetectorRef, Component, OnDestroy} from "@angular/core";
import {ReplaySubject, takeUntil} from "rxjs";
import {Event, PaginationOptions} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";

@Component({
  selector: "app-profile-favorites",
  templateUrl: "./profile-favorites.component.html",
  styleUrls: ["./profile-favorites.component.scss"],
})
export class ProfileFavoritesComponent implements OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  options: PaginationOptions = {
    hasNextPage: false,
    hasPrevPage: false,
    page: 1,
    limit: 12,
    nextPage: null,
    prevPage: null,
  };

  page!: number;

  events: Event[] = [];

  loading!: boolean;

  constructor(
    private eventSvc: EventService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getEvents() {
    this.loading = true;
    this.eventSvc
      .listEvents({
        filter: {
          following: true,
          status: ["published"],
        },
        sort: {
          date_created: "desc",
        },
        paginate: {
          page: this.page,
          limit: 12,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.events = result.rows;
          this.options = {
            hasNextPage: result.hasNextPage,
            hasPrevPage: result.hasPrevPage,
            page: result.page,
            limit: result.limit,
            nextPage: result.nextPage,
            prevPage: result.prevPage,
          };
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onPageChange(page: number) {
    this.page = page;
    this.getEvents();
    this.cdRef.detectChanges();
  }
}
