<ng-template #form let-i="index">
  <form #ngForm="ngForm" [formGroup]="noteForm" class="my-3">
    <div>
      <textarea
        rows="3"
        class="form-control focus-shadow-none rounded-0 py-3"
        placeholder="نص الملاحظة"
        formControlName="content"
        [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(noteForm.controls.content, ngForm)}"
      ></textarea>
      <div *ngIf="noteForm.controls.content.invalid" class="invalid-feedback">
        {{ noteForm.controls.content.errors | error }}
      </div>
    </div>
    <div class="mt-3 d-flex gap-3 justify-content-end">
      <button [disabled]="loading" class="btn btn-primary px-5 py-3 rounded-0" (click)="save(i)">إرسال</button>
      <button [disabled]="loading" (click)="toggleNoteForm(i)" class="btn btn-outline-secondary px-5 py-3 rounded-0">
        إلغاء
      </button>
    </div>
  </form>
</ng-template>

<div class="p-4">
  <mtx-loader [loading]="loading">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div *ngIf="!showNoteForm" class="text-end mt-4">
          <button class="btn btn-outline-secondary px-5 py-3 rounded-0" (click)="toggleNoteForm()">
            <fa-icon [icon]="faPlus" class="me-3"></fa-icon>
            إضافة ملاحظة
          </button>
        </div>

        <div *ngIf="showNoteForm" class="row gap-3">
          <div class="col-12">
            <ng-container *ngTemplateOutlet="form; context: {index: undefined}"></ng-container>
          </div>
        </div>

        <p *ngIf="notes && notes.length > 0" class="fw-light mt-5">جميع الملاحظات المتاحة ({{ totalRows }})</p>

        <ng-container *ngFor="let note of notes; index as noteIndex">
          <div class="card rounded-0 py-3 bg-dark bg-opacity-10 border-0">
            <div class="card-body d-flex gap-2">
              <img
                image
                [src]="note.user?.photo?.source"
                style="width: 50px; height: 50px"
                [alt]="note.user?.full_name"
                class="rounded-circle mx-3"
              />

              <div class="flex-grow-1">
                <p class="overflow-anywhere">{{ note.content }}</p>

                <p class="mb-0">
                  <span class="fw-light">{{ note.date_created | date: "yyyy/MM/dd" }}</span>
                </p>

                <div class="d-flex justify-content-end">
                  <button
                    *ngIf="user && (user.role === 'admin' || note.user_id === user.id)"
                    [disabled]="currentToUpdateNote === note.id"
                    class="btn"
                    (click)="toggleNoteForm(noteIndex)"
                  >
                    <fa-icon [icon]="faPen" title="تعديل" class="text-secondary"></fa-icon>
                  </button>
                  <button
                    *ngIf="user && (user.role === 'admin' || note.user_id === user.id)"
                    class="btn"
                    (click)="deleteNote(noteIndex)"
                  >
                    <fa-icon [icon]="faTrash" title="حذف" class="text-danger"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentToUpdateNote === note.id" class="row">
            <div class="col-12">
              <ng-container *ngTemplateOutlet="form; context: {index: noteIndex}"></ng-container>
            </div>
          </div>
        </ng-container>

        <button
          *ngIf="hasNextPage"
          (click)="getNextPage()"
          class="btn btn-outline-secondary rounded-0 text-center mt-3 py-4 w-100"
        >
          المزيد
        </button>

        <div *ngIf="!notes || !notes.length" class="text-center mt-5 bg-primary text-white py-4">لا توجد ملاحظات</div>
      </div>
    </div>
  </mtx-loader>
</div>
