<mtx-loader [loading]="loading">
  <mat-list class="my-4" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-list-item *ngFor="let file of files" class="border border-1 rounded-1" cdkDrag>
      <div class="drag-placeholder" *cdkDragPlaceholder></div>

      <mat-icon matListItemIcon>note</mat-icon>
      <div matListItemTitle>{{ file.filename_download }}</div>

      <div matListItemMeta>
        <button matTooltip="تعديل \ حذف" matTooltipPosition="above" mat-icon-button (click)="openFileDialog(file.id)">
          <mat-icon>edit</mat-icon>
        </button>
        <button matTooltip="اسحب لإعادة الترتيب" matTooltipPosition="above" mat-icon-button cdkDragHandle>
          <mat-icon>drag_handler</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>

  <button class="mt-3 w-100" mat-flat-button (click)="openFileDialog()">
    <span>إضافة المزيد</span>
    <mat-icon>add</mat-icon>
  </button>
</mtx-loader>
