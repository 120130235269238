import {Stamps} from "./helpers";

export type StorageProvider = "local" | "azure";

export interface File extends Stamps {
  storage: StorageProvider;
  filename_disk: string;
  filename_download: string;
  type: string;
  size_in_bytes: string;
  source: string;
}
