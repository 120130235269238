import {File} from "./file.dto";
import {Stamps} from "./helpers";

export type BadgeStatus = "draft" | "published" | "archived";

export interface BadgeField {
  name: string;
  properties: Record<string, string | number | boolean>;
}

export interface Badge extends Stamps {
  name: string;
  image_id: string;
  image?: File;
  fields: BadgeField[];
  status: BadgeStatus;

  status_ar: string;
}
