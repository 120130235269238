import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup, FormRecord} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {Certificate, CertificateStatus, VALID_CERTIFICATE_FIELD_PROPERTIES} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {
  CertificateService,
  CreateCertificateRequestBody,
  UpdateCertificateRequestBody,
} from "src/app/services/certificate.service";
import {FileService} from "src/app/services/file.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsDirty, markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

type FieldFormGroup = FormGroup<{
  name: FormControl<string>;
  properties: FormRecord;
}>;

const CertificateForm = v.object({
  name: v.string(),
  image_id: v.string({format: "uuid"}),
  fields: v.array(
    v.object({
      name: v.string(),
      properties: v.record(v.enum(VALID_CERTIFICATE_FIELD_PROPERTIES).optional(), v.string()),
    }),
  ),
  status: v.enum(["draft", "published", "archived"]),
});

@Component({
  selector: "app-dashboard-certificate-form",
  templateUrl: "./dashboard-certificate-form.component.html",
  styleUrls: ["./dashboard-certificate-form.component.scss"],
})
export class DashboardCertificateFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faTrash = faTrash;
  faPlus = faPlus;

  VALID_PROPERTIES = VALID_CERTIFICATE_FIELD_PROPERTIES;

  form = new FormGroup(
    {
      name: new FormControl("", {nonNullable: true}),
      image_id: new FormControl("", {nonNullable: true}),
      fields: new FormArray<FieldFormGroup>([
        new FormGroup({
          name: new FormControl("full_name", {nonNullable: true}),
          properties: new FormRecord({}),
        }),
        new FormGroup({
          name: new FormControl("image", {nonNullable: true}),
          properties: new FormRecord({}),
        }),
        new FormGroup({
          name: new FormControl("verify_link", {nonNullable: true}),
          properties: new FormRecord({}),
        }),
        new FormGroup({
          name: new FormControl("qr_code", {nonNullable: true}),
          properties: new FormRecord({}),
        }),
      ]),
      status: new FormControl<CertificateStatus>("published", {nonNullable: true}),
    },
    makeValidators(CertificateForm),
  );

  names = [
    {name: "عنوان الشهادة", value: "title"},
    {name: "اسم المتلقي", value: "full_name"},
    {name: "اسم المتلقي بالإنجليزية", value: "full_name_en"},
    {name: "صورة الشهادة", value: "image"},
    {name: "رابط التحقق", value: "verify_link"},
    {name: "رمز QR", value: "qr_code"},
    {name: "عدد ساعات الحضور", value: "duration_in_hours"},
  ];

  statuses = [
    {name: "مسودة", value: "draft"},
    {name: "منشور", value: "published"},
    {name: "مؤرشف", value: "archived"},
  ];

  loading!: boolean;

  certificate_id?: string;
  previewUrl?: string;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private certificateSvc: CertificateService,
    private fileSvc: FileService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const certificate_id = this.route.snapshot.params["certificate_id"];
    if (certificate_id) {
      this.certificate_id = certificate_id;
      this.getCertificatePreviewUrl();
      this.getCertificate();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get name() {
    return this.form.controls.name;
  }

  get image_id() {
    return this.form.controls.image_id;
  }

  get fields() {
    return this.form.controls.fields;
  }

  get status() {
    return this.form.controls.status;
  }

  get imageSrc() {
    return this.image_id.valid && !!this.image_id.value ? this.fileSvc.getFileDownloadLink(this.image_id.value) : null;
  }

  get hasRemainingField() {
    return !!this.getNextField();
  }

  getNextField() {
    const chosen = this.fields.getRawValue().map((field) => field.name);
    const names = this.names.filter((name) => !chosen.includes(name.value));
    return names.at(0);
  }

  selected(name: string) {
    return this.fields.getRawValue().some((field) => field.name === name);
  }

  patchForm(certificate: Certificate) {
    this.form.patchValue(certificate);
    this.fields.clear();
    certificate.fields.forEach((field) => {
      const properties = new FormRecord({});
      Object.keys(field.properties).forEach((property) => {
        properties.addControl(property, new FormControl(field.properties[property], {nonNullable: true}));
      });
      this.fields.push(
        new FormGroup({
          name: new FormControl(field.name, {nonNullable: true}),
          properties,
        }),
      );
    });
    markAllAsPristine(this.form);
  }

  getCertificatePreviewUrl() {
    if (!this.certificate_id) return;

    this.certificateSvc
      .previewCertificate(this.certificate_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((url) => {
        this.previewUrl = url;
      });
  }

  getCertificate() {
    if (!this.certificate_id) return;

    this.loading = true;
    this.certificateSvc
      .getCertificate(this.certificate_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((certificate) => {
        this.patchForm(certificate);
        this.loading = false;
      });
  }

  onFileChange(event: Event, control: FormControl<string | null | undefined>) {
    this.loading = true;
    this.fileSvc
      .uploadFromEvent(event, undefined, ["image/"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (file) => {
          control.setValue(file.id);
          control.markAsDirty();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
  deleteField(fieldIndex: number) {
    this.fields.removeAt(fieldIndex);
    updateFormStatus(this.form);
  }

  pushNewField() {
    const field = this.getNextField();
    if (!field) return;

    const fieldFormGroup = new FormGroup({
      name: new FormControl(field.value, {nonNullable: true}),
      properties: new FormRecord({}),
    });
    this.fields.push(fieldFormGroup);
    markAllAsDirty(fieldFormGroup);
    this.cdRef.detectChanges();
  }

  prepareForm() {
    const form = this.form.getRawValue();

    const body: CreateCertificateRequestBody | UpdateCertificateRequestBody = {
      name: form.name,
      image_id: form.image_id,
      fields: form.fields.map((field) => ({
        name: field.name,
        properties: field.properties,
      })),
      status: form.status,
    };

    return body;
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.prepareForm();

    if (!form.fields.length) {
      this.toastSvc.error("يرجى تعبئة إعدادات الحقول");
      return;
    }

    this.loading = true;
    if (this.certificate_id) {
      this.certificateSvc
        .updateCertificate(this.certificate_id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (certificate) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.patchForm(certificate);
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.certificateSvc
        .createCertificate(form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (certificate) => {
            markAllAsPristine(this.form);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.router.navigate(["/dashboard", "certificates", certificate.id]);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  isEmpty(record: FormRecord) {
    return Object.keys(record.controls).length === 0;
  }

  isPropertySelected(properties: FormRecord, property: string) {
    return properties.contains(property);
  }

  getNextProperty(properties: FormRecord) {
    const chosen = Object.keys(properties.controls);
    const remaining = VALID_CERTIFICATE_FIELD_PROPERTIES.filter((property) => !chosen.includes(property));
    return remaining.at(0);
  }

  hasRemainingProperties(properties: FormRecord) {
    return !!this.getNextProperty(properties);
  }

  pushNewProperty(properties: FormRecord) {
    const property = this.getNextProperty(properties);
    if (!property) return;

    properties.addControl(property, new FormControl("", {nonNullable: true}));
    markAllAsDirty(properties);
    this.cdRef.detectChanges();
  }

  onPropertyChange(properties: FormRecord, prevProperty: string, newProperty: string) {
    const property = new FormControl(properties.controls[prevProperty].value, {nonNullable: true});
    properties.removeControl(prevProperty);
    properties.addControl(newProperty, property);
    markAllAsDirty(properties);
  }

  isDirty() {
    return this.form.dirty;
  }
}
