import {Component, OnDestroy} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ReplaySubject, takeUntil} from "rxjs";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {AuthService} from "src/app/services/auth.service";
import {ToastService} from "src/app/services/toast.service";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const PasswordForm = v
  .object({
    password: v.string({format: "password"}),
    password_confirm: v.string({format: "password"}),
  })
  .refine((ctx) => ctx.password === ctx.password_confirm, {
    path: ["password_confirm"],
    message: "كلمة المرور غير مطابقة",
  });

@Component({
  selector: "app-profile-security",
  templateUrl: "./profile-security.component.html",
  styleUrls: ["./profile-security.component.scss"],
})
export class ProfileSecurityComponent implements OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new ReplaySubject<void>(1);

  passwordForm = new FormGroup(
    {
      password: new FormControl("", {nonNullable: true}),
      password_confirm: new FormControl("", {nonNullable: true}),
    },
    makeValidators(PasswordForm),
  );

  loading!: boolean;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private authSvc: AuthService,
    private toastSvc: ToastService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get password() {
    return this.passwordForm.controls.password;
  }

  get password_confirm() {
    return this.passwordForm.controls.password_confirm;
  }

  updatePassword() {
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }

    const {password} = this.passwordForm.getRawValue();

    this.loading = true;
    this.authSvc
      .updatePassword({password})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.passwordForm.dirty;
  }
}
