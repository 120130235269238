import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Ticket, TicketStatus, TicketType} from "src/app/dtos/ticket.dto";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {TicketService} from "src/app/services/ticket.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

const TicketForm = v.object({
  type: v.enum(["issue", "inquiry", "request"]),
  description: v.string(),
  status: v.enum(["pending", "in-progress", "done"]),
  notes: v.string({nullable: true}),
});

@Component({
  selector: "app-dashboard-ticket-form",
  templateUrl: "./dashboard-ticket-form.component.html",
  styleUrls: ["./dashboard-ticket-form.component.scss"],
})
export class DashboardTicketFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      type: new FormControl<TicketType>("issue", {nonNullable: true}),
      description: new FormControl<string>("", {nonNullable: true}),
      status: new FormControl<TicketStatus>("pending", {nonNullable: true}),
      notes: new FormControl<string | null>(null),
    },
    makeValidators(TicketForm),
  );

  types = [
    {
      name: "مشكلة",
      value: "issue",
    },
    {
      name: "استفسار",
      value: "inquiry",
    },
    {
      name: "اقتراح",
      value: "request",
    },
  ];

  statuses = [
    {
      name: "قيد الانتظار",
      value: "pending",
    },
    {
      name: "جاري المعالجة",
      value: "in-progress",
    },
    {
      name: "منتهي",
      value: "done",
    },
  ];

  loading!: boolean;

  ticket_id!: string;
  ticket?: Ticket;

  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private ticketSvc: TicketService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const ticket_id = this.route.snapshot.params["ticket_id"];
    if (!ticket_id) {
      this.router.navigate(["/dashboard", "tickets"]);
      return;
    }
    this.ticket_id = ticket_id;
    this.getTicket();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get type() {
    return this.form.controls.type;
  }

  get description() {
    return this.form.controls.description;
  }

  get status() {
    return this.form.controls.status;
  }

  get notes() {
    return this.form.controls.notes;
  }

  patchForm(ticket: Ticket) {
    this.form.patchValue(ticket);
    this.ticket = ticket;
    markAllAsPristine(this.form);
  }

  getTicket() {
    this.loading = true;
    this.ticketSvc
      .getTicket(this.ticket_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ticket) => {
        this.patchForm(ticket);
        this.loading = false;
      });
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.getRawValue();

    this.loading = true;
    this.ticketSvc
      .updateTicket(this.ticket_id, form)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (ticket) => {
          this.patchForm(ticket);
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.form.dirty;
  }
}
