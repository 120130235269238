import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ReplaySubject, takeUntil} from "rxjs";
import {Ticket, TicketStatus, TicketType} from "src/app/dtos/ticket.dto";
import {TicketService} from "src/app/services/ticket.service";
import {makeValidators, v} from "src/app/utils/validations";

const FilterForm = v.object({
  type: v.enum(["issue", "inquiry", "request"]).optional(),
  status: v.enum(["pending", "in-progress", "done"]).optional(),
});

@Component({
  selector: "app-profile-tickets-list",
  templateUrl: "./profile-tickets-list.component.html",
  styleUrls: ["./profile-tickets-list.component.scss"],
})
export class ProfileTicketsListComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ["id", "type", "description", "date_created", "status", "actions"];
  dataSource: MatTableDataSource<Ticket> = new MatTableDataSource();

  form = new FormGroup(
    {
      type: new FormControl<TicketType | undefined>(undefined, {nonNullable: true}),
      status: new FormControl<TicketStatus | undefined>(undefined, {nonNullable: true}),
    },
    makeValidators(FilterForm),
  );

  types = [
    {
      name: "مشكلة",
      value: "issue",
    },
    {
      name: "استفسار",
      value: "inquiry",
    },
    {
      name: "اقتراح",
      value: "request",
    },
  ];

  typesClasses: Record<string, string> = {
    issue: "text-bg-danger",
    inquiry: "text-bg-info",
    request: "text-bg-primary",
  };

  statuses = [
    {
      name: "قيد الانتظار",
      value: "pending",
    },
    {
      name: "جاري المعالجة",
      value: "in-progress",
    },
    {
      name: "منتهي",
      value: "done",
    },
  ];

  statusesClasses: Record<string, string> = {
    pending: "text-bg-secondary",
    "in-progress": "text-bg-primary",
    done: "text-bg-success",
  };

  loading = false;
  totalRows = 0;
  pageIndex = 0;

  constructor(private ticketSvc: TicketService) {}

  ngOnInit(): void {
    this.listTickets();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  listTickets() {
    this.loading = true;
    this.ticketSvc
      .listUserTickets({
        filter: this.form.getRawValue(),
        paginate: {
          page: this.pageIndex + 1,
          limit: 20,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.dataSource.data = result.rows;
          this.totalRows = result.totalRows;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  pageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listTickets();
  }

  search() {
    this.pageIndex = 0;
    this.listTickets();
  }

  reset() {
    this.form.reset({});
    this.search();
  }
}
