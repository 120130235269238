<div class="p-5">
  <div class="d-flex">
    <h2>حضور {{ event?.type_ar }} {{ event?.title }}</h2>
  </div>

  <div class="row my-3 g-3">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        [routerLink]="['/dashboard', 'events', event_id, 'attendees', 'new']"
      >
        <fa-icon [icon]="faPlus" size="2xl"></fa-icon>
        <span>إضافة حضور</span>
      </button>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <input #fileInput type="file" class="visually-hidden" (change)="import($event)" />
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="showImportInstructions()"
      >
        <fa-icon [icon]="faFileImport" size="2xl"></fa-icon>
        <span>إضافة حضور من خلال إكسل</span>
      </button>
    </div>
    <div *ngIf="event?.meeting_id" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="calculateAttendance()"
      >
        <fa-icon [icon]="faClock" size="2xl"></fa-icon>
        <span>احتساب الحضور</span>
      </button>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="notifyAttendees('notification')"
      >
        <fa-icon [icon]="faEnvelope" size="2xl"></fa-icon>
        <span>إرسال بريد للتذكير بالحضور</span>
      </button>
    </div>
    <div *ngIf="event?.evaluation_form_id && event?.ended" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="notifyAttendees('evaluation')"
      >
        <fa-icon [icon]="faSquarePollHorizontal" size="2xl"></fa-icon>
        <span>إرسال بريد التقييم</span>
      </button>
    </div>
    <div *ngIf="event?.meeting_id" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="scheduleAttendeesRegistration()"
      >
        <fa-icon [icon]="faAddressCard" size="2xl"></fa-icon>
        <span>تسجيل الحضور على اللقاء</span>
      </button>
    </div>
    <div *ngIf="event?.meeting_id" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="notifyAttendees('registration')"
      >
        <fa-icon [icon]="faLink" size="2xl"></fa-icon>
        <span>إرسال بريد برابط التسجيل على اللقاء</span>
      </button>
    </div>
    <div *ngIf="event?.certificate_id" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="notifyAttendees('certificate')"
      >
        <fa-icon [icon]="faCertificate" size="2xl"></fa-icon>
        <span>إرسال بريد برابط الشهادة لمن تمت الطباعة لهم</span>
      </button>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <button
        class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
        (click)="openNotificationDialog()"
      >
        <fa-icon [icon]="faEnvelope" size="2xl"></fa-icon>
        <span>إرسال رسالة مخصصة</span>
      </button>
    </div>
  </div>

  <hr />

  <form class="mt-3" autocomplete="off" [formGroup]="form">
    <div class="row d-flex align-items-center my-3">
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>الاسم</mat-label>
          <input matInput placeholder="الاسم" formControlName="full_name" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>البريد الإلكتروني</mat-label>
          <input matInput placeholder="البريد الإلكتروني" formControlName="email" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>الحالة</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let s of statuses" [value]="s.value">
              {{ s.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-checkbox formControlName="evaluated">قيم؟</mat-checkbox>
      </div>
      <div class="col-md-3">
        <mat-checkbox formControlName="certificate">طُبعت الشهادة؟</mat-checkbox>
      </div>
      <div class="col d-flex gap-2 p-3">
        <button mat-flat-button color="accent" (click)="reset()">إزالة</button>
        <button mat-flat-button color="accent" (click)="search()">بحث</button>
      </div>
    </div>
  </form>

  <div>
    <mtx-loader [loading]="loading">
      <mat-table matTableResponsive [dataSource]="dataSource">
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; index as i">
            {{ i + 1 + pageIndex * 20 }}
          </mat-cell>
        </ng-container>

        <!-- full_name Column -->
        <ng-container matColumnDef="full_name">
          <mat-header-cell *matHeaderCellDef>الاسم</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.user?.full_name ?? row.full_name }}</mat-cell>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef>البريد الإلكتروني</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.user?.email ?? row.email }}
          </mat-cell>
        </ng-container>

        <!-- duration_in_seconds Column -->
        <ng-container matColumnDef="duration_in_minutes">
          <mat-header-cell *matHeaderCellDef>عدد دقائق الحضور</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.participant?.duration_in_seconds > 0 ? (row.participant?.duration_in_seconds / 60).toFixed(2) : 0 }}
          </mat-cell>
        </ng-container>

        <!-- evaluated Column -->
        <ng-container matColumnDef="evaluated">
          <mat-header-cell *matHeaderCellDef>تم التقييم؟</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox [disabled]="true" [checked]="!!row.evaluation_form_response_id"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- certificate Column -->
        <ng-container matColumnDef="certificate">
          <mat-header-cell *matHeaderCellDef>تم طباعة الشهادة؟</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox [disabled]="true" [checked]="!!row.certificate_file_id"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>الحالة</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.status_ar }}
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-spinner *ngIf="processing[row.id]" [diameter]="25"></mat-spinner>
            <a
              *ngIf="!processing[row.id]"
              mat-icon-button
              [routerLink]="['/dashboard', 'events', event_id, 'attendees', row.id]"
              matTooltip="تعديل"
              matTooltipPosition="above"
            >
              <mat-icon>edit</mat-icon>
            </a>
            <button
              *ngIf="!processing[row.id] && !evaluated(row) && eligibileForCertificate(row) && event?.ended"
              mat-icon-button
              matTooltip="إرسال بريد التقييم"
              matTooltipPosition="above"
              (click)="notifyAttendee(row.id, 'evaluation')"
            >
              <mat-icon>grading</mat-icon>
            </button>
            <button
              *ngIf="
                !processing[row.id] &&
                evaluated(row) &&
                eligibileForCertificate(row) &&
                !row.certificate_file &&
                event?.ended
              "
              mat-icon-button
              matTooltip="طباعة الشهادة"
              matTooltipPosition="above"
              (click)="printCertificate(row.id)"
            >
              <mat-icon>print</mat-icon>
            </button>
            <button
              *ngIf="row.certificate_file && !processing[row.id]"
              mat-icon-button
              matTooltip="إرسال بريد الشهادة"
              matTooltipPosition="above"
              (click)="notifyAttendee(row.id, 'certificate')"
            >
              <mat-icon>mail</mat-icon>
            </button>
            <a
              *ngIf="row.certificate_file && !processing[row.id]"
              [href]="row.certificate_file.source"
              target="_blank"
              mat-icon-button
              matTooltip="تحميل الشهادة"
              matTooltipPosition="above"
            >
              <mat-icon>download</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div *ngIf="this.dataSource.data.length === 0" class="text-center py-5">لا يوجد حضور</div>

      <mat-paginator
        #paginator
        [length]="totalRows"
        [pageIndex]="pageIndex"
        [pageSize]="20"
        (page)="pageChanged($event)"
      ></mat-paginator>
    </mtx-loader>
  </div>
</div>
