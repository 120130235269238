import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {combineLatest, ReplaySubject, takeUntil} from "rxjs";
import {Badge, Certificate, Event, File, Meeting} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {BadgeService} from "src/app/services/badge.service";
import {CertificateService} from "src/app/services/certificate.service";
import {EventService} from "src/app/services/event.service";
import {FileService} from "src/app/services/file.service";
import {ToastService} from "src/app/services/toast.service";
import {isStrictlyDefined, markAllAsPristine} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

const SettingsForm = v
  .object({
    certificate_id: v.string({format: "uuid", nullable: true}).optional(),
    certificate_image_id: v.string({format: "uuid", nullable: true}).optional(),
    badge_id: v.string({format: "uuid", nullable: true}).optional(),
    badge_image_id: v.string({format: "uuid", nullable: true}).optional(),
    questions_enabled: v.boolean().optional(),
    reviews_enabled: v.boolean().optional(),
    notes_enabled: v.boolean().optional(),
    announcements_enabled: v.boolean().optional(),
    has_meeting: v.boolean().optional(),
    min_attendance_duration_in_minutes: v.number().min(0).nullish(),

    auto_start: v.boolean().optional(),
    auto_start_delay_in_minutes: v.number().min(0).nullish(),
    auto_end: v.boolean().optional(),
    auto_end_delay_in_minutes: v.number().min(0).nullish(),
    auto_close_enrollment: v.boolean().optional(),
    auto_close_enrollment_delay_in_minutes: v.number().min(0).nullish(),
    max_allowed_attendees: v.number().min(0).optional(),
  })
  .superRefine((arg, ctx) => {
    if (
      arg.has_meeting === true &&
      (arg.min_attendance_duration_in_minutes === undefined || arg.min_attendance_duration_in_minutes === null)
    ) {
      ctx.addIssue({
        code: v.IssueCode.custom,
        path: ["min_attendance_duration_in_minutes"],
        message: "يرجى إدخال عدد دقائق الحضور",
      });

      return v.NEVER;
    }

    if (
      arg.auto_start !== undefined &&
      arg.auto_start &&
      (arg.auto_start_delay_in_minutes === undefined || arg.auto_start_delay_in_minutes === null)
    ) {
      ctx.addIssue({
        code: v.IssueCode.custom,
        path: ["auto_start_delay_in_minutes"],
        message: "يرجى إدخال عدد الدقائق قبل إرسال التذكير تلقائيًا",
      });

      return v.NEVER;
    }

    if (
      arg.auto_end !== undefined &&
      arg.auto_end &&
      (arg.auto_end_delay_in_minutes === undefined || arg.auto_end_delay_in_minutes === null)
    ) {
      ctx.addIssue({
        code: v.IssueCode.custom,
        path: ["auto_end_delay_in_minutes"],
        message: "يرجى إدخال عدد الدقائق قبل إنهاء الفعالية تلقائيًا",
      });

      return v.NEVER;
    }

    if (
      arg.auto_close_enrollment !== undefined &&
      arg.auto_close_enrollment &&
      (arg.auto_close_enrollment_delay_in_minutes === undefined || arg.auto_close_enrollment_delay_in_minutes === null)
    ) {
      ctx.addIssue({
        code: v.IssueCode.custom,
        path: ["auto_close_enrollment_delay_in_minutes"],
        message: "يرجى إدخال عدد الدقائق فبل إغلاق التسجيل تلقائيًا",
      });

      return v.NEVER;
    }
  });

@Component({
  selector: "app-dashboard-event-settings-form",
  templateUrl: "./dashboard-event-settings-form.component.html",
  styleUrls: ["./dashboard-event-settings-form.component.scss"],
})
export class DashboardEventSettingsFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      certificate_id: new FormControl<string | null | undefined>(null),
      certificate_image_id: new FormControl<string | null | undefined>(null),
      badge_id: new FormControl<string | null | undefined>(null),
      badge_image_id: new FormControl<string | null | undefined>(null),
      questions_enabled: new FormControl<boolean>(false, {nonNullable: true}),
      reviews_enabled: new FormControl<boolean>(false, {nonNullable: true}),
      notes_enabled: new FormControl<boolean>(false, {nonNullable: true}),
      announcements_enabled: new FormControl<boolean>(false, {nonNullable: true}),
      has_meeting: new FormControl<boolean>(false, {nonNullable: true}),
      min_attendance_duration_in_minutes: new FormControl<number | null | undefined>(null),
      auto_start: new FormControl(false, {nonNullable: true}),
      auto_start_delay_in_minutes: new FormControl<number | null | undefined>(null),
      auto_end: new FormControl(false, {nonNullable: true}),
      auto_end_delay_in_minutes: new FormControl<number | null | undefined>(null),
      auto_close_enrollment: new FormControl(false, {nonNullable: true}),
      auto_close_enrollment_delay_in_minutes: new FormControl<number | null | undefined>(null),
      max_allowed_attendees: new FormControl<number | null | undefined>(null),
    },
    makeValidators(SettingsForm),
  );

  loading!: boolean;

  event_id!: string;

  event!: Event;
  certificates!: Certificate[];
  badges!: Badge[];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastSvc: ToastService,
    private eventSvc: EventService,
    private certificateSvc: CertificateService,
    private badgeSvc: BadgeService,
    private fileSvc: FileService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get certificate_id() {
    return this.form.controls.certificate_id;
  }

  get certificate_image_id() {
    return this.form.controls.certificate_image_id;
  }

  get badge_id() {
    return this.form.controls.badge_id;
  }

  get badge_image_id() {
    return this.form.controls.badge_image_id;
  }

  get questions_enabled() {
    return this.form.controls.questions_enabled;
  }

  get reviews_enabled() {
    return this.form.controls.reviews_enabled;
  }

  get notes_enabled() {
    return this.form.controls.notes_enabled;
  }

  get announcements_enabled() {
    return this.form.controls.announcements_enabled;
  }

  get has_meeting() {
    return this.form.controls.has_meeting;
  }

  get min_attendance_duration_in_minutes() {
    return this.form.controls.min_attendance_duration_in_minutes;
  }

  get auto_start() {
    return this.form.controls.auto_start;
  }

  get auto_start_delay_in_minutes() {
    return this.form.controls.auto_start_delay_in_minutes;
  }

  get auto_end() {
    return this.form.controls.auto_end;
  }

  get auto_end_delay_in_minutes() {
    return this.form.controls.auto_end_delay_in_minutes;
  }

  get auto_close_enrollment() {
    return this.form.controls.auto_close_enrollment;
  }

  get auto_close_enrollment_delay_in_minutes() {
    return this.form.controls.auto_close_enrollment_delay_in_minutes;
  }

  get max_allowed_attendees() {
    return this.form.controls.max_allowed_attendees;
  }

  get certificateImageSrc() {
    return this.certificate_image_id.valid && !!this.certificate_image_id.value
      ? this.fileSvc.getFileDownloadLink(this.certificate_image_id.value)
      : null;
  }

  get badgeImageSrc() {
    return this.badge_image_id.valid && !!this.badge_image_id.value
      ? this.fileSvc.getFileDownloadLink(this.badge_image_id.value)
      : null;
  }

  patchForm(event: Event) {
    this.event = event;
    this.form.patchValue({
      ...event,
      min_attendance_duration_in_minutes: event.min_attendance_duration_in_seconds
        ? event.min_attendance_duration_in_seconds / 60
        : null,
    });
    markAllAsPristine(this.form);
  }

  getData() {
    this.loading = true;
    combineLatest([this.eventSvc.getEvent(this.event_id), this.certificateSvc.getCertificates(), this.badgeSvc.getBadges()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([event, certificates, badges]) => {
        this.patchForm(event);
        this.certificates = certificates;
        this.badges = badges;
        this.loading = false;
      });
  }

  onFileChange(event: globalThis.Event, control: FormControl<string | null | undefined>) {
    this.loading = true;
    this.fileSvc
      .uploadFromEvent(event, undefined, ["image/"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (file) => {
          control.setValue(file.id);
          control.markAsDirty();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  updateEvent() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.getRawValue();

    const data = {
      ...form,
      min_attendance_duration_in_seconds:
        isStrictlyDefined(form.has_meeting) && isStrictlyDefined(form.min_attendance_duration_in_minutes)
          ? form.min_attendance_duration_in_minutes * 60
          : null,
      auto_start_delay_in_minutes:
        isStrictlyDefined(form.auto_start) && form.auto_start && isStrictlyDefined(form.auto_start_delay_in_minutes)
          ? form.auto_start_delay_in_minutes
          : null,
      auto_end_delay_in_minutes:
        isStrictlyDefined(form.auto_end) && form.auto_end && isStrictlyDefined(form.auto_end_delay_in_minutes)
          ? form.auto_end_delay_in_minutes
          : null,
      auto_close_enrollment_delay_in_minutes:
        isStrictlyDefined(form.auto_close_enrollment) &&
        form.auto_close_enrollment &&
        isStrictlyDefined(form.auto_close_enrollment_delay_in_minutes)
          ? form.auto_close_enrollment_delay_in_minutes
          : null,
    };

    this.loading = true;
    this.eventSvc
      .updateEvent(this.event_id, data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (event) => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.patchForm(event);
          this.eventSvc.checkDashboardTabs();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.form.dirty;
  }
}
