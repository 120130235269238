import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {PaginationOptions} from "src/app/dtos";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  @Input({required: true}) options!: PaginationOptions;
  @Output() pageChange = new EventEmitter<number>();

  ngOnInit(): void {
    this.pageChange.next(1);
  }

  goToNextPage() {
    this.pageChange.next(this.options.page + 1);
  }

  goToPrevPage() {
    this.pageChange.next(this.options.page - 1);
  }

  goToPage(page: number) {
    this.pageChange.next(page);
  }
}
