import {Badge} from "./badge.dto";
import {Certificate} from "./certificate.dto";
import {File} from "./file.dto";
import {Form} from "./form.dto";
import {Stamps} from "./helpers";

export type EventStatus = "draft" | "published" | "archived";

export type EventAction = "follow" | "unfollow";

export type EventType = "course" | "workshop" | "seminar";

export type EventVisibility = "public" | "private";

export interface Event extends Stamps {
  mongo_id: string | null;
  title: string;
  subtitle: string | null;
  description: string | null;
  start_date: Date;
  end_date: Date;
  duration_in_seconds: number;
  max_allowed_attendees: number | null;
  thumbnail_image_id: string | null;
  thumbnail_image?: File | null;
  banner_image_id: string | null;
  banner_image?: File | null;
  tags: string[];
  objectives: string | null;
  prerequisites: string | null;
  audience: string | null;
  type: EventType;
  visibility: EventVisibility;
  ended: boolean;
  status: EventStatus;

  certificate_id: string | null;
  certificate?: Certificate | null;
  certificate_image_id: string | null;
  certificate_image?: File | null;
  badge_id: string | null;
  badge?: Badge | null;
  badge_image_id: string | null;
  badge_image?: File | null;
  evaluation_form_id: string | null;
  evaluation_form?: Form | null;
  questions_enabled: boolean;
  reviews_enabled: boolean;
  notes_enabled: boolean;
  announcements_enabled: boolean;

  has_meeting: boolean;
  meeting_id: string | null;
  min_attendance_duration_in_seconds: number | null;

  payment_required: boolean;

  price: number | null;
  price_currency_code: string | null;

  rating?: number | null;
  users_rated?: number | null;

  action_taken?: EventAction;
  date_action_taken?: Date;

  attendees_count?: number;

  type_ar: string;
  status_ar: string;
  visibility_ar: string;

  auto_start: boolean;
  auto_start_delay_in_minutes: number | null;

  auto_end: boolean;
  auto_end_delay_in_minutes: number | null;

  auto_close_enrollment: boolean;
  auto_close_enrollment_delay_in_minutes: number | null;
  enrollment_closed: boolean;
}
