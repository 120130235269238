import {Component, OnDestroy} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {AuthService} from "src/app/services/auth.service";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const RegisterForm = v
  .object({
    first_name: v.string(),
    middle_name: v.string(),
    last_name: v.string(),
    email: v.string({format: "email"}),
    password: v.string({format: "password"}),
    password_confirm: v.string({format: "password"}),
  })
  .refine(
    (arg) => arg.password === arg.password_confirm,
    () => ({path: ["password_confirm"], message: "كلمة المرور غير متطابقة"}),
  );

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      first_name: new FormControl("", {nonNullable: true}),
      middle_name: new FormControl("", {nonNullable: true}),
      last_name: new FormControl("", {nonNullable: true}),

      email: new FormControl("", {nonNullable: true}),
      password: new FormControl("", {nonNullable: true}),
      password_confirm: new FormControl("", {nonNullable: true}),
    },
    makeValidators(RegisterForm),
  );

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  loading!: boolean;

  constructor(
    public authSvc: AuthService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get first_name() {
    return this.form.controls.first_name;
  }

  get middle_name() {
    return this.form.controls.middle_name;
  }

  get last_name() {
    return this.form.controls.last_name;
  }

  get email() {
    return this.form.controls.email;
  }

  get password() {
    return this.form.controls.password;
  }

  get password_confirm() {
    return this.form.controls.password_confirm;
  }
  register() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const {password_confirm, ...form} = this.form.getRawValue();

    this.loading = true;
    this.authSvc
      .register(form)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.router.navigate(["/login"], {queryParams: {status: "success", message: "registered"}});
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
