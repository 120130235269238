import {Component, OnDestroy, OnInit} from "@angular/core";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {File} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";

@Component({
  selector: "app-event-documents",
  templateUrl: "./event-documents.component.html",
  styleUrls: ["./event-documents.component.scss"],
})
export class EventDocumentsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faDownload = faDownload;

  loading!: boolean;

  files: File[] = [];

  constructor(private eventSvc: EventService) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData() {
    this.loading = true;
    this.eventSvc
      .getEvent$()
      .pipe(
        switchMap((event) => this.eventSvc.getFiles(event.id)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (files) => {
          this.files = files;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
