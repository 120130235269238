import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {AuthService} from "src/app/services/auth.service";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const ResetPasswordForm = v.object({
  password: v.string({format: "password"}),
});

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      password: new FormControl("", {nonNullable: true}),
    },
    makeValidators(ResetPasswordForm),
  );

  token!: string;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  loading!: boolean;

  constructor(
    private authSvc: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get("token");
    if (token) {
      this.token = token;
    } else {
      this.router.navigate([""]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get password() {
    return this.form.controls.password;
  }

  resetPassword() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.authSvc
      .resetPassword({
        password: this.form.getRawValue().password,
        token: this.token,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.router.navigate(["/login"], {queryParams: {status: "success", message: "password_updated"}});
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
