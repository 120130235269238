import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Chapter} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsDirty, markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

type ChapterFormGroup = FormGroup<{
  id: FormControl<string | null | undefined>;
  title: FormControl<string>;
}>;

const ChaptersForm = v.object({
  chapters: v.array(
    v.object({
      id: v.string({format: "uuid", nullable: true}).optional(),
      title: v.string(),
    }),
  ),
});

@Component({
  selector: "app-dashboard-event-chapters-form",
  templateUrl: "./dashboard-event-chapters-form.component.html",
  styleUrls: ["./dashboard-event-chapters-form.component.scss"],
})
export class DashboardEventChaptersFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      chapters: new FormArray<ChapterFormGroup>([]),
    },
    makeValidators(ChaptersForm),
  );

  loading!: boolean;

  event_id!: string;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private toastSvc: ToastService,
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private confirmSvc: ConfirmService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getChapters();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get chapters() {
    return this.form.controls.chapters;
  }

  getChapters() {
    this.loading = true;
    this.eventSvc
      .getChapters(this.event_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((chapters) => {
        this.buildForm(chapters);
        this.loading = false;
      });
  }

  buildForm(chapters: Chapter[]) {
    this.chapters.clear();
    chapters.forEach((chapter) => {
      this.chapters.push(
        new FormGroup({
          id: new FormControl<string | null | undefined>(chapter.id),
          title: new FormControl(chapter.title, {nonNullable: true}),
        }),
      );
    });
    markAllAsPristine(this.form);
  }

  pushNewChapter() {
    const chapter = new FormGroup({
      id: new FormControl<string | null | undefined>(null),
      title: new FormControl("", {nonNullable: true}),
    });
    this.chapters.push(chapter);
    markAllAsDirty(chapter);
    this.cdRef.detectChanges();
  }

  deleteChapter(chapterIndex: number) {
    const chapter = this.chapters.at(chapterIndex);
    if (chapter) {
      const {id} = chapter.getRawValue();
      if (id) {
        this.confirmSvc
          .confirm()
          .afterClosed()
          .pipe(
            filter((result) => !!result),
            switchMap(() => {
              this.loading = true;

              return this.eventSvc.deleteChapter(this.event_id, id);
            }),
            takeUntil(this.unsubscribe$),
          )
          .subscribe({
            next: () => {
              this.toastSvc.success("تم تنفيذ الأمر بنجاح");
              this.loading = false;
              this.getChapters();
            },
            error: () => {
              this.loading = false;
            },
          });
      } else {
        this.chapters.removeAt(chapterIndex);
        updateFormStatus(this.form);
      }
    }
  }

  saveChapter(chapter: ChapterFormGroup, index: number) {
    if (chapter.invalid) {
      chapter.markAllAsTouched();
      return;
    }

    const {id, title} = chapter.getRawValue();
    this.loading = true;
    if (id) {
      this.eventSvc
        .updateChapter(this.event_id, id, {
          title: title,
          order: index + 1,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.getChapters();
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createChapter(this.event_id, {
          title: title,
          order: index + 1,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.getChapters();
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  updateChapters() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const body = this.form
      .getRawValue()
      .chapters.filter((chapter) => !!chapter.id)
      .map((chapter, index) => ({id: chapter.id as string, order: index + 1}));

    this.loading = true;
    this.eventSvc
      .updateChapters(this.event_id, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (chapters) => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.buildForm(chapters);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  drop(event: CdkDragDrop<ChapterFormGroup>) {
    moveItemInArray(this.chapters.controls, event.previousIndex, event.currentIndex);
    this.updateChapters();
  }

  isDirty() {
    return this.form.dirty;
  }
}
