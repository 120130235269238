import {Component, Input} from "@angular/core";
import {faStar as faStarRegular} from "@fortawesome/free-regular-svg-icons";
import {faStar as faStarSolid} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
})
export class RatingComponent {
  faStarSolid = faStarSolid;
  faStarRegular = faStarRegular;

  @Input({required: true}) rating!: number;
  @Input() users?: number;

  get _rating() {
    return this.rating ? Math.max(Math.min(Math.ceil(this.rating), 5), 0) : 0;
  }

  get _users() {
    return this.users ? Math.max(this.users, 0) : 0;
  }
}
