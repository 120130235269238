<mtx-loader [loading]="loading">
  <form class="p-5" [formGroup]="form" (ngSubmit)="createEvent()">
    <div class="d-flex justify-content-between">
      <h2>إنشاء فعالية جديدة</h2>
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>عنوان الفعالية</mat-label>
            <input formControlName="title" type="text" matInput placeholder="عنوان الفعالية" />
            <mat-error *ngIf="title.invalid">{{ title.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>تاريخ بداية الفعالية</mat-label>
            <input
              matInput
              [mtxDatetimepicker]="startDatePicker"
              placeholder="تاريخ بداية الفعالية"
              formControlName="start_date"
              (click)="startDatePicker.open()"
              dir="ltr"
            />
            <mtx-datetimepicker-toggle [for]="startDatePicker" matSuffix></mtx-datetimepicker-toggle>
            <mtx-datetimepicker
              #startDatePicker
              type="datetime"
              mode="portrait"
              twelvehour
              touchUi
              timeInput
              dir="ltr"
            ></mtx-datetimepicker>
            <mat-error *ngIf="start_date.invalid">{{ start_date.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>تاريخ نهاية الفعالية</mat-label>
            <input
              matInput
              [mtxDatetimepicker]="endDatePicker"
              placeholder="تاريخ نهاية الفعالية"
              formControlName="end_date"
              (click)="endDatePicker.open()"
              dir="ltr"
            />
            <mtx-datetimepicker-toggle [for]="endDatePicker" matSuffix></mtx-datetimepicker-toggle>
            <mtx-datetimepicker
              #endDatePicker
              type="datetime"
              mode="portrait"
              twelvehour
              touchUi
              timeInput
              dir="ltr"
            ></mtx-datetimepicker>
            <mat-error *ngIf="end_date.invalid">{{ end_date.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>فترة الفعالية بالدقائق</mat-label>
            <input formControlName="duration_in_minutes" type="number" matInput placeholder="فترة الفعالية بالدقائق" />
            <mat-error *ngIf="duration_in_minutes.invalid">{{ duration_in_minutes.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>صنف الفعالية</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let t of types" [value]="t.value">
                {{ t.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="type.invalid">{{ type.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>خصوصية الفعالية</mat-label>
            <mat-select formControlName="visibility">
              <mat-option *ngFor="let v of visibilities" [value]="v.value">
                {{ v.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="visibility.invalid">{{ visibility.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
