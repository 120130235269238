import {animate, style, transition, trigger} from "@angular/animations";
import {Component, HostListener, Inject, OnInit} from "@angular/core";
import {PLATFORM_SERVICE, PlatformService} from "src/app/services/platform.service";

@Component({
  selector: "app-dashboard-sidebar",
  templateUrl: "./dashboard-sidebar.component.html",
  styleUrls: ["./dashboard-sidebar.component.scss"],
  animations: [trigger("fadeIn", [transition(":enter", [style({opacity: 0}), animate("350ms", style({opacity: 1}))])])],
})
export class DashboardSidebarComponent implements OnInit {
  items: {title: string; route: string; icon: string}[] = [
    {
      title: "الرئيسة",
      route: "/dashboard/home",
      icon: "home",
    },
    {
      title: "المستخدمون",
      route: "/dashboard/users",
      icon: "manage_accounts",
    },
    {
      title: "الفعاليات",
      route: "/dashboard/events",
      icon: "event",
    },
    {
      title: "الدول",
      route: "/dashboard/countries",
      icon: "flag",
    },
    {
      title: "قوالب التقييم",
      route: "/dashboard/forms",
      icon: "grading",
    },
    {
      title: "الشهادات",
      route: "/dashboard/certificates",
      icon: "card_membership",
    },
    {
      title: "البادجات",
      route: "/dashboard/badges",
      icon: "badge",
    },
    {
      title: "الدعم الفني",
      route: "/dashboard/tickets",
      icon: "support_agent",
    },
  ];

  collapsed = false;

  constructor(@Inject(PLATFORM_SERVICE) private platformSvc: PlatformService) {}

  ngOnInit(): void {
    this.onResize();
  }
  @HostListener("window:resize")
  onResize() {
    const SCREEN_BREAKPOINT = 768;

    const window = this.platformSvc.getWindow();
    const screenWidth = window.innerWidth;

    this.collapsed = screenWidth <= SCREEN_BREAKPOINT;
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }
}
