<mtx-loader [loading]="loading">
  <div class="p-5" *ngIf="ticket_id">
    <div class="d-flex">
      <h2>{{ ticket?.type_ar }} بتاريخ {{ ticket?.date_created | date: "yyyy/MM/dd" }}</h2>
    </div>

    <div class="mt-5">
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td class="fw-bold">آخر تحديث</td>
            <td>{{ ticket?.date_updated ? (ticket?.date_updated | date: "yyyy/MM/dd") : "لم يتم التحديث حتى الآن" }}</td>
          </tr>
          <tr>
            <td class="fw-bold">الوصف</td>
            <td [innerText]="ticket?.description"></td>
          </tr>
          <tr>
            <td class="fw-bold">الملاحظات</td>
            <td [innerText]="ticket?.notes ?? 'لم يتم إدخال ملاحظات'"></td>
          </tr>
          <tr>
            <td class="fw-bold">الحالة</td>
            <td>
              <span class="badge {{ statusesClasses[ticket?.status!] }}">{{ ticket?.status_ar }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <form *ngIf="!ticket_id" class="p-5" [formGroup]="form" (ngSubmit)="save()">
    <div class="d-flex justify-content-between">
      <h2>طلب جديد</h2>
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>نوع الطلب</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let t of types" [value]="t.value">
                {{ t.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="type.invalid">{{ type.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الوصف</mat-label>
            <textarea formControlName="description" matInput placeholder="الوصف" rows="5"></textarea>
            <mat-error *ngIf="description.invalid">{{ description.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
