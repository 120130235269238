import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Country} from "src/app/dtos";
import {ConfirmService} from "src/app/services/confirm.service";
import {CountryService} from "src/app/services/country.service";
import {ToastService} from "src/app/services/toast.service";

@Component({
  selector: "app-dashboard-countries-list",
  templateUrl: "./dashboard-countries-list.component.html",
  styleUrls: ["./dashboard-countries-list.component.scss"],
})
export class DashboardCountriesListComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  loading!: boolean;

  countries: Country[] = [];

  constructor(
    private toastSvc: ToastService,
    private countrySvc: CountryService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.getCountries();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCountries() {
    this.loading = true;
    this.countrySvc
      .getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countries) => {
        this.countries = countries;
        this.loading = false;
      });
  }

  drop(event: CdkDragDrop<Country>) {
    moveItemInArray(this.countries, event.previousIndex, event.currentIndex);
    this.updateCountries();
  }

  deleteCountry(country_id: string) {
    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          this.loading = true;
          return this.countrySvc.deleteCountry(country_id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.getCountries();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  updateCountries() {
    this.loading = true;
    this.countrySvc
      .updateCountries(this.countries.map((country, index) => ({id: country.id, order: index + 1})))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (countries) => {
          this.countries = countries;
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
