<div class="p-5">
  <div class="d-flex">
    <h2>الدعم الفني</h2>
  </div>

  <form class="mt-3" autocomplete="off" [formGroup]="form">
    <div class="row d-flex align-items-center my-3">
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>بريد صاحب الطلب</mat-label>
          <input matInput placeholder="بريد صاحب الطلب" formControlName="email" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>نوع الطلب</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let t of types" [value]="t.value">
              {{ t.name }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>حالة الطلب</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let s of statuses" [value]="s.value">
              {{ s.name }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-3 d-flex gap-2 p-3">
        <button mat-flat-button color="accent" (click)="reset()">إزالة</button>
        <button mat-flat-button color="accent" (click)="search()">بحث</button>
      </div>
    </div>
  </form>

  <div>
    <mtx-loader [loading]="loading">
      <mat-table matTableResponsive [dataSource]="dataSource">
        <!-- id Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; index as i">
            {{ i + 1 + pageIndex * 20 }}
          </mat-cell>
        </ng-container>

        <!-- type Column -->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>النوع</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="badge {{ typesClasses[row.type] }}">{{ row.type_ar }}</span>
          </mat-cell>
        </ng-container>

        <!-- user Column -->
        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef>صاحب الطلب</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.user?.full_name }} ({{ row.user?.email }})</mat-cell>
        </ng-container>

        <!-- description Column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>الوصف</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
              {{ row.description }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- date_created Column -->
        <ng-container matColumnDef="date_created">
          <mat-header-cell *matHeaderCellDef>تاريخ الإنشاء</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.date_created | date: "yyyy/MM/dd" }}</mat-cell>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>الحالة</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="badge {{ statusesClasses[row.status] }}">{{ row.status_ar }}</span>
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a
              mat-icon-button
              matTooltip="الاطلاع"
              matTooltipPosition="above"
              [routerLink]="['/dashboard', 'tickets', row.id]"
            >
              <mat-icon>visibility</mat-icon>
            </a>
            <button mat-icon-button matTooltip="حذف" matTooltipPosition="above" (click)="delete(row.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div *ngIf="this.dataSource.data.length === 0" class="text-center py-5">لا توجد بادجات</div>

      <mat-paginator
        #paginator
        [length]="totalRows"
        [pageIndex]="pageIndex"
        [pageSize]="20"
        (page)="pageChanged($event)"
      ></mat-paginator>
    </mtx-loader>
  </div>
</div>
