<nav>
  <ul class="pagination justify-content-center dark">
    <li class="page-item" [ngClass]="{disabled: !options.hasPrevPage}">
      <a class="page-link" href="javascript:void(0)" (click)="goToPrevPage()"><fa-icon [icon]="faChevronRight"></fa-icon></a>
    </li>
    <li *ngIf="options.prevPage" class="page-item">
      <a class="page-link" href="javascript:void(0)" (click)="goToPage(options.prevPage)">{{ options.prevPage }}</a>
    </li>
    <li class="page-item active">
      <a class="page-link" href="javascript:void(0)" (click)="goToPage(options.page)">{{ options.page }}</a>
    </li>
    <li *ngIf="options.nextPage" class="page-item">
      <a class="page-link" href="javascript:void(0)" (click)="goToPage(options.nextPage)">{{ options.nextPage }}</a>
    </li>
    <li class="page-item" [ngClass]="{disabled: !options.hasNextPage}">
      <a class="page-link" href="javascript:void(0)" (click)="goToNextPage()"><fa-icon [icon]="faChevronLeft"></fa-icon></a>
    </li>
  </ul>
</nav>
