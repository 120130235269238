<div class="p-5">
  <div class="d-flex justify-content-between">
    <h2>قوالب التقييم</h2>
    <a mat-flat-button color="primary" [routerLink]="['/dashboard/forms/new']">إضافة</a>
  </div>

  <div>
    <mtx-loader [loading]="loading">
      <mat-table matTableResponsive [dataSource]="dataSource">
        <!-- id Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; index as i">
            {{ i + 1 + pageIndex * 20 }}
          </mat-cell>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>عنوان القالب</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.title }}</mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a mat-icon-button matTooltip="تعديل" matTooltipPosition="above" [routerLink]="['/dashboard', 'forms', row.id]">
              <mat-icon>edit</mat-icon>
            </a>
            <button mat-icon-button matTooltip="حذف" matTooltipPosition="above" (click)="delete(row.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div *ngIf="this.dataSource.data.length === 0" class="text-center py-5">لا توجد قوالب</div>

      <mat-paginator
        #paginator
        [length]="totalRows"
        [pageIndex]="pageIndex"
        [pageSize]="20"
        (page)="pageChanged($event)"
      ></mat-paginator>
    </mtx-loader>
  </div>
</div>
