import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ReplaySubject, takeUntil} from "rxjs";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {makeValidators, v} from "src/app/utils/validations";

export interface DashboardNotificationDialogData {
  event_id: string;
}

const MessageSchema = v.object({
  subject: v.string().trim().min(1),
  content: v.string().trim().min(1),
});

@Component({
  selector: "app-dashboard-notification-dialog",
  templateUrl: "./dashboard-notification-dialog.component.html",
  styleUrls: ["./dashboard-notification-dialog.component.scss"],
})
export class DashboardNotificationDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  loading!: boolean;

  form = new FormGroup(
    {
      subject: new FormControl("", {nonNullable: true}),
      content: new FormControl("", {nonNullable: true}),
    },
    makeValidators(MessageSchema),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DashboardNotificationDialogData,
    private dialogRef: MatDialogRef<DashboardNotificationDialogComponent>,
    private eventSvc: EventService,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize("900px");
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onConfirm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const message = this.form.getRawValue();

    this.loading = true;
    this.eventSvc
      .notifyAttendees(this.data.event_id, {
        type: "custom",
        message: {
          subject: message.subject.trim(),
          content: `<p dir="auto">${message.content.trim()}</p>`,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.toastSvc.success("تم إرسال الرسالة بنجاح");
          this.dialogRef.close();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onDismiss(deleted = false) {
    this.dialogRef.close(deleted);
  }
}
