import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {User} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  faRightFromBracket = faRightFromBracket;

  user!: User | null;

  constructor(
    private authSvc: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUser() {
    this.authSvc
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  logout() {
    if (!this.user) return;

    this.authSvc
      .logout(true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.authSvc.clear();
          // send state to skip dataLoss guard checks
          this.router.navigate([""], {state: {logout: true}});
        },
        error: () => {
          this.authSvc.clear();
          this.router.navigate([""], {state: {logout: true}});
        },
      });
  }
}
