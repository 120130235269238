import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {faPen, faPlus, faThumbsDown, faThumbsUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Review, ReviewAction, User} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {HttpStatusCode, mapError} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const ReviewForm = v.object({
  id: v.string({format: "uuid", nullable: true}).optional(),
  content: v.string(),
  rating: v.number().min(1).max(5),
});

@Component({
  selector: "app-event-reviews",
  templateUrl: "./event-reviews.component.html",
  styleUrls: ["./event-reviews.component.scss"],
})
export class EventReviewsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faPlus = faPlus;
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  faPen = faPen;
  faTrash = faTrash;

  reviewForm = new FormGroup(
    {
      id: new FormControl(""),
      content: new FormControl("", {nonNullable: true}),
      rating: new FormControl(5, {nonNullable: true}),
    },
    makeValidators(ReviewForm),
  );

  filterForm = new FormGroup({
    sort: new FormControl<"date_created" | "likes" | "rating">("rating"),
  });

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  event_id!: string;

  user!: User | null;

  page = 0;
  totalRows!: number;
  hasNextPage!: boolean;

  loading!: boolean;

  review!: Review | null;
  reviews: Review[] = [];

  showReviewForm!: boolean;
  currentToUpdateReview: string | null = null;

  showUserReviewForm!: boolean;

  constructor(
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private authSvc: AuthService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getUser();
    this.getReviews();
    this.getUserReview();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUser() {
    this.authSvc
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  getReviews(push = false) {
    this.loading = true;

    const {sort} = this.filterForm.getRawValue();

    if (!push) {
      this.reviews = [];
    }

    this.eventSvc
      .listReviews(this.event_id, {
        sort: {
          likes: sort === "likes" ? "desc" : undefined,
          rating: sort === "rating" ? "desc" : undefined,
          date_created: sort === "date_created" ? "desc" : undefined,
        },
        paginate: {
          page: this.page + 1,
          limit: 5,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.loading = false;
          this.totalRows = result.totalRows;
          this.hasNextPage = result.hasNextPage;
          this.reviews.push(...result.rows);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  getUserReview() {
    this.eventSvc
      .getReview(this.event_id, "me", true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (review) => {
          this.review = review;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status !== HttpStatusCode.NOT_FOUND) {
            this.toastSvc.error(mapError(error));
          }
        },
      });
  }

  getNextPage() {
    this.page++;
    this.getReviews(true);
  }

  toggleReviewForm(reviewIndex?: number) {
    if (reviewIndex !== undefined) {
      this.showReviewForm = false;
      const review = this.reviews.at(reviewIndex);
      if (review) {
        this.currentToUpdateReview = this.currentToUpdateReview ? null : review.id;
        if (this.currentToUpdateReview) {
          this.reviewForm.reset({
            id: review.id,
            content: review.content,
            rating: review.rating,
          });
        }
      }
    } else {
      this.currentToUpdateReview = null;
      this.showReviewForm = !this.showReviewForm;
      this.reviewForm.reset({
        id: null,
        content: "",
        rating: 5,
      });
    }
  }

  toggleUserReviewForm() {
    if (!this.review) return;

    this.currentToUpdateReview = null;
    this.showReviewForm = false;
    this.showUserReviewForm = !this.showUserReviewForm;
    if (this.showUserReviewForm) {
      this.reviewForm.reset({
        id: this.review.id,
        content: this.review.content,
        rating: this.review.rating,
      });
    }
  }

  save(reviewIndex?: number) {
    if (this.reviewForm.invalid) {
      this.reviewForm.markAllAsTouched();
      return;
    }

    this.loading = true;
    const {id, ...form} = this.reviewForm.getRawValue();
    if (id) {
      this.eventSvc
        .updateReview(this.event_id, id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (review) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            if (reviewIndex !== undefined) {
              this.reviews[reviewIndex] = review;
            }
            this.currentToUpdateReview = null;
            if (this.user && this.user.id === review.id) {
              this.review = review;
            }
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createReview(this.event_id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (review) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.showReviewForm = false;
            this.reviews.unshift(review);
            this.totalRows++;
            this.review = review;
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  saveUserReview() {
    if (this.reviewForm.invalid) {
      this.reviewForm.markAllAsTouched();
      return;
    }

    this.loading = true;
    const {id, ...form} = this.reviewForm.getRawValue();
    if (!id) return;
    this.eventSvc
      .updateReview(this.event_id, id, form)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (review) => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.review = review;
          this.showUserReviewForm = false;
          const existingIndex = this.reviews.findIndex((r) => r.id === review.id);
          if (existingIndex > -1) {
            this.reviews.splice(existingIndex, 1, review);
          }
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  doAction(review: Review, action: ReviewAction) {
    action = review.action_taken === action ? "reset" : action;
    this.eventSvc
      .doReviewAction(this.event_id, review.id, action)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.review) this.patchAction(this.review, action);
        const existingIndex = this.reviews.findIndex((r) => r.id === review.id);
        if (existingIndex > -1) {
          this.patchAction(this.reviews[existingIndex], action);
        }
      });
  }

  patchAction(review: Review, action: ReviewAction) {
    review.likes = review.likes ?? 0;
    review.dislikes = review.dislikes ?? 0;
    if (action === "like") {
      review.likes++;
      if (review.action_taken === "dislike") review.dislikes--;
    } else {
      review.dislikes++;
      if (review.action_taken === "like") review.likes--;
    }
    review.action_taken = action;
  }

  deleteReview(index: number) {
    const review = this.reviews.at(index);

    if (!review) return;

    if (!this.user || (this.user.role !== "admin" && review.user_id !== this.user.id)) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          return this.eventSvc.deleteReview(this.event_id, review.id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.toastSvc.success("تم تنفيذ الأمر بنجاح");
        this.reviews.splice(index, 1);
        this.totalRows--;
        if (this.review && this.review.id === review.id) {
          this.review = null;
        }
      });
  }

  deleteUserReview() {
    if (!this.review) return;

    const review_id = this.review.id;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          this.loading = true;
          return this.eventSvc.deleteReview(this.event_id, review_id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          const existingIndex = this.reviews.findIndex((r) => r.id === review_id);
          if (existingIndex > -1) {
            this.reviews.splice(existingIndex, 1);
          }
          this.review = null;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
