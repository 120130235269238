import {inject} from "@angular/core";
import {CanDeactivateFn, Router} from "@angular/router";
import {map, Observable} from "rxjs";

import {ConfirmService} from "../services/confirm.service";

export type IsDirtyFn = () => boolean | Observable<boolean> | Promise<boolean>;

export interface CanComponentDeactivate {
  isDirty?: IsDirtyFn;
}

export const dataLossGuard: CanDeactivateFn<CanComponentDeactivate> = (component, currentRoute, currentState, nextState) => {
  const confirmSvc = inject(ConfirmService);
  const router = inject(Router);

  const state = router.getCurrentNavigation()?.extras?.state ?? {};

  if (nextState.url === "/profile-wizard" || state["logout"] === true) return true;

  const dirty = component?.isDirty ? component.isDirty() : true;

  if (dirty) {
    return confirmSvc
      .confirm("هل أنت متأكد من المتابعة؟ لن يتم حفظ البيانات التي قمت بتعديلها")
      .afterClosed()
      .pipe(map((result) => !!result));
  } else {
    return true;
  }
};
