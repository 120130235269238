<h2 class="px-3">إدارة الفعالية</h2>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a
    mat-tab-link
    *ngFor="let tab of filteredTabs"
    [routerLink]="resolveRoute(tab.route)"
    routerLinkActive
    #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact: true}"
    [active]="rla.isActive"
  >
    {{ tab.title }}
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <div class="p-5"><router-outlet></router-outlet></div>
</mat-tab-nav-panel>
