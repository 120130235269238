import {Event} from "./event.dto";
import {File} from "./file.dto";
import {Stamps} from "./helpers";
import {MeetingParticipant} from "./meeting.dto";
import {User} from "./user.dto";

export type AttendeeStatus = "enrolled" | "attended";

export interface Attendee extends Stamps {
  mongo_id: string | null;
  event_id: string;
  event?: Event;
  user_id: string | null;
  user?: User | null;
  email: string;
  full_name: string;
  full_name_en: string | null;
  evaluation_form_response_id: string | null;
  meeting_participant_id: string | null;
  participant?: MeetingParticipant | null;
  certificate_file_id: string;
  certificate_file?: File | null;
  badge_file_id: string;
  badge_file?: File | null;
  status: AttendeeStatus;
  status_ar: string;
}

export type PrintJobStatus = "in-progress" | "done";

export interface PrintJob {
  status: PrintJobStatus;
  file?: File | null;
}
