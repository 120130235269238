import {HttpErrorResponse} from "@angular/common/http";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {File} from "src/app/dtos";
import {ConfirmService} from "src/app/services/confirm.service";
import {FileService} from "src/app/services/file.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

export interface FileDialogData {
  id?: string | null;
}

const FileForm = v.object({
  filename_download: v.string(),
});

@Component({
  selector: "app-file-dialog",
  templateUrl: "./file-dialog.component.html",
  styleUrls: ["./file-dialog.component.scss"],
})
export class FileDialogComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  loading!: boolean;
  error?: number;

  info?: File;
  file?: globalThis.File;

  extension!: string;

  form = new FormGroup(
    {
      filename_download: new FormControl("", {nonNullable: true}),
    },
    makeValidators(FileForm),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileDialogData | null,
    private dialogRef: MatDialogRef<FileDialogComponent>,
    private fileSvc: FileService,
    private toastSvc: ToastService,
    private confirmSvc: ConfirmService,
  ) {
    this.dialogRef.updateSize("450px");
  }

  ngOnInit(): void {
    if (this.data?.id) {
      this.getFile(this.data.id);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get filename_download() {
    return this.form.controls.filename_download;
  }

  getFile(file_id: string) {
    this.loading = true;
    this.fileSvc
      .getFile(file_id, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (fileInfo) => {
          this.info = fileInfo;
          this.patchForm(fileInfo.filename_download);
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.error = error.status;
          this.loading = false;
        },
      });
  }

  onFileChange(event: Event) {
    const file = this.fileSvc.getFileFromEvent(event);
    if (!file) return;
    this.file = file;
    this.patchForm(file.name);
  }

  patchForm(name: string) {
    const [fileName, extension] = this.getFileNameAndExtension(name);
    this.form.patchValue({
      filename_download: fileName,
    });
    markAllAsPristine(this.form);
    this.extension = extension;
  }

  getFileNameAndExtension(name: string) {
    const periodIdx = name.lastIndexOf(".");
    const extension = periodIdx > -1 ? name.substring(periodIdx + 1) : "";
    const fileName = periodIdx > -1 ? name.substring(0, periodIdx) : name;
    return [fileName, extension];
  }

  onConfirm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const fileName = [this.filename_download.value, this.extension].filter((part) => !!part).join(".");

    this.loading = true;
    if (this.info) {
      this.fileSvc
        .updateFile(this.info.id, {filename_download: fileName})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (fileInfo) => {
            this.loading = false;
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.dialogRef.close(fileInfo);
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      if (!this.file) {
        this.toastSvc.error("يرجى إختيار الملف");
        return;
      }
      this.fileSvc
        .uploadFromFile(this.file, fileName)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (fileInfo) => {
            this.loading = false;
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.dialogRef.close(fileInfo);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  onDismiss(deleted = false) {
    this.dialogRef.close(deleted);
  }

  deleteFile() {
    const info = this.info;
    if (!info) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          this.loading = true;
          return this.fileSvc.deleteFile(info.id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.onDismiss(true);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  downloadFile() {
    if (!this.info) return;
    this.fileSvc.downloadFile(this.info.id);
  }
}
