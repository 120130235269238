import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {ActivatedRoute} from "@angular/router";
import {DateTime} from "luxon";
import {ReplaySubject, takeUntil} from "rxjs";
import {Event, EventStatus, EventType, EventVisibility, File} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {EventService} from "src/app/services/event.service";
import {FileService} from "src/app/services/file.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

const InfoForm = v
  .object({
    title: v.string(),
    subtitle: v.string({nullable: true}).optional(),
    description: v.string({nullable: true}).optional(),
    type: v.enum(["course", "seminar", "workshop"]),
    start_date: v.dateTime(),
    end_date: v.dateTime(),
    duration_in_minutes: v.number().min(0),
    visibility: v.enum(["public", "private"]),
    thumbnail_image_id: v.string({format: "uuid", nullable: true}).optional(),
    banner_image_id: v.string({format: "uuid", nullable: true}).optional(),
    tags: v.array(v.string()),
    objectives: v.string({nullable: true}).optional(),
    prerequisites: v.string({nullable: true}).optional(),
    audience: v.string({nullable: true}).optional(),
    ended: v.boolean(),
    enrollment_closed: v.boolean(),
    status: v.enum(["draft", "published", "archived"]),
  })
  .refine((form) => form.start_date.toMillis() < form.end_date.toMillis(), {
    path: ["end_date"],
    message: "تاريخ النهاية يجب أن يلي تاريخ البداية",
  });

@Component({
  selector: "app-dashboard-event-info-form",
  templateUrl: "./dashboard-event-info-form.component.html",
  styleUrls: ["./dashboard-event-info-form.component.scss"],
})
export class DashboardEventInfoFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      title: new FormControl("", {nonNullable: true}),
      subtitle: new FormControl<string | null | undefined>(null),
      description: new FormControl<string | null | undefined>(null),
      type: new FormControl<EventType>("seminar", {nonNullable: true}),
      start_date: new FormControl<DateTime>(DateTime.now(), {nonNullable: true}),
      end_date: new FormControl<DateTime>(DateTime.now(), {nonNullable: true}),
      duration_in_minutes: new FormControl<number>(0, {nonNullable: true}),
      visibility: new FormControl<EventVisibility>("public", {nonNullable: true}),
      thumbnail_image_id: new FormControl<string | null | undefined>(null),
      banner_image_id: new FormControl<string | null | undefined>(null),
      tags: new FormControl<string[]>([], {nonNullable: true}),
      objectives: new FormControl<string | null | undefined>(null),
      prerequisites: new FormControl<string | null | undefined>(null),
      audience: new FormControl<string | null | undefined>(null),
      ended: new FormControl<boolean>(false, {nonNullable: true}),
      enrollment_closed: new FormControl<boolean>(false, {nonNullable: true}),
      status: new FormControl<EventStatus>("draft", {nonNullable: true}),
    },
    makeValidators(InfoForm),
  );

  types = [
    {name: "دورة", value: "course"},
    {name: "ورشة", value: "workshop"},
    {name: "ندوة", value: "seminar"},
  ];

  visibilities = [
    {name: "عام", value: "public"},
    {name: "خاص", value: "private"},
  ];

  statuses = [
    {name: "مسودة", value: "draft"},
    {name: "منشور", value: "published"},
    {name: "مؤرشف", value: "archived"},
  ];

  loading!: boolean;

  event_id!: string;

  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private eventSvc: EventService,
    private fileSvc: FileService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getEvent();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get title() {
    return this.form.controls.title;
  }

  get subtitle() {
    return this.form.controls.subtitle;
  }

  get description() {
    return this.form.controls.description;
  }

  get type() {
    return this.form.controls.type;
  }

  get start_date() {
    return this.form.controls.start_date;
  }

  get end_date() {
    return this.form.controls.end_date;
  }

  get duration_in_minutes() {
    return this.form.controls.duration_in_minutes;
  }

  get visibility() {
    return this.form.controls.visibility;
  }

  get thumbnail_image_id() {
    return this.form.controls.thumbnail_image_id;
  }

  get banner_image_id() {
    return this.form.controls.banner_image_id;
  }

  get tags() {
    return this.form.controls.tags;
  }

  get objectives() {
    return this.form.controls.objectives;
  }

  get prerequisites() {
    return this.form.controls.prerequisites;
  }

  get audience() {
    return this.form.controls.audience;
  }

  get ended() {
    return this.form.controls.ended;
  }

  get enrollment_closed() {
    return this.form.controls.enrollment_closed;
  }

  get status() {
    return this.form.controls.status;
  }

  get thumbnailImageSrc() {
    return this.thumbnail_image_id.valid && !!this.thumbnail_image_id.value
      ? this.fileSvc.getFileDownloadLink(this.thumbnail_image_id.value)
      : null;
  }

  get bannerImageSrc() {
    return this.banner_image_id.valid && !!this.banner_image_id.value
      ? this.fileSvc.getFileDownloadLink(this.banner_image_id.value)
      : null;
  }

  addTag(event: MatChipInputEvent) {
    const tag = event.value.trim();
    if (tag.length > 0) {
      this.tags.value.push(tag);
      event.chipInput.clear();
      this.tags.markAsDirty();
    }
  }

  removeTag(tag: string) {
    const index = this.tags.value.indexOf(tag);
    if (index >= 0) {
      this.tags.value.splice(index, 1);
      updateFormStatus(this.form);
    }
  }

  patchForm(event: Event) {
    this.form.patchValue({
      ...event,
      start_date: DateTime.fromJSDate(new Date(event.start_date)),
      end_date: DateTime.fromJSDate(new Date(event.end_date)),
      duration_in_minutes: event.duration_in_seconds / 60,
    });
    markAllAsPristine(this.form);
  }

  getEvent() {
    this.loading = true;
    this.eventSvc
      .getEvent(this.event_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        this.patchForm(event);
        this.loading = false;
      });
  }

  onFileChange(event: globalThis.Event, control: FormControl<string | null | undefined>) {
    this.loading = true;
    this.fileSvc
      .uploadFromEvent(event, undefined, ["image/"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (file) => {
          control.setValue(file.id);
          control.markAsDirty();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  updateEvent() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const {duration_in_minutes, ...form} = this.form.getRawValue();

    const body = {
      ...form,
      start_date: form.start_date.toJSDate(),
      end_date: form.end_date.toJSDate(),
      duration_in_seconds: duration_in_minutes * 60,
    };

    this.loading = true;
    this.eventSvc
      .updateEvent(this.event_id, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (event) => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.patchForm(event);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.form.dirty;
  }
}
