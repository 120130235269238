<mtx-loader [loading]="loading">
  <h1 mat-dialog-title>
    <ng-container *ngIf="!error">{{ data?.id ? "تعديل" : "إضافة" }} لقاء</ng-container>
    <ng-container *ngIf="error">خطأ</ng-container>
  </h1>

  <div mat-dialog-content>
    <form *ngIf="!error" [formGroup]="form">
      <div *ngIf="step === 'type'" class="text-center">
        <h4>يرجى تحديد نوع اللقاء</h4>
        <div class="row justify-content-center mt-3">
          <div class="col-3">
            <button
              class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
              (click)="updateType('meeting')"
            >
              <img src="./assets/images/zoom/meeting.png" alt="Meeting" height="80px" />
              <span>اجتماع</span>
            </button>
          </div>
          <div class="col-3">
            <button
              class="btn btn-lg btn-light d-flex flex-column gap-2 justify-content-center align-items-center border-1 border-opacity-100 border-dark-subtle w-100 h-100 py-4"
              (click)="updateType('webinar')"
            >
              <img src="./assets/images/zoom/webinar.png" alt="webinar" height="80px" />
              <span>ويبنار</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="step === 'details'" class="row d-flex justify-content-center align-items-center">
        <div *ngIf="!data?.id" class="col-12 my-3">
          <button class="btn btn-link" (click)="goToStep('type')">
            <fa-icon [icon]="faArrowRightLong" class="me-3"></fa-icon>
            نوع اللقاء
          </button>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الموضوع</mat-label>
            <input matInput placeholder="الموضوع" formControlName="topic" />
            <mat-error *ngIf="form.controls.topic.invalid">{{ form.controls.topic.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الأجندة</mat-label>
            <textarea matInput formControlName="agenda" placeholder="الأجندة"></textarea>
            <mat-error *ngIf="form.controls.agenda.invalid">{{ form.controls.agenda.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>تاريخ البداية</mat-label>
            <input
              matInput
              [mtxDatetimepicker]="startTimePicker"
              placeholder="تاريخ البداية"
              formControlName="start_time"
              (click)="startTimePicker.open()"
              dir="ltr"
            />
            <mtx-datetimepicker-toggle [for]="startTimePicker" matSuffix></mtx-datetimepicker-toggle>
            <mtx-datetimepicker
              #startTimePicker
              type="datetime"
              mode="portrait"
              twelvehour
              touchUi
              timeInput
              dir="ltr"
            ></mtx-datetimepicker>
            <mat-error *ngIf="form.controls.start_time.invalid">{{ form.controls.start_time.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>مدة اللقاء بالدقائق</mat-label>
            <input matInput placeholder="مدة اللقاء بالدقائق" formControlName="duration" type="number" />
            <mat-error *ngIf="form.controls.duration.invalid">{{ form.controls.duration.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="isWebinar" class="col-12 col-lg-6 mb-3">
          <mat-checkbox [formControl]="settings.controls.hd_video">إتاحة الفيديو عالي الجودة (HD)؟</mat-checkbox>
        </div>

        <div *ngIf="isWebinar" class="col-12 col-lg-6 mb-3">
          <mat-checkbox [formControl]="settings.controls.question_and_answer.controls.enable">
            الأسئلة و الأجوبة؟
          </mat-checkbox>
        </div>

        <div class="col-12 mb-3" [class.col-lg-6]="isWebinar">
          <mat-checkbox [formControl]="settings.controls.meeting_authentication">
            مصادقة الحضور مطلوبة للانضمام؟
          </mat-checkbox>
        </div>

        <div *ngIf="isWebinar" class="col-12 col-lg-6 mb-3">
          <mat-checkbox [formControl]="settings.controls.panelist_authentication">
            مصادقة أعضاء اللجنة مطلوبة للانضمام؟
          </mat-checkbox>
        </div>

        <fieldset *ngIf="isWebinar" class="mt-3">
          <legend class="d-flex align-items-center">
            أعضاء لجنة اللقاء
            <button mat-icon-button (click)="addPanelist()"><mat-icon>add</mat-icon></button>
          </legend>

          <h6 *ngIf="panelists.controls.length === 0" class="text-center">لا يوجد أعضاء</h6>

          <div *ngFor="let panelist of panelists.controls; index as index" class="row border rounded-1 p-3">
            <div class="col-12 col-lg-5">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>اسم العضو {{ index + 1 }}</mat-label>
                <input matInput [placeholder]="'اسم العضو ' + (index + 1)" [formControl]="panelist.controls.name" />
                <mat-error *ngIf="panelist.controls.name.invalid">
                  {{ panelist.controls.name.errors | error }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-10 col-lg-5">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>البريد الإلكتروني</mat-label>
                <input matInput placeholder="البريد الإلكتروني" [formControl]="panelist.controls.email" />
                <mat-error *ngIf="panelist.controls.email.invalid">
                  {{ panelist.controls.email.errors | error }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-2 d-flex justify-content-end">
              <button mat-icon-button (click)="removePanelist(index)"><mat-icon>delete</mat-icon></button>
            </div>
          </div>
        </fieldset>

        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>تفعيل التسجيل التلقائي</mat-label>
            <mat-select [formControl]="settings.controls.auto_recording">
              <mat-option *ngFor="let value of autoRecordingValues" [value]="value.value">
                {{ value.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="settings.controls.auto_recording.invalid">
              {{ settings.controls.auto_recording.errors | error }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>المضيفون البدلاء</mat-label>
            <mat-chip-grid #chipGrid [formControl]="alternative_hosts">
              <mat-chip-row *ngFor="let host of alternative_hosts.value" (removed)="removeHost(host)">
                {{ host }}
                <button matChipRemove type="button">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="ادخل مضيف بديل" [matChipInputFor]="chipGrid" (matChipInputTokenEnd)="addHost($event)" />
            <mat-hint>يرجى الضغط على زر Enter لإضافة المضيف</mat-hint>
            <mat-error *ngIf="alternative_hosts.invalid">
              {{ alternative_hosts.errors | error }}
            </mat-error>
          </mat-form-field>
        </div>

        <fieldset>
          <legend>الترجمة</legend>

          <div class="col-12">
            <mat-checkbox [formControl]="language_interpretation.controls.enable">تفعيل؟</mat-checkbox>
            <mat-error *ngIf="language_interpretation.controls.interpreters.invalid">
              يرجى إضافة مترجم واحد على الأقل
            </mat-error>
          </div>

          <fieldset *ngIf="language_interpretation.controls.enable.value">
            <legend class="d-flex align-items-center gap-2">
              المترجمون
              <button mat-icon-button (click)="addLanguageInterpreter()"><mat-icon>add</mat-icon></button>
            </legend>

            <h6 *ngIf="language_interpretation.controls.interpreters.controls.length === 0" class="text-center">
              لا يوجد مترجمون
            </h6>

            <div
              *ngFor="let interpreter of language_interpretation.controls.interpreters.controls; index as index"
              class="row border rounded-1 p-3"
            >
              <div class="col-12 col-lg-5">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>مترجم {{ index + 1 }}</mat-label>
                  <input matInput [placeholder]="'مترجم ' + (index + 1)" [formControl]="interpreter.controls.email" />
                  <mat-error *ngIf="interpreter.controls.email.invalid">
                    {{ interpreter.controls.email.errors | error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-lg-3">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>من</mat-label>
                  <mat-select [formControl]="interpreter.controls.from">
                    <mat-option *ngFor="let language of languagesForInterpretationChoices" [value]="language.value">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="interpreter.controls.from.invalid">
                    {{ interpreter.controls.from.errors | error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-10 col-lg-3">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>إلى</mat-label>
                  <mat-select [formControl]="interpreter.controls.to">
                    <mat-option *ngFor="let language of languagesForInterpretationChoices" [value]="language.value">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="interpreter.controls.to.invalid">
                    {{ interpreter.controls.to.errors | error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-1 d-flex justify-content-end">
                <button mat-icon-button (click)="removeLanguageInterpreter(index)"><mat-icon>delete</mat-icon></button>
              </div>
            </div>
          </fieldset>
        </fieldset>

        <fieldset class="mt-3">
          <legend>الترجمة للغة الإشارة</legend>

          <div class="col-12">
            <mat-checkbox [formControl]="sign_language_interpretation.controls.enable">تفعيل؟</mat-checkbox>
            <mat-error *ngIf="sign_language_interpretation.controls.interpreters.invalid">
              يرجى إضافة مترجم واحد على الأقل
            </mat-error>
          </div>

          <fieldset *ngIf="sign_language_interpretation.controls.enable.value">
            <legend class="d-flex align-items-center gap-2">
              المترجمون
              <button mat-icon-button (click)="addSignLanguageInterpreter()"><mat-icon>add</mat-icon></button>
            </legend>

            <h6 *ngIf="sign_language_interpretation.controls.interpreters.controls.length === 0" class="text-center">
              لا يوجد مترجمون
            </h6>

            <div
              *ngFor="let interpreter of sign_language_interpretation.controls.interpreters.controls; index as index"
              class="row border rounded-1 p-3"
            >
              <div class="col-12 col-lg-5">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>مترجم {{ index + 1 }}</mat-label>
                  <input matInput [placeholder]="'مترجم ' + (index + 1)" [formControl]="interpreter.controls.email" />
                  <mat-error *ngIf="interpreter.controls.email.invalid">
                    {{ interpreter.controls.email.errors | error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-10 col-lg-5">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>اللغة</mat-label>
                  <mat-select [formControl]="interpreter.controls.sign_language">
                    <mat-option *ngFor="let language of signLanguagesForInterpretationChoices" [value]="language.value">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="interpreter.controls.sign_language.invalid">
                    {{ interpreter.controls.sign_language.errors | error }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-2 d-flex justify-content-end">
                <button mat-icon-button (click)="removeSignLanguageInterpreter(index)"><mat-icon>delete</mat-icon></button>
              </div>
            </div>
          </fieldset>
        </fieldset>
      </div>
    </form>
    <div *ngIf="error" class="text-center text-danger">
      <mat-icon>warning</mat-icon>
      <ng-container [ngSwitch]="error">
        <h4 *ngSwitchCase="'not-found'">تعذر العثور على اللقاء</h4>
        <h4 *ngSwitchCase="'expired'">اللقاء منتهي أو محذوف</h4>
        <h4 *ngSwitchCase="'other'">حدث خطأ يرجى المحاولة في وقت لاحق</h4>
      </ng-container>
    </div>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-between">
    <div>
      <button *ngIf="!error && step === 'details'" mat-flat-button color="primary" (click)="onConfirm()">موافق</button>
      <button mat-flat-button (click)="onDismiss()">إلغاء</button>
    </div>

    <div *ngIf="data?.id && error !== 'other'">
      <button mat-icon-button (click)="deleteMeeting()" color="warn"><mat-icon>delete</mat-icon></button>
    </div>
  </div>
</mtx-loader>
