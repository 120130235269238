<mtx-loader [loading]="loading">
  <form class="p-5" [formGroup]="form" (ngSubmit)="save()">
    <div class="d-flex justify-content-between">
      <h2>{{ ticket ? ticket.type_ar + " من " + ticket.user?.full_name : "طلب" }}</h2>
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div class="mt-5">
      <div *ngIf="ticket">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="fw-bold">صاحب الطلب</td>
              <td>{{ ticket.user?.full_name }}</td>
            </tr>
            <tr>
              <td class="fw-bold">تاريخ الإنشاء</td>
              <td>{{ ticket.date_created | date: "yyyy/MM/dd" }}</td>
            </tr>
            <tr>
              <td class="fw-bold">آخر تحديث</td>
              <td>{{ ticket.date_updated ? (ticket.date_updated | date: "yyyy/MM/dd") : "لم يتم التحديث حتى الآن" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>نوع الطلب</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let t of types" [value]="t.value">
                {{ t.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="type.invalid">{{ type.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الوصف</mat-label>
            <textarea formControlName="description" matInput placeholder="الوصف" rows="5"></textarea>
            <mat-error *ngIf="description.invalid">{{ description.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الملاحظات</mat-label>
            <textarea formControlName="notes" matInput placeholder="الملاحظات" rows="5"></textarea>
            <mat-error *ngIf="notes.invalid">{{ notes.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>حالة الطلب</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let s of statuses" [value]="s.value">
                {{ s.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="status.invalid">{{ status.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
