import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Attendee, AttendeeStatus} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

const AttendeeForm = v.object({
  email: v.string({format: "email"}),
  full_name: v.string(),
  full_name_en: v.string({nullable: true}).optional(),
  status: v.enum(["enrolled", "attended"]),
});

@Component({
  selector: "app-dashboard-attendee-form",
  templateUrl: "./dashboard-attendee-form.component.html",
  styleUrls: ["./dashboard-attendee-form.component.scss"],
})
export class DashboardAttendeeFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      email: new FormControl("", {nonNullable: true}),
      full_name: new FormControl("", {nonNullable: true}),
      full_name_en: new FormControl<string | null | undefined>(null),
      status: new FormControl<AttendeeStatus>("enrolled", {nonNullable: true}),
    },
    makeValidators(AttendeeForm),
  );

  statuses = [
    {name: "مسجل", value: "enrolled"},
    {name: "حضر", value: "attended"},
  ];

  loading!: boolean;

  event_id!: string;
  attendee_id?: string;

  constructor(
    private eventSvc: EventService,
    private toastSvc: ToastService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    const attendee_id = this.route.snapshot.params["attendee_id"];
    if (attendee_id) {
      this.attendee_id = attendee_id;
      this.getAttendee();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get email() {
    return this.form.controls.email;
  }

  get full_name() {
    return this.form.controls.full_name;
  }

  get full_name_en() {
    return this.form.controls.full_name_en;
  }

  get status() {
    return this.form.controls.status;
  }

  getAttendee() {
    if (!this.attendee_id) return;

    this.loading = true;
    this.eventSvc
      .getAttendee(this.event_id, this.attendee_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((attendee) => {
        this.patchForm(attendee);
        this.loading = false;
      });
  }

  patchForm(attendee: Attendee) {
    this.form.patchValue({
      ...attendee,
      full_name: attendee.user?.full_name ?? attendee.full_name,
      full_name_en: attendee.user?.full_name_en ?? attendee.full_name_en ?? null,
      email: attendee.user?.email ?? attendee.email,
    });
    this.form.controls.email.disable();
    markAllAsPristine(this.form);
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    if (this.attendee_id) {
      const {email, ...body} = this.form.getRawValue();
      this.eventSvc
        .updateAttendee(this.event_id, this.attendee_id, body)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (attendee) => {
            this.patchForm(attendee);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createAttendee(this.event_id, this.form.getRawValue())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (attendee) => {
            markAllAsPristine(this.form);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.router.navigate(["/dashboard", "events", this.event_id, "attendees", attendee.id]);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  isDirty() {
    return this.form.dirty;
  }
}
