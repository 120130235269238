import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {DateTime} from "luxon";
import {combineLatest, filter, forkJoin, map, of, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Country, Gender, User} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {AuthService} from "src/app/services/auth.service";
import {ConfirmService} from "src/app/services/confirm.service";
import {CountryService} from "src/app/services/country.service";
import {FileService} from "src/app/services/file.service";
import {ToastService} from "src/app/services/toast.service";
import {html, markAllAsPristine} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const ProfileForm = v.object({
  email: v.string({format: "email"}),
  phone: v.string({format: "phone"}),
  country_id: v.string({format: "uuid"}),

  first_name: v.string().optional(),
  middle_name: v.string().optional(),
  last_name: v.string().optional(),
  first_name_en: v.string().optional(),
  middle_name_en: v.string().optional(),
  last_name_en: v.string().optional(),

  photo_id: v.string({format: "uuid", nullable: true}).optional(),
  gender: v.enum(["male", "female"]).nullish(),
  birth_date: v.dateTime().nullish(),
  job: v.string({nullable: true}).optional(),
  workplace: v.string({nullable: true}).optional(),
  biography: v.string({nullable: true}).optional(),
});

@Component({
  selector: "app-profile-details",
  templateUrl: "./profile-details.component.html",
  styleUrls: ["./profile-details.component.scss"],
})
export class ProfileDetailsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      email: new FormControl({value: "", disabled: true}, {nonNullable: true}),
      phone: new FormControl("", {nonNullable: true}),
      country_id: new FormControl("", {nonNullable: true}),

      first_name: new FormControl({value: "", disabled: true}, {nonNullable: true}),
      middle_name: new FormControl({value: "", disabled: true}, {nonNullable: true}),
      last_name: new FormControl({value: "", disabled: true}, {nonNullable: true}),
      first_name_en: new FormControl("", {nonNullable: true}),
      middle_name_en: new FormControl("", {nonNullable: true}),
      last_name_en: new FormControl("", {nonNullable: true}),

      photo_id: new FormControl<string | null>(null),
      gender: new FormControl<Gender | null>(null),
      birth_date: new FormControl<DateTime | null>(null),
      job: new FormControl<string | null>(null),
      workplace: new FormControl<string | null>(null),
      biography: new FormControl<string | null>(null),
    },
    makeValidators(ProfileForm),
  );

  countries: Country[] = [];

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  loading!: boolean;

  constructor(
    private authSvc: AuthService,
    private countrySvc: CountryService,
    private toastSvc: ToastService,
    private fileSvc: FileService,
    private confirmSvc: ConfirmService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getProfile();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get phone() {
    return this.form.controls.phone;
  }

  get country_id() {
    return this.form.controls.country_id;
  }

  get first_name() {
    return this.form.controls.first_name;
  }

  get middle_name() {
    return this.form.controls.middle_name;
  }

  get last_name() {
    return this.form.controls.last_name;
  }

  get first_name_en() {
    return this.form.controls.first_name_en;
  }

  get middle_name_en() {
    return this.form.controls.middle_name_en;
  }

  get last_name_en() {
    return this.form.controls.last_name_en;
  }

  get email() {
    return this.form.controls.email;
  }

  get photo_id() {
    return this.form.controls.photo_id;
  }

  get photoSrc() {
    return this.photo_id.value ? this.fileSvc.getFileDownloadLink(this.photo_id.value) : null;
  }

  get gender() {
    return this.form.controls.gender;
  }

  get birth_date() {
    return this.form.controls.birth_date;
  }

  get job() {
    return this.form.controls.job;
  }

  get workplace() {
    return this.form.controls.workplace;
  }

  get biography() {
    return this.form.controls.biography;
  }

  patchForm(user: User) {
    this.form.patchValue({
      ...user,
      phone: user.phone ?? "",
      country_id: user.country_id ?? "",
      birth_date: user.birth_date ? DateTime.fromJSDate(new Date(user.birth_date)) : null,
    });
    markAllAsPristine(this.form);
  }

  getProfile() {
    this.loading = true;
    combineLatest([this.countrySvc.getCountries(), this.authSvc.getProfile()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([countries, profile]) => {
        this.countries = countries;
        this.patchForm(profile);
        this.loading = false;
      });
  }

  update() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;

    const {first_name, middle_name, last_name, ...form} = this.form.getRawValue();

    const body = {
      ...form,
      birth_date: form.birth_date ? form.birth_date.toJSDate() : null,
    };
    this.authSvc
      .updateProfile(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (user) => {
          if (this.email.dirty) {
            this.toastSvc.success(
              "تم تنفيذ الأمر بنجاح و سيتم تحديث البريد الإلكتروني بمجرد تأكيده من خلال رسالة سترسل إليه",
            );
          } else {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          }
          this.loading = false;
          this.patchForm(user);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onFileChange(event: Event) {
    this.loading = true;

    this.fileSvc
      .uploadFromEvent(event, undefined, ["image/"])
      .pipe(
        switchMap((file) => {
          return this.authSvc.updateProfile({photo_id: file.id});
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (user) => {
          this.patchForm(user);
          this.authSvc.setUser(user);
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  deletePhoto() {
    if (!this.photo_id.value) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          this.loading = true;
          return this.authSvc.updateProfile({
            photo_id: null,
          });
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (user) => {
          this.patchForm(user);
          this.authSvc.setUser(user);
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.form.dirty;
  }

  openDeletionConfirmDialog() {
    this.confirmSvc
      .confirm({
        level: "danger",
        title: "حذف الحساب الشخصي",
        width: "600px",
        message: html`
          <div>
            بالضغط على زر "متابعة"، سيتم حذف جميع البيانات المتعلقة بحسابكم الشخصي، و يشمل ذلك:
            <ul>
              <li>جميع بيانات الحساب الشخصي و صورة الحساب.</li>
              <li>
                جميع البيانات المتعلقة بحضور الفعاليات مثل: الشهادات، وتقارير الحضور، و التقييمات، و أية أسئلة أو استفسارات،
                و غيرها من البيانات المتعلقة.
              </li>
              <li>الفعاليات المفضلة وأية فعاليات قادمة تم التسجيل عليها مسبقًا.</li>
            </ul>
            بتأكيدكم عملية الحذف فإن ذلك إقرار منكم بذلك و لا يتحمل المكتب مسؤولية أية أضرار قد تحدث نتاج عملية الحذف.
          </div>
        `,
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return of(false);

          return this.authSvc.deleteUser().pipe(map(() => of(true)));
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((result) => {
        if (!result) {
          this.toastSvc.info("تم إلغاء عملية حذف الحساب.");
          return;
        }

        this.authSvc.clear();

        this.toastSvc.success("تم حذف الحساب الشخصي بنجاح. نأسف لرحيلكم ونتشرف بخدمتكم دائمًا.");
        this.router.navigate(["/"]);
      });
  }
}
