import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

import {
  Form,
  FormChoiceQuestionData,
  FormQuestionGrading,
  FormQuestionType,
  FormTextQuestionData,
  FormUploadQuestionData,
  PaginatedResult,
  SortDirection,
} from "../dtos";
import {queryFromJson, SILENCE_ERRORS} from "../utils";

export interface ListFormsQuery {
  filter?: {
    template?: boolean;
    tags?: string[];
  };
  sort?: {
    date_created?: SortDirection;
  };
  paginate?: {
    page: number;
    limit: number;
  };
}

export interface GetTemplatesQuery {
  filter?: {
    tags?: string[];
  };
  sort?: {
    date_created?: SortDirection;
  };
}

export interface CreateFormRequestBody {
  title: string;
  graded: boolean;
  template: boolean;
  tags: string[];
  questions: {
    title: string;
    description?: string | null;
    data: FormChoiceQuestionData | FormTextQuestionData | FormUploadQuestionData;
    grading: FormQuestionGrading | null;
    type: FormQuestionType;
    order: number;
  }[];
}

export interface UpdateFormRequestBody {
  title: string;
  graded: boolean;
  template: boolean;
  tags: string[];
  questions: {
    id?: string | null;
    title: string;
    description?: string | null;
    data: FormChoiceQuestionData | FormTextQuestionData | FormUploadQuestionData;
    grading: FormQuestionGrading | null;
    type: FormQuestionType;
    order: number;
  }[];
}

@Injectable({
  providedIn: "root",
})
export class FormService {
  private readonly V1_ENDPOINTS = {
    LIST_FORMS: (query?: string) => `${environment.server.host}/api/v1/forms` + (query ? `?${query}` : ""),
    CREATE_FORM: () => `${environment.server.host}/api/v1/forms`,
    GET_TEMPLATES: (query?: string) => `${environment.server.host}/api/v1/forms/templates` + (query ? `?${query}` : ""),
    GET_FORM: (form_id: string) => `${environment.server.host}/api/v1/forms/${form_id}`,
    UPDATE_FORM: (form_id: string) => `${environment.server.host}/api/v1/forms/${form_id}`,
    DELETE_FORM: (form_id: string) => `${environment.server.host}/api/v1/forms/${form_id}`,
  };

  constructor(private httpClient: HttpClient) {}

  listForms(query?: ListFormsQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Form>>(this.V1_ENDPOINTS.LIST_FORMS(_query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createForm(body: CreateFormRequestBody, silent = false) {
    return this.httpClient.post<Form>(this.V1_ENDPOINTS.CREATE_FORM(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getTemplates(query?: GetTemplatesQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<Form[]>(this.V1_ENDPOINTS.GET_TEMPLATES(_query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getForm(form_id: string, silent = false) {
    return this.httpClient.get<Form>(this.V1_ENDPOINTS.GET_FORM(form_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateForm(form_id: string, body: UpdateFormRequestBody, silent = false) {
    return this.httpClient.put<Form>(this.V1_ENDPOINTS.UPDATE_FORM(form_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteForm(form_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_FORM(form_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
