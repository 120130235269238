export const environment = {
  server: {
    host: "https://test-api-rest-events.abegs.org",
  },
  client: {
    host: "https://eventstest.abegs.org",
  },
  password: {
    lowercase: 0,
    uppercase: 0,
    digits: 0,
    special: 0,
    length: 8,
  },
  firebase: {
    apiKey: "AIzaSyAfwpV-iS5lMvkBEgLJ4SCvGIY4nELAS14",
    authDomain: "eventstest.abegs.org",
    projectId: "abegs-faalyat",
    appId: "1:27730952164:web:aba90e77baaca9f76151f1",
  },
  analytics: {
    measurementId: "G-7GPXPGEG5B",
  },
};
