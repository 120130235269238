<mtx-loader [loading]="loading">
  <form class="p-5" [formGroup]="form" (ngSubmit)="save()">
    <div class="d-flex justify-content-between">
      <h2>{{ !country_id ? "إضافة" : "تعديل" }} دولة</h2>
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>اسم الدولة</mat-label>
            <input formControlName="name" type="text" matInput placeholder="اسم الدولة" />
            <mat-error *ngIf="name.invalid">{{ name.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الرمز</mat-label>
            <input formControlName="code" type="text" matInput placeholder="الرمز" />
            <mat-error *ngIf="code.invalid">{{ code.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
