import {AfterViewInit, Directive, ElementRef, Input} from "@angular/core";
import Swiper from "swiper";
import {SwiperOptions} from "swiper/types/swiper-options";

@Directive({
  selector: "[swiper]",
  exportAs: "swiper",
})
export class SwiperDirective implements AfterViewInit {
  private swiper?: Swiper;
  @Input() options?: SwiperOptions;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    Object.assign(this.element.nativeElement, this.options ?? {});
    // @ts-ignore
    this.element.nativeElement.initialize();
    //@ts-ignore
    this.swiper = this.element.nativeElement.swiper;
  }

  next() {
    this.swiper?.slideNext();
  }

  previous() {
    this.swiper?.slidePrev();
  }
}
