<ng-template #qform let-i="index">
  <form #questionNgForm="ngForm" [formGroup]="questionForm" class="my-3">
    <div class="input-group">
      <input
        type="text"
        class="form-control focus-shadow-none rounded-0 py-3"
        formControlName="title"
        placeholder="عنوان السؤال"
        [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(questionForm.controls.title, questionNgForm)}"
      />
      <div *ngIf="questionForm.controls.title.invalid" class="invalid-feedback">
        {{ questionForm.controls.title.errors | error }}
      </div>
    </div>
    <div class="mt-3">
      <textarea
        rows="3"
        class="form-control focus-shadow-none rounded-0 py-3"
        placeholder="نص السؤال"
        formControlName="content"
        [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(questionForm.controls.content, questionNgForm)}"
      ></textarea>
      <div *ngIf="questionForm.controls.content.invalid" class="invalid-feedback">
        {{ questionForm.controls.content.errors | error }}
      </div>
    </div>
    <div class="mt-3 d-flex gap-3 justify-content-end">
      <button [disabled]="loading" class="btn btn-primary px-5 py-3 rounded-0" (click)="save(i)">إرسال</button>
      <button [disabled]="loading" (click)="toggleQuestionForm(i)" class="btn btn-outline-secondary px-5 py-3 rounded-0">
        إلغاء
      </button>
    </div>
  </form>
</ng-template>

<ng-template #aform let-questionIndex="questionIndex" let-answerIndex="answerIndex">
  <form #answerNgForm="ngForm" [formGroup]="answerForm" class="my-1">
    <div>
      <textarea
        rows="3"
        class="form-control focus-shadow-none rounded-0 py-3"
        placeholder="نص الإجابة"
        formControlName="content"
        [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(answerForm.controls.content, answerNgForm)}"
      ></textarea>
      <div *ngIf="answerForm.controls.content.invalid" class="invalid-feedback">
        {{ answerForm.controls.content.errors | error }}
      </div>
    </div>
    <div class="mt-3 d-flex gap-3 justify-content-end">
      <button [disabled]="loading" class="btn btn-primary rounded-0" (click)="saveAnswer(questionIndex, answerIndex)">
        إرسال
      </button>
      <button
        [disabled]="loading"
        (click)="toggleAnswerForm(questionIndex, answerIndex)"
        class="btn btn-outline-secondary rounded-0"
      >
        إلغاء
      </button>
    </div>
  </form>
</ng-template>

<div class="p-4">
  <mtx-loader [loading]="loading">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div *ngIf="!showQuestionForm" class="text-end mt-4">
          <button class="btn btn-outline-secondary px-5 py-3 rounded-0" (click)="toggleQuestionForm()">لديك سؤال؟</button>
        </div>

        <div *ngIf="showQuestionForm" class="row gap-3">
          <div class="col-12">
            <ng-container *ngTemplateOutlet="qform; context: {index: undefined}"></ng-container>
          </div>
        </div>

        <form class="row gap-3 mt-3" [formGroup]="filterForm">
          <div class="col-12">
            <div class="input-group">
              <input
                type="text"
                required
                class="form-control focus-shadow-none rounded-0 py-3"
                placeholder="بحث عن سؤال؟"
                formControlName="title"
              />
              <span class="input-group-text p-0 border-0">
                <button [disabled]="loading" (click)="search()" class="btn btn-primary h-100 px-4 rounded-0">
                  <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group">
              <select class="form-select rounded-0 focus-shadow-none text-l-gray py-3" formControlName="sort">
                <option selected [ngValue]="'date_created'">ترتيب حسب الأحدث</option>
                <option [ngValue]="'upvotes'">ترتيب حسب الأعلى تقييمًا</option>
              </select>
            </div>
          </div>
        </form>

        <p *ngIf="questions && questions.length > 0" class="fw-light mt-5">جميع الأسئلة المتاحة ({{ totalRows }})</p>

        <ng-container *ngFor="let question of questions; index as questionIndex">
          <div class="card rounded-0 py-3 bg-dark bg-opacity-10 border-0">
            <div class="card-body d-flex gap-2">
              <img
                image
                [src]="question.user?.photo?.source"
                style="width: 50px; height: 50px"
                [alt]="question.user?.full_name"
                class="rounded-circle mx-3"
              />

              <div class="flex-grow-1">
                <h6 class="fw-bold overflow-hidden">{{ question.title }}</h6>
                <p class="overflow-anywhere">{{ question.content }}</p>

                <p class="mb-0">
                  <span class="me-4 fw-light">{{ question.user?.full_name }}</span>
                  <span class="me-4 fw-light">{{ question.date_created | date: "yyyy/MM/dd" }}</span>
                </p>

                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center gap-2">
                    {{ question.upvotes }}
                    <button
                      class="btn border-0"
                      (click)="doAction(question, 'upvote')"
                      [disabled]="question.action_taken === 'upvote'"
                    >
                      <fa-icon [icon]="faArrowUpLong"></fa-icon>
                    </button>
                    <button
                      class="btn border-0"
                      (click)="doAction(question, 'downvote')"
                      [disabled]="question.action_taken === 'downvote'"
                    >
                      <fa-icon [icon]="faArrowDownLong"></fa-icon>
                    </button>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <button
                      *ngIf="user && user.role === 'admin'"
                      [disabled]="currentToAnswerQuestion === question.id"
                      class="btn"
                      (click)="toggleAnswerForm(questionIndex)"
                    >
                      <fa-icon [icon]="faCommentDots" title="إضافة إجابة" class="text-secondary"></fa-icon>
                    </button>
                    <button
                      *ngIf="user && (user.role === 'admin' || question.user_id === user.id)"
                      [disabled]="currentToUpdateQuestion === question.id"
                      class="btn"
                      (click)="toggleQuestionForm(questionIndex)"
                    >
                      <fa-icon [icon]="faPen" title="تعديل" class="text-secondary"></fa-icon>
                    </button>
                    <button
                      *ngIf="user && (user.role === 'admin' || question.user_id === user.id)"
                      class="btn"
                      (click)="deleteQuestion(questionIndex)"
                    >
                      <fa-icon [icon]="faTrash" title="حذف" class="text-danger"></fa-icon>
                    </button>
                  </div>
                </div>

                <div
                  *ngIf="(!question.answers || !question.answers.length) && currentToAnswerQuestion !== question.id"
                  class="fst-italic"
                >
                  لم يتم الإجابة بعد
                </div>

                <div *ngIf="currentToAnswerQuestion === question.id" class="row">
                  <div class="col-12">
                    <ng-container *ngTemplateOutlet="aform; context: {questionIndex}"></ng-container>
                  </div>
                </div>

                <div *ngIf="question.answers && question.answers.length > 0" class="p-3">
                  <h6 class="fw-bold">الإجابات</h6>
                  <ng-container *ngFor="let answer of question.answers; index as answerIndex">
                    <div class="bg-light fw-bold p-3">
                      <p class="overflow-anywhere">{{ answer.content }}</p>

                      <p class="mb-0">
                        <span *ngIf="user && user.role === 'admin'" class="me-4 fw-light">
                          {{ answer.user?.full_name }}
                        </span>
                        <span class="me-4 fw-light">{{ answer.date_created | date: "yyyy/MM/dd" }}</span>
                      </p>

                      <div *ngIf="user && user.role === 'admin'" class="d-flex justify-content-end gap-2">
                        <button
                          [disabled]="currentToUpdateAnswer === answer.id"
                          class="btn"
                          (click)="toggleAnswerForm(questionIndex, answerIndex)"
                        >
                          <fa-icon [icon]="faPen" title="تعديل" class="text-secondary"></fa-icon>
                        </button>
                        <button class="btn" (click)="deleteAnswer(questionIndex, answerIndex)">
                          <fa-icon [icon]="faTrash" title="حذف" class="text-danger"></fa-icon>
                        </button>
                      </div>
                    </div>
                    <div *ngIf="currentToUpdateAnswer === answer.id" class="row">
                      <div class="col-12">
                        <ng-container *ngTemplateOutlet="aform; context: {questionIndex, answerIndex}"></ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentToUpdateQuestion === question.id" class="row">
            <div class="col-12">
              <ng-container *ngTemplateOutlet="qform; context: {index: questionIndex}"></ng-container>
            </div>
          </div>
        </ng-container>

        <button
          *ngIf="hasNextPage"
          (click)="getNextPage()"
          class="btn btn-outline-secondary rounded-0 text-center mt-3 py-4 w-100"
        >
          المزيد
        </button>

        <div *ngIf="!questions || !questions.length" class="text-center mt-5 bg-primary text-white py-4">لا توجد أسئلة</div>
      </div>
    </div>
  </mtx-loader>
</div>
