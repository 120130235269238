<main class="page-content">
  <div id="login" class="auth">
    <div class="row h-100">
      <div class="d-none d-lg-block col-12 col-lg-6 order-1 order-lg-0" style="min-height: 100vh">
        <img src="./assets/images/auth/auth-1.png" class="cover side-image" alt="" />
      </div>
      <div class="col-12 col-lg-6 order-0 order-lg-1">
        <div class="p-5 d-flex flex-column h-100">
          <div class="d-flex justify-content-between align-items-center">
            <img src="./assets/images/logo/abegs-expanded.svg" height="82px" alt="Logo" />

            <a routerLink="" class="btn btn-link text-secondary">
              <fa-icon [icon]="faArrowLeft"></fa-icon>
            </a>
          </div>
          <div class="flex-grow-1 d-flex flex-column justify-content-center">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
