import {HttpClient, HttpContext} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {map, take} from "rxjs";
import {environment} from "src/environments/environment";

import {Badge, BadgeStatus, PaginatedResult, SortDirection} from "../dtos";
import {queryFromJson, SILENCE_ERRORS} from "../utils";
import {AuthService} from "./auth.service";
import {PLATFORM_SERVICE, PlatformService} from "./platform.service";

export interface ListBadgesQuery {
  filter?: {
    name?: string;
  };
  sort?: {
    date_created?: SortDirection;
  };
  paginate?: {
    page: number;
    limit: number;
  };
}

export interface CreateBadgeRequestBody {
  name: string;
  image_id: string;
  fields: {name: string; properties: Record<string, string | number | boolean>}[];
  status: BadgeStatus;
}

export interface UpdateBadgeRequestBody {
  name: string;
  image_id: string;
  fields: {name: string; properties: Record<string, string | number | boolean>}[];
  status: BadgeStatus;
}

export interface PreviewBadgeQuery {
  image_id?: string;
}

@Injectable({
  providedIn: "root",
})
export class BadgeService {
  private readonly V1_ENDPOINTS = {
    LIST_BADGES: (query?: string) => `${environment.server.host}/api/v1/badges` + (query ? `?${query}` : ""),
    GET_BADGES: () => `${environment.server.host}/api/v1/badges/all`,
    CREATE_BADGE: () => `${environment.server.host}/api/v1/badges`,
    GET_BADGE: (badge_id: string) => `${environment.server.host}/api/v1/badges/${badge_id}`,
    UPDATE_BADGE: (badge_id: string) => `${environment.server.host}/api/v1/badges/${badge_id}`,
    DELETE_BADGE: (badge_id: string) => `${environment.server.host}/api/v1/badges/${badge_id}`,
    PREVIEW_BADGE: (badge_id: string) => `${environment.server.host}/api/v1/badges/${badge_id}/preview`,
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private authSvc: AuthService,
  ) {}

  listBadges(query?: ListBadgesQuery, silent = false) {
    const _query = query ? queryFromJson(query) : undefined;
    return this.httpClient.get<PaginatedResult<Badge>>(this.V1_ENDPOINTS.LIST_BADGES(_query), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getBadges(silent = false) {
    return this.httpClient.get<Badge[]>(this.V1_ENDPOINTS.GET_BADGES(), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createBadge(body: CreateBadgeRequestBody, silent = false) {
    return this.httpClient.post<Badge>(this.V1_ENDPOINTS.CREATE_BADGE(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getBadge(badge_id: string, silent = false) {
    return this.httpClient.get<Badge>(this.V1_ENDPOINTS.GET_BADGE(badge_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateBadge(badge_id: string, body: UpdateBadgeRequestBody, silent = false) {
    return this.httpClient.put<Badge>(this.V1_ENDPOINTS.UPDATE_BADGE(badge_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteBadge(badge_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_BADGE(badge_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  previewBadge(badge_id: string, query?: PreviewBadgeQuery) {
    return this.authSvc.getToken().pipe(
      take(1),
      map((token) => {
        const url = new URL(this.V1_ENDPOINTS.PREVIEW_BADGE(badge_id));
        if (query?.image_id) {
          url.searchParams.set("image_id", query.image_id.toString());
        }
        if (token) {
          url.searchParams.set("at", token);
        }
        return url.toString();
      }),
    );
  }
}
