<mtx-loader [loading]="loading">
  <h1 mat-dialog-title>
    <ng-container *ngIf="!error">{{ info ? "تعديل" : "إضافة" }} ملف</ng-container>
    <ng-container *ngIf="error">خطأ</ng-container>
  </h1>

  <div mat-dialog-content>
    <form *ngIf="!error" [formGroup]="form" novalidate>
      <div class="alert alert-warning text-center" *ngIf="!info">
        يجب ألا يتجاوز حجم الملف 100 ميجابايت و أن لا يكون ملف قابل للتشغيل
      </div>

      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>اسم الملف</mat-label>
            <input matInput placeholder="اسم الملف" formControlName="filename_download" />
            <span matSuffix>{{ extension }}</span>
            <mat-error *ngIf="filename_download.invalid">{{ filename_download.errors | error }}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!info" class="col-12">
          <button mat-flat-button (click)="fileInput.click()" color="primary" class="w-100">اختر</button>
          <input #fileInput type="file" class="visually-hidden" (change)="onFileChange($event)" />
        </div>
      </div>
    </form>
    <div *ngIf="error" class="text-center text-danger">
      <mat-icon>warning</mat-icon>
      <h4>{{ error === 404 ? "تعذر العثور على الملف" : "حدث خطأ يرجى المحاولة في وقت لاحق" }}</h4>
    </div>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-between">
    <div>
      <button *ngIf="!error" [disabled]="!info && !file" mat-flat-button color="primary" (click)="onConfirm()">موافق</button>
      <button mat-flat-button (click)="onDismiss()">إلغاء</button>
    </div>

    <div *ngIf="info">
      <button mat-icon-button (click)="downloadFile()"><mat-icon>download</mat-icon></button>
      <button mat-icon-button (click)="deleteFile()" color="warn"><mat-icon>delete</mat-icon></button>
    </div>
  </div>
</mtx-loader>
