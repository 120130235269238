import {Stamps} from "./helpers";

export type MeetingProvider = "zoom";

export type MeetingType = "meeting" | "webinar";

export type EnableSetting<T> = ({enable: true} & T) | {enable: false};

export const LanguagesForInterpretation = [
  "US", // English
  "CN", // Chinese
  "JP", // Japanese
  "DE", // German
  "FR", // France
  "RU", // Russian
  "PT", // Portuguese
  "ES", // Spanish
  "KR", // Korean
  "D-AR", // Arabic
] as const;

export type LanguageForInterpretation = (typeof LanguagesForInterpretation)[number];

export const LanguagesForInterpretationChoices: {name: string; value: LanguageForInterpretation}[] = [
  {name: "الإنجليزية", value: "US"},
  {name: "الصينية", value: "CN"},
  {name: "اليابانية", value: "JP"},
  {name: "الألمانية", value: "DE"},
  {name: "الفرنسية", value: "FR"},
  {name: "الروسية", value: "RU"},
  {name: "البرتغالية", value: "PT"},
  {name: "الإسبانية", value: "ES"},
  {name: "الكورية", value: "KR"},
  {name: "العربية", value: "D-AR"},
];

export const SignLanguagesForInterpretation = [
  "American",
  "Chinese",
  "French",
  "German",
  "Japanese",
  "Russian",
  "Brazilian",
  "Spanish",
  "Mexican",
  "British",

  "Arabic",
] as const;

export type SignLanguageForInterpretation = (typeof SignLanguagesForInterpretation)[number];

export const SignLanguagesForInterpretationChoices: {name: string; value: SignLanguageForInterpretation}[] = [
  {name: "الأمريكية", value: "American"},
  {name: "الصينية", value: "Chinese"},
  {name: "الفرنسية", value: "French"},
  {name: "الألمانية", value: "German"},
  {name: "اليابانية", value: "Japanese"},
  {name: "الروسية", value: "Russian"},
  {name: "البرازيلية", value: "Brazilian"},
  {name: "الإسبانية", value: "Spanish"},
  {name: "المكسيكية", value: "Mexican"},
  {name: "البريطانية", value: "British"},
  {name: "العربية", value: "Arabic"},
];

export type LanguageInterpretation = EnableSetting<{
  interpreters?: {
    email: string;
    languages: `${LanguageForInterpretation},${LanguageForInterpretation}`;
  }[];
}>;

export type SignLanguageInterpretation = EnableSetting<{
  interpreters?: {email: string; sign_language: SignLanguageForInterpretation}[];
}>;

export interface ZoomMeeting {
  agenda?: string;
  duration?: number;
  join_url?: string;
  settings?: {
    allow_multiple_devices?: boolean;
    alternative_hosts?: string;
    approval_type?: "0" | "1" | "2";
    auto_recording?: "local" | "cloud" | "none";
    host_video?: boolean;
    language_interpretation?: LanguageInterpretation;
    sign_language_interpretation?: SignLanguageInterpretation;
    meeting_authentication?: boolean;
    mute_upon_entry?: boolean;
    participant_video?: boolean;
    show_share_button?: boolean;
    waiting_room?: boolean;
    registrants_confirmation_email?: boolean;
    registrants_email_notification?: boolean;
  };
  start_time?: string;
  topic?: string;
  type?: "1" | "2" | "3" | "8";
}

export interface ZoomWebinar {
  // id?: number;
  agenda?: string;
  duration?: number;
  join_url?: string;
  settings?: {
    allow_multiple_devices?: boolean;
    alternative_hosts?: string;
    approval_type?: "0" | "1" | "2";
    auto_recording?: "local" | "cloud" | "none";
    hd_video?: boolean;
    hd_video_for_attendees?: boolean;
    host_video?: boolean;
    language_interpretation?: LanguageInterpretation;
    sign_language_interpretation?: SignLanguageInterpretation;
    panelist_authentication?: boolean;
    meeting_authentication?: boolean;
    panelists_invitation_email_notification?: boolean;
    panelists_video?: boolean;
    practice_session?: boolean;
    question_and_answer?: {
      enable?: boolean;
    };
    show_share_button?: boolean;
    registrants_confirmation_email?: boolean;
    registrants_email_notification?: boolean;

    panelists?: {name: string; email: string}[];
  };
  start_time?: string;
  topic?: string;
  type?: "5" | "6" | "9";
}

export type Meeting = Stamps & {
  provider: MeetingProvider;
  meeting_id: string;
} & ({meeting_type: "meeting"; meeting: ZoomMeeting} | {meeting_type: "webinar"; webinar: ZoomWebinar});

export interface MeetingParticipant extends Stamps {
  meeting_id: number;
  email: string;
  join_link: string;
  registrant_id: string;
  duration_in_seconds: number;
}
