import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {faBook, faClock, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {catchError, combineLatest, of, ReplaySubject, switchMap, takeUntil, throwError} from "rxjs";
import {Attendee, Event} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {HttpStatusCode, roundToNearestQuarter} from "src/app/utils";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  faClock = faClock;
  faBook = faBook;
  faPenToSquare = faPenToSquare;

  event_id!: string;
  event?: Event;
  attendee?: Attendee | null;

  loading!: boolean;

  constructor(
    private eventSvc: EventService,
    private authSvc: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get duration_in_hours() {
    if (!this.event?.duration_in_seconds) return 0;

    const duration_in_hours = this.event.duration_in_seconds / 60 / 60;

    return roundToNearestQuarter(duration_in_hours);
  }

  getData() {
    this.loading = true;

    combineLatest([
      this.eventSvc.getEvent(this.event_id),
      this.authSvc.getToken().pipe(
        switchMap((token) =>
          token
            ? this.eventSvc.getAttendee(this.event_id, "me", true).pipe(
                catchError((error: HttpErrorResponse) => {
                  if (error.status === HttpStatusCode.NOT_FOUND) return of(null);
                  return throwError(() => error);
                }),
              )
            : of(null),
        ),
      ),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([event, attendee]) => {
          this.event = event;
          this.attendee = attendee;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.router.navigate(["events"]);
        },
      });
  }

  enroll() {
    this.loading = true;
    this.eventSvc
      .enroll(this.event_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toastSvc.success(`تم التسجيل على ال${this.event?.type_ar} بنجاح`);
          this.router.navigate(["/events", this.event_id, "details"]);
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
