import {DOCUMENT} from "@angular/common";
import {Inject, Injectable, InjectionToken, NgZone} from "@angular/core";

export const PLATFORM_SERVICE = new InjectionToken<PlatformService>("PlatformService");

export interface PlatformService {
  getDocument(): Document;
  getWindow(): Window;
  get isPlatformBrowser(): boolean;
  get isPlatformServer(): boolean;
  run(fn: (...args: unknown[]) => void): void;
  runInsideZone(fn: (...args: unknown[]) => void): void;
  runOutsideZone(fn: (...args: unknown[]) => void): void;
}

@Injectable({
  providedIn: "root",
})
export class PlatformBrowserService implements PlatformService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly ngZone: NgZone,
  ) {}

  getDocument(): Document {
    return this.document;
  }

  getWindow(): Window {
    return this.document.defaultView as Window;
  }

  get isPlatformBrowser() {
    return true;
  }

  get isPlatformServer() {
    return false;
  }

  run(fn: (...args: unknown[]) => void) {
    return fn();
  }

  runInsideZone(fn: (...args: unknown[]) => void) {
    this.ngZone.run(fn);
  }

  runOutsideZone(fn: (...args: unknown[]) => void) {
    this.ngZone.runOutsideAngular(fn);
  }
}

@Injectable({
  providedIn: "root",
})
export class PlatformServerService implements PlatformService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly ngZone: NgZone,
  ) {}

  getDocument(): Document {
    return this.document;
  }

  // * adapted from https://github.com/nolimits4web/ssr-window/blob/master/src/window.ts
  getWindow(): Window {
    return {
      document: this.getDocument(),
      navigator: {
        userAgent: "",
      },
      location: {
        hash: "",
        host: "",
        hostname: "",
        href: "",
        origin: "",
        pathname: "",
        protocol: "",
        search: "",
      },
      history: {
        replaceState() {
          return;
        },
        pushState() {
          return;
        },
        go() {
          return;
        },
        back() {
          return;
        },
      },
      CustomEvent: function CustomEvent() {
        return this;
      },
      addEventListener() {
        return;
      },
      removeEventListener() {
        return;
      },
      getComputedStyle() {
        return {
          getPropertyValue() {
            return "";
          },
        };
      },
      Image() {
        return;
      },
      Date() {
        return;
      },
      screen: {},
      setTimeout() {
        return;
      },
      clearTimeout() {
        return;
      },
      matchMedia() {
        return {};
      },
      requestAnimationFrame(callback: FrameRequestCallback) {
        if (typeof setTimeout === "undefined") {
          callback(0);
          return null;
        }
        return setTimeout(() => callback(0), 0);
      },
      cancelAnimationFrame(id: number) {
        if (typeof setTimeout === "undefined") {
          return;
        }
        clearTimeout(id);
      },
    } as unknown as Window;
  }

  get isPlatformBrowser() {
    return false;
  }

  get isPlatformServer() {
    return true;
  }

  run() {
    return;
  }

  runInsideZone() {
    return;
  }

  runOutsideZone() {
    return;
  }
}
