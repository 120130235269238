import {HttpContextToken, HttpErrorResponse} from "@angular/common/http";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {environment} from "src/environments/environment";

export function reduceRoute(route: ActivatedRoute) {
  let _route = route;
  while (_route.firstChild) {
    _route = _route.firstChild;
  }
  return _route;
}

export const SILENCE_ERRORS = new HttpContextToken(() => false);
export const INTERCEPT = new HttpContextToken(() => true);

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  UNSUPPORTED_MEDIA_TYPE = 415,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL = 500,
  BAD_GATEWAY = 502,
}

export const errorMessages = {
  bad_request: "طلب غير صالح",
  unauthorized: "يرجي تسجيل الدخول",
  payment_required: "الدفع مطلوب",
  forbidden: "صلاحيات غير كافية",
  not_found: "غير موجود",
  conflict: "تناقض",
  unsupported_media_type: "ملف غير صالح",
  unprocessable_entity: "لا يمكن معالجة البيانات",
  internal_server_error: "حدث خطأ يرجي إبلاغ إدارة التقنية في حالة تكرار المشكلة",
  bad_gateway: "حدث خطأ يرجي المحاولة لاحقًا",
  validation_error: "يرجي مراجعة صحة البيانات المدخلة",
  limit_part_count: "حدث خطأ أثناء رفع الملفات",
  limit_file_size: "يجب ألا يزيد حجم الملف عن 100 ميجابايت ",
  limit_file_count: "عدد الملفات تجاوز الحد المسموح",
  limit_field_key: "حدث خطأ أثناء رفع الملفات",
  limit_field_value: "حدث خطأ أثناء رفع الملفات",
  limit_field_count: "حدث خطأ أثناء رفع الملفات",
  limit_unexpected_file: "حدث خطأ أثناء رفع الملفات",
  zoom_api: "حدث خطأ أثناء التواصل مع الزووم",
  file_not_found: "ملف غير موجود",
  badge_not_found: "بادج غير موجود",
  certificate_not_found: "شهادة غير موجودة",
  country_not_found: "دولة غير موجودة",
  event_not_found: "فعالية غير موجودة",
  chapter_not_found: "محتوى غير موجود",
  section_not_found: "محتوى غير موجود",
  attendee_not_found: "حضور غير موجود",
  meeting_not_found: "لقاء غير موجود",
  user_not_found: "مستخدم غير موجود",
  form_not_found: "نموذج غير موجود",
  form_question_not_found: "سؤال غير موجود",
  participant_not_found: "حضور غير موجود",
  form_response_not_found: "لا توجد تعبئة للنموذج",
  question_not_found: "سؤال غير موجود",
  question_answer_not_found: "إجابة غير موجودة",
  note_not_found: "ملاحظة غير موجودة",
  review_not_found: "تقييم غير موجود",
  announcement_not_found: "إعلان غير موجود",
  ticket_not_found: "طلب دعم فني غير موجود",
  invalid_email_or_password: "البريد الإلكتروني أو كلمة المرور خاطئة",
  user_suspended: "الحساب معلق",
  email_not_verified:
    "لم يتم تفعيل البريد الإلكتروني الخاص بهذا الحساب. سيتم إرسال بريد التفعيل مرة أخري لذا يرجى التحقق من البريد الإلكتروني",
  invalid_token: "رمز غير صالح",
  missing_token: "رمز مفقود",
  token_expired: "رمز منتهي",
  event_has_no_certificate: "الفعالية بدون شهادة",
  event_has_no_badge: "الفعالية بدون بادج",
  cannot_determine_certificate_elibility: "لا يمكن احتساب أحقية الحصول على الشهادة",
  event_has_no_evaluation_form: "الفعالية بدون نموذج تقييم",
  attendee_not_eligible_for_certificate: "الحضور لا يستحق الشهادة",
  attendee_already_evaluated: "تم التقييم بالفعل",
  email_already_taken: "البريد الإلكتروني مسجل بالفعل",
  cannot_update_form_with_responses: "لا يمكن تحديث أو استبدال نموذج تمت تعبته",
  cannot_delete_form_with_responses: "لا يمكن حذف نموذج تمت تعبئته",
  cannot_update_meeting_with_participants: "لا يمكن تحديث أو استبدال لقاء مسجل عليه حضور",
  cannot_delete_meeting_with_participants: "لا يمكن حذف لقاء مسجل عليه حضور",
  employee_email_update_forbidden: "تغيير البريد الإلكتروني غير مسموح لموظفي المكتب",
  employee_password_update_forbidden: "تحديث كلمة المرور غير مسموح لموظفي المكتب",
  employee_password_reset_forbidden: "تغيير كلمة المرور غير مسموح لموظفي المكتب",
  employee_local_login_forbidden: "تسجيل الدخول غير مسموح لموظفي المكتب",
  employee_local_register_forbidden: "التسجيل مغلق لموظفي المكتب",
  insufficient_permissions: "صلاحيات غير كافية",
  end_date_must_follow_start_date: "تاريخ النهاية يجب أن يلي تاريخ البداية",
  cannot_change_certificate_when_meeting_has_participants: "لا يمكن تغيير الشهادة على فعالية لقاؤها مسجل عليه حضور",
  cannot_delete_attached_certificate: "لا يمكن حذف الشهادة لأنها مربوطة بفعاليات لقاءاتهن مسجل عليهن حضور",
  already_enrolled: "مسجل بالفعل",
  max_allowed_attendees_reached: "تم الوصول إلى أقصى عدد من المسجلين",
  questions_are_disabled: "الأسئلة غير مفعلة",
  notes_are_disabled: "الملاحظات غير مفعلة",
  reviews_are_disabled: "التقييمات غير مفعلة",
  announcements_are_disabled: "الإعلانات غير مفعلة",
  user_already_reviewed: "المستخدم قيم بالفعل",
  profile_already_updated: "لا يمكن تعديل بيانات الحساب أكثر من مرة واحدة",
  profile_already_created: "تم استكمال بيانات الحساب مسبقًا",
  user_must_change_password: "لابد من تغيير كلمة المرور للمتابعة و ستصلكم رسالة على البريد الإلكتروني لتغيير كلمة المرور",
  event_has_ended: "الفعالية منتهية",
  enrollment_has_been_closed: "تم إغلاق التسجيل لهذه الفعالية",
  missing_file: "يرجى إختيار ملف",
  no_sheets_in_file: "لا توجد بيانات في الملف المرفوع",
  event_was_not_attended: "لم يتم استكمال شروط حضور الفعالية",
  event_has_no_meeting: "يرجي إضافة إعدادات اللقاء",
  event_is_not_published: "لا يمكن التسجيل على فعالية غير منشورة",
  event_is_not_public: "لا يمكن التسجيل على فعالية غير معلنة",
  printer_service_api: "حدث خطأ يرجي إبلاغ إدارة التقنية في حالة تكرار المشكلة",
  certificate_not_printed: "لم يتم طباعة الشهادة",
  zoom_webinar_over: "تم إنهاء الويبنار و لا يمكنكم التسجيل",
  zoom_webinar_add_registrant_rate_limit: "لا يمكنكم التسجيل على هذه الفعالية. يرجى إعادة المحاولة في وقت لاحق.",
  invalid_login_attempt: "محاولة تسجيل دخول غير سليمة، يرجى مراجعة البيانات و إعادة المحاولة",
  user_deleted: "لا يمكن تسجيل الدخول بحساب محذوف",
};

export type ErrorCode = keyof typeof errorMessages;

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export function queryFromJson(query: any, parents: string[] = []) {
  const result: string[] = [];
  for (const key in query) {
    const value = query[key];
    const path = parents
      .concat(key)
      .map((parent) => `[${parent}]`)
      .join("");
    if (Array.isArray(value) && value.length > 0) {
      const stringified = value
        .filter((v) => !!v.trim())
        .map((v) => `${path}[]=${encodeURIComponent(v)}`)
        .join("&");
      if (stringified) {
        result.push(stringified);
      }
    } else if (typeof value === "object") {
      result.push(queryFromJson(value, parents.concat(key)));
    } else if ((typeof value === "string" && value !== "") || typeof value === "number" || typeof value === "boolean") {
      result.push(`${path}=${encodeURIComponent(value)}`);
    }
  }
  return result.filter((part) => !!part).join("&");
}

export function updateFormStatus(form: AbstractControl) {
  if (form instanceof FormControl) {
    return;
  }

  if (form instanceof FormArray) {
    form.controls.forEach((control) => updateFormStatus(control));

    const pristine = form.controls.every((control) => control.pristine);

    if (pristine) {
      form.markAsPristine();
    } else {
      form.markAsDirty();
    }

    return;
  }

  if (form instanceof FormGroup) {
    let pristine = true;
    for (const key in form.controls) {
      const control = form.controls[key];
      updateFormStatus(control);
      pristine = pristine && control.pristine;
    }

    if (pristine) {
      form.markAsPristine();
    } else {
      form.markAsDirty();
    }

    return;
  }

  throw new Error("Unsupported control");
}

function capitalize<T extends string>(arg: T): Capitalize<T> {
  return (arg.charAt(0).toUpperCase() + arg.slice(1)) as Capitalize<T>;
}

function markAllAs(form: AbstractControl, status: "touched" | "untouched" | "pristine" | "dirty") {
  const markerKey: `markAs${Capitalize<typeof status>}` = `markAs${capitalize(status)}`;

  if (form instanceof FormControl) {
    form[markerKey]();
    return;
  }

  if (form instanceof FormArray) {
    form.controls.forEach((control) => markAllAs(control, status));
    form[markerKey]();
    return;
  }

  if (form instanceof FormGroup) {
    for (const key in form.controls) {
      const control = form.controls[key];
      markAllAs(control, status);
    }
    form[markerKey]();
    return;
  }

  throw new Error("Unsupported control");
}

export function markAllAsDirty(form: AbstractControl) {
  markAllAs(form, "dirty");
}

export function markAllAsPristine(form: AbstractControl) {
  markAllAs(form, "pristine");
}

export function isDefined<T>(val: T): val is Exclude<T, undefined> {
  return val !== undefined;
}

export function isStrictlyDefined<T>(val: T): val is Exclude<T, undefined | null> {
  return val !== undefined && val !== null;
}

export function isUndefined(val: unknown): val is undefined {
  return val === undefined;
}

export function isNullish(val: unknown): val is undefined | null {
  return val === undefined || val === null;
}

export function html(literals: TemplateStringsArray, ...expressions: unknown[]) {
  let string = "";

  for (const [index, literal] of literals.entries()) {
    string += literal;

    if (index in expressions) string += expressions[index];
  }

  return string;
}

export function convertArabicNumerals(value: string) {
  const numerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  let result: string = value;
  numerals.forEach((numeral, index) => {
    result = result.replaceAll(numeral, index.toString());
  });

  return result;
}

export function mapError(error: HttpErrorResponse) {
  return errorMessages[error.error.errorCode as ErrorCode] || errorMessages["internal_server_error"];
}

export function roundToNearestQuarter(value: number) {
  return Math.round((value * 4) / 4).toFixed(2);
}

export function extractText(document: Document, html: string) {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.innerText;
}

export function normalizeUrl(url: string) {
  let normalizedUrl = url;
  while (normalizedUrl.startsWith("/")) {
    normalizedUrl = normalizedUrl.slice(1);
  }
  return normalizedUrl.startsWith(environment.client.host) ? normalizedUrl : `${environment.client.host}/${normalizedUrl}`;
}
