<div class="p-5">
  <div class="d-flex justify-content-between">
    <h2>الفعاليات</h2>
    <a mat-flat-button color="primary" [routerLink]="['/dashboard/events/new']">إضافة</a>
  </div>

  <form class="mt-3" autocomplete="off" [formGroup]="form">
    <div class="row d-flex align-items-center my-3">
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-label>عنوان الفعالية</mat-label>
          <input matInput placeholder="عنوان الفعالية" formControlName="title" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-3 d-flex gap-2 p-3">
        <button mat-flat-button color="accent" (click)="reset()">إزالة</button>
        <button mat-flat-button color="accent" (click)="search()">بحث</button>
      </div>
    </div>
  </form>

  <div>
    <mtx-loader [loading]="loading">
      <mat-table matTableResponsive [dataSource]="dataSource">
        <!-- id Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; index as i">
            {{ i + 1 + pageIndex * 20 }}
          </mat-cell>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>اسم الدورة</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.title }}</mat-cell>
        </ng-container>

        <!-- start_date Column -->
        <ng-container matColumnDef="start_date">
          <mat-header-cell *matHeaderCellDef>تاريخ البداية</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ displayDate(row.start_date) }}
          </mat-cell>
        </ng-container>

        <!-- end_date Column -->
        <ng-container matColumnDef="end_date">
          <mat-header-cell *matHeaderCellDef>تاريخ النهاية</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ displayDate(row.end_date) }}
          </mat-cell>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>الحالة</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.status_ar }}
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-spinner *ngIf="processing[row.id]" [diameter]="25"></mat-spinner>
            <button
              *ngIf="!processing[row.id] && row.status === 'published'"
              mat-icon-button
              (click)="toggleEndedFlag(row)"
              [matTooltip]="row.ended ? 'بدأ الفعالية' : 'إنهاء الفعالية'"
              matTooltipPosition="above"
            >
              <mat-icon>
                {{ row.ended ? "refresh" : "done" }}
              </mat-icon>
            </button>
            <button
              *ngIf="row.status !== 'published' && !processing[row.id]"
              mat-icon-button
              matTooltip="نشر الفعالية"
              matTooltipPosition="above"
              (click)="publish(row)"
            >
              <mat-icon>publish</mat-icon>
            </button>
            <a
              *ngIf="!processing[row.id]"
              mat-icon-button
              matTooltip="تعديل الفعالية"
              matTooltipPosition="above"
              [routerLink]="['/dashboard', 'events', row.id]"
            >
              <mat-icon>edit</mat-icon>
            </a>
            <button
              *ngIf="!processing[row.id]"
              mat-icon-button
              matTooltip="الحضور"
              matTooltipPosition="above"
              [routerLink]="['/dashboard', 'events', row.id, 'attendees']"
            >
              <mat-icon>visibility</mat-icon>
            </button>
            <!-- <button
              *ngIf="!processing[row.id] && row.meeting_id"
              mat-icon-button
              matTooltip="احتساب الحضور"
              matTooltipPosition="above"
              (click)="calculateAttendance(row)"
            >
              <mat-icon>pending_actions</mat-icon>
            </button> -->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div *ngIf="this.dataSource.data.length === 0" class="text-center py-5">لا توجد فعاليات</div>

      <mat-paginator
        #paginator
        [length]="totalRows"
        [pageIndex]="pageIndex"
        [pageSize]="20"
        (page)="pageChanged($event)"
      ></mat-paginator>
    </mtx-loader>
  </div>
</div>
