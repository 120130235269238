import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {AuthService} from "src/app/services/auth.service";
import {ErrorCode} from "src/app/utils";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthService,
  ) {}

  get token(): string | undefined {
    return this.route.snapshot.queryParams["token"];
  }

  ngOnInit(): void {
    if (!this.token) {
      this.router.navigate([""]);
      return;
    }

    this.authSvc
      .verifyEmail({token: this.token}, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.router.navigate(["login"], {queryParams: {status: "success", message: "email_verified"}});
        },
        error: (error: HttpErrorResponse) => {
          const errorCode = error.error.errorCode as ErrorCode;
          this.router.navigate(["login"], {queryParams: {status: "error", message: errorCode}});
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
