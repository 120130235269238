import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {faClock, faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {combineLatest, ReplaySubject, switchMap, takeUntil, tap} from "rxjs";
import {Attendee, Chapter, Event} from "src/app/dtos";
import {AttendeeService} from "src/app/services/attendee.service";
import {EventService} from "src/app/services/event.service";
import {PLATFORM_SERVICE, PlatformService} from "src/app/services/platform.service";
import {ToastService} from "src/app/services/toast.service";
import {roundToNearestQuarter} from "src/app/utils";

@Component({
  selector: "app-event-overview",
  templateUrl: "./event-overview.component.html",
  styleUrls: ["./event-overview.component.scss"],
})
export class EventOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faClock = faClock;
  faBook = faBook;
  faPenToSquare = faPenToSquare;

  loading!: boolean;
  event?: Event;
  attendee?: Attendee;
  event_id!: string;
  chapters?: Chapter[];

  constructor(
    private eventSvc: EventService,
    private attendeeSvc: AttendeeService,
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get duration_in_hours() {
    if (!this.event?.duration_in_seconds) return 0;

    const duration_in_hours = this.event.duration_in_seconds / 60 / 60;

    return roundToNearestQuarter(duration_in_hours);
  }

  get sections() {
    if (!this.chapters) return 0;

    return this.chapters.length;
  }

  get certificate() {
    if (!this.event || !this.attendee || !this.event.certificate_id) return false;

    if (this.event.evaluation_form_id && !this.attendee.evaluation_form_response_id) return false;

    return this.attendee.status === "attended";
  }

  get evaluation() {
    if (!this.event || !this.attendee || !this.event.ended) return false;

    if (!this.event.evaluation_form_id) return false;

    return this.attendee.status === "attended";
  }

  getData() {
    this.loading = true;

    combineLatest([this.eventSvc.getEvent$(), this.eventSvc.getAttendee$()])
      .pipe(
        tap(([event, attendee]) => {
          this.event = event;
          this.attendee = attendee;
          this.event_id = event.id;
        }),
        switchMap(([event]) => {
          return this.eventSvc.getChapters(event.id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((chapters) => {
        this.chapters = chapters;
        this.loading = false;
      });
  }

  downloadBadge() {
    if (!this.attendee) return;

    if (this.attendee.badge_file) {
      this.platformSvc.getWindow().open(this.attendee.badge_file.source, "_blank");
      return;
    }

    this.loading = true;
    this.attendeeSvc
      .getAttendeeBadge(this.attendee.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          if (result.file) {
            this.platformSvc.getWindow().open(result.file.source, "_blank");
          } else {
            this.toastSvc.success("جاري طباعة البادج. يرجى مراجعة الصفحة في وقت لاحق.");
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  downloadCertificate() {
    if (!this.attendee) return;

    if (this.attendee.certificate_file) {
      this.platformSvc.getWindow().open(this.attendee.certificate_file.source, "_blank");
      return;
    }

    this.loading = true;
    this.attendeeSvc
      .getAttendeeCertificate(this.attendee.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          if (result.file) {
            this.platformSvc.getWindow().open(result.file.source, "_blank");
          } else {
            this.toastSvc.success(
              "جاري طباعة الشهادة و سيصلكم إشعار عن طريق البريد الإلكتروني عند الانتهاء. كما يمكنكم الوصول إلى الشهادة عن طريق مراجعة الصفحة في وقت لاحق",
            );
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
