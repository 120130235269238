<app-navbar></app-navbar>

<mat-drawer-container autosize>
  <mat-drawer mode="side" disableClose opened>
    <app-dashboard-sidebar></app-dashboard-sidebar>
  </mat-drawer>

  <main class="w-100 bg-white">
    <router-outlet></router-outlet>
  </main>
</mat-drawer-container>

<app-footer></app-footer>
