import {HttpErrorResponse} from "@angular/common/http";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import {DateTime} from "luxon";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {
  LanguageForInterpretation,
  LanguageInterpretation,
  LanguagesForInterpretation,
  LanguagesForInterpretationChoices,
  Meeting,
  MeetingType,
  SignLanguageForInterpretation,
  SignLanguageInterpretation,
  SignLanguagesForInterpretation,
  SignLanguagesForInterpretationChoices,
  ZoomMeeting,
  ZoomWebinar,
} from "src/app/dtos";
import {ConfirmService} from "src/app/services/confirm.service";
import {CreateMeetingRequestBody, MeetingService} from "src/app/services/meeting.service";
import {ToastService} from "src/app/services/toast.service";
import {HttpStatusCode, markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, v} from "src/app/utils/validations";

export interface DashboardMeetingDialogData {
  id?: string | null;
  supplements?: {
    topic: string;
    duration: number; // in minutes
    agenda?: string;
    start_time: Date;
  };
}

type LanguageInterpreterFormGroup = FormGroup<{
  email: FormControl<string>;
  from: FormControl<LanguageForInterpretation>;
  to: FormControl<LanguageForInterpretation>;
}>;

type SignLanguageInterpreterFormGroup = FormGroup<{
  email: FormControl<string>;
  sign_language: FormControl<SignLanguageForInterpretation>;
}>;

type PanelistFormGroup = FormGroup<{
  name: FormControl<string>;
  email: FormControl<string>;
}>;

const MeetingForm = v
  .object({
    agenda: v.nativeString().min(0).max(2000).optional(),
    duration: v.number().min(0),
    start_time: v.dateTime(),
    topic: v.string().max(200),
    settings: v.object({
      meeting_authentication: v.boolean(),
      alternative_hosts: v.array(v.string({format: "email"})),
      auto_recording: v.enum(["local", "cloud", "none"]),
      language_interpretation: v.discriminatedUnion("enable", [
        v.object({enable: v.literal(false)}),
        v.object({
          enable: v.literal(true),
          interpreters: v
            .array(
              v.object({
                email: v.string({format: "email"}),
                from: v.enum(LanguagesForInterpretation),
                to: v.enum(LanguagesForInterpretation),
              }),
            )
            .min(1),
        }),
      ]),
      sign_language_interpretation: v.discriminatedUnion("enable", [
        v.object({enable: v.literal(false)}),
        v.object({
          enable: v.literal(true),
          interpreters: v
            .array(
              v.object({
                email: v.string({format: "email"}),
                sign_language: v.enum(SignLanguagesForInterpretation),
              }),
            )
            .min(1),
        }),
      ]),
    }),
  })
  .and(
    v.discriminatedUnion("meeting_type", [
      v.object({meeting_type: v.literal("meeting")}),
      v.object({
        meeting_type: v.literal("webinar"),
        settings: v.object({
          hd_video: v.boolean(),
          panelist_authentication: v.boolean(),
          panelists: v.array(v.object({name: v.string(), email: v.string({format: "email"})})),
          question_and_answer: v.object({
            enable: v.boolean(),
          }),
        }),
      }),
    ]),
  );

@Component({
  selector: "app-dashboard-meeting-dialog",
  templateUrl: "./dashboard-meeting-dialog.component.html",
  styleUrls: ["./dashboard-meeting-dialog.component.scss"],
})
export class DashboardMeetingDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faArrowRightLong = faArrowRightLong;

  languagesForInterpretationChoices = LanguagesForInterpretationChoices;
  signLanguagesForInterpretationChoices = SignLanguagesForInterpretationChoices;

  loading!: boolean;
  error?: "expired" | "not-found" | "other";

  step: "type" | "details" = "type";

  form = new FormGroup(
    {
      meeting_type: new FormControl<MeetingType>("webinar", {nonNullable: true}),

      agenda: new FormControl<string | undefined>(undefined, {nonNullable: true}),
      duration: new FormControl<number>(0, {nonNullable: true}),
      start_time: new FormControl<DateTime>(DateTime.now(), {nonNullable: true}),
      topic: new FormControl<string>("", {nonNullable: true}),
      settings: new FormGroup({
        meeting_authentication: new FormControl(false, {nonNullable: true}),
        alternative_hosts: new FormControl<string[]>([], {nonNullable: true}),
        auto_recording: new FormControl<"local" | "cloud" | "none">("none", {nonNullable: true}),
        language_interpretation: new FormGroup({
          enable: new FormControl<boolean>(false, {nonNullable: true}),
          interpreters: new FormArray<LanguageInterpreterFormGroup>([]),
        }),
        sign_language_interpretation: new FormGroup({
          enable: new FormControl<boolean>(false, {nonNullable: true}),
          interpreters: new FormArray<SignLanguageInterpreterFormGroup>([]),
        }),

        // webinar specific settings
        hd_video: new FormControl<boolean>(false, {nonNullable: true}),
        panelist_authentication: new FormControl(false, {nonNullable: true}),
        panelists: new FormArray<PanelistFormGroup>([]),
        question_and_answer: new FormGroup({
          enable: new FormControl<boolean>(false, {nonNullable: true}),
        }),
      }),
    },
    makeValidators(MeetingForm),
  );

  meeting!: Meeting | undefined;

  types = [
    {name: "اجتماع", value: "meeting"},
    {name: "ويبنار", value: "webinar"},
  ];

  autoRecordingValues = [
    {name: "على الجهاز", value: "local"},
    {name: "من خلال زووم", value: "cloud"},
    {name: "غير مفعل", value: "none"},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DashboardMeetingDialogData | null,
    private dialogRef: MatDialogRef<DashboardMeetingDialogComponent>,
    private meetingSvc: MeetingService,
    private toastSvc: ToastService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize("900px");
    if (this.data?.id) {
      this.step = "details";
      this.getMeeting(this.data.id);
    } else if (this.data?.supplements) {
      this.form.patchValue({
        topic: this.data.supplements.topic,
        duration: this.data.supplements.duration,
        agenda: this.data.supplements.agenda,
        start_time: DateTime.fromJSDate(this.data.supplements.start_time),
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get settings() {
    return this.form.controls.settings;
  }

  get alternative_hosts() {
    return this.form.controls.settings.controls.alternative_hosts;
  }

  get language_interpretation() {
    return this.form.controls.settings.controls.language_interpretation;
  }

  get sign_language_interpretation() {
    return this.form.controls.settings.controls.sign_language_interpretation;
  }

  get panelists() {
    return this.form.controls.settings.controls.panelists;
  }

  get isWebinar() {
    return this.form.controls.meeting_type.value === "webinar";
  }

  updateType(type: "meeting" | "webinar") {
    this.form.controls.meeting_type.patchValue(type);
    this.step = "details";
  }

  goToStep(step: "type" | "details") {
    this.step = step;
  }

  getMeeting(meeting_id: string) {
    this.loading = true;
    this.meetingSvc
      .getMeeting(meeting_id, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (meeting) => {
          this.patchForm(meeting);
          this.meeting = meeting;
          if (
            (meeting.meeting_type === "meeting" && !meeting.meeting) ||
            (meeting.meeting_type === "webinar" && !meeting.webinar)
          ) {
            this.error = "expired";
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.error = error.status === HttpStatusCode.NOT_FOUND ? "not-found" : "other";
          this.loading = false;
        },
      });
  }

  addHost(event: MatChipInputEvent) {
    const host = event.value.trim();
    if (host.length > 0) {
      this.alternative_hosts.value.push(host);
      event.chipInput.clear();
      this.alternative_hosts.markAsDirty();
    }
  }

  removeHost(host: string) {
    const index = this.alternative_hosts.value.indexOf(host);
    if (index >= 0) {
      this.alternative_hosts.value.splice(index, 1);
      updateFormStatus(this.form);
    }
  }

  addLanguageInterpreter() {
    this.language_interpretation.controls.interpreters.push(
      new FormGroup({
        email: new FormControl("", {nonNullable: true}),
        from: new FormControl<LanguageForInterpretation>("US", {nonNullable: true}),
        to: new FormControl<LanguageForInterpretation>("D-AR", {nonNullable: true}),
      }),
    );
  }

  removeLanguageInterpreter(index: number) {
    this.language_interpretation.controls.interpreters.removeAt(index);
  }

  addSignLanguageInterpreter() {
    this.sign_language_interpretation.controls.interpreters.push(
      new FormGroup({
        email: new FormControl("", {nonNullable: true}),
        sign_language: new FormControl<SignLanguageForInterpretation>("Arabic", {nonNullable: true}),
      }),
    );
  }

  removeSignLanguageInterpreter(index: number) {
    this.sign_language_interpretation.controls.interpreters.removeAt(index);
  }

  addPanelist() {
    this.panelists.controls.push(
      new FormGroup({
        name: new FormControl("", {nonNullable: true}),
        email: new FormControl("", {nonNullable: true}),
      }),
    );
  }

  removePanelist(index: number) {
    this.panelists.removeAt(index);
  }

  patchForm(meeting: Meeting) {
    if (meeting.meeting_type === "meeting") {
      this.form.patchValue({
        agenda: meeting.meeting.agenda,
        duration: meeting.meeting.duration,
        meeting_type: "meeting",
        start_time: meeting.meeting.start_time ? DateTime.fromISO(meeting.meeting.start_time) : DateTime.now(),
        topic: meeting.meeting.topic,
        settings: {
          meeting_authentication: !!meeting.meeting.settings?.meeting_authentication,
          alternative_hosts: meeting.meeting.settings?.alternative_hosts
            ? meeting.meeting.settings.alternative_hosts.split(",")
            : [],
          auto_recording: meeting.meeting.settings?.auto_recording,
          language_interpretation: meeting.meeting.settings?.language_interpretation
            ? {enable: meeting.meeting.settings.language_interpretation.enable}
            : undefined,
          sign_language_interpretation: meeting.meeting.settings?.sign_language_interpretation
            ? {enable: meeting.meeting.settings.sign_language_interpretation.enable}
            : undefined,
        },
      });

      if (meeting.meeting.settings?.language_interpretation?.enable) {
        this.settings.controls.language_interpretation.controls.interpreters.clear();
        meeting.meeting.settings.language_interpretation.interpreters?.forEach((interpreter) => {
          const from = interpreter.languages.split(",")[0] as LanguageForInterpretation;
          const to = interpreter.languages.split(",")[1] as LanguageForInterpretation;
          this.settings.controls.language_interpretation.controls.interpreters.push(
            new FormGroup({
              email: new FormControl(interpreter.email, {nonNullable: true}),
              from: new FormControl(from, {nonNullable: true}),
              to: new FormControl(to, {nonNullable: true}),
            }),
          );
        });
      }

      if (meeting.meeting.settings?.sign_language_interpretation?.enable) {
        this.settings.controls.sign_language_interpretation.controls.interpreters.clear();
        meeting.meeting.settings.sign_language_interpretation.interpreters?.forEach((interpreter) => {
          this.settings.controls.sign_language_interpretation.controls.interpreters.push(
            new FormGroup({
              email: new FormControl(interpreter.email, {nonNullable: true}),
              sign_language: new FormControl(interpreter.sign_language, {nonNullable: true}),
            }),
          );
        });
      }
    } else {
      this.form.patchValue({
        agenda: meeting.webinar.agenda,
        duration: meeting.webinar.duration,
        meeting_type: "webinar",
        start_time: meeting.webinar.start_time ? DateTime.fromISO(meeting.webinar.start_time) : DateTime.now(),
        topic: meeting.webinar.topic,
        settings: {
          meeting_authentication: !!meeting.webinar.settings?.meeting_authentication,
          panelist_authentication: !!meeting.webinar.settings?.panelist_authentication,
          alternative_hosts: meeting.webinar.settings?.alternative_hosts
            ? meeting.webinar.settings.alternative_hosts.split(",")
            : [],
          auto_recording: meeting.webinar.settings?.auto_recording,
          language_interpretation: meeting.webinar.settings?.language_interpretation
            ? {enable: meeting.webinar.settings.language_interpretation.enable}
            : undefined,
          sign_language_interpretation: meeting.webinar.settings?.sign_language_interpretation
            ? {enable: meeting.webinar.settings.sign_language_interpretation.enable}
            : undefined,
          hd_video: meeting.webinar.settings?.hd_video,
          question_and_answer: meeting.webinar.settings?.question_and_answer
            ? {
                enable: meeting.webinar.settings.question_and_answer.enable,
              }
            : undefined,
        },
      });

      if (meeting.webinar.settings?.language_interpretation?.enable) {
        this.settings.controls.language_interpretation.controls.interpreters.clear();
        meeting.webinar.settings.language_interpretation.interpreters?.forEach((interpreter) => {
          const from = interpreter.languages.split(",")[0] as LanguageForInterpretation;
          const to = interpreter.languages.split(",")[1] as LanguageForInterpretation;
          this.settings.controls.language_interpretation.controls.interpreters.push(
            new FormGroup({
              email: new FormControl(interpreter.email, {nonNullable: true}),
              from: new FormControl(from, {nonNullable: true}),
              to: new FormControl(to, {nonNullable: true}),
            }),
          );
        });
      }

      if (meeting.webinar.settings?.sign_language_interpretation?.enable) {
        this.settings.controls.sign_language_interpretation.controls.interpreters.clear();
        meeting.webinar.settings.sign_language_interpretation.interpreters?.forEach((interpreter) => {
          this.settings.controls.sign_language_interpretation.controls.interpreters.push(
            new FormGroup({
              email: new FormControl(interpreter.email, {nonNullable: true}),
              sign_language: new FormControl(interpreter.sign_language, {nonNullable: true}),
            }),
          );
        });
      }

      if (meeting.webinar.settings?.panelists) {
        this.settings.controls.panelists.clear();
        meeting.webinar.settings.panelists.forEach((panelist) => {
          this.settings.controls.panelists.push(
            new FormGroup({
              email: new FormControl(panelist.email, {nonNullable: true}),
              name: new FormControl(panelist.name, {nonNullable: true}),
            }),
          );
        });
      }
    }
    markAllAsPristine(this.form);
  }

  buildMeeting() {
    const form = this.form.getRawValue();

    const {language_interpretation, sign_language_interpretation} = form.settings;

    if (form.meeting_type === "meeting") {
      const meeting: ZoomMeeting = {
        agenda: form.agenda,
        duration: form.duration,
        start_time: form.start_time.toJSDate().toISOString(),
        topic: form.topic,
        type: "2", // scheduled meeting,
        settings: {
          allow_multiple_devices: false,
          alternative_hosts: form.settings.alternative_hosts.join(","),
          approval_type: "0",
          auto_recording: form.settings.auto_recording,
          host_video: true,
          language_interpretation: {
            enable: language_interpretation.enable,
            interpreters: language_interpretation.enable
              ? language_interpretation.interpreters.map((interpreter) => ({
                  email: interpreter.email,
                  languages: `${interpreter.from},${interpreter.to}`,
                }))
              : undefined,
          } as LanguageInterpretation,
          sign_language_interpretation: {
            enable: sign_language_interpretation.enable,
            interpreters: sign_language_interpretation.enable
              ? sign_language_interpretation.interpreters.map((interpreter) => ({
                  email: interpreter.email,
                  sign_language: interpreter.sign_language,
                }))
              : undefined,
          } as SignLanguageInterpretation,
          meeting_authentication: form.settings.meeting_authentication,
          mute_upon_entry: true,
          participant_video: false,
          show_share_button: true,
          waiting_room: true,
          registrants_confirmation_email: false,
          registrants_email_notification: false,
        },
      };
      return meeting;
    } else {
      const webinar: ZoomWebinar = {
        agenda: form.agenda,
        duration: form.duration,
        start_time: form.start_time.toJSDate().toISOString(),
        topic: form.topic,
        type: "5", // webinar,
        settings: {
          allow_multiple_devices: false,
          alternative_hosts: form.settings.alternative_hosts.join(","),
          approval_type: "0",
          auto_recording: form.settings.auto_recording,
          hd_video: form.settings.hd_video,
          hd_video_for_attendees: form.settings.hd_video,
          host_video: true,
          language_interpretation: {
            enable: language_interpretation.enable,
            interpreters: language_interpretation.enable
              ? language_interpretation.interpreters.map((interpreter) => ({
                  email: interpreter.email,
                  languages: `${interpreter.from},${interpreter.to}`,
                }))
              : undefined,
          } as LanguageInterpretation,
          sign_language_interpretation: {
            enable: sign_language_interpretation.enable,
            interpreters: sign_language_interpretation.enable
              ? sign_language_interpretation.interpreters.map((interpreter) => ({
                  email: interpreter.email,
                  sign_language: interpreter.sign_language,
                }))
              : undefined,
          } as SignLanguageInterpretation,
          meeting_authentication: form.settings.meeting_authentication,
          panelist_authentication: form.settings.panelist_authentication,
          panelists: form.settings.panelists,
          panelists_invitation_email_notification: true,
          panelists_video: true,
          practice_session: true,
          question_and_answer: form.settings.question_and_answer,
          show_share_button: true,
          registrants_confirmation_email: false,
          registrants_email_notification: false,
        },
      };
      return webinar;
    }
  }

  onConfirm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const meeting = this.buildMeeting();

    this.loading = true;
    if (this.data?.id) {
      this.meetingSvc
        .updateMeeting(this.data.id, meeting)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (meeting) => {
            this.loading = false;
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.dialogRef.close(meeting);
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      let body: CreateMeetingRequestBody;
      if (this.form.controls.meeting_type.value === "meeting") {
        body = {
          provider: "zoom",
          meeting_type: "meeting",
          meeting: meeting as ZoomMeeting,
        };
      } else {
        body = {
          provider: "zoom",
          meeting_type: "webinar",
          webinar: meeting as ZoomWebinar,
        };
      }

      this.meetingSvc
        .createMeeting(body)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (meeting) => {
            this.loading = false;
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.dialogRef.close(meeting);
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  onDismiss(deleted = false) {
    this.dialogRef.close(deleted);
  }

  deleteMeeting() {
    const id = this.data?.id;

    if (!id) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          this.loading = true;
          return this.meetingSvc.deleteMeeting(id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.onDismiss(true);
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
