import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Form} from "src/app/dtos";
import {ConfirmService} from "src/app/services/confirm.service";
import {FormService} from "src/app/services/form.service";
import {ToastService} from "src/app/services/toast.service";

@Component({
  selector: "app-dashboard-forms-list",
  templateUrl: "./dashboard-forms-list.component.html",
  styleUrls: ["./dashboard-forms-list.component.scss"],
})
export class DashboardFormsListComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>(1);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ["id", "title", "actions"];
  dataSource: MatTableDataSource<Form> = new MatTableDataSource();

  loading = false;
  totalRows = 0;
  pageIndex = 0;

  constructor(
    private formSvc: FormService,
    private toastSvc: ToastService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.listForms();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  listForms() {
    this.loading = true;
    this.formSvc
      .listForms({
        filter: {
          tags: ["evaluation"],
          template: true,
        },
        paginate: {
          page: this.pageIndex + 1,
          limit: 20,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.dataSource.data = result.rows;
          this.totalRows = result.totalRows;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  pageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listForms();
  }

  delete(form_id: string) {
    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          this.loading = true;
          return this.formSvc.deleteForm(form_id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: () => {
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
          this.listForms();
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
