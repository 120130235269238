import {Pipe, PipeTransform} from "@angular/core";
import {ValidationErrors} from "@angular/forms";

@Pipe({
  name: "error",
  pure: false,
})
export class ErrorPipe implements PipeTransform {
  transform(errors: ValidationErrors | null): string;
  transform(errors: ValidationErrors | null, allErrors: true): string[];
  transform(errors: ValidationErrors | null, allErrors = false): string | string[] {
    const nought = allErrors ? [""] : "";
    if (errors === null || Object.keys(errors).length === 0) return nought;

    const messages = Object.values(errors);

    return allErrors ? messages : messages[0];
  }
}
