<mtx-loader [loading]="loading">
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="updateEvent()">
    <div class="d-flex justify-content-end">
      <button mat-flat-button color="primary" type="submit">حفظ</button>
    </div>

    <div>
      <h5>إعدادات الفعالية</h5>
      <div class="row d-flex align-items-center mt-4">
        <div class="col-md-2">
          <mat-checkbox formControlName="auto_start">إرسال الإشعارات تلقائيًا؟</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-form-field
            *ngIf="auto_start.valid && auto_start.value"
            class="w-100"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <mat-label>عدد الدقائق قبل إرسال الإشعارات تلقائيًا قبل بداية الفعالية</mat-label>
            <input
              type="number"
              matInput
              placeholder="عدد الدقائق قبل إرسال الإشعارات تلقائيًا قبل بداية الفعالية"
              formControlName="auto_start_delay_in_minutes"
            />
            <mat-error *ngIf="auto_start_delay_in_minutes.invalid">
              {{ auto_start_delay_in_minutes.errors | error }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row d-flex align-items-center mt-4">
        <div class="col-md-2">
          <mat-checkbox formControlName="auto_end">إنهاء الفعالية تلقائيًا؟</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-form-field
            *ngIf="auto_end.valid && auto_end.value"
            class="w-100"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <mat-label>عدد الدقائق قبل إنهاء الفعالية تلقائيًا بعد نهاية الفعالية</mat-label>
            <input
              type="number"
              matInput
              placeholder="عدد الدقائق قبل إنهاء الفعالية تلقائيًا بعد نهاية الفعالية"
              formControlName="auto_end_delay_in_minutes"
            />
            <mat-error *ngIf="auto_end_delay_in_minutes.invalid">
              {{ auto_end_delay_in_minutes.errors | error }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row d-flex align-items-center mt-4">
        <div class="col-md-2">
          <mat-checkbox formControlName="auto_close_enrollment">إغلاق التسجيل تلقائيًا؟</mat-checkbox>
        </div>
        <div class="col-md-4">
          <mat-form-field
            *ngIf="auto_close_enrollment.valid && auto_close_enrollment.value"
            class="w-100"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <mat-label>عدد الدقائق قبل إغلاق التسجيل تلقائيًا قبل بداية الفعالية</mat-label>
            <input
              type="number"
              matInput
              placeholder="عدد الدقائق قبل إغلاق التسجيل تلقائيًا قبل بداية الفعالية"
              formControlName="auto_close_enrollment_delay_in_minutes"
            />
            <mat-error *ngIf="auto_close_enrollment_delay_in_minutes.invalid">
              {{ auto_close_enrollment_delay_in_minutes.errors | error }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row d-flex align-items-center mt-4">
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>أقصى عدد للحضور</mat-label>
            <input type="number" matInput placeholder="أقصى عدد للحضور" formControlName="max_allowed_attendees" />
            <mat-error *ngIf="max_allowed_attendees.invalid">{{ max_allowed_attendees.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="my-4">
      <h5>إعدادات الشهادة</h5>
      <div class="row d-flex align-items-center mt-4">
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الشهادة</mat-label>
            <mat-select formControlName="certificate_id">
              <mat-option *ngFor="let certificate of certificates" [value]="certificate.id">
                {{ certificate.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="certificate_id.invalid">{{ certificate_id.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div
          *ngIf="certificate_id.valid && !!certificate_id.value"
          class="col-md-6 d-flex flex-column justify-content-between align-items-center my-4"
        >
          <img image [src]="certificateImageSrc" style="height: 200px" class="img-fluid" alt="certificate-image" />
          <input
            #certificateImageInput
            type="file"
            class="visually-hidden"
            (change)="onFileChange($event, certificate_image_id)"
          />
          <button mat-flat-button (click)="certificateImageInput.click()" color="accent" class="w-100 mt-4" type="button">
            اختر صورة الشهادة
          </button>
        </div>
      </div>
    </div>
    <div class="my-4">
      <h5>إعدادات البادج</h5>
      <div class="row d-flex align-items-center mt-4">
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>البادج</mat-label>
            <mat-select formControlName="badge_id">
              <mat-option *ngFor="let badge of badges" [value]="badge.id">
                {{ badge.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="badge_id.invalid">{{ badge_id.errors | error }}</mat-error>
          </mat-form-field>
        </div>

        <div
          *ngIf="badge_id.valid && !!badge_id.value"
          class="col-md-6 d-flex flex-column justify-content-between align-items-center my-4"
        >
          <img image [src]="badgeImageSrc" style="height: 200px" class="img-fluid" alt="badge-image" />
          <input #badgeImageInput type="file" class="visually-hidden" (change)="onFileChange($event, badge_image_id)" />
          <button mat-flat-button (click)="badgeImageInput.click()" color="accent" class="w-100 mt-4" type="button">
            اختر صورة البادج
          </button>
        </div>
      </div>
    </div>
    <div class="my-4">
      <h5>إعدادات اللقاء</h5>
      <div class="row mt-4">
        <div class="col-md-2">
          <mat-checkbox formControlName="has_meeting">الفعالية لها لقاء (زووم)؟</mat-checkbox>
        </div>
        <div *ngIf="has_meeting.valid && has_meeting.value" class="col-md-4">
          <mat-form-field class="w-100" appearance="outline" subscriptSizing="dynamic">
            <mat-label>أقل عدد لدقائق الحضور</mat-label>
            <input
              type="number"
              matInput
              placeholder="أقل عدد لدقائق الحضور"
              formControlName="min_attendance_duration_in_minutes"
            />
            <mat-error *ngIf="min_attendance_duration_in_minutes.invalid">
              {{ min_attendance_duration_in_minutes.errors | error }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="my-4">
      <h5>إعدادات إضافية</h5>
      <div class="row mt-4">
        <div class="col-md-6">
          <mat-checkbox formControlName="questions_enabled">تفعيل الأسئلة؟</mat-checkbox>
        </div>
        <div class="col-md-6">
          <mat-checkbox formControlName="reviews_enabled">تفعيل التقييمات؟</mat-checkbox>
        </div>
        <div class="col-md-6">
          <mat-checkbox formControlName="notes_enabled">تفعيل الملاحظات؟</mat-checkbox>
        </div>
        <div class="col-md-6">
          <mat-checkbox formControlName="announcements_enabled">تفعيل الإعلانات؟</mat-checkbox>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
