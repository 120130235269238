import {HttpClient, HttpContext} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

import {Meeting, ZoomMeeting, ZoomWebinar} from "../dtos";
import {SILENCE_ERRORS} from "../utils";

export type CreateMeetingRequestBody = {provider: "zoom"} & (
  | {meeting_type: "meeting"; meeting: ZoomMeeting}
  | {meeting_type: "webinar"; webinar: ZoomWebinar}
);

export type UpdateMeetingRequestBody = ZoomMeeting | ZoomWebinar;

@Injectable({
  providedIn: "root",
})
export class MeetingService {
  private readonly V1_ENDPOINTS = {
    GET_MEETING: (meeting_id: string) => `${environment.server.host}/api/v1/meetings/${meeting_id}`,
    CREATE_MEETING: () => `${environment.server.host}/api/v1/meetings`,
    UPDATE_MEETING: (meeting_id: string) => `${environment.server.host}/api/v1/meetings/${meeting_id}`,
    DELETE_MEETING: (meeting_id: string) => `${environment.server.host}/api/v1/meetings/${meeting_id}`,
  };

  constructor(private httpClient: HttpClient) {}

  getMeeting(meeting_id: string, silent = false) {
    return this.httpClient.get<Meeting>(this.V1_ENDPOINTS.GET_MEETING(meeting_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  createMeeting(body: CreateMeetingRequestBody, silent = false) {
    return this.httpClient.post<Meeting>(this.V1_ENDPOINTS.CREATE_MEETING(), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateMeeting(meeting_id: string, body: UpdateMeetingRequestBody, silent = false) {
    return this.httpClient.put<Meeting>(this.V1_ENDPOINTS.UPDATE_MEETING(meeting_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteMeeting(meeting_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_MEETING(meeting_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }
}
