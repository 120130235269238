<mtx-loader [loading]="loading">
  <div class="container-fluid p-5">
    <div class="row">
      <div class="col-md-3 d-flex flex-column align-items-center">
        <img
          image
          fallback="avatar"
          [src]="photoSrc"
          class="d-block rounded-circle border border-2 border-very-light-gray"
          [alt]="first_name.value"
          style="width: 100px; height: 100px"
        />
        <input #fileInput type="file" class="visually-hidden" (change)="onFileChange($event)" />
        <div class="d-flex gap-2">
          <button class="btn btn-light mt-2" (click)="fileInput.click()" type="button">تغيير</button>
          <button *ngIf="!!photoSrc" class="btn btn-light mt-2" (click)="deletePhoto()" type="button">حذف</button>
        </div>
      </div>
      <div class="col-md-9">
        <form #ngForm="ngForm" [formGroup]="form" class="" autocomplete="off" (ngSubmit)="update()">
          <div class="row">
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="first_name">الاسم الأول</label>
              <div class="input-group mb-3">
                <input
                  id="first_name"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="first_name"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(first_name, ngForm)}"
                  readonly
                />
                <div *ngIf="first_name.invalid" class="invalid-feedback">{{ first_name.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="middle_name">الاسم الأوسط</label>
              <div class="input-group mb-3">
                <input
                  id="middle_name"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="middle_name"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(middle_name, ngForm)}"
                  readonly
                />
                <div *ngIf="middle_name.invalid" class="invalid-feedback">{{ middle_name.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="last_name">الاسم الأخير</label>
              <div class="input-group mb-3">
                <input
                  id="last_name"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="last_name"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(last_name, ngForm)}"
                  readonly
                />
                <div *ngIf="last_name.invalid" class="invalid-feedback">{{ last_name.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="first_name_en">الاسم الأول بالإنجليزية</label>
              <div class="input-group mb-3">
                <input
                  id="first_name_en"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="first_name_en"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(first_name_en, ngForm)}"
                />
                <div *ngIf="first_name_en.invalid" class="invalid-feedback">{{ first_name_en.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="middle_name_en">الاسم الأوسط بالإنجليزية</label>
              <div class="input-group mb-3">
                <input
                  id="middle_name_en"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="middle_name_en"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(middle_name_en, ngForm)}"
                />
                <div *ngIf="middle_name_en.invalid" class="invalid-feedback">{{ middle_name_en.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="last_name_en">الاسم الأخير بالإنجليزية</label>
              <div class="input-group mb-3">
                <input
                  id="last_name_en"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="last_name_en"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(last_name_en, ngForm)}"
                />
                <div *ngIf="last_name_en.invalid" class="invalid-feedback">{{ last_name_en.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="email">البريد الإلكتروني</label>
              <div class="input-group mb-3">
                <input
                  id="email"
                  type="email"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="email"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(email, ngForm)}"
                  readonly
                />
                <div *ngIf="email.invalid" class="invalid-feedback">{{ email.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="phone">رقم الجوال</label>
              <app-phone-input
                class="mb-3"
                formControlName="phone"
                [invalid]="errorStateMatcher.isErrorState(phone, ngForm)"
                [error]="phone.errors | error"
              ></app-phone-input>
            </div>
            <div class="col-lg-4">
              <label class="fw-light text-l-gray mb-2" for="country_id">الدولة</label>
              <div class="input-group mb-3">
                <select
                  class="form-select py-3 focus-shadow-none"
                  id="country_id"
                  formControlName="country_id"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(country_id, ngForm)}"
                >
                  <option *ngFor="let country of countries" [value]="country.id">
                    {{ country.name }}
                  </option>
                </select>
                <div *ngIf="country_id.invalid" class="invalid-feedback">{{ country_id.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="fw-light text-l-gray mb-2" for="birth_date">تاريخ الميلاد</label>
              <div class="input-group mb-3">
                <input
                  id="birth_date"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="birth_date"
                  [mtxDatetimepicker]="birthDatePicker"
                  (click)="birthDatePicker.open()"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(birth_date, ngForm)}"
                  dir="ltr"
                />
                <mtx-datetimepicker
                  #birthDatePicker
                  type="date"
                  mode="portrait"
                  twelvehour
                  touchUi
                  timeInput
                  dir="ltr"
                ></mtx-datetimepicker>
              </div>
              <div *ngIf="birth_date.invalid" class="invalid-feedback">{{ birth_date.errors | error }}</div>
            </div>
            <div class="col-lg-6">
              <label class="fw-light text-l-gray mb-2" for="job">الوظيفة</label>
              <div class="input-group mb-3">
                <input
                  id="job"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="job"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(job, ngForm)}"
                />
                <div *ngIf="job.invalid" class="invalid-feedback">{{ job.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="fw-light text-l-gray mb-2" for="workplace">جهة العمل</label>
              <div class="input-group mb-3">
                <input
                  id="workplace"
                  type="text"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="workplace"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(workplace, ngForm)}"
                />
                <div *ngIf="workplace.invalid" class="invalid-feedback">{{ workplace.errors | error }}</div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="fw-light text-l-gray mb-2" for="gender">الجنس</label>
              <div class="input-group mb-3">
                <select
                  class="form-select py-3 focus-shadow-none"
                  id="gender"
                  formControlName="gender"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(gender, ngForm)}"
                >
                  <option class="fst-italic" selected [ngValue]="null" [disabled]="country_id.value !== null">
                    دون تحديد
                  </option>
                  <option value="male">ذكر</option>
                  <option value="female">أنثى</option>
                </select>
                <div *ngIf="gender.invalid" class="invalid-feedback">{{ gender.errors | error }}</div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="mb-3">
                <label class="fw-light text-l-gray mb-2" for="info">معلومات عنك</label>
                <textarea
                  id="info"
                  rows="5"
                  class="form-control py-3 focus-shadow-none"
                  formControlName="biography"
                  [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(biography, ngForm)}"
                ></textarea>
                <div *ngIf="biography.invalid" class="invalid-feedback">{{ biography.errors | error }}</div>
              </div>
            </div>
            <div class="col-12">
              <button class="btn btn-primary w-100 py-3 mt-5" type="submit">حفظ</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr class="my-5" />
    <div class="row">
      <div class="col-md-3 d-flex flex-column align-items-center">
        <h5 class="text-danger fw-bold">حذف الحساب</h5>
      </div>
      <div class="col-md-9">
        <p>حذف بيانات الحساب الشخصي و جميع البيانات المتعلقة باستخدام المنصة.</p>
        <button class="btn btn-outline-danger" type="button" (click)="openDeletionConfirmDialog()">حذف الحساب</button>
      </div>
    </div>
  </div>
</mtx-loader>
