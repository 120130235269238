import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {catchError, combineLatest, of, ReplaySubject, switchMap, takeUntil, tap, throwError} from "rxjs";
import {Attendee, Event, Form, FormResponse} from "src/app/dtos";
import {EventService} from "src/app/services/event.service";
import {FormService} from "src/app/services/form.service";
import {ToastService} from "src/app/services/toast.service";
import {HttpStatusCode} from "src/app/utils";

@Component({
  selector: "app-event-evaluation",
  templateUrl: "./event-evaluation.component.html",
  styleUrls: ["./event-evaluation.component.scss"],
})
export class EventEvaluationComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  loading!: boolean;

  event_id!: string;
  event?: Event;
  attendee?: Attendee;
  form?: Form;
  response = new Map<string, string>();

  constructor(
    private eventSvc: EventService,
    private router: Router,
    private formSvc: FormService,
    private toastSvc: ToastService,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get invalid() {
    if (!this.form?.questions) return true;
    return this.response.size !== this.form?.questions.length;
  }

  getData() {
    this.loading = true;
    combineLatest([this.eventSvc.getEvent$(), this.eventSvc.getAttendee$()])
      .pipe(
        tap(([event, attendee]) => {
          this.event = event;
          this.attendee = attendee;
          this.event_id = event.id;
        }),
        switchMap(([event]) => {
          const evaluation_form_id = event.evaluation_form_id;
          if (!evaluation_form_id) {
            this.router.navigate(["/events", event.id, "details"]);
            return throwError(() => new Error("Event has no evaluation from"));
          }
          return this.formSvc.getForm(evaluation_form_id);
        }),
        tap((form) => {
          this.form = form;
        }),
        switchMap(() => {
          return this.eventSvc.getEvaluationFormResponse(this.event_id, true);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NOT_FOUND && error.error.errorCode === "form_response_not_found") {
            return of(null);
          }
          return throwError(() => error);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.patchForm(response);
          }
          this.loading = false;
        },
      });
  }

  updateResponse(question_id: string, value: string) {
    this.response.set(question_id, value);
  }

  patchForm(response: FormResponse) {
    const mapping = new Map<string, string>();
    response.answers.forEach((answer) => {
      mapping.set(answer.question_id, answer.values[0]);
    });
    this.response = mapping;
  }

  submit() {
    if (this.invalid) {
      this.toastSvc.error("يرجى استكمال تعبئة الحقول");
      return;
    }

    this.loading = true;

    const response = Array.from(this.response.entries()).map(([question_id, value]) => ({question_id, value}));

    this.eventSvc
      .submitEvaluation(this.event_id, response)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.patchForm(response);
          this.loading = false;
          if (this.event?.certificate_id && !this.attendee?.certificate_file) {
            this.toastSvc.success(
              "جاري طباعة الشهادة و سيصلكم إشعار عن طريق البريد الإلكتروني عند الانتهاء. كما يمكنكم الوصول إلى الشهادة عن طريق مراجعة الصفحة في وقت لاحق",
            );
          } else {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          }
          this.eventSvc.refresh();
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
