import {HttpClient, HttpContext} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {throwError} from "rxjs";
import {SILENCE_ERRORS} from "src/app/utils";
import {environment} from "src/environments/environment";

import {File} from "../dtos";
import {PLATFORM_SERVICE, PlatformService} from "./platform.service";
import {ToastService} from "./toast.service";

export type StorageProvider = "local" | "azure";

export interface UpdateFileRequestBody {
  filename_download?: string;
}

export interface UploadFileRequestBody {
  filename_download?: string;
  file: globalThis.File;
}

@Injectable({
  providedIn: "root",
})
export class FileService {
  private readonly V1_ENDPOINTS = {
    DOWNLOAD_FILE: (file_id: string) => `${environment.server.host}/api/v1/files/${file_id}/download`,
    GET_FILE: (file_id: string) => `${environment.server.host}/api/v1/files/${file_id}`,
    UPDATE_FILE: (file_id: string) => `${environment.server.host}/api/v1/files/${file_id}`,
    DELETE_FILE: (file_id: string) => `${environment.server.host}/api/v1/files/${file_id}`,
    UPLOAD_FILE: () => `${environment.server.host}/api/v1/files`,
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_SERVICE) private platformSvc: PlatformService,
    private toastSvc: ToastService,
  ) {}

  downloadFile(file_id: string) {
    const window = this.platformSvc.getWindow();
    window.location.href = this.V1_ENDPOINTS.DOWNLOAD_FILE(file_id);
  }

  getFileDownloadLink(file_id: string) {
    return this.V1_ENDPOINTS.DOWNLOAD_FILE(file_id);
  }

  getFile(file_id: string, silent = false) {
    return this.httpClient.get<File>(this.V1_ENDPOINTS.GET_FILE(file_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  updateFile(file_id: string, body: UpdateFileRequestBody, silent = false) {
    return this.httpClient.put<File>(this.V1_ENDPOINTS.UPDATE_FILE(file_id), body, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  deleteFile(file_id: string, silent = false) {
    return this.httpClient.delete<void>(this.V1_ENDPOINTS.DELETE_FILE(file_id), {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  uploadFile(body: UploadFileRequestBody, silent = false) {
    const formData = new FormData();

    formData.append("files", body.file, body.filename_download ?? body.file.name);

    return this.httpClient.post<File>(this.V1_ENDPOINTS.UPLOAD_FILE(), formData, {
      context: new HttpContext().set(SILENCE_ERRORS, silent),
    });
  }

  getFileFromEvent(event: globalThis.Event) {
    const target = event.target as HTMLInputElement | null;
    if (!target) return null;

    const files = target.files;
    if (!files || !files.length) return null;

    const file = files.item(0);
    if (!file) return null;

    return file;
  }

  validateFile(
    file: globalThis.File | null,
    accept?: string[],
  ): {valid: true; file: globalThis.File} | {valid: false; reason: Error} {
    if (!file) {
      this.toastSvc.error("يرجى إختيار ملف للمتابعة");
      return {valid: false, reason: new Error("No file was selected")};
    }

    if (
      !file.type ||
      file.type.startsWith("application/x-msdownload") ||
      (accept && !accept.some((type) => file.type.startsWith(type)))
    ) {
      this.toastSvc.error("صيغة الملف غير مقبولة");
      return {valid: false, reason: new Error("File type is not allowed")};
    }

    if (file.size >= 1024 * 1024 * 100) {
      this.toastSvc.error("حجم الملف يتجاوز 100 ميجابايت");
      return {valid: false, reason: new Error("File size exceeds 100MB")};
    }

    return {valid: true, file};
  }

  uploadFromEvent(event: globalThis.Event, filename_download?: string, accept?: string[]) {
    const file = this.getFileFromEvent(event);

    const result = this.validateFile(file, accept);

    if (!result.valid) {
      return throwError(() => result.reason);
    }

    return this.uploadFile({file: result.file, filename_download: filename_download});
  }

  uploadFromFile(file: globalThis.File | null, filename_download?: string, accept?: string[]) {
    const result = this.validateFile(file, accept);

    if (!result.valid) {
      return throwError(() => result.reason);
    }

    return this.uploadFile({file: result.file, filename_download: filename_download});
  }
}
