<ng-template #form let-i="index">
  <form #ngForm="ngForm" [formGroup]="announcementForm" class="my-3">
    <div class="input-group">
      <input
        type="text"
        class="form-control focus-shadow-none rounded-0 py-3"
        formControlName="title"
        placeholder="عنوان الإعلان"
        [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(announcementForm.controls.title, ngForm)}"
      />
      <div *ngIf="announcementForm.controls.title.invalid" class="invalid-feedback">
        {{ announcementForm.controls.title.errors | error }}
      </div>
    </div>
    <div class="mt-3">
      <textarea
        rows="3"
        class="form-control focus-shadow-none rounded-0 py-3"
        placeholder="نص الإعلان"
        formControlName="content"
        [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(announcementForm.controls.content, ngForm)}"
      ></textarea>
      <div *ngIf="announcementForm.controls.content.invalid" class="invalid-feedback">
        {{ announcementForm.controls.content.errors | error }}
      </div>
    </div>
    <div class="mt-3 d-flex gap-3 justify-content-end">
      <button [disabled]="loading" class="btn btn-primary px-5 py-3 rounded-0" (click)="save(i)">إرسال</button>
      <button [disabled]="loading" (click)="toggleAnnouncementForm(i)" class="btn btn-outline-secondary px-5 py-3 rounded-0">
        إلغاء
      </button>
    </div>
  </form>
</ng-template>

<div class="p-4">
  <mtx-loader [loading]="loading">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div *ngIf="!showAnnouncementForm && user && user.role === 'admin'" class="text-end mt-4">
          <button class="btn btn-outline-secondary px-5 py-3 rounded-0" (click)="toggleAnnouncementForm()">
            <fa-icon [icon]="faPlus" class="me-3"></fa-icon>
            إضافة إعلان
          </button>
        </div>

        <div *ngIf="showAnnouncementForm" class="row gap-3">
          <div class="col-12">
            <ng-container *ngTemplateOutlet="form; context: {index: undefined}"></ng-container>
          </div>
        </div>

        <p *ngIf="announcements && announcements.length > 0" class="fw-light mt-5">
          جميع الإعلانات المتاحة ({{ totalRows }})
        </p>

        <ng-container *ngFor="let announcement of announcements; index as announcementIndex">
          <div class="card rounded-0 py-3 bg-dark bg-opacity-10 border-0">
            <div class="card-body d-flex gap-2">
              <img
                image
                [src]="announcement.user?.photo?.source"
                style="width: 50px; height: 50px"
                [alt]="announcement.user?.full_name"
                class="rounded-circle mx-3"
              />

              <div class="flex-grow-1">
                <h6 class="fw-bold overflow-hidden">{{ announcement.title }}</h6>
                <p class="overflow-anywhere">{{ announcement.content }}</p>

                <p class="mb-0">
                  <span class="me-4 fw-light">{{ announcement.user?.full_name }}</span>
                  <span class="me-4 fw-light">{{ announcement.date_created | date: "yyyy/MM/dd" }}</span>
                </p>

                <div class="d-flex justify-content-end">
                  <button
                    *ngIf="user && user.role === 'admin'"
                    [disabled]="currentToUpdateAnnouncement === announcement.id"
                    class="btn"
                    (click)="toggleAnnouncementForm(announcementIndex)"
                  >
                    <fa-icon [icon]="faPen" title="تعديل" class="text-secondary"></fa-icon>
                  </button>
                  <button *ngIf="user && user.role === 'admin'" class="btn" (click)="deleteAnnouncement(announcementIndex)">
                    <fa-icon [icon]="faTrash" title="حذف" class="text-danger"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentToUpdateAnnouncement === announcement.id" class="row">
            <div class="col-12">
              <ng-container *ngTemplateOutlet="form; context: {index: announcementIndex}"></ng-container>
            </div>
          </div>
        </ng-container>

        <button
          *ngIf="hasNextPage"
          (click)="getNextPage()"
          class="btn btn-outline-secondary rounded-0 text-center mt-3 py-4 w-100"
        >
          المزيد
        </button>

        <div *ngIf="!announcements || !announcements.length" class="text-center mt-5 bg-primary text-white py-4">
          لا توجد إعلانات
        </div>
      </div>
    </div>
  </mtx-loader>
</div>
