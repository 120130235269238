import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import {ActivatedRoute} from "@angular/router";
import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {filter, ReplaySubject, switchMap, takeUntil} from "rxjs";
import {Note, User} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";
import {ConfirmService} from "src/app/services/confirm.service";
import {EventService} from "src/app/services/event.service";
import {ToastService} from "src/app/services/toast.service";
import {makeValidators, v} from "src/app/utils/validations";

const NoteForm = v.object({
  content: v.string(),
});

@Component({
  selector: "app-event-notes",
  templateUrl: "./event-notes.component.html",
  styleUrls: ["./event-notes.component.scss"],
})
export class EventNotesComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faPlus = faPlus;
  faPen = faPen;
  faTrash = faTrash;

  showNoteForm!: boolean;
  currentToUpdateNote: string | null = null;

  loading!: boolean;

  noteForm = new FormGroup(
    {
      id: new FormControl(""),
      content: new FormControl("", {nonNullable: true}),
    },
    makeValidators(NoteForm),
  );

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  event_id!: string;

  user!: User | null;

  page = 0;
  totalRows!: number;
  hasNextPage!: boolean;

  notes: Note[] = [];

  constructor(
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private authSvc: AuthService,
    private confirmSvc: ConfirmService,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getUser();
    this.getNotes();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUser() {
    this.authSvc
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  getNotes(push = false) {
    this.loading = true;

    if (!push) {
      this.notes = [];
    }

    this.eventSvc
      .listNotes(this.event_id, {
        paginate: {
          page: this.page + 1,
          limit: 5,
        },
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.loading = false;
          this.totalRows = result.totalRows;
          this.hasNextPage = result.hasNextPage;
          this.notes.push(...result.rows);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  getNextPage() {
    this.page++;
    this.getNotes(true);
  }

  toggleNoteForm(noteIndex?: number) {
    if (noteIndex !== undefined) {
      this.showNoteForm = false;
      const note = this.notes.at(noteIndex);
      if (note) {
        this.currentToUpdateNote = this.currentToUpdateNote ? null : note.id;
        if (this.currentToUpdateNote) {
          this.noteForm.reset({
            id: note.id,
            content: note.content,
          });
        }
      }
    } else {
      this.currentToUpdateNote = null;
      this.showNoteForm = !this.showNoteForm;
      this.noteForm.reset({
        id: null,
        content: "",
      });
    }
  }

  save(noteIndex?: number) {
    if (this.noteForm.invalid) {
      this.noteForm.markAllAsTouched();
      return;
    }

    this.loading = true;
    const {id, ...form} = this.noteForm.getRawValue();
    if (id) {
      this.eventSvc
        .updateNote(this.event_id, id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (note) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            if (noteIndex !== undefined) {
              this.notes[noteIndex] = note;
            }
            this.currentToUpdateNote = null;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.eventSvc
        .createNote(this.event_id, form)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (note) => {
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
            this.showNoteForm = false;
            this.notes.unshift(note);
            this.totalRows++;
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  deleteNote(index: number) {
    const note = this.notes.at(index);

    if (!note) return;

    if (!this.user || (this.user.role !== "admin" && note.user_id !== this.user.id)) return;

    this.confirmSvc
      .confirm()
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap(() => {
          return this.eventSvc.deleteNote(this.event_id, note.id);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.toastSvc.success("تم تنفيذ الأمر بنجاح");
        this.notes.splice(index, 1);
        this.totalRows--;
      });
  }
}
