import {BreakpointObserver} from "@angular/cdk/layout";
import {StepperOrientation} from "@angular/cdk/stepper";
import {DOCUMENT} from "@angular/common";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {Country} from "src/app/dtos";
import {AuthService} from "src/app/services/auth.service";
import {CountryService} from "src/app/services/country.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsPristine} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

const ProfileForm = v.object({
  first_name: v.string(),
  middle_name: v.string(),
  last_name: v.string(),
  accept: v.literal(true),
  phone: v.string({format: "phone", nullable: true}).optional(),
  country_id: v.string({format: "uuid", nullable: true}).optional(),
});

@Component({
  selector: "app-profile-wizard",
  templateUrl: "./profile-wizard.component.html",
  styleUrls: ["./profile-wizard.component.scss"],
})
export class ProfileWizardComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new ReplaySubject<void>(1);

  faSpinner = faSpinner;

  form = new FormGroup(
    {
      first_name: new FormControl("", {nonNullable: true}),
      middle_name: new FormControl("", {nonNullable: true}),
      last_name: new FormControl("", {nonNullable: true}),
      accept: new FormControl(false, {nonNullable: true}),
      country_id: new FormControl(""),
      phone: new FormControl(""),
    },
    makeValidators(ProfileForm),
  );

  orientation: StepperOrientation = "horizontal";
  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();
  loading!: boolean;
  success!: boolean;

  countries: Country[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private countrySvc: CountryService,
    private authSvc: AuthService,
    private toastSvc: ToastService,

    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe("(min-width: 992px)")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({matches}) => {
        this.orientation = matches ? "horizontal" : "vertical";
      });

    this.getCountries();
    this.getProfile();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get first_name() {
    return this.form.controls.first_name;
  }

  get middle_name() {
    return this.form.controls.middle_name;
  }

  get last_name() {
    return this.form.controls.last_name;
  }

  get accept() {
    return this.form.controls.accept;
  }

  get country_id() {
    return this.form.controls.country_id;
  }

  get phone() {
    return this.form.controls.phone;
  }

  getProfile() {
    this.loading = true;
    this.authSvc
      .getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (user) => {
          if (user.profile_created) {
            this.document.location.href = "/";
            return;
          }
          this.form.patchValue({
            first_name: user.first_name,
            middle_name: user.middle_name,
            last_name: user.last_name,
            phone: user.phone ?? "",
            country_id: user.country_id ?? "",
          });
          markAllAsPristine(this.form);
          this.loading = false;
        },
      });
  }

  getCountries() {
    this.countrySvc
      .getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countries) => {
        this.countries = countries;
      });
  }

  save() {
    if (this.form.controls.phone.value === "+") this.phone.setValue(null);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;

    const form = this.form.getRawValue();

    const profile = {
      ...form,
    };

    this.authSvc
      .createProfile(profile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.success = true;
          this.toastSvc.success("تم الحفظ بنجاح");
          this.document.location.href = "/";
        },
        error: () => {
          this.loading = false;
        },
      });
  }
  validate(control: AbstractControl) {
    if (control.valid) return;
    control.markAllAsTouched();
  }
}
