/* eslint-disable @typescript-eslint/no-empty-interface */
import {File} from "./file.dto";
import {Stamps} from "./helpers";

export type FormQuestionType = "choice" | "text" | "upload";

export type FormQuestionChoiceType = "radio" | "checkbox";

export interface FormChoiceQuestionData {
  type: FormQuestionChoiceType;
  options: {
    value: string;
  }[];
}

export interface FormTextQuestionData {}

export interface FormUploadQuestionData {}

export interface FormQuestionGrading {
  points: number;
  answers: {
    values: string[];
  }[];
}

export interface FormQuestionAnswer {
  grade: {
    correct: boolean;
    score: number;
  } | null;
  values: string[];
  files: string[];
  question_id: string;
}

export interface FormQuestion extends Stamps {
  form_id: string;
  title: string;
  description: string | null;
  data: FormChoiceQuestionData;
  grading: FormQuestionGrading | null;
  type: FormQuestionType;
  order: number;
}

export interface Form extends Stamps {
  title: string;
  graded: boolean;
  questions?: FormQuestion[];
  template: boolean;
  tags: string[];
}

export interface FormResponse extends Stamps {
  form_id: string;
  form?: Form;
  answers: FormQuestionAnswer[];
  score: number | null;
  files?: File[];
}
