import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MatExpansionPanel} from "@angular/material/expansion";
import {ActivatedRoute} from "@angular/router";
import {combineLatest, of, ReplaySubject, switchMap, takeUntil, tap} from "rxjs";
import {Event, Form, FormChoiceQuestionData} from "src/app/dtos";
import {CanComponentDeactivate} from "src/app/guards/data-loss.guard";
import {EventService} from "src/app/services/event.service";
import {FormService, UpdateFormRequestBody} from "src/app/services/form.service";
import {ToastService} from "src/app/services/toast.service";
import {markAllAsDirty, markAllAsPristine, updateFormStatus} from "src/app/utils";
import {makeValidators, ShowOnDirtyErrorStateMatcher, v} from "src/app/utils/validations";

type QuestionFormGroup = FormGroup<{
  id: FormControl<string | null | undefined>;
  title: FormControl<string>;
  data: FormArray<FormControl<string>>;
}>;

const EvaluationForm = v.object({
  questions: v
    .array(
      v.object({
        id: v.string({format: "uuid", nullable: true}).optional(),
        title: v.string(),
        data: v.array(v.string()).min(1),
      }),
    )
    .min(1),
});

@Component({
  selector: "app-dashboard-event-evaluation-form",
  templateUrl: "./dashboard-event-evaluation-form.component.html",
  styleUrls: ["./dashboard-event-evaluation-form.component.scss"],
})
export class DashboardEventEvaluationFormComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  unsubscribe$ = new ReplaySubject<void>(1);

  form = new FormGroup(
    {
      questions: new FormArray<QuestionFormGroup>([]),
    },
    makeValidators(EvaluationForm),
  );

  loading!: boolean;

  event_id!: string;
  event!: Event;

  templates!: Form[];
  selectedTemplate!: string | null;

  errorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  constructor(
    private toastSvc: ToastService,
    private eventSvc: EventService,
    private formSvc: FormService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.event_id = this.route.snapshot.params["event_id"];
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get questions() {
    return this.form.controls.questions;
  }

  buildForm(form: Form) {
    this.questions.clear();
    const questions = form.questions ?? [];
    questions.forEach((question) => {
      if (question.type !== "choice") return;
      const choices = question.data;
      this.questions.push(
        new FormGroup({
          id: new FormControl<string | null | undefined>(question.id),
          title: new FormControl(question.title, {nonNullable: true}),
          data: new FormArray(choices.options.map((choice) => new FormControl(choice.value, {nonNullable: true}))),
        }),
      );
    });
    markAllAsPristine(this.form);
  }

  getData() {
    this.loading = true;
    combineLatest([this.formSvc.getTemplates({filter: {tags: ["evaluation"]}}), this.eventSvc.getEvent(this.event_id)])
      .pipe(
        tap(([templates, event]) => {
          this.templates = templates;
          this.event = event;
        }),
        switchMap(([, event]) => {
          return event.evaluation_form_id ? this.formSvc.getForm(event.evaluation_form_id) : of(null);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((form) => {
        if (form) {
          this.buildForm(form);
        }
        this.loading = false;
      });
  }

  copyQuestion(questionIndex: number, event?: globalThis.Event, panel?: MatExpansionPanel) {
    if (event && panel) {
      event.stopPropagation();
      panel.close();
    }
    const question = this.questions.at(questionIndex);
    if (question) {
      const rawValue = question.getRawValue();
      const copy = new FormGroup({
        id: new FormControl<string | null | undefined>(null),
        title: new FormControl(rawValue.title, {nonNullable: true}),
        data: new FormArray<FormControl<string>>(
          rawValue.data.map((choice) => new FormControl(choice, {nonNullable: true})),
        ),
      });
      this.questions.push(copy);
      markAllAsDirty(copy);
      this.cdRef.detectChanges();
    }
  }

  deleteQuestion(index: number) {
    this.questions.removeAt(index);
    updateFormStatus(this.form);
  }

  deleteChoice(questionIndex: number, choiceIndex: number) {
    const question = this.questions.at(questionIndex);
    if (!question) return;

    question.controls.data.removeAt(choiceIndex);

    updateFormStatus(this.form);
  }

  createQuestion() {
    const question = new FormGroup({
      id: new FormControl<string | null | undefined>(null),
      title: new FormControl("", {nonNullable: true}),
      data: new FormArray<FormControl<string>>([]),
    });
    this.questions.push(question);
    markAllAsDirty(question);
    this.cdRef.detectChanges();
  }

  createChoice(questionIndex: number, event?: globalThis.Event, panel?: MatExpansionPanel) {
    if (event && panel) {
      event.stopPropagation();
      panel.open();
    }
    const question = this.questions.at(questionIndex);
    if (!question) return;

    const choice = new FormControl("", {nonNullable: true});
    question.controls.data.push(choice);
    markAllAsDirty(choice);
    this.cdRef.detectChanges();
  }

  clearForm() {
    this.selectedTemplate = null;
    this.questions.clear();
    markAllAsDirty(this.form);
  }

  useTemplate() {
    if (!this.selectedTemplate) return;

    this.loading = true;
    this.formSvc
      .getForm(this.selectedTemplate)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (form) => {
          this.buildForm(form);
          this.form.markAsDirty();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  drop(event: CdkDragDrop<QuestionFormGroup>) {
    moveItemInArray(this.questions.controls, event.previousIndex, event.currentIndex);
  }

  prepareForm() {
    const form = this.form.getRawValue();

    const body: UpdateFormRequestBody = {
      title: `نموذج تقييم ${this.event.type_ar} ${this.event.title}`,
      graded: false,
      template: false,
      tags: ["evaluation"],
      questions: form.questions.map((question, index) => ({
        id: question.id,
        title: question.title,
        data: {
          type: "radio",
          options: question.data.map((choice) => ({value: choice})),
        } as FormChoiceQuestionData,
        grading: null,
        order: index + 1,
        type: "choice",
        description: null,
      })),
    };

    return body;
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    if (this.event.evaluation_form_id) {
      this.formSvc
        .updateForm(this.event.evaluation_form_id, this.prepareForm())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (form) => {
            this.buildForm(form);
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.formSvc
        .createForm(this.prepareForm())
        .pipe(
          tap((form) => {
            this.buildForm(form);
          }),
          switchMap((form) => {
            return this.eventSvc.updateEvent(this.event_id, {evaluation_form_id: form.id});
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe({
          next: (event) => {
            this.event = event;
            this.toastSvc.success("تم تنفيذ الأمر بنجاح");
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  saveAsTemplate() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.prepareForm();
    form.template = true;
    this.formSvc
      .createForm(form)
      .pipe(
        switchMap(() => {
          return this.formSvc.getTemplates({filter: {tags: ["evaluation"]}});
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (templates) => {
          this.templates = templates;
          this.toastSvc.success("تم تنفيذ الأمر بنجاح");
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  isDirty() {
    return this.form.dirty;
  }
}
