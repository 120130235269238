<mtx-loader [loading]="loading">
  <form #ngForm="ngForm" [formGroup]="form" autocomplete="off">
    <div class="row mb-5">
      <div class="col-md-6 order-1 order-md-0">
        <mat-form-field appearance="outline" class="w-100 mt-2 mt-md-0" subscriptSizing="dynamic">
          <mat-select
            (selectionChange)="useTemplate()"
            [(value)]="selectedTemplate"
            placeholder="اختر نموذج استمارة التقييم"
          >
            <mat-option class="model-option" *ngFor="let template of templates" [value]="template.id">
              {{ template.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6 d-flex justify-content-end align-items-center gap-2 order-0 order-md-1">
        <button (click)="clearForm()" mat-flat-button color="accent">تفريغ</button>
        <button (click)="saveAsTemplate()" mat-flat-button color="accent">حفظ كقالب</button>
        <button (click)="save()" mat-flat-button color="primary">حفظ</button>
      </div>
    </div>
    <mat-accordion formArrayName="questions" cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-expansion-panel
        *ngFor="let question of questions.controls; index as questionIndex"
        [formGroup]="question"
        cdkDrag
        hideToggle
        #panel
      >
        <div class="drag-placeholder" *cdkDragPlaceholder></div>

        <mat-expansion-panel-header>
          <mat-panel-title>
            <button matTooltip="اسحب لإعادة الترتيب" matTooltipPosition="above" mat-icon-button matSuffix cdkDragHandle>
              <mat-icon>drag_handler</mat-icon>
            </button>
            <span
              *ngIf="errorStateMatcher.isErrorState(question, ngForm)"
              class="bg-danger rounded-circle me-2"
              style="width: 5px; height: 5px"
            ></span>
            <span [ngClass]="{'text-danger fw-bolder': errorStateMatcher.isErrorState(question, ngForm)}">
              سؤال {{ questionIndex + 1 }}
            </span>
          </mat-panel-title>
          <mat-panel-description class="d-flex justify-content-between">
            <div>{{ question.value.title }}</div>
            <div *ngIf="!panel.expanded">
              <button
                matTooltip="إضافة إختيار"
                matTooltipPosition="above"
                mat-icon-button
                matSuffix
                (click)="createChoice(questionIndex, $event, panel)"
              >
                <mat-icon>add</mat-icon>
              </button>
              <button
                matTooltip="استنساخ السؤال"
                matTooltipPosition="above"
                mat-icon-button
                matSuffix
                (click)="copyQuestion(questionIndex, $event, panel)"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              <button
                matTooltip="حذف السؤال"
                matTooltipPosition="above"
                mat-icon-button
                matSuffix
                (click)="deleteQuestion(questionIndex)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>نص السؤال</mat-label>
          <input matInput type="text" placeholder="ادخل نص السؤال" [formControl]="question.controls.title" />

          <mat-error *ngIf="question.controls.title.invalid">{{ question.controls.title.errors | error }}</mat-error>
        </mat-form-field>

        <mat-card-content
          *ngIf="question.controls.data.length > 0; then choicesTemplate; else noChoicesTemplate"
        ></mat-card-content>
        <ng-template #choicesTemplate>
          <mat-card-content>
            <h5 class="mb-4">الإختيارات</h5>
            <div class="row">
              <div *ngFor="let choice of question.controls.data.controls; index as choiceIndex" class="col-3">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>الإختيار {{ choiceIndex + 1 }}</mat-label>
                  <input matInput type="text" placeholder="ادخل نص الإختيار" [formControl]="choice" />

                  <span matTextPrefix class="me-2 fw-bold">{{ choiceIndex + 1 }}.</span>
                  <button mat-icon-button matSuffix (click)="deleteChoice(questionIndex, choiceIndex)">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <mat-error *ngIf="choice.invalid">{{ choice.errors | error }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <mat-divider></mat-divider>
          </mat-card-content>
        </ng-template>
        <ng-template #noChoicesTemplate>
          <mat-card-content class="text-center">
            <h5>لا توجد إختيارات</h5>
          </mat-card-content>
        </ng-template>

        <mat-card-actions class="d-flex justify-content-end">
          <button
            matTooltip="إضافة إختيار"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            (click)="createChoice(questionIndex)"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            matTooltip="استنساخ السؤال"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            (click)="copyQuestion(questionIndex)"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
          <button
            matTooltip="حذف السؤال"
            matTooltipPosition="above"
            mat-icon-button
            matSuffix
            (click)="deleteQuestion(questionIndex)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-card-actions>
      </mat-expansion-panel>
    </mat-accordion>
    <button class="mt-3 w-100" mat-flat-button (click)="createQuestion()">
      <span>إضافة المزيد</span>
      <mat-icon>add</mat-icon>
    </button>
  </form>
</mtx-loader>
