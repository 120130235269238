<mtx-loader [loading]="loading">
  <form #ngForm="ngForm" class="p-5" [formGroup]="form" (ngSubmit)="save()">
    <div class="d-flex justify-content-between">
      <h2>{{ !badge_id ? "إضافة" : "تعديل" }} بادج</h2>
      <div class="d-flex gap-2">
        <a *ngIf="previewUrl" [href]="previewUrl" mat-flat-button color="accent" target="_blank">استعراض</a>
        <button mat-flat-button color="primary" type="submit">حفظ</button>
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>اسم البادج</mat-label>
            <input formControlName="name" type="text" matInput placeholder="اسم البادج" />
            <mat-error *ngIf="name.invalid">{{ name.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-6 d-flex flex-column justify-content-between align-items-center my-4 gap-3">
          <img
            image
            [src]="imageSrc"
            style="height: 200px"
            class="img-fluid"
            alt="thumbnail"
            [ngClass]="{'border border-2 border-danger': errorStateMatcher.isErrorState(image_id, ngForm)}"
          />
          <input #fileInput type="file" class="visually-hidden" (change)="onFileChange($event, image_id)" />
          <p *ngIf="errorStateMatcher.isErrorState(image_id, ngForm)" class="text-danger m-0">يرجى إختيار صورة البادج</p>
          <button mat-flat-button (click)="fileInput.click()" color="accent" class="w-100" type="button">
            اختر صورة البادج
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h5>الإعدادات</h5>
          <h5 *ngIf="!fields.controls.length" class="text-danger">لا توجد إعدادات لحقول البادج.</h5>
          <div
            class="row border border-2 pt-4"
            *ngFor="let field of fields.controls; index as fieldIndex"
            [formGroup]="field"
          >
            <div class="col-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>الحقل</mat-label>
                <mat-select [formControl]="field.controls.name">
                  <mat-option *ngFor="let name of names" [value]="name.value" [disabled]="selected(name.value)">
                    {{ name.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="field.controls.name.invalid">{{ field.controls.name.errors | error }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Top</mat-label>
                <input [formControl]="field.controls.top" type="text" matInput placeholder="Top" />
                <mat-error *ngIf="field.controls.top.invalid">{{ field.controls.top.errors | error }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Left</mat-label>
                <input [formControl]="field.controls.left" type="text" matInput placeholder="Left" />
                <mat-error *ngIf="field.controls.left.invalid">{{ field.controls.left.errors | error }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <button
                matTooltip="حذف الحقل"
                matTooltipPosition="above"
                mat-icon-button
                (click)="deleteField(fieldIndex)"
                type="button"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <button *ngIf="hasRemainingField" class="mt-3 w-100" mat-flat-button (click)="pushNewField()" type="button">
            <span>إضافة المزيد</span>
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <div class="col-12 mt-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>الحالة</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let s of statuses" [value]="s.value">
                {{ s.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="status.invalid">{{ status.errors | error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mtx-loader>
