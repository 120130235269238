<ng-template #form let-i="index" let-id="id">
  <form #ngForm="ngForm" [formGroup]="reviewForm" class="my-3">
    <div class="input-group">
      <div class="rating">
        <input type="radio" name="rating" [value]="5" id="5" formControlName="rating" />
        <label for="5">☆</label>
        <input type="radio" name="rating" [value]="4" id="4" formControlName="rating" />
        <label for="4">☆</label>
        <input type="radio" name="rating" [value]="3" id="3" formControlName="rating" />
        <label for="3">☆</label>
        <input type="radio" name="rating" [value]="2" id="2" formControlName="rating" />
        <label for="2">☆</label>
        <input type="radio" name="rating" [value]="1" id="1" formControlName="rating" />
        <label for="1">☆</label>
      </div>
      <div *ngIf="reviewForm.controls.rating.invalid" class="invalid-feedback">
        {{ reviewForm.controls.rating.errors | error }}
      </div>
    </div>
    <div class="mt-3">
      <textarea
        rows="3"
        class="form-control focus-shadow-none rounded-0 py-3"
        placeholder="التعليق"
        formControlName="content"
        [ngClass]="{'is-invalid': errorStateMatcher.isErrorState(reviewForm.controls.content, ngForm)}"
      ></textarea>
      <div *ngIf="reviewForm.controls.content.invalid" class="invalid-feedback">
        {{ reviewForm.controls.content.errors | error }}
      </div>
    </div>
    <div *ngIf="!id" class="mt-3 d-flex gap-3 justify-content-end">
      <button [disabled]="loading" class="btn btn-primary px-5 py-3 rounded-0" (click)="save(i)">إرسال</button>
      <button [disabled]="loading" (click)="toggleReviewForm(i)" class="btn btn-outline-secondary px-5 py-3 rounded-0">
        إلغاء
      </button>
    </div>
    <div *ngIf="id" class="mt-3 d-flex gap-3 justify-content-end">
      <button [disabled]="loading" class="btn btn-primary px-5 py-3 rounded-0" (click)="saveUserReview()">إرسال</button>
      <button [disabled]="loading" (click)="toggleUserReviewForm()" class="btn btn-outline-secondary px-5 py-3 rounded-0">
        إلغاء
      </button>
    </div>
  </form>
</ng-template>

<div class="p-4">
  <mtx-loader [loading]="loading">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div *ngIf="!showReviewForm" class="text-end mt-4">
          <button *ngIf="!review" class="btn btn-outline-secondary px-5 py-3 rounded-0" (click)="toggleReviewForm()">
            <fa-icon [icon]="faPlus" class="me-3"></fa-icon>
            إضافة تقييم
          </button>
        </div>

        <div *ngIf="showReviewForm" class="row gap-3">
          <div class="col-12">
            <ng-container *ngTemplateOutlet="form; context: {index: undefined}"></ng-container>
          </div>
        </div>

        <div *ngIf="!!review">
          <h5 class="fw-bold">تقييمى</h5>
          <div class="card rounded-0 py-3 mt-3 bg-dark bg-opacity-10 border-0">
            <div class="card-body d-flex gap-2">
              <img
                image
                [src]="review.user?.photo?.source"
                style="width: 50px; height: 50px"
                [alt]="review.user?.full_name"
                class="rounded-circle mx-3"
              />

              <div class="flex-grow-1">
                <app-rating [rating]="review.rating"></app-rating>
                <p class="overflow-anywhere">{{ review.content }}</p>

                <p class="mb-0">
                  <span class="me-4 fw-light">{{ review.user?.full_name }}</span>
                  <span class="me-4 fw-light">{{ review.date_created | date: "yyyy/MM/dd" }}</span>
                </p>

                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center gap-2">
                    {{ review.likes }}
                    <button class="btn border-0" (click)="doAction(review, 'like')">
                      <fa-icon [icon]="faThumbsUp"></fa-icon>
                    </button>
                    <button class="btn border-0" (click)="doAction(review, 'dislike')">
                      <fa-icon [icon]="faThumbsDown"></fa-icon>
                    </button>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <button class="btn" (click)="toggleUserReviewForm()">
                      <fa-icon [icon]="faPen" title="تعديل" class="text-secondary"></fa-icon>
                    </button>
                    <button class="btn" (click)="deleteUserReview()">
                      <fa-icon [icon]="faTrash" title="حذف" class="text-danger"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showUserReviewForm" class="row">
            <div class="col-12">
              <ng-container *ngTemplateOutlet="form; context: {id: review.id}"></ng-container>
            </div>
          </div>

          <hr />
        </div>

        <form class="row gap-3 mt-3" [formGroup]="filterForm">
          <div class="col-md-6">
            <div class="input-group">
              <select
                class="form-select rounded-0 focus-shadow-none text-l-gray py-3"
                formControlName="sort"
                (change)="getReviews()"
              >
                <option selected [ngValue]="'date_created'">ترتيب حسب الأحدث</option>
                <option [ngValue]="'rating'">ترتيب حسب الأعلى تقييمًا</option>
                <option [ngValue]="'likes'">ترتيب حسب الإعجابات</option>
              </select>
            </div>
          </div>
        </form>

        <p *ngIf="reviews && reviews.length > 0" class="fw-light mt-5">جميع التقييمات المتاحة ({{ totalRows }})</p>

        <ng-container *ngFor="let review of reviews; index as reviewIndex">
          <div class="card rounded-0 py-3 bg-dark bg-opacity-10 border-0">
            <div class="card-body d-flex gap-2">
              <img
                image
                [src]="review.user?.photo?.source"
                style="width: 50px; height: 50px"
                [alt]="review.user?.full_name"
                class="rounded-circle mx-3"
              />

              <div class="flex-grow-1">
                <app-rating [rating]="review.rating"></app-rating>
                <p class="overflow-anywhere">{{ review.content }}</p>

                <p class="mb-0">
                  <span class="me-4 fw-light">{{ review.user?.full_name }}</span>
                  <span class="me-4 fw-light">{{ review.date_created | date: "yyyy/MM/dd" }}</span>
                </p>

                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center gap-2">
                    {{ review.likes }}
                    <button class="btn border-0" (click)="doAction(review, 'like')">
                      <fa-icon [icon]="faThumbsUp"></fa-icon>
                    </button>
                    <button class="btn border-0" (click)="doAction(review, 'dislike')">
                      <fa-icon [icon]="faThumbsDown"></fa-icon>
                    </button>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <button
                      *ngIf="user && (user.role === 'admin' || review.user_id === user.id)"
                      [disabled]="currentToUpdateReview === review.id"
                      class="btn"
                      (click)="toggleReviewForm(reviewIndex)"
                    >
                      <fa-icon [icon]="faPen" title="تعديل" class="text-secondary"></fa-icon>
                    </button>
                    <button
                      *ngIf="user && (user.role === 'admin' || review.user_id === user.id)"
                      class="btn"
                      (click)="deleteReview(reviewIndex)"
                    >
                      <fa-icon [icon]="faTrash" title="حذف" class="text-danger"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentToUpdateReview === review.id" class="row">
            <div class="col-12">
              <ng-container *ngTemplateOutlet="form; context: {index: reviewIndex}"></ng-container>
            </div>
          </div>
        </ng-container>

        <button
          *ngIf="hasNextPage"
          (click)="getNextPage()"
          class="btn btn-outline-secondary rounded-0 text-center mt-3 py-4 w-100"
        >
          المزيد
        </button>

        <div *ngIf="!reviews || !reviews.length" class="text-center mt-5 bg-primary text-white py-4">لا توجد تقييمات</div>
      </div>
    </div>
  </mtx-loader>
</div>
